import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSurveyScale } from '../../services/surveyScale.service';
import { SearchSurveyScaleAnswer } from './searchSurveyScaleAnswer';
import { EditSurveyScaleAnswer } from './editSurveyScaleAnswer';
import {FaPlus, FaSave, FaTimesCircle} from "react-icons/fa";
import {Button, Col, Form, Input, Row} from "antd";

export function EditSurveyScale(props) {
    const [surveyScale, setSurveyScale] = useState({
        SurveyScaleId: 0,
        Description: "",
        Active: true
    });
    const [refreshAnswer, setRefreshAnswer] = useState(0);
    const [showEditAnswer, setShowEditAnswer] = useState(false);
    const [selectedAnswer, setSelectedAnswer] = useState();
    const { surveyId, id } = useParams();

    const onSave = () => {
        if (surveyScale.SurveyScaleId && surveyScale.SurveyScaleId > 0)
            useSurveyScale.updateSurveyScale(surveyScale);
        else {
            useSurveyScale.createSurveyScale(surveyScale).then(data => {
                setSurveyScale({
                    SurveyScaleId: data.surveyScaleId,
                    Description: data.description,
                    Active: data.active
                });
            });
        }
    };

    const handleInputValue = (e) => setSurveyScale({...surveyScale, [e.target.name]: e.target.value})

    useEffect(() => {
        if (parseInt(id) !== 0) {
            useSurveyScale.getSurveyScaleById(id).then((result) => {
                setSurveyScale({
                    SurveyScaleId: result.surveyScaleId,
                    Description: result.description,
                    Active: result.active
                });
            });
        }

        setRefreshAnswer(prev => prev + 1);
        setSelectedAnswer(null);
        setShowEditAnswer(false);
    }, [props.survey]);

    const onSelectAnswer = (scaleAnswer) => {
        setShowEditAnswer(true);
        setSelectedAnswer({
            SurveyScaleAnswerId: scaleAnswer.surveyScaleAnswerId,
            SurveyScaleId: scaleAnswer.surveyScaleId,
            Description: scaleAnswer.description,
            ScaleValue: scaleAnswer.scaleValue,
            AnswerOrder: scaleAnswer.answerOrder,
            Active: scaleAnswer.active
        });
    }
    const handleOnNewScaleAnswer = () => {
        setSelectedAnswer(null);
        setShowEditAnswer(true);
    };
    const handleOnCancelEditQuestion = () => {
        setSelectedAnswer(null);
        setShowEditAnswer(false);
    }
    const handleOnSaveEditQuestion = () => {
        setRefreshAnswer(prev => prev + 1);
        setShowEditAnswer(false);
    }

    return (
        <div className="dashboard-admin-container">
            <Form
                onFinish={onSave}
                className="form-backoffice"
                layout={'vertical'}
                fields={[
                    {
                        "name": ["Description"],
                        "value": surveyScale.Description
                    },
                ]}
            >
                <Row gutter={16}>
                    <Col xs={24}>
                        <Form.Item
                            label={'Descripción'}
                            name="Description"
                            rules={[
                                {
                                    required: true,
                                    message: '*Campo Requerido'
                                }
                            ]}
                        >
                            <Input
                                type="text"
                                name="Description"
                                onChange={handleInputValue}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <div className="form-backoffice-footer">
                            <Button
                                type={'primary'}
                                htmlType={'submit'}
                            >
                                <p>Guardar</p>
                                <FaSave />
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Form>
            {
                (surveyScale.SurveyScaleId !== 0) &&
                <Row gutters={16}>
                    <Col md={12} sm={24} xs={24}>
                        <div className="admin-heading mobile">
                            <h4>Lista de respuestas</h4>
                            {
                                (!showEditAnswer) ?
                                    <Button type={'primary'}
                                            htmlType={'button'} onClick={handleOnNewScaleAnswer}>
                                        <p>Agregar respuesta</p>
                                        <FaPlus />
                                    </Button>
                                    :
                                    <Button type={'primary'}
                                        htmlType="button"
                                        onClick={handleOnCancelEditQuestion}
                                    >
                                        <p>Cancelar</p>
                                        <FaTimesCircle />
                                    </Button>
                            }
                        </div>
                        {
                            showEditAnswer ?
                                <EditSurveyScaleAnswer
                                    surveyScaleId={surveyScale.SurveyScaleId}
                                    currentAnswer={selectedAnswer}
                                    onCancelEdit={handleOnCancelEditQuestion}
                                    onSave={handleOnSaveEditQuestion}
                                />
                                : null
                        }
                        <SearchSurveyScaleAnswer
                            surveyScale={{ surveyScaleId: props.surveyScaleId }}
                            onSelectAnswer={onSelectAnswer}
                            refresh={refreshAnswer}
                        />
                    </Col>
                </Row>
            }

        </div>
    );
}
