import React, {useEffect, useRef, useState} from 'react';
import {Header} from "../header";
import {Button, Table, Tooltip, Col, Row, Input, Space, Pagination} from "antd";
import {FaCopy, FaPaste, FaPen, FaTrash} from "react-icons/fa";
import {rewardProviderService} from "../../services/control/rewardProvider.service";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";

const MySwal = withReactContent(Swal)
export function AdminRewardProvider() {

    const [rewardsData, setRewardsData] = useState({ rewards: [] });
    const handleDelete = (id) => {
        MySwal.fire({
            title: '¿Estás seguro?',
            text: "No podrás revertir esta acción",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#FF002A',
            confirmButtonText: 'Eliminar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if(result.isConfirmed) {
                rewardProviderService.deleteProvider(id).then((result) => getRewardProviders());
            }
        })
    };

    useEffect(() => {
        getRewardProviders();
    }, []);

    async function getRewardProviders() {
        rewardProviderService.get().then((result) => {
            if (result) setRewardsData({
                rewards: result.sort((a,b) => {
                    return b.rewardProviderId - a.rewardProviderId
                })
            });
        });
    }

    const [rewardsUrlLogo, setRewardsUrlLogo] = useState({});

    useEffect(() => {
        setRewardsUrlLogo(rewardsData.rewards.map(reward => {
            return {
                id: reward.rewardProviderId,
                urlLogo: reward.urlLogo
            }
        }));
    },[rewardsData])

    const [urlCopied, setUrlCopied] = useState('');
    async function copyNumberCard(idProvider) {
        const currentUrlSelected = rewardsUrlLogo.filter(data => data.id === idProvider);
        try {
            await navigator.clipboard.writeText(currentUrlSelected.map(item => item.urlLogo));
            setUrlCopied(idProvider);
        } catch (e) {
            setUrlCopied('');
            throw e;
        } finally {
            setTimeout(() => {
                setUrlCopied('');
            }, 2000);
        }
    }

    const buttons = [
        {
            "id": 1,
            "nameButton": "Agregar proveedor",
            "link": "/operator/details-rewardProvider/0"
        }
    ];

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Buscar`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Buscar
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Limpiar
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        Cerrar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 100,
            defaultSortOrder: 'descend',
            sortDirections: ['descend','ascend'],
            showSorterTooltip: false,
            sorter: (a, b) => a.id - b.id,
            fixed: 'left',
        },
        {
            title: 'Nombre',
            dataIndex: 'name',
            key: 'name',
            sortDirections: ['descend','ascend'],
            showSorterTooltip: false,
            sorter: (a, b) => {
                if(a.name < b.name) {
                    return -1;
                }
                if(a.name > b.name) {
                    return 1;
                }
                return 0;
            },
            ...getColumnSearchProps('name')
        },
        {
            title: 'Url logotipo',
            dataIndex: 'logo',
            key: 'logo',
        },
        {
            title: 'Opciones',
            dataIndex: 'options',
            key: 'options',
            width: 150,
        }
    ];

    const data = rewardsData.rewards.map(reward => {
        return {
            key: reward.rewardProviderId,
            id: reward.rewardProviderId,
            name: reward.businessName,
            logo: <div className="logo">
                <Input type="text" value={reward.urlLogo} readOnly />
                <Button type="primary" htmlType="button"
                        onClick={() => copyNumberCard(reward.rewardProviderId)}
                >
                    {(urlCopied === reward.rewardProviderId) ? <FaPaste /> : <FaCopy />}
                </Button>
            </div>,
            options:
                <>
                    <Button
                        type="primary"
                        className="dark"
                        htmlType="button"
                        onClick={() => window.location.href=`/operator/details-rewardProvider/${reward.rewardProviderId}`}
                        style={{marginRight: '.5rem'}}
                        icon={<FaPen />}
                    ></Button>
                    <Tooltip placement="top" title='Eliminar'>
                        <Button
                            type="primary"
                            className="red"
                            htmlType="button"
                            onClick={() => handleDelete(reward.rewardProviderId)}
                            icon={<FaTrash />}
                        ></Button>
                    </Tooltip>
                </>
        }
    })

    const [currentPage, setCurrentPage] = useState(1)
    const [sizesPages, setSizesPages] = useState(10)

    const onChangePage = (page,pageSize) => {
        setCurrentPage(page) ;
        setSizesPages(pageSize);
    }

    return (
        <section className="dashboard-operator-body">
            <Header
                title="Control de Proveedores"
                description=""
                buttons={buttons}
                btnBack={[]}
            />
            <Row>
                <Col xs={24}>
                    <Table columns={columns}
                           dataSource={data.slice((currentPage - 1) * sizesPages, currentPage * sizesPages)}
                           scroll={{ x: '992px', y: 800 }}
                           pagination={false}
                           locale={{
                               triggerDesc: 'Ordenamiento descendente',
                               triggerAsc: 'Ordenamiento ascedente',
                               cancelSort: 'Cancelar ordenamiento',
                               emptyText: 'Sin registros',
                           }}
                    />
                    <Pagination
                        total={data.length}
                        defaultPageSize={sizesPages}
                        responsive={true}
                        defaultCurrent={1}
                        current={currentPage}
                        hideOnSinglePage={true}
                        onChange={onChangePage}
                        size={true}
                    />
                </Col>
            </Row>
        </section>
    );
}
