import React, { useState, useEffect } from "react";
import "./userActivity.scss";
import { backendService } from "../../services/backend.service";
import Moment from "react-moment";
import { Nav, NavItem, NavLink, TabContent, Table, TabPane } from "reactstrap";
import { Link } from "react-router-dom";
import "moment/locale/es";
import { directoryInfoService } from "../../services/directoryService.service";
import { postulanteService } from "../../services/postulantJob.service";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";

export function UserActivity() {
  const [activeTab, setActiveTab] = useState("1");

  const [userActivity, setUserActivity] = useState([]);
  const [userDirectry, setUserDirectory] = useState([]);
  const [userPostulant, setUserPostulant] = useState([]);

  useEffect(() => {
    backendService.userProfileActivity(false).then((data) => {
      setUserActivity(data);
    });

    directoryInfoService.getMyDirectory().then((data) => {
      setUserDirectory(data);
    });

    postulanteService.GetByUserId(localStorage["userId"]).then((data) => {
      setUserPostulant(data);
    });
  }, []);

  const deleteDirectory = (id) => {
    directoryInfoService.deleteDirectory(id)
      .then((dataDirectoryInfo) => {
        if (dataDirectoryInfo.active == false) {
          swal({ text: "El directorio ha sido dado de baja", icon: "success", closeOnClickOutside: false });
          directoryInfoService.getMyDirectory().then((dataDirectoryInfo) => {
            setUserDirectory(dataDirectoryInfo);
          });
        }
        else {
          swal({ text: "No se pudo dar de baja", icon: "error", closeOnClickOutside: false });
        }
      })
      .catch((error) => {
        swal({ text: "No se pudo dar de baja", icon: "error", closeOnClickOutside: false });
      });
  };

  return (
    <div className="tabs-user">
      <Nav tabs>
        <NavItem>
          <NavLink className={activeTab === "1" ? "active" : ""} onClick={() => setActiveTab("1")}>
            <span><i className="icon-capture"></i></span>
            <p>Actividades del usuario</p>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className={activeTab === "2" ? "active" : ""} onClick={() => setActiveTab("2")}>
            <span><i className="icon-help"></i></span>
            <p>Servicios publicados</p>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className={activeTab === "3" ? "active" : ""} onClick={() => setActiveTab("3")}>
            <span><i className="icon-docs-postuleds"></i></span>
            <p>Mis postulaciones</p>
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <div className="table-container table-responsive">
            <h5>Historial de actividades</h5>
            <Table className="table-activities" hover>
              <thead>
                <tr>
                  <th scope="col">Tipo de actividad</th>
                  <th scope="col" className="min_width">Actividad</th>
                  <th scope="col" className="min_width">Fecha de término</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(userActivity) &&
                  userActivity.map((item, index) => {
                    return (
                      <tr key={index + index}>
                        <td><p className="border-pointed">{item.type}</p></td>
                        <td>{item.name}</td>
                        <td>
                          <Moment locale="es" format="DD [de] MMMM [de] YYYY hh:mm [hrs]">{item.expirationDate}</Moment>
                        </td>
                      </tr>
                    );
                  })
                }
                {
                  (userActivity.length === 0) && (
                    <tr key={194378917234}>
                      <td colSpan={3} style={{ textAlign: 'center' }}>
                        <i className="icon-empty" style={{ fontSize: '8rem', padding: '1rem 0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}></i>
                        <p>No hay registros</p>
                      </td>
                    </tr>)
                }
              </tbody>
            </Table>
          </div>
        </TabPane>
        <TabPane tabId="2">
          <div className="table-container table-responsive">
            <h5>Lista de servicios anunciados</h5>
            <Table className="table-services-published" hover>
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Nombre completo</th>
                  <th scope="col">Categoría</th>
                  <th scope="col">Subcategoría</th>
                  <th scope="col" className="min_width">Vigencia</th>
                  <th scope="col">Status</th>
                  <th scope="col">Baja de Servicio</th>
                </tr>
              </thead>
              <tbody>
                {
                  Array.isArray(userDirectry) &&
                  userDirectry.map((p) => {
                    return (
                      <tr key={p.directoryInfoId}>
                        <td>{p.directoryInfoId}</td>
                        <td>
                          {p.directoryInfoStatusId === 6 || p.directoryInfoStatusId === 2 ? (
                            <Link
                              to={{
                                pathname: `/register-services/${p.directoryInfoId}`,
                                state: { isPay: true }
                              }}
                            >
                              <span className="link-data">{p.fullName}</span>{" "}
                            </Link>
                          ) : (
                            <span>{p.fullName}</span>
                          )}
                        </td>
                        <td><p className="border-solid">{p.categoryServiceName}</p></td>
                        <td>{p.subCategoryServiceName}</td>
                        <td>
                          <Moment locale="es" format="DD [de] MMMM [de] YYYY hh:mm [hrs]">{p.createDate}</Moment>
                        </td>
                        <td style={{ width: "15%" }}>
                          <span className={
                            p.directoryInfoStatusId === 1
                              ? "item_badge pending"
                              : p.directoryInfoStatusId === 2
                                ? "item_badge success"
                                : p.directoryInfoStatusId === 3
                                  ? "item_badge rejected"
                                  : "item_badge defeated"
                          }
                          >
                            {p.directoryInfoStatusName}
                          </span>
                        </td>
                        <td>
                          {p.directoryInfoStatusId === 2 && <button className="item_badge rejected" onClick={() => deleteDirectory(p.directoryInfoId)}>Dar de Baja</button>}
                        </td>
                      </tr>
                    )
                  })
                }
                {
                  (userDirectry.length === 0) && (
                    <tr key={117486329}>
                      <td colSpan={7} style={{ textAlign: 'center' }}>
                        <i className="icon-empty" style={{ fontSize: '8rem', padding: '1rem 0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}></i>
                        <p>No hay registros</p>
                      </td>
                    </tr>)
                }
              </tbody>
            </Table>
          </div>
        </TabPane>
        <TabPane tabId="3">
          <div className="table-container table-responsive">
            <h5>Lista de mis postulaciones</h5>
            <Table className="table-postulations" hover>
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col" className="min_width">Cargo o área </th>
                  <th scope="col">Lugar</th>
                  <th scope="col">Categoría</th>
                  <th scope="col">Empresa</th>
                  <th scope="col" className="min_width">Fecha de postulación</th>
                </tr>
              </thead>
              <tbody>
                {userPostulant.map((postulant, index) => {
                  return (
                    <tr key={postulant.jobId + index + index}>
                      <td>{index + 1}</td>
                      <td>
                        <Link to={'/details-job/' + postulant.jobId}>
                          <span className="link-data">{postulant.jobTitle}</span>
                        </Link>
                      </td>
                      <td>{postulant.jobLocation}</td>
                      <td>{postulant.jobCategory}</td>
                      <td>{postulant.jobCompanyName}</td>
                      <td><Moment locale="es" format="DD MMMM YYYY">{postulant.postulationDate}</Moment></td>
                    </tr>
                  )
                })
                }
                {
                  (userPostulant.length === 0) && (
                    <tr key={3198271}>
                      <td colSpan={6} style={{ textAlign: 'center' }}>
                        <i className="icon-empty" style={{ fontSize: '8rem', padding: '1rem 0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}></i>
                        <p>No hay registros</p>
                      </td>
                    </tr>)
                }
              </tbody>
            </Table>
          </div>
        </TabPane>
      </TabContent>
    </div>
  );
}
