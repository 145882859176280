import React, { useEffect, useState } from "react";
import "./prideMusic.scss";
import { Col, Row } from "antd";
import bannerMusic from "../../assets/imgs/banner-music.jpg";
import { FooterHome } from "../../components/footer/footerHome";
import PrideMusicCard from "../../components/GenericSection/prideMusicCard";
import { useGenericSection } from "../../services/genericSection.service";
import InfiniteScroll from "react-infinite-scroll-component";

export function PrideMusic() {
  const [data, setData] = useState([]);
  const [disabledHasMore, setDisabledHasMore] = useState(false);
  const loadMore = () => {
    if (data.length > 0) {
      const lastitem = data.slice(-1).pop();
      loadData(lastitem.genericSectionItemId);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const loadData = (id) => {
    useGenericSection.getGenericItemByCompanyAndSection(1, id).then(items => {
      if (items.length > 0) {
        const newItems = [...data, ...items];
        setData(newItems);
      } else {
        setDisabledHasMore(true);
      }
    });
  }

  return (
    <section className="dashboard-container-page">
      <Row gutter={16}>
        <Col xs={24}>
          <h2>Pride Music</h2>
        </Col>
        <Col xs={24}>
          <div className="drawer__bg__sections">
            <img
              src={bannerMusic}
              alt="Pride Music, Música por y para nuestra comunidad"
            />
          </div>
          {/* <div className="pride-music-header">
            <div
              className="pride-music-content"
              style={{ backgroundImage: `url(${bgHeader})` }}
            >
              <div className="pride-music-info">
                <h3>Música por y para nuestra comunidad</h3>
                <p>
                  ¿Conoces a algún artista o grupo LGBTQ+ que podríamos agregar
                  a esta sección? Notifícalo a Administrador PrideMx.
                </p>
              </div>
              <div className="pride-music-img">
                <img src={imgHeader} alt="" />
              </div>
            </div>
          </div> */}
        </Col>
        <Col xs={24}>
          <InfiniteScroll
            dataLength={data.length}
            next={() => loadMore()}
            hasMore={!disabledHasMore}
            loader={<h4>No tienes Pride Music previos</h4>}
            scrollableTarget="scrollableDiv"
            style={{overflow: 'unset !important'}}
          >
            <Row gutter={16}>
              {data.map((item, index) => {
                return (
                  <Col xl={8} lg={12} md={12} xs={24}>
                    <PrideMusicCard key={index} item={item} />
                  </Col>
                );
              })}
            </Row>
          </InfiniteScroll>
        </Col>
        <Col xs={24}>
          <FooterHome />
        </Col>
      </Row>
    </section>
  );
}
