import React from "react";
import imgBg from "../../assets/imgs/register/imgBg_register.png";

export const RegisterAside = () => {
  return (
    <article className="register__info">
      <img src={imgBg} alt="Regístrate en PrideMx y encuentra un mundo diseñado para ti." title="Registro PrideMx"/>
    </article>
  );
};
