import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./hubItemProvider.scss";
import { ecommerceService } from "../../services/ecommerce.service";
import {BsChevronLeft} from "react-icons/bs";

export class HubItemProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      providersOptions: [],
    };
  }

  componentWillMount() {
    ecommerceService.getProviders().then((result) => {
      this.setState({ providersOptions: result });
    });
  }

  render() {
    const { providersOptions } = this.state;
    return (
        <>
          {providersOptions.map((providersOption) => {
            return (
                <>
                  <Link to={"/provider/" + providersOption.id} className="discount-card">
                    <img src={providersOption.providerLogo} alt="" />
                  </Link>        
                </>
            );
          })}
        </>
    );
  }
}
