import React, { useState, useEffect } from "react";
import { HeadRewards } from "./headRewards";
import "./rewards.scss";
import { rewardsService } from "../../services/rewards.service";
import { FormGroup, Label, Input } from "reactstrap";
import { FooterHome } from "../../components/footer/footerHome";
import Moment from "react-moment";
import moment from "moment";
import "moment/locale/es";
import {Space, Table, Row, Col, Badge} from "antd";
import {Button} from "rsuite";

export function AccountStatus() {
  const [rewardsFilterByDate, setRewardsFilterByDate] = useState([]);
  const [reward, setReward] = useState([]);
  const [rewardsStatement, setRewardsStatement] = useState([]);

  useEffect(() => {
    rewardsService.rewardsFilterByDate().then((result) => {
      setRewardsFilterByDate(result);
      getRewardsStatement(result[0].startDate + "|" + result[0].endDate);
    });
  }, []);

  const getRewardsStatement = async (dateRange) => {
    rewardsService.rewardsStatement(dateRange).then((result) => {
      setReward(result.reward);
      setRewardsStatement(result.items);
    });
  };

  const columnsStatus = [
    {
      title: 'Fecha de movimiento',
      dataIndex: 'date',
      key: 'date',
      sorter: (a, b) =>  moment(a.date.props.children).unix() - moment(b.date.props.children).unix(),
    },
    {
      title: 'Tipo de Movimiento',
      dataIndex: 'action',
      key: 'action',
    },
    {
      title: 'Concepto',
      dataIndex: 'concept',
      key: 'concept',
    },

    {
      title: 'Monto de movimiento',
      dataIndex: 'options',
      key: 'options',
    },
  ];


  const dataStatus = rewardsStatement.map(item => {
    return {
      key: item.id,
      date: <Moment format="DD/MM/YYYY">{item.date}</Moment>,
      action: item.action,
      concept: item.concept,
      options: <><Space>
        <Badge count={item.reward} color='#FF7400' />
      </Space></>
    };
  })

  return (
    <section className="dashboard-container-page">
      <HeadRewards reward={reward} />
      <div className="container-redemption">
        <Row>
          <Col xs={24}><h3>Estado de cuentas</h3></Col>
          <Col xs={24}>
            <div className="container-redemption-header">
              <FormGroup>
                <Label for="exampleSelectMulti">Filtro de fechas</Label>
                <Input
                  type="select"
                  onChange={(event) => getRewardsStatement(event.target.value)}
                >
                  {rewardsFilterByDate.map((item) => (
                    <option value={item.startDate + "|" + item.endDate}>
                      {moment(item.startDate).format("MMMM YYYY")} -{" "}
                      {moment(item.endDate).format("MMMM YYYY")}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </div>
          </Col>
          <Col xs={24}>
            <div className="container-redemption-table table-responsive">
              <Table
                columns={columnsStatus}
                dataSource={dataStatus}
                className="table-rewards"
                scroll={{ x: '992px', y: 800 }}
                locale={{
                  triggerDesc: 'Ordenamiento descendente',
                  triggerAsc: 'Ordenamiento ascedente',
                  cancelSort: 'Cancelar ordenamiento',
                  emptyText: 'Sin registros',
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
      <Row>
        <Col xs={24}>
          <FooterHome />
        </Col>
      </Row>
    </section>
  );
}
