import React, { Fragment, useEffect, useState } from "react";
import useInputSingleForm from "../components/CustonHooks/inputFormHook";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { callApi } from './service'

export const metaPixelService = {
    trackEvent,
    saveEvent,
    customEvent,
    simpleEvent
}

const token = process.env.REACT_APP_PIXEL_TOKEN
const pixelId = process.env.REACT_APP_PIXEL_ID

const testData = {
    "id": 0,
    "section": 'LearningCenter',
    "content_type": 'Link',
}

async function trackEvent(type, name, data, currentUrl) {
    window.fbq("init", pixelId);
    window.fbq(type, name, data);

    var params = {
        type: name,
        pixelData: data,
        url: currentUrl
    }

    callApi('/backend/PixelTrack', 'POST', params, {
        SkipValidationToken: true,
      }).then((result) => {
        if (result.responseCode !== '00') {
            console.log('error',result);
        }
    });
}

async function saveEvent(type, name, data) {
    data = {...data, access_token: token}
    window.fbq("init", pixelId);
    window.fbq(type, name, data);
}

async function customEvent(type, name, data = null) {
    window.fbq("init", pixelId);
    if(data){
        window.fbq(type, name, data);
    } else {
        window.fbq(type, name);
    }
}

async function simpleEvent(type, name, testCode = null) {
    window.fbq("init", pixelId);
    if(testCode){
        window.fbq(type, name, {test_event_code: testCode});
    } else {
        window.fbq(type, name);
    }
    var params = {
        type: name,
        pixelData: {}
    }
    callApi('/backend/PixelTrack', 'POST', params).then((result) => {
        if (result.responseCode !== '00') {
            console.log('error',result);
        }
    });
}

// fbq('track', 'ViewContent', {
//     content_name: 'Content Title',
//     content_category: 'Learning',
//     content_ids: ['1234'],
//     content_type: 'product',
//     value: 0.50,
//     currency: 'MXN'
//    }); {test_event_code: 'test123'}
