import React, { useRef, useState } from 'react';
import SearchDeparment from './searchDepartment';
import { deparmentService } from '../../services/deparment.service';
import { useSelector } from 'react-redux';
import {FaSave, FaTimesCircle} from "react-icons/fa";
import {Button, Col, Form, Input, Row} from "antd";
import {HeadingAdmin} from "../components/headingAdmin/headingAdmin";
import {TitleAdmin} from "../components/titleAdmin/titleAdmin";
import {PlusCircleOutlined} from "@ant-design/icons";
import MySwal from "sweetalert2";

export const AdminDeparment = () => {
	const companyId = useSelector(state => state.userSettings.companyId)
	const gridRef = useRef();
	const [deparmentInit,setDepartmentInit] = useState({
		DepartmentId: 0,
		CompanyId: 0,
		DepartmentName: ''
	});
	const [showEdit, setShowEdit] = useState(false);

	const onSave = () => {
		if (parseInt(deparmentInit.DepartmentId) === 0) {
			deparmentService.createDeparment(deparmentInit).then((result) => {
				gridRef.current.refreshDataGrid();
				MySwal.fire({
					title: "Grupo creado exitosamente",
					icon: "success"
				});
			});
		} else {
			deparmentService.updateDeparment(deparmentInit).then((result) => {
				gridRef.current.refreshDataGrid();
				MySwal.fire({
					title: "Grupo actualizado exitosamente",
					icon: "success"
				});
			});
		}
		setDepartmentInit({
			DepartmentId: 0,
			CompanyId: 0,
			DepartmentName: ''
		});
		setShowEdit(false);
	};

	const handleOnNewDeparment = () => {
		setDepartmentInit({
			DepartmentId: 0,
			CompanyId: companyId,
			DepartmentName: ''
		});
		setShowEdit(true);
	};

	const handleModeEdit = () => setShowEdit(!showEdit);

	const handleRowSelect = (gridItem) => {
		setShowEdit(true);
		setDepartmentInit({
			DepartmentId: gridItem.departmentId,
			CompanyId: gridItem.companyId,
			DepartmentName: gridItem.departmentName
		});
	}

	const handleInputValue = (e) => setDepartmentInit({...deparmentInit, [e.target.name]: e.target.value})

	return (
		<>
			<TitleAdmin
				title={'Lista de Grupos'}
				subtitle={'Listado y alta de grupos'}
				btnBack={false}

			/>
			<HeadingAdmin
				text={!(showEdit) ? 'Agregar grupo' : 'Cancelar'}
				icon={!(showEdit) ? <PlusCircleOutlined /> : <FaTimesCircle />}
				event={() => !(showEdit) ? handleOnNewDeparment() : handleModeEdit()}
			/>
			<Row gutter={16}>
				<Col xs={24}>
					{(showEdit) ?
						<Form
							onFinish={onSave}
							layout={'vertical'}
							className={'form-backoffice'}
							fields={[
								{
									name: ['DepartmentName'],
									value: deparmentInit.DepartmentName
								}
							]}
						>
							<h3>{deparmentInit.DepartmentId === 0 ? "Nuevo grupo" : "Editar grupo"}</h3>
							<Row>
								<Col md={12} sm={12} xs={24}>
									<Form.Item
										label={'Grupo'}
										name="DepartmentName"
										rules={[
											{
												required: true,
												message: '*Campo requerido'
											},
											{
												pattern: new RegExp(/^\S/),
												message: "No se aceptan espacios en blanco al inicio",
											},
										]}
									>
										<Input
											name="DepartmentName"
											onChange={handleInputValue}
										/>
									</Form.Item>
								</Col>
								<Col xs={24}>
									<div className="form-backoffice-footer">
										<Button type="primary" htmlType={'submit'}>
											<p>Guardar</p>
											<FaSave />
										</Button>
									</div>
								</Col>
							</Row>
						</Form>
						:
						<SearchDeparment handleRowSelect={handleRowSelect} ref={gridRef} />
					}
				</Col>
			</Row>

		</>
	);
}
