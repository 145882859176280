import React from "react";
import { Button, Card } from "antd";
import { FaChevronLeft } from "react-icons/fa";

export const HeaderCourse = ({ event, title }) => {
  return (
    <div className={"event-pride-approve-header"}>
      <Button onClick={event} className="btn-back-post">
        <FaChevronLeft />
      </Button>
      <h2 className={"course-view-title"}>{title}</h2>
    </div>
  );
};
