import React, {useEffect, useState, forwardRef, useImperativeHandle, useRef} from 'react';
import { adminAudienceService } from '../../services/adminAudience.service';
import {FaEdit, FaTrash} from "react-icons/fa";
import {Button, Input, Space, Table, Tag, Tooltip} from "antd";
import MySwal from "sweetalert2";
import {SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import moment from "moment/moment";
import dayjs from "dayjs";

const SearchAdminAudience = forwardRef((props, ref) => {

    const [dataGrid, setDataGrid] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    useImperativeHandle(ref, () => ({
        refreshDataGrid: refreshDataGrid
    }));

    const handleDelete = (data) => {
        MySwal.fire({
            title: '¿Estás seguro?',
            text: "No podrás revertir esta acción",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Eliminar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if(result.isConfirmed)
                adminAudienceService.deletet(data).then((result) => {
                    getAll();
                    MySwal.fire({
                        title: "¡Se eliminó correctamente!",
                        icon: "success",
                        confirmButtonText: "Confirmar"
                    });
                });
        })
    };

    async function getAll() {
        adminAudienceService.getAll()
            .then((result) => {
                if (result) setDataGrid(result);
            });
    }

    const refreshDataGrid = () => getAll();

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{padding: 8,}} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Buscar`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Buscar
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Limpiar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const columns = [
        {
            title: 'Título del evento',
            key: 'title',
            dataIndex: 'title',
            ...getColumnSearchProps('title')
        },
        {
            title: 'Fecha de evento',
            key: 'date',
            dataIndex: 'date',
            defaultSortOrder: 'descend',
            sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix()
        },
        {
            title: 'Recompensa',
            key: 'reward',
            dataIndex: 'reward',
            sorter: (a, b) => {
                if(a.reward < b.reward) {
                    return -1;
                }
                if(a.reward > b.reward) {
                    return 1;
                }
                return 0;
            },
            sortDirections: ['descend','ascend'],
        },
        {
            title: 'URL',
            key: 'url',
            dataIndex: 'url'
        },
        {
            title: 'Opciones',
            key: 'options',
            dataIndex: 'options',
        },
    ];

    const data = dataGrid.map((item,i) => {
        return (
            {
                key: i,
                title: item.title,
                date: dayjs(item.conferenceDate).format('DD/MMM/YYYY'),
                reward: item.reward,
                url: <Tooltip title={item.conferenceUrl}>
                   <a target={'_blank'} href={item.conferenceUrl}>{(item.conferenceUrl.length > 40) ? item.conferenceUrl.substring(0, 40)+'...' : item.conferenceUrl}</a>
                </Tooltip>,
                status: <Tag color={item.active ? 'green' : 'red'}>{item.active ? 'Activo' : 'Inactivo'}</Tag>,
                options: <Space wrap>
                    <Button type="primary" htmlType={'button'} className="edit" onClick={() => props.handleRowSelect(item)}>
                        <FaEdit />
                    </Button>
                    <Button type="primary" htmlType={'button'} className="delete" onClick={() => handleDelete(item)}>
                        <FaTrash />
                    </Button>
                </Space>
            }
        );
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    useEffect(() => {
        getAll();
    }, []);

    return (
        <Table
            className="table-admin"
            dataSource={data}
            columns={columns}
            locale={{
                triggerDesc: 'Ordenamiento descendente',
                triggerAsc: 'Ordenamiento ascedente',
                cancelSort: 'Cancelar ordenamiento',
                emptyText: 'Sin registros',
            }}
            scroll={{ x: '992px', y: 800 }}
        />
    );

});

export default SearchAdminAudience;
