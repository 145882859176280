import React from 'react'
import LogoPrideCom from "../../assets/imgs/R_C.png";
import { ModalContact } from "../../components/modalContact/modalContact";

export const FooterFull = () => {

  const AnioActual = () => {
    var fecha = new Date().getFullYear();
    return <>{fecha}</>;
  };

  return (
    <footer className="wrapper_footer ">
    <div className="container-lg">
      <div className="sec_footer">
        <div className="cont_brand">
          <a href="#welcome">
            <img
              className="logo_brand"
              src={LogoPrideCom}
              alt="PrideMx, Todo el poder de la comunidad en un solo espacio"
            />
          </a>
          <div className="box_social_net">
            <div className="slabel">Síguenos en</div>
            <div className="box_link_fa">
              <a
                target="_blank"
                className="facebook"
                href="https://www.facebook.com/SomosPrideMx/"
              >
                <i className="fa fa-facebook-f"></i>
              </a>
              <a
                target="_blank"
                className="instagram"
                href="https://instagram.com/somos_pridemx?igshid=YmMyMTA2M2Y="
              >
                <i className="fa fa-instagram"></i>
              </a>
              <a
                target="_blank"
                className="twitter"
                href="https://twitter.com/SomosPrideMx"
              >
                <svg
                  style={{ marginBottom: "5px" }}
                  viewBox="0 0 512 512"
                  height="20"
                >
                  <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
                </svg>
              </a>
              <a
                target="_blank"
                className="twitter"
                href="https://www.youtube.com/@pridemx"
              >
                <i className="fab fa-youtube"></i>
              </a>
              <a
                target="_blank"
                className="twitter"
                href="https://www.linkedin.com/company/82353363"
              >
                <i className="fab fa-linkedin-in"></i>
              </a>
              <a
                target="_blank"
                className="twitter"
                href="https://www.tiktok.com/@somos_pridemx"
              >
                <i className="fab fa-tiktok"></i>
              </a>
            </div>
          </div>
        </div>
        <ul className="ul_links">
          <li>
            <h6>Soporte</h6>
          </li>
          <li>
            <ModalContact />
          </li>
          <li>
            <a
              className="link_footer"
              target="_blank"
              href="/ambienteseguro.pdf"
            >
              Decálogo
            </a>
          </li>
          <li>
            <a
              className="link_footer"
              target="_blank"
              href="/avisoprivacidad.pdf"
            >
              Aviso de Privacidad
            </a>
          </li>
          <li>
            <a
              className="link_footer"
              target="_blank"
              href="/terminosycondiciones.pdf"
            >
              Términos y Condiciones
            </a>
          </li>
        </ul>
      </div>
      <div className="foot_right">
        <AnioActual /> ©PrideMx. Todos los derechos reservados
      </div>
    </div>
  </footer>
  )
}
