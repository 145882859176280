import React from 'react';
import { EditReward } from './editReward'
import {Header} from "../header";

export function DetailsReward(props) {

    return (
        <section className="dashboard-operator-body">
            <Header
                title={props.match.params.id != 0 ? "Editar recompensa" : "Nueva recompensa"}
                description=""
                buttons={[
                    {
                        "id": 1,
                        "nameButton": "Agregar recompensa",
                        "link": "/operator/details-reward/0"
                    }
                ]}
                btnBack={[
                    {
                        "id": 2,
                        "nameButton": "Regresar",
                        "link": "/operator/controlreward/1"
                    }
                ]}
            />
            <EditReward rewardProductId={props.match.params.id} />
        </section>
    );
}   
