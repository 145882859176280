import React from "react";
import "./audience.scss";
import "moment/locale/es";
import {Button, Card} from "antd";
import {AiOutlinePlayCircle} from "react-icons/ai";
import dayjs from "dayjs";
import es from "dayjs/locale/es";
import imgEmpty from "../../assets/imgs/no-image.jpeg";
import { audienceService } from "../../services/audience.service";

export const CardAudience = (props) => {

    const addView = (auditoriumId) =>  audienceService.insertView(auditoriumId);

    return (
        <>
            <Card className={`card-audience`} key={props.i}>
                <div
                    className={`card-audience__thumbnail ${(props.publishedItems || props.onLive) ? 'cursor' : ''}`}
                    onClick={() => props.handleLinkConference(props.item, props.onLive, props.publishedItems)}
                >
                    {(props.onLive) &&(
                        <span className="-on-live">
                            <svg height="12" width="12" className="blinking">
                              <circle cx="6" cy="6" r="4" fill="red" />.
                            </svg>
                            En Vivo
                        </span>)
                    }
                    {<img src={(props.item.files.length > 0) ? props.item.files[0].path : imgEmpty} alt={props.item.data.title}/>}
                    {
                        (props.publishedItems || props.onLive) && (
                            <Button
                                onClick={() => {
                                    props.handleLinkConference(props.item, props.onLive, props.publishedItems);
                                    addView(props.item.data.auditoriumId);
                                }}
                                htmlType={'button'}
                                type={'primary'}
                            >
                                <AiOutlinePlayCircle />
                            </Button>
                        )
                    }
                </div>
                <div className="card-audience__info">
                    <div className={'text'}>
                        <h5>
                            { (props.item.data.title.length >= 50) ?
                                props.item.data.title.substring(0,50) + '...'
                                :
                                props.item.data.title
                            }
                        </h5>
                        <p>
                            <span> {props.publishedDate ? 'Emisión:' : 'Emitido:'}</span>
                            {dayjs(props.item.data.conferenceDate).locale(es).format('ddd DD')} de {' '}
                            {dayjs(props.item.data.conferenceDate).locale(es).format('MMMM')} del {' '}
                            {dayjs(props.item.data.conferenceDate).locale(es).format('YYYY')}
                        </p>
                        <p>
                            {props.publishedDate && !(props.onLive) && (<span>A las:</span>)}
                            {props.publishedDate && !(props.onLive) && (`${dayjs(props.item.data.conferenceDate).locale(es).format('HH:mm')} hrs` : '')}
                        </p>
                    </div>
                </div>
            </Card>
        </>
    );
}
