import React, {useEffect, useState, forwardRef, useImperativeHandle, useRef} from 'react';
import { companyPostService } from '../../services/companyPost.service';
import { backendService } from "../../services/backend.service";
import {Button, Input, Space, Table, Tag} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {FaEdit, FaTrash} from "react-icons/fa";
import moment from "moment";
import MySwal from "sweetalert2";
import dayjs from "dayjs";

const SearchCompanyPost = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        refreshDataGrid: refreshDataGrid
    }));
    const [dataGrid, setDataGrid] = useState([]);
    const [originValues, setOriginValues] = useState({
        currentWriter: ""
    });
    const trigger = undefined;
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const handleDelete = (e, data) =>  {
        MySwal.fire({
            title:"¿Deseas desactivar esta publicación?",
            text: "No podrás revertir esta acción",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Eliminar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if(result.isConfirmed) companyPostService.deletet(data).then((result) => getAll());
        })
    }

    async function getAll() {
        companyPostService.getAll(0)
            .then((result) => {
                if (result) setDataGrid(result);
            });
    }

    const refreshDataGrid = () => getAll();

    const getProfileInfo = () => {
        backendService.userProfile().then((data) => {
            setOriginValues({
                currentWriter: data.firstName+' '+data.middleName+' '+data.lastName ?? ""
            });
        });
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{padding: 8,}} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Buscar`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{marginBottom: 8, display: 'block',}}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{width: 90,}}
                    >
                        Buscar
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{width: 90,}}
                    >
                        Limpiar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{color: filtered ? '#1677ff' : undefined,}}/>
        ),
        onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) setTimeout(() => searchInput.current?.select(), 100);
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{backgroundColor: '#ffc069', padding: 0,}}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (text),
    });

    const columnsCompany = [
        {
            title: 'Título del artículo',
            dataIndex: 'title',
            key: 'title',
            ...getColumnSearchProps('title'),
        },
        {
            title: 'Fecha de publicación',
            dataIndex: 'date',
            key: 'date',
            sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
            sortDirections: ['descend','ascend'],
        },
        {
            title: 'Vistas',
            dataIndex: 'views',
            key: 'views',
            sorter: (a, b) => {
                if(a.views < b.views) {
                    return -1;
                }
                if(a.views > b.views) {
                    return 1;
                }
                return 0;
            },
            sortDirections: ['descend','ascend'],
        },
        {
            title: 'Autor',
            dataIndex: 'author',
            key: 'author',
            ...getColumnSearchProps('author'),
        },
        {
            title: 'Acciones',
            dataIndex: 'options',
            key: 'options',
        },
    ];

    const dataCompany = dataGrid.map((item,i) => {
        return {
            key: i,
            title: item.title,
            date: dayjs(item.publishDate).format('DD/MMM/YYYY'),
            views: item.totalViews,
            author: item.writter,
            options: <Space>
                <Button
                    type={'primary'}
                    htmlType={'button'}
                    className="edit"
                    onClick={(e) => props.handleRowSelect(item)}
                >
                    <FaEdit />
                </Button>
                {item.active ?
                    <>
                        <Button
                            type={'primary'}
                            htmlType={'button'}
                            className="delete"
                            onClick={(e) => handleDelete(e, item)}
                        >
                            <FaTrash />
                        </Button>
                    </>
                    : null
                }
            </Space>
        };
    })

    useEffect(() => {
        getProfileInfo();
    }, [trigger]);

    useEffect(() => {
        getAll();
    }, []);

    return (
        <Table
            className="table-admin"
            dataSource={dataCompany}
            columns={columnsCompany}
            scroll={{ x: '992px', y: 800 }}
            locale={{
                triggerDesc: 'Ordenamiento descendente',
                triggerAsc: 'Ordenamiento ascedente',
                cancelSort: 'Cancelar ordenamiento',
                emptyText: 'Sin registros',
            }}
        />
    );

});

export default SearchCompanyPost;
