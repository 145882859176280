import React, { useRef, useState } from "react";
import SearchBranchOffice from "./searchBranchOffice";
import { branchOfficeService } from "../../../services/branchOffice.service";
import "../../admin/admin.scss";
import { FaSave, FaTimesCircle } from "react-icons/fa";
import { TitleAdmin } from "../../components/titleAdmin/titleAdmin";
import { HeadingAdmin } from "../../components/headingAdmin/headingAdmin";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row } from "antd";
import Swal from "sweetalert2";

export function BranchOffice() {
  const gridRef = useRef();
  const [showEdit, setShowEdit] = useState(false);
  const [initBranchOff, setInitBranchOff] = useState({
    BranchOfficeId: 0,
    BranchOfficeName: "",
  });

  const onSave = () => {
    if (initBranchOff.BranchOfficeId === 0) {
      branchOfficeService.create(initBranchOff).then((result) => {
        gridRef.current.refreshDataGrid();
        cleanObj();
        setShowEdit(false);
        Swal.fire({
          text: "Se ha creado exitosamente una nueva sucursal",
          icon: "success",
          showCloseButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      });
    } else {
      branchOfficeService.update(initBranchOff).then((result) => {
        gridRef.current.refreshDataGrid();
        cleanObj();
        setShowEdit(false);
        Swal.fire({
          text: "Se ha actualizado exitosamente la sucursal",
          icon: "success",
          showCloseButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      });
    }
  };

  const handleRowSelect = (gridItem) => {
    setShowEdit(true);
    setInitBranchOff({
      BranchOfficeId: gridItem.branchOfficeId,
      BranchOfficeName: gridItem.branchOfficeName,
    });
  };

  const handleOnCancel = () => {
    cleanObj();
    setShowEdit(false);
  };

  const handleOnNew = () => {
    setInitBranchOff(initBranchOff);
    setShowEdit(true);
  };

  const cleanObj = () =>
    setInitBranchOff({ BranchOfficeId: 0, BranchOfficeName: "" });

  const handleInputChange = (e) =>
    setInitBranchOff({ ...initBranchOff, [e.target.name]: e.target.value });

  return (
    <>
      <TitleAdmin
        title={"Sucursales"}
        subtitle={"Listado y alta de sucursales"}
        btnBack={false}
      />
      <HeadingAdmin
        text={!showEdit ? "Agregar Sucursal" : "Cancelar"}
        icon={!showEdit ? <PlusCircleOutlined /> : <FaTimesCircle />}
        event={() => (!showEdit ? handleOnNew() : handleOnCancel())}
      />
      {showEdit ? (
        <Form
          onFinish={onSave}
          layout={"vertical"}
          className={"form-backoffice"}
          fields={[
            {
              name: ["BranchOfficeName"],
              value: initBranchOff.BranchOfficeName,
            },
          ]}
        >
          <h4>
            {initBranchOff.BranchOfficeId === 0
              ? "Nuevo sucursal"
              : "Editar sucursal"}
          </h4>
          <Row gutter={16}>
            <Col xs={24}>
              <Form.Item
                label={"Nombre de sucursal"}
                htmlFor={"BranchOfficeName"}
                rules={[
                  {
                    required: true,
                    message: "*Campo Requerido",
                  },
                  {
                    pattern: new RegExp(/^\S/),
                    message: "No se aceptan espacios en blanco al inicio",
                  },
                ]}
                name="BranchOfficeName"
              >
                <Input
                  name="BranchOfficeName"
                  type="text"
                  onChange={handleInputChange}
                />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <div className="form-backoffice-footer">
                <Button htmlType={"submit"} type="primary">
                  <p>Guardar</p>
                  <FaSave />
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      ) : (
        <SearchBranchOffice handleRowSelect={handleRowSelect} ref={gridRef} />
      )}
    </>
  );
}
