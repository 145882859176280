import swal from "sweetalert";
import { callApi, callApiFileStorage } from "./service";

export const ticketsEventService = {
  getAvailableToSale,
  getByUser,
  GetEventSectors,
  create,
  getToAuthorize,
  changeStatus,
};

async function getAvailableToSale() {
  const result = await callApi("/TicketsEvent/AvailableToSale", "GET");
  if (result.responseCode === "00") {
    return result.data;
  } else {
    console.log(result.responseMessage);
    return [];
  }
}

async function getByUser() {
  const result = await callApi("/TicketsEvent/ByUser", "GET");
  if (result.responseCode === "00") {
    return result.data;
  } else {
    console.log(result.responseMessage);
    return [];
  }
}

async function create(model) {
  const result = await callApiFileStorage(
    "/TicketsEvent/Create",
    "POST",
    model,
    false
  );

  if (result.responseCode === "00") return true;
  else {
    console.log(result.responseMessage);
    swal({
      text: `Error al crear evento: ${result.responseMessage}`,
      icon: "error",
    });
    return false;
  }
}

async function GetEventSectors() {
  const result = await callApi("/TicketsEvent/EventSectors", "GET");
  if (result.responseCode === "00") {
    return result.data;
  } else {
    console.log(result.responseMessage);
    return [];
  }
}

async function getToAuthorize() {
  const result = await callApi("/TicketsEvent/ToAuthorize", "GET");
  if (result.responseCode === "00") {
    return result.data;
  } else {
    console.log(result.responseMessage);
    return [];
  }
}

async function changeStatus(model) {
  const result = await callApi("/TicketsEvent/ChangeStatus", "PATCH", model);

  if (result.responseCode === "00") {
    swal({
      text: `El evento se ${model.isApproved ? "autorizó" :"rechazó"} correctamente`,
      icon: "success",
    });
    return true;
  } else {
    console.log(result.responseMessage);
    swal({
      text: `Error al ${model.isApproved ? "autorizar" :"rechazar"} evento: ${result.responseMessage}`,
      icon: "error",
    });
    return false;
  }
}
