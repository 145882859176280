import React, {useEffect, useRef, useState} from "react";
import Swal from "sweetalert2";
import './inputFile.scss';
import {AiOutlineCheckCircle, AiOutlineCloudUpload, AiOutlineFileDone} from "react-icons/ai";
import {MdClear} from "react-icons/md";

export const InputFile = ({
                              onFilesSelected,
                              width = 0,
                              height = 0,
                              edit = false,
                              accept,
                              valueFile = [],
                              oneFile = true,
                              maxFiles = 1,
                              nameFile,
                              deleteImage,
                          }) => {
    const [files, setFiles] = useState([]);
    const inputFileRef = useRef();
    const maxSize = (process.env.REACT_APP_MAX_FILESIZE * 1024);
    const [format, setFormat] = useState([]);
    const [acceptedFiles, setAcceptedFiles] = useState([]);

    const handleRemoveFile = (index,files,file) =>  {
        const filesReceived = Array.from(files);
        const newFiles = filesReceived.filter((_, i) => i !== index);
        setFiles(newFiles);
        deleteImage(newFiles,file);
        if(newFiles.length === 0) cleanInput();
    };

    const cleanInput = () => inputFileRef.current.value = null;

    const handleFileChange = (e) => {
        e.preventDefault();
        let newFiles = [];
        let filesToUpload = [];

        if(e.dataTransfer) newFiles = Array.from(e.dataTransfer.files);
        else newFiles = Array.from(e.target.files)

        newFiles.forEach(currentFile => {
            const sizeCurrentFile = Math.round(currentFile.size / 1024);
            const fileType = currentFile.type?.split("/")[1] ?? '';

            if (sizeCurrentFile > maxSize) {
                if(newFiles.length === 1) {
                    showAlert('¡Ups!','EL archivo debe ser menor a ' + process.env.REACT_APP_MAX_FILESIZE + ' MB.','error');
                    selectedFilesUpload(filesToUpload)
                    cleanInput();
                    return;
                } else
                    return showAlert('¡Cuidado!',`Los archivos que exceden los ${process.env.REACT_APP_MAX_FILESIZE} MB no se subieron`,'warning');
            }

            if(accept.includes('image') && (fileType === 'png' || fileType === 'jpg' || fileType === 'jpeg' || fileType === 'svg' || fileType === 'webp')) {
                const currentImg = URL.createObjectURL(currentFile)
                let img = document.createElement("img"), dimensions = {};
                img.src = currentImg;
                img.onload = function () {
                    dimensions = {
                        width: img.width,
                        height: img.height
                    };
                    if(newFiles.length === 1) {
                        if(dimensions.width > width || dimensions.height > height) {
                            showAlert('¡Ups!','La imagen excede las dimensiones permitidas','error');
                            selectedFilesUpload(filesToUpload)
                            cleanInput();
                        } else {
                            filesToUpload.push(currentFile);
                            selectedFilesUpload(filesToUpload);
                            cleanInput();
                        }
                    } else {
                        if(dimensions.width > width || dimensions.height > height)
                            showAlert('¡Cuidado!','Las imágenes que exceden las dimensiones permitidas no se subieron','warning');
                        else  {
                            filesToUpload.push(currentFile);
                            selectedFilesUpload(filesToUpload);
                            cleanInput();
                        }
                    }
                };
            } else if(format.includes(fileType) ) {
                filesToUpload.push(currentFile);
                selectedFilesUpload(filesToUpload);
                cleanInput();
            } else {
                showAlert('El archivo es un formato no permitido');
                cleanInput();
            }
        });
    }


    const selectedFilesUpload = (filesReceived) => {
        const newFiles = Array.from(filesReceived);
        if(oneFile) {
            setFiles(newFiles);
            onFilesSelected(newFiles)
        } else {
            files.forEach(item => newFiles.push(item))
            setFiles(newFiles)
            onFilesSelected(newFiles)
        }
    }

    const showAlert = (title,textAlert,type) => Swal.fire({title: title, text: textAlert, icon: type});

    useEffect(() => {
        if(edit) setFiles(valueFile);
    }, [valueFile]);

    useEffect(() => {
        const format = [], acceptedFiles = [];

        for(let i  = 0; i < accept.length; i++) {
            if(accept[i] === 'image') {
                format.push('png', 'jpg', 'webp', 'svg', 'jpeg')
                acceptedFiles.push('image/png','image/svg','image/webp','image/jpeg','image/jpg')
            }
            if(accept[i] === 'pdf') {
                format.push('pdf')
                acceptedFiles.push('application/pdf')
            }
            if(accept[i] === 'audio') {
                format.push('mp3','wav')
                acceptedFiles.push('audio/*')
            }
            if(accept[i] === 'video') {
                format.push('mp4','mov','mkv,')
                acceptedFiles.push('video/mov','video/mp4','video/mkv')
            }
            if(accept[i] === 'cvs') {
                format.push('cvs','xls','xlsx')
                acceptedFiles.push('application/csv','application/xlsx','application/xls')
            }
            if(accept[i] === 'document') {
                format.push('doc','docx','txt')
                acceptedFiles.push('application/doc','application/docx','application/txt')
            }
        }
        setFormat(format);
        setAcceptedFiles(acceptedFiles);
    }, [accept]);

    return (
        <section className="drag-drop-container">
            <div
                className={`document-uploader ${
                    files.length > 0 ? "upload-box active" : "upload-box"
                }`}
                onDrop={handleFileChange}
                onDragOver={(e) => e.preventDefault()}
            >
                <div className="upload-info">
                    {((oneFile && files.length === 0) || (!(oneFile) && files.length < maxFiles)) ?
                        <>
                            <AiOutlineCloudUpload />
                            <div>
                                <p>Arrastra y suelta tus archivos aquí</p>
                                <p>Límite de {process.env.REACT_APP_MAX_FILESIZE}MB por archivo.
                                    <br/>Archivos permitidos: {format.join(', ')}
                                </p>
                                {accept.includes('image') ? <p>Dimensiones recomendadas: {width}x{height}</p> : null}
                            </div>
                        </>
                        :
                        <>
                            <AiOutlineFileDone />
                            <div>
                                <p>Límite de archivos alcanzado</p>
                            </div>
                        </>
                    }
                </div>
                <input
                    ref={inputFileRef}
                    type="file"
                    hidden
                    id={`${nameFile}`}
                    onChange={handleFileChange}
                    accept={acceptedFiles}
                    name={`${nameFile}`}
                    multiple={!(oneFile)}
                />
                {
                    ((oneFile && files.length === 0) || (!(oneFile) && files.length < maxFiles)) ?
                        <label htmlFor={`${nameFile}`} className="browse-btn">Buscar archivos</label>
                        : null
                }

                {files.length > 0 && (
                    <div className="file-list">
                        <div className="file-list__container">
                            {files.map((file, index) => (
                                <div className="file-item" key={index}>
                                    <div className="file-info">
                                        <p>{file?.name ?? file.fileName}</p>
                                        {/* <p>{file.type}</p> */}
                                    </div>
                                    <div className="file-actions">
                                        <MdClear onClick={() => {handleRemoveFile(index,files,file);} } />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {files.length > 0 && (
                    <div className="success-file">
                        <AiOutlineCheckCircle
                            style={{ color: "#6DC24B", marginRight: 1 }}
                        />
                        {files.length <= 1 ? <p> Archivo seleccionado</p> : <p> Archivos seleccionados</p>}
                    </div>
                )}
            </div>
        </section>
    );
}
