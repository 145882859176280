import React, {useEffect, useState} from "react";
import {Button, Input} from "antd";
import {FaMinus, FaPlus} from "react-icons/fa";
import './inputAdd.scss';

export const InputAdd = ({maxLength, maxQuantity, minQuantity, handleCurrentNumber, valueInt, disabled = false, name}) => {

    const [quantity, setQuantity] = useState(valueInt);

    const addNumber = () => {
        if(parseInt(quantity) <= maxQuantity) setQuantity(parseInt(quantity) + 1)
    }

    const minusNumber = () => {
        if(parseInt(quantity) !== minQuantity) setQuantity(parseInt(quantity) - 1)
    }

    const handleQuantity = (e) => {
        const value = e.target.value;
        if(parseInt(!value)) setQuantity(minQuantity)
        else setQuantity(value)
        if(value === '') setQuantity(minQuantity)
        if(parseInt(value) > maxQuantity) setQuantity(31)
        if(parseInt(value) < minQuantity) setQuantity(minQuantity)
    }

    useEffect(() => {
        handleCurrentNumber(parseInt(quantity))
    }, [quantity])

    useEffect(() => setQuantity(valueInt), [valueInt])

    return (
        <div className="container-numbers">
            <Button onClick={minusNumber} disabled={(disabled === true) ? disabled : (parseInt(quantity) === minQuantity)}>
                <FaMinus />
            </Button>
            <Input
                size="large"
                type="text"
                name={`${name}`}
                value={quantity}
                maxLength={maxLength}
                onChange={handleQuantity}
                disabled={disabled}
                style={{minHeight: '50px'}}
            />
            <Button onClick={addNumber} disabled={(disabled === true) ? disabled : (parseInt(quantity) === maxQuantity)}>
                <FaPlus />
            </Button>
        </div>
    );
}
