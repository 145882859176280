import React, {useEffect, useRef, useState} from 'react';
import { companyService } from '../../services/control/company.service';
import {Button, Input, Space, Table, Tag,Pagination} from "antd";
import {FaPen, FaTrash,} from "react-icons/fa";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {SearchOutlined} from "@ant-design/icons";
import Highlighter from 'react-highlight-words';
const MySwal = withReactContent(Swal);

export function SearchCompany({statusValue}) {
    const [companiesData, setCompaniesData] = useState({ companies: [] });
    const handleDelete = (data) => {
        MySwal.fire({
            title: '¿Estás seguro?',
            text: "No podrás revertir esta acción",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#FF002A',
            confirmButtonText: 'Eliminar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if(result.isConfirmed)
                companyService.deleteCompany(data).then((result) => getCompanies(statusValue));
        })
    };

    useEffect(() => {
        getCompanies(statusValue);
    }, [statusValue]);

    async function getCompanies(stat) {
        companyService.get(stat).then((result) => {
            if (result) {
                setCompaniesData({
                    companies: result.sort((a,b) => {
                        return b.companyId - a.companyId;
                    })
                })
            }
        });
    }

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Buscar`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Buscar
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Limpiar
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        Cerrar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            defaultSortOrder: 'descend',
            sortDirections: ['descend','ascend'],
            showSorterTooltip: false,
            sorter: (a, b) => a.id - b.id,
            width: 100,
            fixed: 'left'
        },
        {
            title: 'Nombre',
            dataIndex: 'name',
            key: 'name',
            sortDirections: ['descend','ascend'],
            showSorterTooltip: false,
            sorter: (a, b) => {
                if(a.name < b.name) {
                    return -1;
                }
                if(a.name > b.name) {
                    return 1;
                }
                return 0;
            },
            ...getColumnSearchProps('name')
        },
        {
            title: 'Dominio',
            dataIndex: 'domain',
            key: 'domain',
            sortDirections: ['descend','ascend'],
            showSorterTooltip: false,
            sorter: (a, b) => {
                if(a.domain < b.domain) {
                    return -1;
                }
                if(a.domain > b.domain) {
                    return 1;
                }
                return 0;
            },
        },
        {
            title: 'Activo',
            dataIndex: 'active',
            key: 'active',
            width: 100,
        },
        {
            title: 'Opciones',
            dataIndex: 'options',
            key: 'options',
            width: 150,
        },
    ];

    const data = companiesData.companies.map(company => {
        return {
            key: company.companyId,
            id: company.companyId,
            name: company.companyName,
            domain: company.primeDomain,
            active: company.active ? <Tag color="green" key={company.companyId}>Activo</Tag> :
                                     <Tag color="red" key={company.companyId}>Inactivo</Tag>,
            options: <>
                    <Button
                        type="primary"
                        className="dark"
                        htmlType="button"
                        onClick={() => window.location.href=`/operator/details-company/${company.companyId}`}
                        style={{marginRight: '.5rem'}}
                        icon={<FaPen />}
                        disabled={!company.active}
                    >
                    </Button>
                    <Button
                        type="primary"
                        className="red"
                        htmlType="button"
                        onClick={() => handleDelete(company.companyId)}
                        icon={<FaTrash />}
                        disabled={!company.active}
                    >
                    </Button>
                </>
        }
    });

    const [currentPage, setCurrentPage] = useState(1)
    const [sizesPages, setSizesPages] = useState(10)

    const onChangePage = (page,pageSize) => {
        setCurrentPage(page)
        setSizesPages(pageSize)
    }

    return (
        <>
            <Table columns={columns}
                   dataSource={data.slice((currentPage - 1) * sizesPages, currentPage * sizesPages)}
                   scroll={{ x: '992px', y: 800 }}
                   pagination={false}
                   locale={{
                       triggerDesc: 'Ordenamiento descendente',
                       triggerAsc: 'Ordenamiento ascedente',
                       cancelSort: 'Cancelar ordenamiento',
                       emptyText: 'Sin registros',
                   }}
           />
            <Pagination
                total={data.length}
                defaultPageSize={sizesPages}
                responsive={true}
                defaultCurrent={1}
                current={currentPage}
                hideOnSinglePage={true}
                onChange={onChangePage}
                simple={true}
            />
        </>

    );
}
