import React, { Component } from "react";
import { Link } from "react-router-dom";
import { cartHook } from "../../hooks/cart.hook";
import { connect } from "react-redux";
import { ecommerceService } from "../../services/ecommerce.service";
import Moment from "react-moment";

import SuccessImg from "../../assets/imgs/ico_ticket.svg";
import {Button, Col, Result, Row, Space} from "antd";

class OrderDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderDetail: "",
    };
  }

  componentWillMount() {
    this.orderDetail(this.props.match.params.id);
  }

  orderDetail = (orderId) => {
    window.setTimeout(
      function () {
        ecommerceService.getPurchaseOrderDetail(orderId).then((result) => {
          this.setState({ orderDetail: result });
          cartHook.clean(result.productType == 10);
        });
      }.bind(this),
      2000
    );
  };

  render() {
    const { orderDetail } = this.state;
    return (
      <div className="dashboard-container-page">
          <Row gutter={[16,0]}>
            <Col
                className={'bg-light rounded-2'}
                 xs={{
                  span: 24
                }}
                md={{
                    span: 12,
                    offset: 6
                }}
            >
              <Result
                  status="success"
                  title={(orderDetail && orderDetail.productType !== 10) ? "¡Compra exitosa!" : "¡Gracias por tu donación!"}
                  subTitle={(orderDetail) &&
                      `En breve recibirás un correo con la información de tu ${
                          orderDetail.productType !== 10 ? "compra" : "donativo"
                      }.`}
                  extra={[
                      <p>
                          <b>Número de pedido: </b>
                          {orderDetail.orderNumber}
                      </p>,
                      <p>
                          <b>Estatus: </b>
                          {orderDetail.status}
                      </p>,
                      <p>
                          <b>Fecha: </b>
                          <Moment format="DD/MM/YYYY">{orderDetail.date}</Moment>
                      </p>,
                    (orderDetail && orderDetail.productType !== 10) ? (
                        <Space className={'mt-3'}>
                          <Link
                              className="btn secundary ml-1 mr-1"
                              to="/discount"
                          >
                            Ir a tienda
                          </Link>
                          <Link
                              className="btn primary ml-1 mr-1"
                              to="/shopping-history"
                          >
                            Mis compras
                          </Link>
                        </Space>
                    ) : (
                        <Space className={'mt-3'}>
                            <Link
                                className="btn secundary ml-1 mr-1"
                                to="/caring-community"
                            >
                                Ir a fundaciones
                            </Link>
                        </Space>
                    ),
                  ]}
              />
            </Col>
          </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    countrySetting: state.countrySetting,
  };
};

export default connect(mapStateToProps)(OrderDetail);
