import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import useInputForm from '../../components/CustonHooks/FormHook'
import { EditLevel } from './editLevel'
import { SearchLevel } from './searchLevel'
import { useAcknowledgment } from '../../services/acknowledgment.service'
import { useAcknowledgmentLadder } from '../../services/acknowledgmentLadder.service'

export function EditCategory(props) {
	let { id } = useParams()
	const [acknowledgmentTypeId, setAcknowledgmentTypeId] = useState(id)
	const [isNew, setIsNew] = useState(id === undefined)
	const [acknowledgment, setAcknowledgment] = useState({})
	const [levels, setLevels] = useState([])
	const [showEditLevel, setShowEditLevel] = useState(false)
	const [refresh, setRefresh] = useState(false)
	const [selectLevel, setSelectLevel] = useState()

	const setAcknowledgmentFixed = (data) => {
		data.validFrom = data.validFrom.split("T")[0]
		data.validTo = data.validTo.split("T")[0]
		setAcknowledgment(data)
	}

	const addLevel = (level) => {
		let aux = levels
		aux.push(level)
		setLevels(aux)
		setRefresh(true)
	}

	const updateLevel = (level) => {
		let aux = levels
		let index = aux.findIndex(x => x.companyAcknowledgmentLadderId === level.companyAcknowledgmentLadderId)
		aux[index] = level
		setLevels(aux)
		setRefresh(true)
	}

	const deleteLevel = (level) => {
		useAcknowledgmentLadder.deleteAcknowledgment(level).then((result) => {
			if (result.responseCode === '00') {
				let aux = levels
				let index = aux.findIndex(x => x.companyAcknowledgmentLadderId === level.companyAcknowledgmentLadderId)
				aux[index].active = false
				setLevels(aux)
				setRefresh(true)
			}
		})
	}

	const onSave = () => {
		if (isNew) {
			useAcknowledgment.createAcknowledgment(inputs)
				.then((data) => {
					if (data) {
						setAcknowledgmentTypeId(data.acknowledgmentTypeId)
						setIsNew(false)
						setAcknowledgmentFixed(data)
					}
				})
		}
		else {
			useAcknowledgment.updateAcknowledgment(inputs)
		}
	};

	const { handleSubmit, handleInputChange, inputs } = useInputForm(onSave, acknowledgment);

	useEffect(() => {
		if (isNew) {
			setAcknowledgment({})
		}
		else {
			useAcknowledgment.getAcknowledgment(id)
				.then((data) => {
					setAcknowledgmentFixed(data)
					useAcknowledgmentLadder.getAcknowledgments(id)
						.then((data) => setLevels(data))
				})
		}
	}, [id]);

	return (
		<div className="wrapper_form_survey card shadows p-4 ">
			<form onSubmit={handleSubmit} className="mb-5">
				<fieldset >
					<legend className="text fnt_medium">{isNew ? "Nueva" : "Editar"} categoría</legend>
					<div className="row">
						<div className="col-md-6 col-lg-4">
							<div className="form-group">
								<label for="title">Título</label>
								<input type="text" name="title" className="form-control" onChange={handleInputChange} value={inputs.title} required />
							</div>
						</div>
						<div className="col-md-6 col-lg-4">
							<div className="form-group">
								<label for="descritption">Descripción</label>
								<textarea type="text" rows="3" name="descritption" className="form-control" onChange={handleInputChange} value={inputs.descritption} required />
							</div>
						</div>
						<div className="col-md-6 col-lg-4">
							<div className="form-group text-center">
								<label for="active">Activar</label>
								<input type="checkbox" className="form-control cursor" name="active" onChange={handleInputChange} checked={inputs.active} />
							</div>
						</div>
						<div className="col-md-6 col-lg-4">
							<div className="form-group">
								<label for="validFrom">Vigencia desde</label>
								<input className="form-control" type="date" name="validFrom" onChange={handleInputChange} value={inputs.validFrom} required />
							</div>
						</div>
						<div className="col-md-6 col-lg-4">
							<div className="form-group">
								<label for="validTo">Vigencia hasta</label>
								<input className="form-control" type="date" name="validTo" onChange={handleInputChange} value={inputs.validTo} required />
							</div>
						</div>
					</div>
					<div className="mt-4">
						<Link to="/AdminBoard">
							<button className="btn secundary minimum ml-1 mr-1" type="button">{isNew ? 'Cancelar' : 'Regresar'}</button>
						</Link>
						<button className="btn primary minimum" type="submit">Guardar</button>
					</div>
				</fieldset>
			</form>

			{!isNew &&
				<div className="row">
					<div className="col-12">
						<fieldset >
							<legend className="text fnt_medium">Lista de niveles</legend>
							<div className="mb-3">
								<span className="pl-2 link_action" onClick={() => { setSelectLevel(null); setShowEditLevel(true); }}>
									<i className="fas fa-plus"></i> Agregar nivel
								</span>
							</div>

							{showEditLevel &&
								<EditLevel
									categoryId={acknowledgmentTypeId}
									currentLevel={selectLevel}
									setShowEditLevel={setShowEditLevel}
									addLevel={addLevel}
									updateLevel={updateLevel}
								/>
							}
							{levels.length > 0 &&
								<SearchLevel
									levels={levels}
									setSelectLevel={setSelectLevel}
									setShowEditLevel={setShowEditLevel}
									deleteLevel={deleteLevel}
									refresh={refresh}
									setRefresh={setRefresh}
								/>
							}

						</fieldset>
					</div>
				</div>
			}
		</div>
	);
}   
