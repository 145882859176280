import React from "react";
import { BiPhone } from "react-icons/bi";
import { IoMail } from "react-icons/io5";
import { ItemSocial } from "../../pages/interestGroup/itemSocial";

const InterestGroupCard = ({ item }) => {
  return (
    <div className="card-interest-group">
      <div className="front">
        <img src={item.imageUrl} alt="" />
        <h4>{item.title}</h4>
      </div>
      <div className="back">
        <div className="info">
          <h6>
            <span>DATOS DE CONTACTO</span>
          </h6>
          <h4>{item.title}</h4>
          {item.genericContactInfo &&
            item.genericContactInfo.map((contactInfo, index) => {
              if (contactInfo.contactTypeName == "Teléfono") {
                return (
                  <p key={index}>
                    <span>
                      <BiPhone />
                    </span>{" "}
                    {contactInfo.contactValue}
                  </p>
                );
              }

              if (
                contactInfo.contactTypeName == "Email" &&
                contactInfo.contactValue
              ) {
                return (
                  <div className={"email"} key={index}>
                    <p>
                      <IoMail />
                    </p>
                    <div className={"email-text"}>
                      {contactInfo.contactValue}
                    </div>
                  </div>
                );
              }
            })}
          <div className="social-media social__interestgroup">
            {item.genericContactInfo &&
              item.genericContactInfo.map((contactInfo, index) => {
                if (contactInfo.iconImage) {
                  return (
                    <ItemSocial
                      key={index}
                      socialIcoName={contactInfo.contactTypeName}
                      socialIco={contactInfo.iconImage}
                      socialUrl={contactInfo.contactValue}
                    />
                  );
                }
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InterestGroupCard;
