import React, { useEffect, useState } from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { JobBankService } from "../../services/jobBank.service";
import "./jobBank.scss";
import useInputForm from "../../components/CustonHooks/FormHook";
import CreatableSelect from "react-select/creatable";
import { ValidacionEmail } from "../../utils/ValidacionMail";
import { useHistory } from "react-router-dom";
import { Input, InputGroup, InputGroupText, Label } from "reactstrap";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { Button } from "rsuite";
import { Row, Col } from "antd";
import { FaChevronRight } from "react-icons/fa";

export function FormEditJob({ id, job, setJob, toggleView }) {
  const history = useHistory();
  const [datos, setDatos] = useState({});

  const [editorState, setEditorState] = useState();
  const [selectedOption, setSelectedOption] = useState([]);
  const [options, setOptions] = useState([]);
  const [defaultContentState, setDefaultContentState] = useState();

  useEffect(() => {
    JobBankService.getCategories().then((result) => {
      if (!result) return;
      setOptions(
        result.map((x) => ({ value: x.jobCategoryId, label: x.name }))
      );
    });

    JobBankService.getJobById(id).then((result) => {
      // se obtiene un objeto del tipo EditorState para la descripcion del trabajo
      let blocksFromHtml = htmlToDraft(result.description);
      let { contentBlocks, entityMap } = blocksFromHtml;
      let contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      let editorState = EditorState.createWithContent(contentState);
      setDefaultContentState(editorState);
      setEditorState(
        draftToHtml(convertToRaw(editorState.getCurrentContent()))
      );

      const chargeData = async () => {
        const imageFile = await getFile(result.companyImage);
        return imageFile;
      };

      setJob(result);

      chargeData().then((imageFile) => {
        setDatos({
          title: result.title,
          categoria: result.jobCategory,
          location: result.location,
          direction: result.direction,
          titleCompany: result.companyName,
          imagen: imageFile,
          infoSalarial: result.salary,
          descripcion: result.description,
          phoneNumber: result.phoneNumber,
          mail: result.email,
        });
      });

      setSelectedOption(
        result.jobCategory.map((element) => {
          return { value: element.jobCategoryId, label: element.name };
        })
      );
    });
  }, []);

  const getFile = async (url) => {
    let barra = url.lastIndexOf("/");
    let punto = url.lastIndexOf(".");
    let nombre = url.substring(barra + 1, punto);

    let response = await fetch(url);
    let data = await response.blob();
    let file = new File([data], nombre);
    let container = new DataTransfer();
    container.items.add(file);
    document.querySelector("#file-upload").files = container.files;
    return file;
  };

  function updateImage(e) {
    const file = e.target.files[0];
    inputs.imagen = file;
  }

  const onSave = () => {
    inputs.descripcion = editorState;
    inputs.categoria = selectedOption;
    let error = ValidacionEmail(inputs.mail);
    if (error === false) {
      swal({ text: "Correo incorrecto, por favor revise.", icon: "error" });
      return;
    }
    if (inputs.categoria.length === 0) {
      swal({ text: "Seleccion al menos una categoría", icon: "error" });
      return;
    }
    if (inputs.descripcion === "") {
      swal({ text: "Ingrese una descripción", icon: "error" });
      return;
    }
    insertJob();
  };

  async function insertJob() {
    let formData = new FormData();
    formData.append("JobId", job.jobId);

    formData.append("CompanyId", process.env.REACT_APP_COMPANY);
    formData.append("Title", inputs.title);
    //Intentar agarrar lo que pone el editor y ponerlo un string
    formData.append("Description", inputs.descripcion);
    formData.append("Currency", "MXN");
    formData.append("Salary", inputs.infoSalarial);
    formData.append("Location", inputs.location);
    formData.append("Direction", inputs.direction);
    formData.append("CompanyName", inputs.titleCompany);
    //En CompanyImage debería ir el logo de la empresa
    formData.append("CompanyImage", inputs.imagen);
    formData.append("PhoneNumber", inputs.phoneNumber);
    formData.append("Email", inputs.mail);

    selectedOption.forEach((element, index) => {
      formData.append(
        `JobCategory[${index}].JobCategoryId`,
        element?.__isNew__ ? 0 : element.value
      );
      formData.append(`JobCategory[${index}].Name`, element.label);
    });

    JobBankService.updateJob(formData).then((result) => {
      JobBankService.getJobById(id).then((result) => {
        // se obtiene un objeto del tipo EditorState para la descripcion del trabajo
        let blocksFromHtml = htmlToDraft(result.description);
        let { contentBlocks, entityMap } = blocksFromHtml;
        let contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        let editorState = EditorState.createWithContent(contentState);
        setDefaultContentState(editorState);
        setEditorState(
          draftToHtml(convertToRaw(editorState.getCurrentContent()))
        );

        getFile(result.companyImage);

        setJob(result);

        setDatos({
          title: result.title,
          categoria: result.jobCategory,
          location: result.location,
          direction: result.direction,
          titleCompany: result.companyName,
          imagen: result.companyImage,
          infoSalarial: result.salary,
          descripcion: result.description,
          phoneNumber: result.phoneNumber,
          mail: result.email,
        });

        setSelectedOption(
          result.jobCategory.map((element) => {
            return { value: element.jobCategoryId, label: element.name };
          })
        );
      });
      Swal.fire({
        text: "Se ha actualizado la oferta de trabajo",
        icon: "success",
        showCloseButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then(() => {
        toggleView();
      });
    });
  }

  const { inputs, handleInputChange, handleSubmit, setInputs } = useInputForm(
    onSave,
    datos
  );

  const onEditorStateChange = (editorState) => {
    setEditorState(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  return (
    <>
      <article className="steps">
        <div className="item active">
          <span>1</span>
        </div>
        <div className="lines"></div>
        <div className="item">
          <span>2</span>
        </div>
      </article>
      <article className="content">
        <form onSubmit={handleSubmit}>
          <div className="content_shadows_form">
            <Row gutter={16}>
              <Col xs={24}>
                <h6>Información de la vacante</h6>
              </Col>
              <Col md={12} xs={24}>
                <div className="form-dashboard-group">
                  <Label>Título de la vacante</Label>
                  <input
                    type="text"
                    placeholder="Título de la vacante"
                    name="title"
                    required={true}
                    defaultValue={job.title}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
              <Col md={12} xs={24}>
                <div className="form-dashboard-group">
                  <Label for="categoria"> Categoría</Label>
                  <CreatableSelect
                    closeMenuOnSelect={false}
                    value={selectedOption}
                    required={true}
                    onChange={setSelectedOption}
                    placeholder="Selecciona una categoría"
                    isMulti
                    className="basic-multi-select"
                    classNamePrefix="select"
                    options={options}
                    id="categoria"
                  />
                </div>
              </Col>
              <Col xs={24}>
                <div className="form-dashboard-group">
                  <Label for="direction">Dirección</Label>
                  <input
                    type="text"
                    placeholder="Ubicación"
                    name="direction"
                    onChange={handleInputChange}
                    required={true}
                    defaultValue={job.direction}
                  />
                </div>
              </Col>
              <Col xs={24}>
                <div className="form-dashboard-group">
                  <Label for="location">Localidad</Label>
                  <input
                    type="text"
                    placeholder="Localidad"
                    name="location"
                    onChange={handleInputChange}
                    required={true}
                    defaultValue={job.location}
                  />
                </div>
              </Col>
              <Col md={12} xs={24}>
                <div className="form-dashboard-group">
                  <Label for="titleCompany">Nombre de la empresa</Label>
                  <input
                    type="text"
                    placeholder="Nombre de la empresa"
                    name="titleCompany"
                    onChange={handleInputChange}
                    required={true}
                    defaultValue={job.companyName}
                  />
                </div>
              </Col>
              <Col md={12} xs={24}>
                <div className="form-dashboard-group">
                  <Label for="mail">Correo</Label>
                  <input
                    type="text"
                    placeholder="Correo electrónico"
                    name="mail"
                    onChange={handleInputChange}
                    required={true}
                    defaultValue={job.email}
                  />
                </div>
              </Col>
              <Col md={12} xs={24}>
                <div className="form-dashboard-group">
                  <Label>Número de contacto</Label>
                  <input
                    placeholder="Número de teléfono"
                    name="phoneNumber"
                    type="text"
                    maxLength={10}
                    minLength={10}
                    onKeyPress={(e) => {
                      // Solo se admiten números
                      e = e || window.event;
                      var charCode = e.keyCode || e.which;
                      var charStr = String.fromCharCode(charCode);
                      if (isNaN(charStr)) e.preventDefault();
                    }}
                    onChange={handleInputChange}
                    required={true}
                    defaultValue={job.phoneNumber}
                  />
                </div>
              </Col>
              <Col md={12} xs={24}>
                <div className="form-dashboard-group">
                  <Label for="">Logo empresa</Label>
                  <input
                    type="file"
                    id="file-upload"
                    placeholder={job.companyImage}
                    accept="image/*"
                    name="imagen"
                    onChange={(x) => updateImage(x)}
                  />
                </div>
              </Col>
              <Col xs={24} className="mt-3 mb-3">
                <h6>Información salarial</h6>
              </Col>
              <Col md={12} xs={24}>
                <Label for="">Salario bruto</Label>
                <InputGroup className="group-append">
                  <InputGroupText>
                    Salario bruto
                    <Input type="radio" name="exampleRadios" />
                  </InputGroupText>
                  <Input
                    type="number"
                    min={0}
                    step="0.01"
                    name="infoSalarial"
                    onChange={handleInputChange}
                    required={true}
                    defaultValue={job.salary}
                  />
                </InputGroup>
              </Col>
              <Col xs={24} className="mt-3 mb-3">
                <br />
                <h6>Descripción de la empresa</h6>
              </Col>
              <Col xs={24}>
                {defaultContentState && (
                  <Editor
                    onEditorStateChange={onEditorStateChange}
                    wrapperClassName="wrapper__editor_text"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    toolbar={{
                      options: [
                        "inline",
                        "colorPicker",
                        "blockType",
                        "fontSize",
                        // "fontFamily",
                        "list",
                        "textAlign",
                        // "image",
                        "link",
                        "remove",
                        "history",
                      ],
                      inline: {
                        options: [
                          "bold",
                          "italic",
                          "underline",
                          "strikethrough",
                        ],
                      },
                      list: { inDropdown: true },
                      textAlign: { inDropdown: true },
                      link: { inDropdown: true },
                      history: { inDropdown: true },
                    }}
                  />
                )}
              </Col>
              <Col xs={24}>
                <div className="form-dashboard-footer">
                  <Button className="btn-dark" type="submit">
                    <p>Modificar empleo</p>
                    <FaChevronRight />
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </form>
      </article>
    </>
  );
}
