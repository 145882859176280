import { callApi } from './service'
import swal from "sweetalert"


export const companyEventTypeService = {
    createCompanyActionType,
    updateCompanyActionType,
    getCompanyEventType,
    deletetCompanyEventType,
    getByEventId,
    getAllowedToAddressCompanyEventTypes,
    getByCompanyAndEventType
};


async function createCompanyActionType(params) {
    return callApi('/backoffice/CompanyEventType', 'POST', params).then((result) => {
        if (result.responseCode === '00') {
            swal({ text: "El tipo de evento se creó correctamente", icon: "success" })
        } else {
            swal({ text: "Error al crear el tipo de evento", icon: "error" });
        }
    });
}

async function updateCompanyActionType(params) {

    return callApi('/backoffice/CompanyEventType', 'PUT', params).then((result) => {
        if (result.responseCode === '00') {
            swal({ text: "El tipo de evento se actualizó correctamente", icon: "success" })
        } else {
            swal({ text: "Error al actualizar el tipo de evento", icon: "error" });
        }
    });
}

async function getCompanyEventType() {
    return callApi('/backoffice/CompanyEventType/getByCompany', 'GET').then((result) => {

        if (result.responseCode == '00') {
            return result.data;
        } else {
            alert(result.responseMessage);
            return null;
        }

    });
}

async function getByEventId(eventId) {
    return callApi(`/backoffice/CompanyEventType/GetByEventId?eventId=${eventId}`, 'GET').then((result) => {

        if (result.responseCode == '00') {
            return result.data;
        } else {
            alert(result.responseMessage);
            return null;
        }

    });
}

async function getByCompanyAndEventType(companyId, eventTypeName) {
    return callApi(`/backoffice/CompanyEventType/GetByCompanyAndEventType?companyId=${companyId}&eventTypeName=${eventTypeName}`, 'GET').then((result) => {

        if (result.responseCode == '00') {
            return result.data;
        } else {
            alert(result.responseMessage);
            return null;
        }

    });
}

async function getAllowedToAddressCompanyEventTypes(companyId) {
    return callApi(`/backoffice/CompanyEventType/GetAllowedToAddress?companyId=${companyId}`, 'GET').then((result) => {

        if (result.responseCode == '00') {
            return result.data;
        } else {
            alert(result.responseMessage);
            return null;
        }

    });
}

async function deletetCompanyEventType(params) {
    return callApi('/backoffice/CompanyEventType', 'DELETE', params).then((result) => {
        if (result.responseCode === '00') {
            swal({ text: "El tipo de evento se desactivo correctamente", icon: "success" })
        } else {
            console.log(result.responseMessage);
            swal({ text: "Error al desactivar el tipo de evento", icon: "error" });
        }
    });
}