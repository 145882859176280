import React, { useState, useEffect } from "react";
import { Button, Collapse } from "reactstrap";
import { BiChevronDown, BiChevronRight } from "react-icons/bi";
import dayjs from "dayjs";

export function ItemMorePostGallery({
  filter,
  getGallery,
  year,
  months,
  currentFilter,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [counterSelected, setCounterSelected] = useState(0);
  const [yearsCounting, setYearCounting] = useState([]);

  useEffect(() => {
    const anio = new Date().getFullYear();
    if (anio === parseInt(year)) setIsOpen(true);
    countingPostsByYear();
  }, []);

  const formattingDate = (date, index) => {
    getGallery(dayjs(date).format("YYYY-MM-DDTHH:mm:ss"));
    setCounterSelected(index);
  };

  const countingPostsByYear = () => {
    let counter = 0;
    months.map((month, i) => (counter += month.count));
    setYearCounting(counter);
  };

  return (
    <div key={year}>
      <Button onClick={toggle} className="btn-publication-white">
        <p>
          {filter.year} <span>{yearsCounting}</span>
        </p>
        <BiChevronDown />
      </Button>
      <Collapse className={`box_collapse`} isOpen={isOpen}>
        <ul>
          {filter.months.map((item, i) => (
            <li key={i}>
              <Button
                className={`btn-publication-white ${
                  item.filterMonthDate == currentFilter ? "active" : ""
                }`}
                onClick={() => formattingDate(item.filterMonthDate, i)}
              >
                <p>
                  {item.month}
                  {months.map((month, index) => {
                    return month.month === item.month ? (
                      <span key={index}>{month.count}</span>
                    ) : (
                      ""
                    );
                  })}
                </p>
                <BiChevronRight />
              </Button>
            </li>
          ))}
        </ul>
      </Collapse>
    </div>
  );
}
