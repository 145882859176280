import React from "react";
import {Header} from "./header";

export const Operator = () => {
    return (
        <section className="dashboard-operator-body">
            <Header
                title="Mi tablero"
                description=""
                buttons={[]}
            />
        </section>
    );
}
