import React from "react";
import "./caringCommunity.scss";
import "moment/locale/es";
import { ItemCaring } from "./itemCaring";
import { ToolDiscount } from "../../components/toolDiscount/toolDiscount";
import {Col, Row} from "antd";
import {FooterHome} from "../../components/footer/footerHome";

export function CaringCommunity(props) {
  return (
    <section className="dashboard-container-page">
      <div className="content_surveys w-100">
        <Row gutter={16}>
          <Col xs={24}>
            <h2>Comunidad Solidaria | Acumulado $80,150</h2>
          </Col>
          <Col xs={24}>
            <div className="caring-community-header">
              <p className="badge-dashed green">
                Sabemos que aún hay muchas causas que se pueden apoyar.
                <br />
                Si conoces alguna compártenos su información por chat a PrideMx
                Administrador.
              </p>
              <ToolDiscount isCaringCommunity={true} />
            </div>
          </Col>
          <Col xs={24}>
            {/* {process.env.REACT_APP_ENVIROMENT == "prod" && ( */}
            <div className="iframe-powerbi">
              <iframe
                  title="DashboardBanner prueba"
                  width="600"
                  height="373.5"
                  src="https://app.powerbi.com/view?r=eyJrIjoiNjk4N2JjMTUtNTQxYS00OGQyLWI0M2ItM2RiZWFlM2UyNDlmIiwidCI6IjkzNTk1ZjgyLWNhMzgtNDZiMy1hOTZlLTgzY2FjYTI5ZTMwNSJ9&pageName=ReportSection"
                  frameborder="0"
                  allowFullScreen="true"
              ></iframe>
            </div>
            {/* )} */}
          </Col>
          <Col xs={24}>
            <h3>Fundaciones sin fines de lucro</h3>
            <ItemCaring />
          </Col>
        </Row>
      </div>
      <Row>
        <Col xs={24}>
          <FooterHome />
        </Col>
      </Row>
    </section>
  );
}
