import swal from "sweetalert";
import { callApi } from "./service"

export const paymentService = {
    sendRedPayCardToken,
    verifyCard
};

async function sendRedPayCardToken(param, directoryId) {
    return callApi(`/BackOffice/RedPayCardToken?directoryInfoid=${directoryId}`, 'POST', param)
        .then(result => {
            if (result.responseCode === "00") {
                swal({ text: "Exito", icon: "success" });
                return result.data;
            }
            else
                swal({ text: "Error al guardar la tarjeta ", icon: "error" });
        });
}

async function verifyCard(param, directoryId) {
    return callApi(`/BackOffice/VerifyCard?directoryInfoid=${directoryId}`, 'POST', param)
        .then(result => {
            return result;
        });
}