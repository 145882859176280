import React, { Component } from "react";
import { ToolDiscount } from "../../../components/toolDiscount/toolDiscount";

import ProviderImage from "../../../components/providerImage/providerImage";
import ProviderVideo from "../../../components/providerVideo/providerVideo";
import ItemProduct from "../../../components/itemProduct/itemProduct";

import "./provider.scss";
import { ecommerceService } from "../../../services/ecommerce.service";
import {Col, Row} from "reactstrap";
import {FooterHome} from "../../../components/footer/footerHome";

export class Provider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      providerProducts: {
        provider: {},
        products: [],
      },
    };
    this.isCaringCommunity =
      props.location.pathname.includes("caring-community");
  }

  componentWillMount() {
    ecommerceService
      .getProviderProducts(this.props.match.params.id)
      .then((result) => {
        this.setState({ providerProducts: result });
      });
  }

  render() {
    const { providerProducts } = this.state;
    const { provider } = providerProducts;
    const { products } = providerProducts;
    return (
      <div className="dashboard-container-page">
          <div className="provider-header">
            <div className="provider-header-logo">
                <img src={provider.urlLogoClrs} alt="LogoProvider" />
              </div>
            <ToolDiscount isCaringCommunity={this.isCaringCommunity} />
          </div>
          <h3>{provider.info ? provider.info : "Compra aquí tu tarjeta de regalo con los mejores descuentos"}</h3>

        <Row>
          <Col sm={12}>
            <div className="info_media">
              {provider.urlVideo != "" ? (
                <ProviderVideo urlVideo={provider.urlVideo} />
              ) : (
                <ProviderImage urlImageVideo={provider.urlImageVideo} />
              )}
            </div>
          </Col>
            <Col sm={12}>
                <div className="items-promo">
                    {products.map((item) => (<ItemProduct product={item} provider={provider} />))}
                </div>
            </Col>
            <Col sm={12}>
                <FooterHome />
            </Col>
        </Row>
      </div>
    );
  }
}
