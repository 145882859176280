import { callApi, callApiFileStorage } from "./service";
import swal from "sweetalert";

export const directoryTypeService ={
    getDirectoryType
};

async function getDirectoryType(){
    return callApi(`/BackEnd/DirectoryType`, "GET").then((result) => {
        if (result.responseCode == "00") {
          return result.data;
        } else {
          return null;
        }
      });
}