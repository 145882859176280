import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { JobBankService } from "../../services/jobBank.service";
import { BsChevronLeft } from "react-icons/bs";
import { Button, Col, FormGroup, Label, Row } from "reactstrap";
import { FaChevronRight, FaPlus } from "react-icons/fa";

export function FormSection({ toggleView, job }) {
  const [sections, setSections] = useState([]);
  const [sectionValues, setSectionValues] = useState([]);

  useEffect(() => {
    JobBankService.getJobSections().then((data) => {
      setSections(data);
      setSectionValues(
        data.map((section) => {
          return {
            JobSectionTypeId: section.jobSectionTypeId,
            JobSection: {
              Description: "",
              JobSectionTypeId: section.jobSectionTypeId,
            },
          };
        })
      );
    });
  }, []);

  const onEditorStateChange = (editorState, section) => {
    let descriptionHtml = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    let newSectionValues = sectionValues.map((x) => {
      if (x.JobSectionTypeId === section.jobSectionTypeId) {
        x.JobSection.Description = descriptionHtml;
        x.JobSection.jobSectionTypeId = section.jobSectionTypeId;
      }
      return x;
    });
    setSectionValues(newSectionValues);
  };

  const onSave = (e) => {
    var validSections = false;
    sectionValues.map((x) => {
      if (
        x.JobSection.Description != "" &&
        x.JobSection.description != "<p></p>" &&
        x.JobSection.description != "<p></p>\n"
      )
        validSections = true;
    });
    if (validSections == false) {
      Swal.fire({
        text: "Favor de completar al menos una sección",
        icon: "warning",
        showCloseButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      return;
    }
    let JobSections = {
      JobId: job?.jobId,
      JobSection: sectionValues.flatMap((x) => x.JobSection),
    };
    JobBankService.insertJobSections(JobSections).then((data) => {
      if (data !== null)
        Swal.fire({
          title: "Registro exitoso",
          text: "Nuestro equipo está revisando tu información y en breve podrás verla disponible.",
          icon: "success",
          showCloseButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then(() => {
          window.location.href = "/job-bank";
        });
    });
  };

  const returnJob = () => {
    if (job?.jobId) {
      window.location.href = "/edit-job/" + job.jobId;
    } else {
      toggleView();
    }
  };

  return (
    <>
      <article className="steps">
        <div className="item active">
          <span>1</span>
        </div>
        <div className="lines"></div>
        <div className="item active">
          <span>2</span>
        </div>
      </article>

      <article>
        <div className="flex-end">
          <button
            type="button"
            className="btn-back"
            onClick={() => returnJob()}
          >
            <i>
              <BsChevronLeft />
            </i>
            <span>Volver</span>
          </button>
        </div>
        <Row>
          <Col sm={12}>
            <h6>Información adicional</h6>
          </Col>
          {sections.map((section, index) => {
            return (
              <div key={index}>
                <Col sm={12} className="form-dashboard-group">
                  <Label>
                    {section.name === "Ubicacion" ? "Ubicación" : section.name}
                  </Label>
                </Col>
                <Col sm={12}>
                  <FormGroup>
                    <Editor
                      onEditorStateChange={(e) =>
                        onEditorStateChange(e, section)
                      }
                      wrapperClassName="wrapper__editor_text"
                      editorClassName="editor-class"
                      toolbarClassName="toolbar-class"
                      toolbar={{
                        options: [
                          "inline",
                          "colorPicker",
                          "blockType",
                          "fontSize",
                          // "fontFamily",
                          "list",
                          "textAlign",
                          // "image",
                          "link",
                          "remove",
                          "history",
                        ],
                        inline: {
                          options: [
                            "bold",
                            "italic",
                            "underline",
                            "strikethrough",
                          ],
                        },
                        list: { inDropdown: true },
                        textAlign: { inDropdown: true },
                        link: { inDropdown: true },
                        history: { inDropdown: true },
                      }}
                    />
                  </FormGroup>
                </Col>
              </div>
            );
          })}
        </Row>
        <Row>
          <Col sm={12}>
            <div className="form-dashboard-footer">
              <Button className="btn-dark" onClick={onSave}>
                <p>Registrar empleo</p>
                <FaChevronRight />
              </Button>
            </div>
          </Col>
        </Row>
      </article>
    </>
  );
}
