import React from "react";
import {Card} from "antd";

export const EventCardInformative = ({title,subtitle,paragraph}) => {

    return (
        <Card className={'event-pride-ticket-card'}>
            <h4>{title}</h4>
            <h2>{subtitle}</h2>
            <p>{paragraph}</p>
        </Card>
    );
}
