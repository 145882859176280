import React, {useEffect, useState, forwardRef, useImperativeHandle, useRef} from 'react';
import { companyPostService } from '../../services/companyPost.service';
import {Button, Input, Space, Table} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import moment from "moment/moment";
import {FaEdit, FaTrash} from "react-icons/fa";
import MySwal from "sweetalert2";
import dayjs from "dayjs";

const SearchGalleries = forwardRef((props, ref) => {

    const [dataGrid, setDataGrid] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    useImperativeHandle(ref, () => ({
        refreshDataGrid: refreshDataGrid
    }));

    const handleDelete = (event, data) => {
        MySwal.fire({
            title: '¿Estás seguro?',
            text: "No podrás revertir esta acción",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#FF002A',
            confirmButtonText: 'Eliminar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if(result.isConfirmed)
                companyPostService.deletet(data).then((result) => getAll());
        })
    };

    async function getAll() {
        companyPostService.getAll(1)
            .then((result) => {
                if (result) setDataGrid(result);
            });
    }

    const refreshDataGrid = () => getAll();

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{padding: 8,}} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Buscar`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{marginBottom: 8, display: 'block',}}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{width: 90,}}
                    >
                        Buscar
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{width: 90,}}
                    >
                        Limpiar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{color: filtered ? '#1677ff' : undefined,}}/>
        ),
        onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) setTimeout(() => searchInput.current?.select(), 100);
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{backgroundColor: '#ffc069', padding: 0,}}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (text),
    });

    const columns = [
        {
            title: 'Título de álbum',
            dataIndex: 'title',
            key: 'title',
            ...getColumnSearchProps('title'),
        },
        {
            title: 'Fecha de publicación',
            dataIndex: 'date',
            key: 'date',
            sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
        },
        {
            title: 'Elementos',
            dataIndex: 'elements',
            key: 'elements',
            sorter: (a, b) => {
                if(a.elements < b.elements) {
                    return -1;
                }
                if(a.elements > b.elements) {
                    return 1;
                }
                return 0;
            },
            sortDirections: ['descend','ascend'],
        },
        {
            title: 'Acciones',
            dataIndex: 'options',
            key: 'options',
        },
    ];

    const data = dataGrid.map((item,i) => {
        return {
            key: i,
            title: item.title,
            date: dayjs(item.publishDate).format('DD/MMM/YYYY'),
            elements: `${item.galleryImageCount} Elemento(s)`,
            options: <Space>
                <Button
                    type={'primary'}
                    htmlType={'button'}
                    className="edit"
                    onClick={(event) => props.handleRowSelect(item)}
                >
                    <FaEdit />
                </Button>
                {item.active ?
                    <>
                        <Button
                            type={'primary'}
                            htmlType={'button'}
                            className="delete"
                            onClick={(event) => handleDelete(event, item)}
                        >
                            <FaTrash />
                        </Button>
                    </>
                    : null
                }
            </Space>
        };
    })

    useEffect(() => {
        getAll();
    }, []);

    return (
        <>
            <Table
                dataSource={data}
                columns={columns}
                className={'table-admin'}
                scroll={{ x: '992px', y: 800 }}
                locale={{
                    triggerDesc: 'Ordenamiento descendente',
                    triggerAsc: 'Ordenamiento ascedente',
                    cancelSort: 'Cancelar ordenamiento',
                    emptyText: 'Sin registros',
                }}
            />
        </>
    );

});

export default SearchGalleries;
