import { callApi, callApiFile } from './service'
import Swal from "sweetalert2";

export const useSurvey = {
    createSurvey,
    updateSurvey,
    deleteSurvey,
    getSurveys,
    getSurveysById,
    getSurveysInfoById,
    getChartData,
    downloadCSV
};
async function createSurvey(params) {
    return callApi('/backoffice/Survey/Create', 'POST', params).then((result) => {
        if (result.responseCode === '00') {
            Swal.fire({
                text: "Se guardó exitosamente",
                icon: "success"
            }).then(() => window.location.href = '/admin/details-survey/' + result.data)
        } else {
            console.log(result.responseMessage);
            Swal.fire({ text: "Error al guardar", icon: "error" });
        }
    });
}
async function getSurveys() {
    return callApi('/backoffice/Survey', 'GET').then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            alert(result.responseMessage);
            return null;
        }
    });
}

async function getSurveysById(id) {
    return callApi('/backoffice/Survey/Get/' + id, 'GET').then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            Swal.fire({
                text: result.responseMessage,
                icon: "error"
            }).then((value) => {
                window.location.href = '/AdminSurvey'
            });
            return null;
        }
    });
}

async function getSurveysInfoById(id) {
    return callApi('/backoffice/Survey/GetSurveyInfo/' + id, 'GET').then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            Swal.fire({
                text: result.responseMessage,
                icon: "error"
            }).then((value) => {
                window.location.href = '/AdminSurvey'
            });
            return null;
        }
    });
}

async function getChartData(surveyId, questionId, surveyGroupId) {
    return callApi('/backoffice/Survey/GetChartData/' + surveyId + '/' + questionId + '/' + surveyGroupId, 'GET').then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            alert(result.responseMessage);
            return null;
        }
    });
}
async function updateSurvey(params) {
    return callApi('/backoffice/Survey/PutSurvey', 'PUT', params).then((result) => {
        if (result.responseCode === '00') {
            Swal.fire({
                text: "La encuesta se guardó exitosamente",
                icon: "success"
            })
        } else {
            console.log(result.responseMessage);
            Swal.fire({
                text: "Error al guardar encuesta",
                icon: "error"
            });
        }
    });
}
async function deleteSurvey(params) {
    return callApi('/backoffice/Survey', 'DELETE', params).then((result) => {
        if (result.responseCode === '00') {
            Swal.fire({
                text: "La encuesta se desactivó correctamente",
                icon: "success"
            })
            return result.data;
        } else {
            console.log(result.responseMessage);
            Swal.fire({
                text: "Error al desactivar encuesta",
                icon: "error"
            });
            return null;
        }
    });
}

async function downloadCSV(id) {
    return callApiFile('/backoffice/Survey/GetCSV/' + id, 'GET').then(function (blob) {
        if (blob.size !== 0) {
            const FileSaver = require("file-saver");
            FileSaver.saveAs(blob, "Encuesta" + id + ".csv");
        }
    });
}
