import React from 'react';
import { EditRewardProvider } from './editRewardProvider'
import {Header} from "../header";

export function DetailsRewardProvider(props) {

    const buttons = [
        {
            "id": 1,
            "nameButton": "Agregar proveedor",
            "link": "/operator/details-rewardProvider/0"
        }
    ];

    return (
        <div className="dashboard-operator-body">
            <Header
                title={props.match.params.id != 0 ? "Editar proveedor" : "Nuevo proveedor"}
                description=""
                buttons={buttons}
                btnBack={[
                    {
                        "id": 2,
                        "nameButton": "Regresar",
                        "link": "/operator/providers/1"
                    }
                ]}
            />
            <EditRewardProvider />
        </div>
    );
}   
