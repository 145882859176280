import React, {useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import { productFilterService } from '../../services/backoffice/rewardProductFilter.service';
import {Button, Input, Space, Table} from "antd";
import {FaEdit, FaTrash} from "react-icons/fa";
import MySwal from "sweetalert2";
import {SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";

export function SearchProductFilter(props) {
    const [rewardProductFilterData, setRewardProductFilterData] = useState({ rewardProductFilter: [] });
    const history = useHistory();
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const handleDelete = (data) => {
        MySwal.fire({
            title: "¿Estás seguro que deseas borrar esta recompensa?",
            text: "No podrás revertir esta acción",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#FF002A',
            confirmButtonText: 'Eliminar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if(result.isConfirmed)  productFilterService.deleteProductFilter(data).then((result) => {  getRewardProductFilters(); });
        })
    };

    async function getRewardProductFilters() {
        productFilterService.getAll().then((result) => {
            if (result) {
                setRewardProductFilterData({ rewardProductFilter: result });
            }
        });
    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{padding: 8,}} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Buscar`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{marginBottom: 8, display: 'block',}}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        htmlType={'button'}
                        style={{width: 90,}}
                    >
                        Buscar
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        htmlType={'button'}
                        style={{width: 90,}}
                    >
                        Limpiar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{color: filtered ? '#1677ff' : undefined,}}/>
        ),
        onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) setTimeout(() => searchInput.current?.select(), 100);
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{backgroundColor: '#ffc069', padding: 0,}}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (text),
    });

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            fixed: 'left',
            width: 80
        },
        {
            title: 'Producto',
            dataIndex: 'product',
            key: 'product',
            ...getColumnSearchProps('product'),
            sorter: (a, b) => {
                if(a.product.length < b.product.length) {
                    return -1;
                }
                if(a.product.length > b.product.length) {
                    return 1;
                }
                return 0;
            },
        },
        {
            title: 'Sucursal',
            dataIndex: 'branchOffice',
            key: 'branchOffice',
            sorter: (a, b) => {
                if(a.branchOffice.length < b.branchOffice.length) {
                    return -1;
                }
                if(a.branchOffice.length > b.branchOffice.length) {
                    return 1;
                }
                return 0;
            },
            sortDirections: ['descend','ascend'],
        },
        {
            title: 'Grupo',
            dataIndex: 'group',
            key: 'group',
        },
        {
            title: 'Categoría',
            dataIndex: 'category',
            key: 'category',
            sorter: (a, b) => {
                if(a.category.length < b.category.length) {
                    return -1;
                }
                if(a.category.length > b.category.length) {
                    return 1;
                }
                return 0;
            },
            sortDirections: ['descend','ascend'],
        },
        {
            title: 'Stock',
            dataIndex: 'stock',
            key: 'stock',
            sorter: (a, b) => {
                if(a.stock.length < b.stock.length) {
                    return -1;
                }
                if(a.stock.length > b.stock.length) {
                    return 1;
                }
                return 0;
            },
            sortDirections: ['descend','ascend'],
        },
        {
            title: 'Puntos',
            dataIndex: 'points',
            key: 'points',
            sorter: (a, b) => a.points - b.points,
            sortDirections: ['descend','ascend'],
        },
        {
            title: 'Acciones',
            dataIndex: 'options',
            key: 'options',
        },
    ];

    const dataSource = rewardProductFilterData.rewardProductFilter.map(pf => {
        return {
            key: pf.rewardProductFilterId,
            id: pf.rewardProductFilterId,
            product: pf.productDescription,
            branchOffice: pf.branchOfficeName == null ? "<Todas>" : pf.branchOfficeName,
            group: pf.departmentName == null ? "<Todos>" : pf.departmentName,
            category: pf.roleName == null ? "<Todos>" : pf.roleName,
            stock: pf.filterStock,
            points: pf.points,
            options: <><Space>
                <Button type="primary" htmlType={'button'} className="edit" onClick={() => history.push('/admin/detail-product-filter/'+pf.rewardProductFilterId)}>
                    <FaEdit />
                </Button>
                <Button type="primary" htmlType={'button'} className="delete" onClick={() => handleDelete(pf.rewardProductFilterId)}>
                    <FaTrash />
                </Button>
            </Space></>
        };
    })

    useEffect(() => {
        getRewardProductFilters();
    }, [props.refresh]);

    return (
        <div className='wpr_quest_search'>
            <Table columns={columns} dataSource={dataSource} className="table-admin" scroll={{ x: '992px', y: 800 }}
                   locale={{
                       triggerDesc: 'Ordenamiento descendente',
                       triggerAsc: 'Ordenamiento ascedente',
                       cancelSort: 'Cancelar ordenamiento',
                       emptyText: 'Sin registros',
                   }}
            />
        </div>
    );
}
