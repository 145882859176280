import React from "react";
import { Link } from "react-router-dom";
import { ModalContact } from '../modalContact/modalContact'
import "./footer.scss";

export function FooterHome(props) {
  return (
    <footer
      className={`dashboard_footer ${props.inMuro ? "in_muro" : "general"}`}
    >
      <ul>
        <li>
            <ModalContact />
        </li>
        <li>
          <Link className="link_footer" to="/ambienteseguro.pdf" target="_blank">
            Decálogo
          </Link>
        </li>
        <li>
          <Link className="link_footer" to="/avisoprivacidad.pdf" target="_blank">
            Aviso de Privacidad
          </Link>
        </li>
        <li>
          <Link className="link_footer" to="/terminosycondiciones.pdf" target="_blank">
            Términos y Condiciones
          </Link>
        </li>
      </ul>
    </footer>
  );
}
