import React, {useEffect, useState} from 'react';
import {helperService} from '../../services/helper.service';
import {userProfileService} from '../../services/backoffice/userProfile.service';
import MultiSelect from "react-multi-select-component";
import {FaChevronRight} from "react-icons/fa";
import {Col, Form, Row, Button, Select} from "antd";
import {Table} from "reactstrap";
import {Pagination} from "rsuite";

export const SelectUsers = ({ selectUser, title, filter, usersFilter }) => {
    const [configInit,setConfigInit] = useState({
        Departments: [],
        JobRoleId: 0
    })
    const [departaments, setDepartaments] = useState([]);
    const [userSelec, setUserSelec] = useState([]);
    const [dataUsers, setDataUsers] = useState({
        list: [],
        totalPages: 1,
        totalCount: 0,
        pageIndex: 1
    });
    const [usersNotInFilter, setUsersNotInFilter] = useState([]);
    const [dataAllUsers, setDataAllUsers] = useState([]);
    const [pageCount, setPageCount] = useState(10);
    const [jobRoles, setJobRoles] = useState([]);

    const EventFilter = (result, pageIndex) => {
        if (result) {
            if (filter) {
                setDataUsers({ ...dataUsers,
                    list: result.list,
                    totalCount: result.totalCount,
                    totalPages: result.totalPages,
                    pageIndex: pageIndex
                });

                const datauser = { list:
                    result.list, totalCount: result.totalCount,
                    totalPages: result.totalPages,
                    pageIndex: pageIndex
                }
                if (dataAllUsers.length > 0) {
                    const datauser = {
                        list: result.list,
                        totalCount: result.totalCount,
                        totalPages: result.totalPages,
                        pageIndex: pageIndex
                    }
                    JointUser(datauser, dataAllUsers);
                } else if (userSelec.length > 0) setCheck(datauser);
            }
            else {
                setDataUsers({ ...dataUsers,
                    list: result.list,
                    totalCount: result.totalCount,
                    totalPages: result.totalPages,
                    pageIndex: pageIndex
                });
                const datauser = {
                    list: result.list,
                    totalCount: result.totalCount,
                    totalPages: result.totalPages,
                    pageIndex: pageIndex
                }
                if (dataAllUsers.length > 0) {
                    JointUser(datauser, dataAllUsers);
                } else if (userSelec.length > 0) { setCheck(datauser); }
            }
        }
    }

    const handleOnSearch = (pageIndex) => {
        if (configInit.Departments.length > 0) {
            const model = {
                Departments: configInit.Departments.map(e => e.value).join(","), JobRoleId: parseInt(configInit.JobRoleId) === 0 ? 0 : configInit.JobRoleId, BranchOfficeId: 0,
                PageIndex: pageIndex,
                PageCount: pageCount,
            };

            if (usersFilter !== undefined) {
                if (usersNotInFilter.length === 0 || parseInt(pageIndex) === 1) {
                    userProfileService.getUsersByFilter(model)
                        .then((result) => {
                            let NotInList = result;
                            let start = (model.PageCount * pageIndex) - model.PageCount;
                            let end = (model.PageCount * pageIndex);

                            usersFilter.map((user) => NotInList = NotInList.filter((item) => parseInt(item.userProfileId) !== user.id));

                            let totalPa = Math.trunc(NotInList.length / model.PageCount);
                            totalPa = Number.isInteger(totalPa) ? totalPa : Math.trunc(totalPa) + 1;
                            let pageitems = NotInList.slice(start, end);
                            setUsersNotInFilter(NotInList);
                            setDataUsers({
                                ...dataUsers,
                                list: pageitems,
                                totalCount: NotInList.length,
                                totalPages: totalPa,
                                pageIndex: pageIndex
                            });
                        });
                }
                else {
                    let start = (model.PageCount * pageIndex) - model.PageCount;
                    let end = (model.PageCount * pageIndex);
                    let pageitems = usersNotInFilter.slice(start, end);
                    let totalPa = (usersNotInFilter.length / model.PageCount);
                    totalPa = Number.isInteger(totalPa) ? totalPa : Math.trunc(totalPa) + 1;
                    setDataUsers({
                        ...dataUsers,
                        list: pageitems,
                        totalCount: usersNotInFilter.length,
                        totalPages: totalPa,
                        pageIndex: pageIndex
                    });
                }
            } else userProfileService.getUsersByFilterPage(model).then((result) => EventFilter(result, pageIndex));
        }
    }

    const handleSelectUser = (event, data) => {
        let localUsers = [];
        data.check = event.target.checked;
        if (data.check) {
            localUsers = [...userSelec, {
                id: data.userProfileId,
                name: `${data.middleName} ${data.lastName} ${data.firstName}`,
                department: data.department?.departmentName
            }];
            setUserSelec(localUsers);
        } else {
            localUsers = userSelec.filter((item) => parseInt(item.id) !== parseInt(data.userProfileId));
            setUserSelec(localUsers);
        }
        selectUser(localUsers);
        if (dataAllUsers.length > 0) {
            let itemAll = dataAllUsers.find((x) => parseInt(x.userProfileId) === parseInt(data.userProfileId));
            itemAll.check = data.check;
        }
    };

    const JointUser = (userData, usersAll) => {
        userData.list.map(data => {
            let itemAll = usersAll.find((item) => parseInt(item.userProfileId) === parseInt( data.userProfileId));
            data.check = itemAll.check;
        });
        setDataUsers({
            ...dataUsers,
            list: userData.list,
            totalCount: userData.totalCount,
            totalPages: userData.totalPages,
            pageIndex: userData.pageIndex
        });
    }

    const setCheck = (userData) => {
        userData.list.map(data => {
            let itemAll = userSelec.find((item) => parseInt(item.id) === parseInt(data.userProfileId));
            if (itemAll) data.check = true;
        });

        setDataUsers({
            ...dataUsers,
            list: userData.list,
            totalCount: userData.totalCount,
            totalPages: userData.totalPages,
            pageIndex: userData.pageIndex
        });
    }

    const handleSelectUserAll = (event) => {
        setUserSelec([]);
        let localUsers = [];
        let status = event.target.checked;
        dataUsers.list.map(data => data.check = event.target.checked);

        if (dataAllUsers.length === 0) {
            const model = {
                Departments: configInit.Departments.map(e => e.value).join(","),
                JobRoleId: parseInt(configInit.JobRoleId === '' ? 0 : configInit.JobRoleId), BranchOfficeId: 0
            };

            userProfileService.getUsersByFilter(model)
                .then((result) => {
                    if (result) {
                        let users = result;
                        users.map(data => {
                            if (status && !data.check) {
                                userSelec.push({
                                    id: data.userProfileId,
                                    name: `${data.middleName} ${data.lastName} ${data.firstName}`,
                                    department: data.department?.departmentName
                                })
                                setUserSelec([...userSelec]);
                                localUsers = userSelec;
                            }

                            data.check = status;
                        });
                        selectUser(localUsers);
                        setDataAllUsers(users);
                    }
                });
        } else {
            let users = dataAllUsers;
            users.map(data => {
                if (status && !data.check) {
                    userSelec.push({
                        id: data.userProfileId,
                        name: `${data.middleName} ${data.lastName} ${data.firstName}`,
                        department: data.department?.departmentName
                    })
                    setUserSelec([...userSelec]);
                    localUsers = userSelec;
                }

                data.check = status;
            });
            selectUser(localUsers);
            setDataAllUsers(users);
            JointUser(dataUsers, users);
        }

    }

    const handleSelectPage = (page) => handleOnSearch(page);

    const getDepartaments = () => {
        helperService.getGenericCatalog("/backoffice/Department")
            .then((result) => {
                if (result) setDepartaments(result.map(item => ({
                    label: item.departmentName,
                    value: item.departmentId
                })))
                else setDepartaments([]);
            });
    }

    const getCatalogJobRole = async () => {
        helperService.getGenericCatalog("/backoffice/JobRole")
            .then((result) => {
                if (result) setJobRoles(result.map(item => {
                    return {
                        "label": item.name,
                        "value": item.jobRoleId
                    }
                }));
            });
    }

    const handleSelectJobRole = (value) => setConfigInit({...configInit, JobRoleId: value})

    useEffect(() => {
        getDepartaments();
        getCatalogJobRole();
    }, []);

    useEffect(() => {
        handleOnSearch(1);
    }, [usersFilter]);

    return (
        <Row gutter={16}>
            <Col xs={24}><h5>{title}</h5></Col>
            <Col md={12} sm={24} xs={24}>
                <Form.Item
                    label={'Grupos'}
                    htmlFor={'DepartmentId'}
                >
                    <MultiSelect
                        name="DepartmentId"
                        options={departaments}
                        value={configInit.Departments}
                        onChange={(e) => setConfigInit({ ...configInit, Departments: e })}
                        overrideStrings={{
                            "allItemsAreSelected": "Todos los grupos.",
                            "clearSearch": "Borrar búsqueda",
                            "noOptions": "Sin opciones",
                            "search": "Buscar",
                            "selectAll": "Seleccionar todo",
                            "selectSomeItems": "Selecciona una opción"
                        }}
                        labelledBy={''}/>
                </Form.Item>
            </Col>
            <Col md={12} sm={24} xs={24}>
                <Form.Item
                    label="Categorías"
                    htmlFor={'JobRoleId'}
                    name={'JobRoleId'}
                >
                    <Select
                        name={'JobRoleId'}
                        onChange={handleSelectJobRole}
                        defaultValue={configInit.JobRoleId}
                    >
                        <Select.Option key={0} value={0}>Todos</Select.Option>
                        {
                            jobRoles.map(item => {
                                return (
                                    <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>
                                )
                            })
                        }
                    </Select>
                </Form.Item>
            </Col>
            <Col xs={24}>
                <div className={'form-backoffice-footer'}>
                    <Button type="primary" htmlType={'button'} onClick={() => handleOnSearch(1)}>
                        <p>Consultar</p>
                        <FaChevronRight />
                    </Button>
                </div>
            </Col>
            <Col xs={24}>
                <div className="table-responsive">
                    <Table className="admin-table" hover>
                        <thead>
                        <tr>
                            <th>No. Usuario</th>
                            <th>Nombre de Usuario</th>
                            <th>Grupo</th>
                            <th>
                                {dataUsers.list.length > 0 ?
                                    <label className="toggler-wrapper dark" htmlFor="checkboxAll">
                                        <input type="checkbox" id="checkboxAll" onClick={handleSelectUserAll} />
                                        <div className="toggler-slider">
                                            <div className="toggler-knob"></div>
                                        </div>
                                        <p>Seleccionar</p>
                                    </label>
                                    : "Seleccionar"}
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {dataUsers.list.length > 0 ? dataUsers.list.map(item => (
                                <tr key={item.userProfileId}>
                                    <td>{item.employeeNo}</td>
                                    <td>{item.middleName} {item.lastName} {item.firstName}</td>
                                    <td>{item.department?.departmentName}</td>
                                    <td>
                                        <label className="toggler-wrapper dark" htmlFor={`check-${item.userProfileId}`}>
                                            <input type="checkbox" id={`check-${item.userProfileId}`} onClick={(event) => handleSelectUser(event, item)} checked={item.check} />
                                            <div className="toggler-slider">
                                                <div className="toggler-knob"></div>
                                            </div>
                                        </label>
                                    </td>
                                </tr>
                            )) :
                            <tr key={1}>
                                <td colSpan="4" className="text-center"><p>No hay registros</p></td>
                            </tr>
                        }
                        </tbody>
                    </Table>
                    {
                        dataUsers.list.length > 0 ?
                            <div className={'admin-table-footer'}>
                                <Pagination
                                    prev last next first
                                    size="lg"
                                    ellipsis maxButtons={10}
                                    boundaryLinks pages={dataUsers.totalPages}
                                    activePage={dataUsers.pageIndex}
                                    onSelect={handleSelectPage}
                                />
                            </div>
                            : ""
                    }
                </div>
            </Col>
        </Row>
    );
}
