import React, { useState, useEffect } from "react";
import { directoryInfoService } from "../../services/directoryService.service";
import { Link } from "react-router-dom";
import ImgRecompensas from "../../assets/imgs/iconos/ico_recompensas.png";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { useHistory } from "react-router-dom";
import { Button, Tooltip } from "antd";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import Swal from "sweetalert2";

export const ItemDirectory = ({
  directoryItem,
  isAdmin,
  setdirectoryInfoToAuthorize,
  convertToArray,
  profileId,
  sectionOn,
  updateListP,
  updateListA,
  noPost,
  noDaysGrace,
}) => {
  const minValue = 1;
  const maxValue = 5;
  const [estrellas, setEstrellas] = useState([0, 0, 0, 0, 0]);
  const [avrScore, setAvrScore] = useState(0);
  const [avrText, setAvrText] = useState(0);
  const [avrScoreA, setAvrScoreA] = useState(0);
  const [avrScoreB, setAvrScoreB] = useState(0);
  const [avrScoreC, setAvrScoreC] = useState(0);
  const [edit, setEdit] = useState(true);
  const [editA, setEditA] = useState(true);
  const [editB, setEditB] = useState(true);
  const [editC, setEditC] = useState(true);
  const [isEval, setIsEval] = useState(false);
  const [evalA, setEvalA] = useState(0);
  const [evalB, setEvalB] = useState(0);
  const [evalC, setEvalC] = useState(0);
  let history = useHistory();

  const [activeBoxTooltip, setBoxTooltip] = useState("");

  // const showBoxTooltip = () => {
  //   setBoxTooltip(activeBoxTooltip === "" ? " is-show" : "");
  // };

  const directoryContacts = directoryItem.directoryContacts;
  const directoryRatings = directoryItem.directoryRatings;

  /*
    let phoneContact = {};
    let emailContact = {};
    let socialNetworks = [];

    if (directoryContacts && directoryContacts.length > 0) {
      phoneContact = directoryContacts.find(
        (element) => element.contactTypeName == "Teléfono"
      );
      emailContact = directoryContacts.find(
        (element) => element.contactTypeName == "Email"
      );
      socialNetworks = directoryContacts.filter(
        (element) =>
          element.contactTypeName !== "Email" &&
          element.contactTypeName !== "Teléfono"
      );
    }*/

  useEffect(() => {
    let service;
    if (directoryRatings && directoryRatings.length > 0) {
      const sum = directoryRatings.reduce(function (prev, current) {
        return prev + +current.score;
      }, 0);

      const average = sum / directoryRatings.length;
      const averagefloor = Math.floor(average);

      setAvrText(average.toFixed(1));
      setAvrScore(directoryRatings.length);

      const serviceRating = directoryRatings.filter(
        (item) => 1 === item.directoryRatingTypeId
      );
      const priceRating = directoryRatings.filter(
        (item) => 2 === item.directoryRatingTypeId
      );
      const qualityRating = directoryRatings.filter(
        (item) => 3 === item.directoryRatingTypeId
      );

      const uno = directoryRatings.filter((item) => 1 == item.score).length;
      const dos = directoryRatings.filter((item) => 2 == item.score).length;
      const tres = directoryRatings.filter((item) => 3 == item.score).length;
      const cuatro = directoryRatings.filter((item) => 4 == item.score).length;
      const cinco = directoryRatings.filter((item) => 5 == item.score).length;

      setEstrellas([uno, dos, tres, cuatro, cinco]);

      if (serviceRating.length > 0) {
        service = infoType(serviceRating);
        setAvrScoreA(service[0]);
        setEditA(service[1]);
        setEvalA(service[2]);
      }
      if (priceRating.length > 0) {
        service = infoType(priceRating);
        setAvrScoreB(service[0]);
        setEditB(service[1]);
        setEvalB(service[2]);
      }
      if (qualityRating.length > 0) {
        service = infoType(qualityRating);
        setAvrScoreC(service[0]);
        setEditC(service[1]);
        setEvalC(service[2]);
      }
    }
  }, []);

  useEffect(() => {
    if (!editA && !editB && !editC) {
      setEdit(false);
    }
  }, [editA, editB, editC]);

  const infoType = (item) => {
    const data = [0, true, 0];
    const sum = item.reduce(function (prev, current) {
      return prev + +current.score;
    }, 0);
    const average = sum / item.length;
    const averagefloor = Math.floor(average);
    data[0] = averagefloor;
    const userScore = item.filter((item) => profileId == item.userProfileAsp);
    if (userScore.length > 0) {
      data[1] = false;
      data[2] = userScore[0].score;
    }
    return data;
  };

  const approveService = (directoryItem) => {
    Swal.fire({
      title: "¿Desea aprobar esta publicación?",
      text: "No podrás revertir esta acción",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "rgb(16, 173, 79)",
      confirmButtonText: "Aprobar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        directoryInfoService
          .approveDirectoryGrace(
            directoryItem.directoryInfoId,
            directoryItem.creatorEmail,
            noPost,
            noDaysGrace
          )
          .then((response) => {
            if (response)
              Swal.fire({
                text: "Publicación aceptada exitosamente",
                icon: "success",
              });
            updateListP();
            updateListA();
          });
      }
    });
  };

  const rejectService = (directoryItem) => {
    Swal.fire({
      title: "¿Desea rechazar esta publicación?",
      text: "No podrás revertir esta acción",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#FF002A",
      confirmButtonText: "Rechazar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        directoryInfoService
          .rejectDirectory(directoryItem.directoryInfoId)
          .then((response) => {
            if (response) {
              directoryInfoService
                .getAllByCompany(process.env.REACT_APP_COMPANY, 1)
                .then((dataToAuth) => {
                  setdirectoryInfoToAuthorize(convertToArray(dataToAuth));
                  Swal.fire({
                    text: "Publicación rechazada exitosamente",
                    icon: "success",
                  });
                  updateListP();
                });
            }
          });
      }
    });
  };

  /*const insertScore = (score, type) => {
    const data = new FormData();
    data.append("DirectoryRatingId", 0);
    data.append("DirectoryInfoId", directoryItem.directoryInfoId);
    data.append("DirectoryRatingTypeId", type);
    data.append("UserProfileId", 0);
    data.append("UserProfileAsp", profileId);
    data.append("Score", score);
    data.append("Active", true);

    directoryInfoService.setDirectoryScore(data).then((response) => {
      if (response) {
        if (type == 1) setEditA(false);
        if (type == 2) setEditB(false);
        if (type == 3) setEditC(false);
        return true;
      }
    });
    return false;
  }*/

  // const imageBack = () => {
  //   let urlbackground = "";
  //   if (Array.isArray(directoryItem.contactImage)) urlbackground = directoryItem.contactImage[0];
  //   else urlbackground = emptyImg;

  //   return (<div className="img-item-blur" style={{backgroundImage: `url(${urlbackground})`,}}></div>);
  // };

  return (
    <>
      {isAdmin && directoryItem.directoryInfoStatusName === "En trámite" ? (
        <div className="card-directory-admin">
          <Button
            size={"small"}
            type={"primary"}
            htmlType={"button"}
            className="success"
            onClick={() => approveService(directoryItem)}
          >
            Autorizar
            <FaCheckCircle />
          </Button>
          <Button
            size={"small"}
            className="cancel"
            type={"primary"}
            htmlType={"button"}
            onClick={() => rejectService(directoryItem)}
          >
            Rechazar
            <FaTimesCircle />
          </Button>
        </div>
      ) : null}
      <div className={`card-directory-main ${isAdmin ? "admin" : ""}`}>
        <div className="card-directory-main-thumbnail">
          {directoryItem.isEnableRewards ? (
            <Link to="/Rewards" className="img-rewards">
              <img
                src={ImgRecompensas}
                alt="Este articulo tiene recompensas en PrideMx"
              />
            </Link>
          ) : null}
          <Splide
            options={{
              pagination: true,
              arrows: true,
              autoplay: true,
              rewind: true,
              perMove: 1,
              perPage: 1,
              rewindByDrag: true,
              speed: 900,
              drag: true,
            }}
          >
            {directoryItem.contactImage &&
              directoryItem.contactImage.map((itemImage, indice) => {
                return (
                  <SplideSlide>
                    <div className="group-item">
                      <div
                        className="img-item-blur"
                        style={{ backgroundImage: `url(${itemImage})` }}
                      ></div>
                      <img
                        src={itemImage}
                        alt={`${directoryItem.fullName + indice}`}
                      />
                    </div>
                  </SplideSlide>
                );
              })}
          </Splide>
        </div>
        <div className="card-directory-main-content">
          <div className="card-directory-main-content-info">
            <Link
              className={'title-link'}
              to={{
                pathname: !sectionOn
                  ? `/register-services/${directoryItem.directoryInfoId}`
                  : `/details-directory/${directoryItem.directoryInfoId}`,
                state: {
                  Item: directoryItem,
                  profileId: profileId,
                  isPay: false,
                },
              }}
            >
              {directoryItem.fullName?.length > 30
                ? `${directoryItem.fullName.substring(0, 30)}...`
                : directoryItem.fullName}
            </Link>
            <div className={'text-link'}>
              {directoryItem.directoryDescription?.length > 120 ? (
                <>
                  {directoryItem.directoryDescription.substring(0, 120)}...
                  <Link
                    to={{
                      pathname: !sectionOn
                        ? `/register-services/${directoryItem.directoryInfoId}`
                        : `/details-directory/${directoryItem.directoryInfoId}`,
                      state: {
                        Item: directoryItem,
                        profileId: profileId,
                        isPay: false,
                      },
                    }}
                  >
                    Leer más
                  </Link>
                </>
              ) : (
                directoryItem.directoryDescription
              )}
            </div>
          </div>
          <div className="card-directory-main-content-extra">
            {directoryItem.createUserName?.length > 30 ? (
              <Tooltip placement="topLeft" title={directoryItem.createUserName}>
                <h6>{directoryItem.createUserName.substring(0, 30)}...</h6>
              </Tooltip>
            ) : (
              <h6>{directoryItem.createUserName}</h6>
            )}
            <div className="category">
              <span>{directoryItem.categoryServiceName} </span>
              <span className="badge-directory">
                {directoryItem.subCategoryServiceName}
              </span>
            </div>
            <div className="location">
              <Tooltip placement="topLeft" title={directoryItem.location}>
                <p>
                  {directoryItem.location?.length > 25
                    ? directoryItem.location.substring(0, 25) + "..."
                    : directoryItem.location}
                </p>
              </Tooltip>
              <p className="ranking">
                <i className="fa-solid fa-star"></i> {avrText} de 5
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
