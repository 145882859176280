import React, { useState } from 'react';
import { SearchCompany } from './searchCompany';
import {Select, Row, Col, Form} from "antd";
import {Header} from "../header";

export function AdminCompany() {
    
    const [statusSelected, setStatus] = useState(true)
    const handleSelectChange = (value) => setStatus(value);

    return (
        <section className="dashboard-operator-body">
            <Header
                title="Empresas"
                description=""
                buttons={[
                    {
                        "id": 1,
                        "nameButton": "Agregar empresa",
                        "link": "/operator/details-company/0"
                    }
                ]}
                btnBack={[]}
            />
            <Row>
                <Col xs={24}>
                    <Form layout="vertical" className="form-pride" style={{padding: '0'}}>
                        <Form.Item label="Estatus de Empresas">
                            <Select
                                defaultValue={true}
                                size="large"
                                style={{width: '200px'}}
                                onChange={handleSelectChange}
                                options={[
                                    { value: true, label: 'Activo' },
                                    { value: false, label: 'Inactivo' },
                                ]}
                            />
                        </Form.Item>
                    </Form>
                </Col>
                <Col xs={24}>
                    <SearchCompany statusValue={statusSelected} />
                </Col>
            </Row>
        </section>
    );
}
