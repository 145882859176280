import React, { useEffect, useState } from 'react';
import { useQuestion } from '../../services/surveyQuestion.service'
import { Link } from 'react-router-dom/cjs/react-router-dom';
import {Button, Col, Form, Input, Row, Select} from "antd";
import {FaPlus, FaSave} from "react-icons/fa";
import {useSurveyScale} from "../../services/surveyScale.service";
import {useSurveyAnswerType} from "../../services/surveyAnswerType.service";
import Swal from "sweetalert2";


export function EditQuestion({currentQuestion,surveyId,onSaveParent,isSurveyAlreadyStarted}) {

	const [scalesValues, setScalesValues] = useState([]);
	const [answerValues, setAnswerValues] = useState([]);
	const [question, setQuestion] = useState({
		SurveyQuestionId: "",
		SurveyId: parseInt(surveyId),
		SurveyAnswerTypeId: "",
		Question: "",
		SurveyScaleId: ""
	});

	const onSave = () => {
		if (question.SurveyQuestionId && question.SurveyQuestionId > 0) {
			useQuestion.updateQuestion({
				SurveyQuestionId: question.SurveyQuestionId,
				SurveyId: question.SurveyId,
				SurveyAnswerTypeId: question.SurveyAnswerTypeId,
				Question: question.Question,
				SurveyScaleId: question.SurveyScaleId ? question.SurveyScaleId : 0 })
				.then((result) => {
					onSaveParent();
				})
			Swal.fire({
				icon: "success",
				confirmButtonColor: "#80d96b",
				title: "La pregunta se actualizó correctamente",
				showConfirmButton: false,
				timer: 1500
			});
		}
		else {
			useQuestion.createQuestion({
				SurveyId: question.SurveyId,
				SurveyAnswerTypeId: question.SurveyAnswerTypeId,
				Question: question.Question,
				SurveyScaleId: question.SurveyScaleId ? question.SurveyScaleId : 0
			}).then((result) => {
				onSaveParent();
			});

			Swal.fire({
				icon: "success",
				confirmButtonColor: "#80d96b",
				title: "La pregunta se creó correctamente",
				showConfirmButton: false,
				timer: 1500
			});
		}
	};

	const handleInputValue = (e) => setQuestion({...question, [e.target.name]: e.target.value})

	const handleChangeSelectEscale = (value) => setQuestion({...question, SurveyScaleId: value });

	const handleChangeSelectTypeAnswer = (value) => setQuestion({...question, SurveyAnswerTypeId: value });

	const getCatalogScales = () => {
		useSurveyScale.getSurveyScales()
			.then((result) => {
				setScalesValues(result.map(item => {
					return {
						value: item.surveyScaleId,
						label: item.description
					}
				}))
			});
	}

	const getCatalogAnswers = () => {
		useSurveyAnswerType.getSurveyAnswerTypes()
			.then((result) => {
				setAnswerValues(result.map(item => {
					return {
						label: item.description,
						value: item.surveyAnswerTypeId
					}
				}))
			});
	}

	useEffect(() => {
		getCatalogScales();
		getCatalogAnswers();
		if (currentQuestion) {
			setQuestion({
				SurveyQuestionId: parseInt(currentQuestion.SurveyQuestionId),
				SurveyId: parseInt(currentQuestion.SurveyId),
				SurveyAnswerTypeId: parseInt(currentQuestion.SurveyAnswerTypeId),
				Question: currentQuestion.Question,
				SurveyScaleId: parseInt(currentQuestion.SurveyScaleId)
			});
		}
		//setRefreshAnswers(prev => prev + 1);
		//setSelectedAnswer(null);
		//setShowEditAnswer(false);
	}, [currentQuestion]);

	return (
		<Col xs={24}>
			<Form
				onFinish={onSave}
				className="form-backoffice"
				layout={'vertical'}
				autoComplete="off"
				fields={[
					{
						"name": ["Question"],
						"value": question.Question
					},
					{
						"name": ["surveyAnswerTypeId"],
						"value": question.SurveyAnswerTypeId
					},
					{
						"name": ["surveyScaleId"],
						"value": question.SurveyScaleId
					},
				]}
			>
				<Row gutter={16}>
					<Col xs={24}>
						<Form.Item
							label={'Pregunta'}
							htmlFor={'Question'}
							name="Question"
							onChange={handleInputValue}
							rules={[
								{
									required: true,
									message: '*Campo requerido'
								}
							]}
						>
							<Input type="text" name="Question" />
						</Form.Item>
					</Col>
					<Col xs={24}>
						<Form.Item
							label={'Tipo de respuesta'}
							htmlFor={'surveyAnswerTypeId'}
							rules={[
								{
									required: true,
									message: '*Campo requerido'
								}
							]}
							name={'surveyAnswerTypeId'}
							initialValue={question.SurveyAnswerTypeId}
						>
							<Select
								name={'surveyAnswerTypeId'}
								onChange={handleChangeSelectTypeAnswer}
								options={answerValues}
								disabled={(isSurveyAlreadyStarted)}
							/>
						</Form.Item>
					</Col>
					{question.SurveyAnswerTypeId === 3 &&
						<Col xs={24}>
							<Form.Item
								label={'Tipo de escala'}
								htmlFor={'surveyScaleId'}
								name={'surveyScaleId'}
								rules={[
									{
										required: true,
										message: '*Campo requerido'
									}
								]}
							>
								<Select
									name={'surveyScaleId'}
									onChange={handleChangeSelectEscale}
									options={scalesValues}
									disabled={(isSurveyAlreadyStarted)}
								/>
							</Form.Item>
							<div className="form-backoffice-footer">
								<Link to={`/admin/AdminSurveyScale/${surveyId}`}>
									<p>Agregar tipo de escala</p>
									<FaPlus />
								</Link>
							</div>
						</Col>
					}
					<Col xs={24}>
						<div className="form-backoffice-footer">
							<Button type="primary" htmlType={'submit'}>
								<p>Guardar</p>
								<FaSave />
							</Button>
						</div>
					</Col>
				</Row>
			</Form>
		</Col>

	);
}
