import React, { useEffect, useState } from 'react';
import { companyService } from '../../services/control/company.service';
import {Col, Row, Form, Button, Switch, Select, Input, Tooltip} from "antd";
import {InfoCircleOutlined, KeyOutlined, LinkOutlined, MailOutlined, PhoneOutlined} from "@ant-design/icons";
import {FaKey} from "react-icons/fa";
import { IoBusiness} from "react-icons/io5";

export function EditCompany({companyId}) {

    const {Option} = Select;
    const onSave = (data) => {
        if (companyId !== 0) companyService.edit(company);
        if (companyId === 0)companyService.create(company);
    };
    
    const [isRvol, setIsRvol] = useState(false);
    const [companyRVOL, setCompanyRVOL] = useState({CompanyId : 0});
    const [company, setCompany] = useState({});

    useEffect(() => {
        setCompany({
            companyId: companyId,
            companyName: "",
            businessName: "",
            primeDomain: "",
            telephone1: "",
            telephone2: "",
            username: "",
            password: "",
            active: true
        })
    }, [companyId])

    useEffect(() => {
        if (companyId !== 0) {
            companyService.getById(companyId).then((result) => {
                setCompany({
                    companyId: result.companyId,
                    companyName: result.companyName,
                    businessName: result.businessName,
                    primeDomain: result.primeDomain,
                    telephone1: result.telephone1,
                    telephone2: result.telephone2,
                    username: result.userName,
                    password: result.password,
                    active: result.active
                });
            });
        }
        if (companyId === 0)
            companyService.getRVOL().then((result) => setCatalogs({ rvolCompany: result }));
    }, [companyId]);

    const handleCheckChange = () => {
        if(isRvol) {
            setIsRvol(false);
            setCompanyRVOL({CompanyId : 0, CompanyName : ""})
            setCompany({...company, companyId: companyId})
        }
        if(!isRvol) setIsRvol(true);
    }

    const handleSelectChange = (idSelect) => {
        const companyName = catalogs.rvolCompany.filter(data => data.companyId === idSelect);
        setCompanyRVOL({ CompanyId: idSelect })
        setCompany({
            ...company,
            companyName: companyName[0].businessName
        })
    }

    const [catalogs, setCatalogs] = useState({ rvolCompany: [] });

    const handleInputChange = (e) => setCompany({...company, [e.target.name]: e.target.value})

    console.log(company,'company')

    return (
        <>
            <Form
                onFinish={onSave}
                className="form-pride"
                layout="vertical"
                autoComplete="off"
                fields={[
                    {
                        "name": ["companyName"],
                        "value": company.companyName,
                    },
                    {
                        "name": ["businessName"],
                        "value": company.businessName,
                    },
                    {
                        "name": ["primeDomain"],
                        "value": company.primeDomain,
                    },
                    {
                        "name": ["telephone1"],
                        "value": company.telephone1,
                    },
                    {
                        "name": ["telephone2"],
                        "value": company.telephone2,
                    },
                    {
                        "name": ["username"],
                        "value": company.username,
                    },
                    {
                        "name": ["password"],
                        "value": company.password,
                    },
                ]}
            >
                <Row gutter={16}>
                    {companyId === 0 &&
                        <>
                            <Col md={(isRvol) ? 8 : 24} sm={24} xs={24}>
                                <Form.Item
                                    label="¿Existe en Red Voucher Online?"
                                    name="isRVOL"
                                >
                                    <Switch checkedChildren="Sí"
                                            unCheckedChildren="No"
                                            onChange={handleCheckChange}
                                            checked={isRvol}
                                            name="isRVOL"
                                    />
                                </Form.Item>
                            </Col>
                            {isRvol &&
                                <Col md={12} sm={24} xs={24}>
                                    <Form.Item
                                        label="Compañía de Red Voucher"
                                        name="rvolCompany"
                                        rules={[
                                            {
                                                required: (isRvol),
                                                message: "*Campo requerido"
                                            }
                                        ]}
                                    >
                                        <Select
                                            showSearch={true}
                                            name="rvolCompany"
                                            onSelect={handleSelectChange}
                                            value={companyRVOL.CompanyId}
                                            size="large"
                                            placeholder="Selecciona una compañía"
                                            optionFilterProp="children"
                                            filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            options={catalogs.rvolCompany.map(item => {
                                                return {
                                                    value: item.companyId,
                                                    label: item.companyName
                                                }
                                            })}
                                        />
                                    </Form.Item>
                                </Col>
                            }
                        </>
                    }
                    <Col xl={8} lg={12} md={12} sm={12} xs={24}>
                        <input type="hidden" name="companyId" onChange={handleInputChange} />
                        <Form.Item
                            label="Nombre"
                            name="companyName"
                            onChange={handleInputChange}
                            rules={[
                                {
                                    required: true,
                                    message: '*Campo requerido'
                                }
                            ]}
                        >
                            <Input size="large"
                                   name="companyName"
                                   onKeyPress={(e) => {
                                       const regex = new RegExp("^[a-zA-Z ]+$");
                                       const key = String.fromCharCode(!e.charCode ? e.which : e.charCode);
                                       if (!regex.test(key)) {
                                           e.preventDefault();
                                           return false;
                                       }
                                   }}
                                   prefix={<IoBusiness className="site-form-item-icon" />}
                                   placeholder="Identificador de la empresa"
                            />
                        </Form.Item>
                    </Col>
                    <Col xl={8} lg={12} md={12} sm={12} xs={24}>
                        <Form.Item
                            label="Nombre de la Empresa"
                            name="businessName"
                            onChange={handleInputChange}
                            rules={[
                                {
                                    required: true,
                                    message: '*Campo requerido'
                                }
                            ]}
                        >
                            <Input size="large" type="text"
                                   name="businessName"
                                   onKeyPress={(e) => {
                                       const regex = new RegExp("^[a-zA-Z ]+$");
                                       const key = String.fromCharCode(!e.charCode ? e.which : e.charCode);
                                       if (!regex.test(key)) {
                                           e.preventDefault();
                                           return false;
                                       }
                                   }}
                                   placeholder="Nombre o razón social de la empresa"
                                   prefix={<IoBusiness className="site-form-item-icon" />}
                            />
                        </Form.Item>
                    </Col>
                    <Col xl={8} lg={12} md={12} sm={24} xs={24}>
                        <Form.Item
                           label="Dominio"
                           name="primeDomain"
                           onChange={handleInputChange}
                           rules={[
                               {
                                   required: true,
                                   message: '*Campo requerido'
                               }
                           ]}
                        >
                            <Input
                                placeholder="Dominio corporativo"
                                size="large"
                                name="primeDomain"
                                prefix={<LinkOutlined className="site-form-item-icon" />}
                                suffix={
                                    <Tooltip title="Nombre completo de tu dominio">
                                        <InfoCircleOutlined style={{color: 'rgba(0,0,0,.45)',}}/>
                                    </Tooltip>
                                }
                            />
                        </Form.Item>
                    </Col>
                    <Col xl={8} lg={12} md={12} sm={12} xs={24}>
                        <Form.Item
                            label="Teléfono oficina"
                            name="telephone1"
                            rules={[
                                {
                                    required: true,
                                    message: '*Campo requerido'
                                },
                                {
                                    min: 10,
                                    message: 'Mínimo 10 dígitos'
                                }
                            ]}
                        >
                            <Input size="large"
                                   type="text"
                                   prefix={<PhoneOutlined className="site-form-item-icon" />}
                                   placeholder="Teléfono principal"
                                   name="telephone1"
                                   maxLength={10}
                                   onKeyPress={(e) => {
                                       // Solo se admiten números
                                       e = e || window.e;
                                       const charCode = e.keyCode || e.which;
                                       const charStr = String.fromCharCode(charCode);
                                       if(isNaN(charStr)) e.preventDefault();
                                   }}
                                   onChange={handleInputChange}
                            />
                        </Form.Item>
                    </Col>
                    <Col xl={8} lg={12} md={12} sm={12} xs={24}>
                        <Form.Item
                            label="Teléfono alterno"
                            name="telephone2"
                        >
                            <Input size="large" type="text" name="telephone2" placeholder="Teléfono alterno"
                                   onKeyPress={(e) => {
                                       e = e || window.e;
                                       const charCode = e.keyCode || e.which;
                                       const charStr = String.fromCharCode(charCode);
                                       if (isNaN(charStr)) e.preventDefault();
                                   }}
                                   maxLength={10}
                                   prefix={<PhoneOutlined className="site-form-item-icon" />}
                                   onChange={handleInputChange}
                            />
                        </Form.Item>
                    </Col>
                    {companyId === 0 ?
                        <>
                        <Col xl={8} lg={12} md={12} sm={12} xs={24}>
                            <Form.Item
                                label="Usuario de R.H inicial"
                                name="username"
                                onChange={handleInputChange}
                                rules={[
                                    {
                                        required: true,
                                        message: '*Campo requerido'
                                    },
                                    {
                                        pattern: new RegExp(/^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/g),
                                        type: 'email',
                                        message: 'Formato de correo electrónico erróneo'
                                    },
                                ]}
                            >
                                <Input name="username" size="large"
                                       type="email"
                                       placeholder="Correo corporativo"
                                       prefix={<MailOutlined className="site-form-item-icon" />}
                                />
                            </Form.Item>
                        </Col>
                        <Col xl={8} lg={12} md={12} sm={12} xs={24}>
                            <Form.Item
                                label="Contraseña"
                                name="password"
                                onChange={handleInputChange}
                                rules={[
                                    {
                                        required: true,
                                        message: '*Campo requerido'
                                    },
                                    {
                                        pattern: new RegExp(/^(?=.{8,}$)(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?\W).*$/g),
                                        message: <small>La contraseña debe tener:
                                            <ul style={{marginLeft: '1.5rem'}}>
                                                <li>1 letra mayúscula</li>
                                                <li>1 letra minúscula</li>
                                                <li>1 número</li>
                                                <li>1 símbolo</li>
                                            </ul>
                                        </small>
                                    },
                                    {
                                        min: 8,
                                        message: <small>*Mínimo 8 carácteres</small>
                                    }
                                ]}
                            >
                                <Input.Password size="large"
                                                type="password"
                                                placeholder="Contraseña"
                                                name="password"
                                                minLength={8}
                                                prefix={<FaKey className="site-form-item-icon" />}
                                />
                            </Form.Item>
                        </Col>
                    </>
                    : null}
                    <Col xs={24} className="flex-end">
                        <Button htmlType="submit" type="primary">
                            Guardar
                            <i className="icon-save"></i>
                        </Button>
                    </Col>
                </Row>
            </Form>
        </>
    );
}   
