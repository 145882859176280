import React, { useState, useEffect } from 'react';
import { ToolDiscount } from '../../../components/toolDiscount/toolDiscount'
import PurchasedItem from './purchasedItem';
import { PaginatedPage } from './pagination';
import './shoppingHistory.scss'
import { ecommerceService } from '../../../services/ecommerce.service';
import {Col, Row} from "reactstrap";

export function ShoppingHistory() {

    const [shoppingHistory, setShoppingHistory] = useState([]);

    const [page, setPage] = useState(1);
    const totalPages = shoppingHistory.length > 0 ? shoppingHistory.length : 1;

    useEffect(() => {
        ecommerceService.getPurchaseOrder()
            .then(result => setShoppingHistory(result));
    }, []);

    return (
        <section className="dashboard-container-page">
           <Row>
               <Col sm={12}>
                   <h2>Mis compras</h2>
               </Col>
               <Col sm={12}>
                   <ToolDiscount />
               </Col>
               <Col sm={12}>
                   <div className="contain_shop_history">
                       <div className="boxes_hop_history">
                           {
                               shoppingHistory.length > 0
                                   ? shoppingHistory[page - 1].map(item => {
                                       return <PurchasedItem {...item} />
                                   })
                                   : ""
                           }
                           <div className="paginated_page">
                               <PaginatedPage
                                   totalPages={totalPages}
                                   currentPage={page}
                                   onChange={page => setPage(page)}
                               />
                           </div>
                       </div>
                   </div>
               </Col>
           </Row>
        </section>
    );
}
