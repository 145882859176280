import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Link } from "react-router-dom";
import "./jobBank.scss";
import useInputForm from "../../components/CustonHooks/FormHook";
import CreatableSelect from "react-select/creatable";
import { ValidacionEmail } from "../../utils/ValidacionMail";
import Swal from "sweetalert2";
import { FormEditJob } from "./FormEditJob";
import { FormEditSectionJob } from "./FormEditSectionJob";
import { Col, Row } from "antd";
import { FooterHome } from "../../components/footer/footerHome";
import { FaChevronLeft } from "react-icons/fa";

export function EditJob(props) {
  const [datos, setDatos] = useState({
    title: "",
    categoria: [],
    ubicacion: "",
    location: "",
    titleCompany: "",
    imagen: null,
    infoSalarial: "",
    descripcion: "",
    phoneNumber: "",
    mail: "",
  });
  const [viewForm, setViewForm] = useState("1");
  const [job, setJob] = useState({});
  let { id } = useParams();

  const toggleView = (sec) => {
    //if (viewForm == 2 && sec == 3) subirForm();
    if (viewForm !== sec) setViewForm(sec);
  };

  return (
    <div className="dashboard-container-page job_page_sub">
      <div className="admin-subtitle job_subtitle">
        <Link to="/job-bank" className="btn-back">
          <FaChevronLeft />
        </Link>
        <h2>Edición de empleo</h2>
      </div>
      <div className="border-pointed green">
        <p>
          Llena el siguiente formulario con los datos para poder continuar con
          la edición de la vacante.
        </p>
      </div>
      <div className="job-bank-register-form">
        {
          {
            1: (
              <FormEditJob
                id={id}
                datos={datos}
                setDatos={setDatos}
                toggleView={() => toggleView("2")} //sig
                setJob={setJob}
                job={job}
              />
            ),
            2: (
              <FormEditSectionJob
                toggleView={() => toggleView("1")}
                job={job}
                id={id}
              />
            ),
          }[viewForm]
        }
      </div>
      <Row>
        <Col xs={24}>
          <FooterHome />
        </Col>
      </Row>
    </div>
  );
}
