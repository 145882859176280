import React, { useEffect, useState } from "react";
import { chatService } from "./../../services/chat.service";
import { Searches } from "./searches";
import { TtlsChats } from "./ttlsChats";
import { AvatarChats } from "./avatarChats";
import { FaCommentDots, FaTimesCircle} from "react-icons/fa";
import {BsChevronLeft} from "react-icons/bs";

export function ListContact({ showChat }) {
  const [contacts, setContacts] = useState([]);
  const [blockedContacts, setBlockedContacts] = useState([]);
  const [contactsSearch, setContactsSearch] = useState([]);
  let currentInitial = "";

  useEffect(() => {
    chatService.getContacts().then((result) => {
      const activeContacts = result.activeContacts.filter(
        (item) => !result.blockedContacts.includes(item.userId)
      );
      const blockedContacts = result.activeContacts.filter((item) =>
        result.blockedContacts.includes(item.userId)
      );
      setContacts(activeContacts);
      setContactsSearch(activeContacts);
      setBlockedContacts(blockedContacts);
    });
  }, []);

  const handleSearch = (text) => {
    if (text) {
      text = text.toUpperCase();
      let contactFiltered = contacts.filter((item) =>
        item.name.removeAccents().toUpperCase().includes(text)
      );

      if (contactFiltered.length > 0) {
        let contactsFiltered = Object.assign([], contactFiltered);
        setContactsSearch(contactsFiltered);
        return;
      }

      setContactsSearch([]);
    } else setContactsSearch(contacts);
  };

  const handleOpenChat = (user) => {
    const chat = {
      contactId: user.userId,
      name: user.name,
      image: user.urlImage,
      identityFlagName: user.flag,
      readOnly: false,
    };
    showChat(chat);
  };

  const [openBlocked, setOpenBlocked] = useState("");
  const handleOpenBlocked = () => {
    setOpenBlocked(openBlocked === "" ? "open" : "");
  };

  return (
    <div className="sections-chat active contacts-container list-users p-3">
      <div className="list-users-options">
        <TtlsChats title="Contactos" />
        <button className="btn-new-group" onClick={handleOpenBlocked}>
          <FaTimesCircle />
          <span>Bloqueados</span>
        </button>
      </div>
      <Searches placeHolder="Buscar contacto" handleSearch={handleSearch} />
      <div className="contacts-container-content">
          {contactsSearch.map((contact) => {
            let initial = "";
            const removeAccents = contact.name.substring(0, 1).removeAccents();

            if (currentInitial != removeAccents) {
              currentInitial = removeAccents;
              initial = removeAccents;
            }

            return (
              <>
                {initial && (<div className="letter-divider"><span>{initial}</span></div>)}
                <div className="item" key={contact.userId}>
                  <AvatarChats
                    img={contact.urlImage}
                    flagClass={contact.flag}
                  />
                  <div className="item-chat">
                    <p className="name">{contact.name}</p>
                    <div className="options">
                      <span className="opt_chat" onClick={() => handleOpenChat(contact)}>
                        <FaCommentDots />
                      </span>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      <div className={`list-user-blocked ${openBlocked}`}>
        <div className="d-flex-between" style={{paddingBottom: '1rem'}}>
          <button type="button" className="button-back" onClick={handleOpenBlocked}><BsChevronLeft /></button>
          <h6>Usuarios bloqueados</h6>
        </div>
        {blockedContacts.map((contact) => (
          <div className="item" key={contact.userId}>
            <AvatarChats img={contact.urlImage} flagClass={contact.flag} />
            <div className="item-chat">
              <p className="name">{contact.name}</p>
              <div className="options">
                <span onClick={() => handleOpenChat(contact)}><FaCommentDots /></span>
              </div>
            </div>
          </div>
        ))}
      </div>

    </div>
  );
}
