import React, { useState } from "react";
import "./rewards.scss";

import { ItemRewardCupon } from "./itemRewardCupon";

export function WrapperCategoriesRewards({
  reward,
  rewardCategory,
  rewardProducts,
  getRewardProducts,
}) {
  const [categoryActive, setCategoryActive] = useState(0);

  return (
    <>
      <div className="discount-categories">
        <div
          className={`discount-categories-item ${
            categoryActive === 0 ? "active" : ""
          }`}
          onClick={() => {
            getRewardProducts(0);
            setCategoryActive(0);
          }}
        >
          <span className="icon">
            <i className="icon-all"></i>
          </span>
          <span className="tag">Todos</span>
        </div>
        {rewardCategory.map((item) => (
          <div
            className={`discount-categories-item ${
              categoryActive === item.rewardCategoryId ? "active" : ""
            }`}
            key={item.rewardCategoryId}
            onClick={() => {
              getRewardProducts(item.rewardCategoryId);
              setCategoryActive(item.rewardCategoryId);
            }}
          >
            <span className="icon">
              <i className={item.urlIcon}></i>
            </span>
            <span className="tag">{item.description}</span>
          </div>
        ))}
      </div>
      <div className="rewards-cards">
        {rewardProducts.map((item) => (
          <ItemRewardCupon
            setCategoryActive={setCategoryActive}
            getRewardProducts={getRewardProducts}
            reward={reward}
            {...item}
          />
        ))}
      </div>
    </>
  );
}
