import React, { useEffect, useState } from 'react';
import { SearchAnswer } from './searchAnswers';
import { EditAnswer } from './answer';
import { SurveyTypes } from '../../enums/surveyTypes';
import {Row} from "antd";
import {AlertResult} from "../components/alertResult";

export function EditQuestionAnswer(props, {surveyId}) {

	const [question, setQuestion] = useState({
		SurveyQuestionId: "",
		SurveyId: parseInt(surveyId),
		SurveyAnswerTypeId: "",
		Question: ""
	});
	const [refreshAnswers, setRefreshAnswers] = useState(0);

	useEffect(() => {
		if (props.currentQuestion) {
			setQuestion({
				SurveyQuestionId: props.currentQuestion.SurveyQuestionId,
				SurveyId: props.currentQuestion.SurveyId,
				SurveyAnswerTypeId: props.currentQuestion.SurveyAnswerTypeId,
				Question: props.currentQuestion.Question
			});
		}
		setRefreshAnswers(prev => prev + 1);
		props.setSelectedAnswer(null);
	}, [props.currentQuestion]);

	const handleOnSelectAnswer = (answer) => {
		props.onCancelEdit(true,null)
		props.setSelectedAnswer({
			SurveyAnswerConfigId: answer.surveyAnswerConfigId,
			SurveyQuestionId: answer.surveyQuestionId,
			AnswerOrder: answer.answerOrder,
			Answer: answer.answer,
			IsRight: answer.isRight,
			IsImage: answer.isImage,
			StepAnswer: answer.stepAnswer
		});
	}

	const handleOnSaveEditAnswer = () => setRefreshAnswers(prev => prev + 1);

	return (
		<>
			{question.SurveyAnswerTypeId !== parseInt(SurveyTypes.MULTIMEDIA) && question.SurveyAnswerTypeId !== parseInt(SurveyTypes.OPEN) ?
				<>
					<Row xs={24}>
						{(props.editAnswer) &&
							<EditAnswer
								surveyQuestions={props.surveyQuestions}
								isMultipleOption={(question.SurveyAnswerTypeId === parseInt(SurveyTypes.MULTIPLE_OPTION))}
								surveyQuestionId={question.SurveyQuestionId}
								currentAnswer={props.selectedAnswer}
								onSave={handleOnSaveEditAnswer}
							/>
						}
						<SearchAnswer
							isSurveyAlreadyStarted={props.isSurveyAlreadyStarted}
							surveyQuestionId={question.SurveyQuestionId}
							onSelectAnswer={handleOnSelectAnswer}
							refresh={refreshAnswers}
						/>
					</Row>
				</>
				:
				<AlertResult
					title={'¡Ups!'}
					subTitle="Este tipo de pregunta no necesita añadir respuestas"
				/>
			}
		</>
	);
}
