import { callApi, callApiFileStorage } from "./service";
import swal from "sweetalert";

export const newAccountService = {
  getMaritalStatusCatalog,
  getNacionalityCatalog,
  getStateCatalog,
};

async function getMaritalStatusCatalog() {
  return callApi(`/BackEnd/GetMaritalStatusCatalog`, "GET", undefined, {
    SkipValidationToken: true,
  }).then((result) => {
    if (result.responseCode == "00") {
      return result.data;
    } else {
      return result.responseMessage;
    }
  });
}

async function getNacionalityCatalog() {
  return callApi(`/BackEnd/GetNationalityCatalog`, "GET", undefined, {
    SkipValidationToken: true,
  }).then((result) => {
    if (result.responseCode === "00") {
      return result.data;
    } else {
      return result.responseMessage;
    }
  });
}

async function getStateCatalog() {
  return callApi(`/BackEnd/GetCountryStateCatalog`, "GET", undefined, {
    SkipValidationToken: true,
  } ).then((result) => {
    if (result.responseCode == "00") {
      return result.data;
    } else {
      return result.responseMessage;
    }
  });
}
