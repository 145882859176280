import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { App } from "./App";
import * as registerServiceWorker from "./registerServiceWorker";
import swal from "sweetalert"

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/theme/main.scss";
import { Provider } from "react-redux";
import store from "./store/configureStore";
import "./extensions/string.extension";

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
  document.getElementById("root")
);

registerServiceWorker.register({
  onUpdate: (registration) => {
    swal({ text: "Actualización disponible", icon: "info" })
    .then(() => {
      if (registration && registration.waiting) {
        registration.waiting.postMessage({ type: "SKIP_WAITING" });
      }
  
      if ("caches" in window) {
        caches.keys().then((names) => {
          // Delete all the cache files
          names.forEach((name) => {
            caches.delete(name);
          });
        });
      }
  
      window.location.reload(true);
    })
  },
});
