import React  from "react";

export function BoxListEcommerce(props) {
  return (
    <div className="  content_card_audience">
      <h4 className="text-muted text fnt_medium mb-4">
        Disfruta de productos y descuentos exclusivos
      </h4>
      <div className="row">
        <div className="col-sm-6 col-lg-4 mb-4">
          <a
            className="item_ecommerce shadows card"
            href="https://tiendaserhumano.redvouchers.com/Inicio"
            target="_blank"
          >
            <figure>
              <img
                src="https://as-mktp-serhumano-backoffice-mx-prod.azurewebsites.net/imgs/stores/3/products/pi00000256.png?v=20220125103810177"
                alt="Ecommerce"
              />
            </figure>
            <h5 className="mb-0 text fnt_bold text-uppercase">Ser Humano AC</h5>
            <p className="paragraph text-muted">Marketplace</p>
          </a>
        </div>
        <div className="col-sm-6 col-lg-4 mb-4">
          <a
            className="item_ecommerce shadows card"
            href="https://tiendaadogcion.redvouchers.com/Inicio"
            target="_blank"
          >
            <figure>
              <img
                src="https://as-mktp-adogcion-backoffice-mx-prod.azurewebsites.net/imgs/groups/categories/11/p00000011.png?v=20220127105012944"
                alt="Ecommerce"
              />
            </figure>
            <h5 className="mb-0 text fnt_bold text-uppercase">Tienda Adogción</h5>
            <p className="paragraph text-muted">Marketplace</p>
          </a>
        </div>
      </div>
    </div>
  );
}
