import React, { useRef, useEffect, useState } from "react";
import { chatService } from "./../../services/chat.service";
import { TtlsChats } from "./ttlsChats";
import { AvatarChats } from "./avatarChats";
import moment from "moment";
import { Button } from "rsuite";

export function NewGroup({ isOpenGroup, lastOpenGroup, updateGroup }) {
  const [contacts, setContacts] = useState([]);
  const [contactsSearch, setContactsSearch] = useState([]);
  const [form, setForm] = useState({
    name: "",
    expirationDate: "",
    contacts: [],
  });
  const [infoGroupSec, secInfoGroupSec] = useState("1");
  const [findContact, setFindContact] = useState("");
  const buttonRef = useRef();
  const toggle = (sec) => {
    if (infoGroupSec !== sec) secInfoGroupSec(sec);
  };

  useEffect(() => {
    chatService.getContacts().then((result) => {
      setContacts(result.activeContacts);
      setContactsSearch(result.activeContacts);
    });
  }, []);

  useEffect(() => {
    if (updateGroup) {
      setForm({
        name: updateGroup.name,
        expirationDate: moment(updateGroup.expirationDate).format("YYYY-MM-DD"),
        contacts: updateGroup.contacts,
      });
    } else {
      setForm({ name: "", expirationDate: "", contacts: [] });
    }
    console.log("form: ", form);
  }, [updateGroup]);

  const handleSearch = (text) => {
    setFindContact(text);
    if (text) {
      text = text.removeAccents().toUpperCase();
      let contactFiltered = contacts.filter((item) =>
        item.name.removeAccents().toUpperCase().includes(text)
      );
      if (contactFiltered.length > 0) {
        let contactsFiltered = Object.assign([], contactFiltered);
        setContactsSearch(contactsFiltered);
        return;
      }

      setContactsSearch([]);
    } else setContactsSearch(contacts);
  };

  const handelForm = (e) => {
    var value;
    if (e.target.name == "expirationDate") {
      value = moment(e.target.value).format("YYYY-MM-DD");
    } else if (e.target.name == "Image") {
      value = URL.createObjectURL(e.target.files[0]);
    } else {
      value = e.target.value;
    }
    setForm({ ...form, [e.target.name]: value });
    console.log(form.Image);
  };

  const handleCheckbox = (e) => {
    if (e.target.checked)
      setForm({ ...form, contacts: [...form.contacts, e.target.name] });
    else
      setForm({
        ...form,
        contacts: [...form.contacts.filter((item) => item != e.target.name)],
      });
  };

  const onSubmit = () => {
    if (form.name && form.contacts.length > 0) {
      buttonRef.current.disabled = true;
      chatService
        .managementGroup({
          Id: updateGroup ? updateGroup.id : null,
          Name: form.name,
          Image: "",
          Contacts: form.contacts,
          ExpirationDate: form.expirationDate ? form.expirationDate : null,
        })
        .then(() => {
          buttonRef.current.disabled = false;
          setForm({ name: "", expirationDate: "", contacts: [] });
          toggle(1);
          lastOpenGroup();
        })
        .catch((error) => {
          buttonRef.current.disabled = false;
          console.log(error);
        });
    }
  };

  const selectAllUsers = (value) => {
    if (value.target.checked) {
      setForm({ ...form, contacts: contactsSearch.map((user) => user.userId) });
    } else {
      setForm({ ...form, contacts: [] });
    }
  };

  return (
    <div className={`sec_new_group ${isOpenGroup}`}>
      {
        {
          1: (
            <div className="cont_new_group__info">
              {/* <div className="drawer_avatar_gp avatarINewGroup">
                <span className="toback" onClick={lastOpenGroup}>
                  <i className="fas fa-chevron-left"></i>
                </span>
                <AvatarChats img={form.Image} />
                <label
                  for="GroupImage"
                  className="custom-file-upload icons"
                >
                  <ion-icon name="camera-outline"></ion-icon>
                </label>
                <input
                  id="GroupImage"
                  type="file"
                  name="Image"
                  accept="image/*"
                  onChange={(e) => {
                    handelForm(e);
                  }}
                />
                <button type="submit" className="icons ico_save">
                  <i className="fas fa-check"></i>
                </button>
              </div> */}
              <div className="drawer_info_gp">
                <div className="form-group mt-2">
                  <TtlsChats title="Asunto del grupo" />
                  <input
                    type="text"
                    className="form-control mt-1"
                    name="name"
                    value={form.name}
                    id="group"
                    aria-describedby="asuntoGrupo"
                    placeholder="Agrega un asunto al grupo"
                    onChange={(e) => handelForm(e)}
                  />
                </div>
                <div className="form-group">
                  <TtlsChats title="Vigencia del grupo" />
                  <input
                    className="form-control mt-1"
                    type="date"
                    id="expirationDate"
                    name="expirationDate"
                    value={form.expirationDate}
                    onChange={(e) => handelForm(e)}
                  />
                </div>
              </div>
              <div className="text-center ">
                <Button className="" onClick={() => toggle(2)}>
                  Siguiente <ion-icon name="arrow-forward-outline"></ion-icon>
                </Button>
              </div>
            </div>
          ),
          2: (
            <div className="cont_new_group__contact">
              <div className="box_inputs">
                <div className="to_back_gr" onClick={() => toggle(1)}>
                  <i className="fas fa-chevron-left"></i>
                </div>
                <div className="box_search_contact">
                  <label className="text fnt_medium">
                    Añadir participantes
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Buscar contacto"
                    onChange={(e) => handleSearch(e.target.value)}
                    value={findContact}
                  />
                </div>

                <span className="ico_form">
                  <input type="checkbox" onChange={selectAllUsers} />
                  <small className="text fnt_medium">
                    {form.contacts.length}/{contactsSearch.length}
                  </small>
                </span>
              </div>

              <div className="box_list_contact">
                {contactsSearch.map((user) => (
                  <div
                    className="inputGroup"
                    key={user.userId}
                    title={user.name}>
                    <input
                      id={user.userId}
                      name={user.userId}
                      type="checkbox"
                      onChange={handleCheckbox}
                      checked={form.contacts.includes(user.userId)}
                    />
                    <label for={user.userId}>
                      <AvatarChats img={user.urlImage} />
                      <div className="name_chat text fnt_book">{user.name}</div>
                    </label>
                  </div>
                ))}
              </div>
              <div className="box_btns_options ">
                <button
                  className="btn primary minimum "
                  ref={buttonRef}
                  disabled={!form.name || form.contacts.length == 0}
                  onClick={onSubmit}>
                  {updateGroup ? "Actualizar" : "Crear"} grupo
                </button>
              </div>
            </div>
          ),
        }[infoGroupSec]
      }
    </div>
  );
}
