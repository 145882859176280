import React, {useEffect, useRef, useState} from 'react';
import { rewardProductService } from '../../services/control/rewardProduct.service';
import {Form, Col, Row, Input, Button, Switch, InputNumber, Select, Image, Modal} from "antd";
import imgPdf from '../../assets/imgs/pdf.png';
import {useParams} from "react-router-dom";
import {FaDownload, FaUpload, FaUser} from "react-icons/fa";
import {URL as _URL} from "url";

export function EditReward(props) {

    const { Option } = Select;
    const {id} = useParams();
    const fileImage = useRef();

    const onSave = (data) => {
        if (reward.rewardProductId && reward.rewardProductId > 0)
            rewardProductService.edit(reward);
        else
            rewardProductService.create(reward);
    };

    const [reward, setReward] = useState({});
    const [catalogs, setCatalogs] = useState({ rewardProvider: [], rewardCategory: [] });

    useEffect(() => {
        setReward({
            rewardProductId: id,
            title: "",
            titleAdmin: "",
            description: "",
            points: 0,
            lowStock: 0,
            popUpText: "",
            urlImage: "",
            urlPdf: "",
            isBarCode: false,
            isVisible: false,
            posX: 0,
            posY: 0,
            fontSize: 0,
            restriction: "",
            twoValidation: false,
            rewardProviderId: null,
            rewardCategoryId: null,
            active: true,
            couponUrl: "",
            filePDF: null,
            fileImage: null
        })

        rewardProductService.getCatalogs().then((result) => setCatalogs(result));
    }, [id])

    useEffect(() => {
        if (parseInt(id) !== 0) {
            rewardProductService.getById(parseInt(id)).then((result) => {
                setReward({
                    rewardProductId: result.rewardProductId,
                    title: result.title,
                    titleAdmin: result.titleAdmin,
                    description: result.description,
                    points: result.points,
                    lowStock: result.lowStock,
                    popUpText: result.popUpText,
                    urlImage: result.urlImage,
                    urlPdf: result.urlPdf,
                    isBarCode: result.isBarCode,
                    isVisible: result.isVisible,
                    posX: result.posX,
                    posY: result.posY,
                    fontSize: result.fontSize,
                    restriction: result.restriction,
                    twoValidation: result.twoValidation,
                    rewardProviderId: result.rewardProviderId,
                    rewardCategoryId: result.rewardCategoryId,
                    active: result.active,
                    couponUrl: result.couponUrl,
                    fileImage: null,
                    filePDF: null
                });
            });
        }
    }, [catalogs])

    const handleBarcode = () => setReward( {...reward, isBarCode: (!reward.isBarCode)})
    const handleVisible = () => setReward( {...reward, isVisible: (!reward.isVisible)})
    const handleSelectProvider = (value) => setReward( {...reward, rewardProviderId: value})
    const handleSelectCategory = (value) => setReward( {...reward, rewardCategoryId: value})
    const handleInputValue = (e) => setReward( {...reward, [e.target.name]: e.target.value})

    const handleInputFileImage = (e) => {

        var ev = e.target.files;
        if (ev) {
            if (ev.length === 0) return setReward({...reward, fileImage: null, urlImage: null});
            let img: HTMLImageElement, dimensions = {};
            img = document.createElement("img");

            img.onload = function () {
                dimensions = {
                    width: img.width,
                    height: img.height
                };
                if(dimensions.width != 600 || dimensions.height != 600) {
                    Modal.error({
                        title: 'Hay un problema',
                        content: 'La imagen debe tener las medidas de 600 x 600.',
                    });
                    img = null;
                    ev = null;
                } else {
                    const objectUrl = URL.createObjectURL(ev[0])
                    setReward( {...reward, fileImage: ev[0], urlImage: objectUrl})
                }
            };
            img.src = URL.createObjectURL(ev[0]);
            return;
        }

        if(e.target.files[0].size > 2048){
            alert('El peso del archivo excede los 2MB')
        }
    }

    const handleInputFile = (e) => {

        if(e.target.files.length === 0) return setReward({...reward, filePDF: null, urlPdf: null})

        if(e.target.files[0].size > 2000000) return alert('El peso del archivo excede los 2MB')

        setReward( {...reward, filePDF: e.target.files[0], urlPdf: imgPdf})
    }

    const handleInputPosX = (value) => setReward({...reward, posX: value})
    const handleInputPosY = (value) => setReward({...reward, posY: value})
    const handleInputLowStock = (value) => setReward({...reward, lowStock: value})
    const handleInputFontSize = (value) => setReward({...reward, fontSize: value})

    return (
        <div className="dashboard-operator-body">
            <Form
                onFinish={onSave}
                className="form-pride"
                layout="vertical"
                autoComplete="off"
                fields={[
                    {
                        "name": ["rewardCategoryId"],
                        "value": reward.rewardCategoryId,
                    },
                    {
                        "name": ["rewardProviderId"],
                        "value": reward.rewardProviderId,
                    },
                    {
                        "name": ["title"],
                        "value": reward.title,
                    },
                    {
                        "name": ["urlImage"],
                        "value": reward.urlImage,
                    },
                    {
                        "name": ["titleAdmin"],
                        "value": reward.titleAdmin,
                    },
                    {
                        "name": ["urlPdf"],
                        "value": reward.urlPdf,
                    },
                    {
                        "name": ["description"],
                        "value": reward.description,
                    },
                    {
                        "name": ["isBarCode"],
                        "value": reward.isBarCode,
                    },
                    {
                        "name": ["isVisible"],
                        "value": reward.isVisible,
                    },
                    {
                        "name": ["popUpText"],
                        "value": reward.popUpText,
                    },
                    {
                        "name": ["posX"],
                        "value": reward.posX,
                    },
                    {
                        "name": ["posY"],
                        "value": reward.posY,
                    },
                    {
                        "name": ["lowStock"],
                        "value": reward.lowStock,
                    },
                    {
                        "name": ["fontSize"],
                        "value": reward.fontSize,
                    },
                ]}
            >
                <Row gutter={16}>
                    <Col xl={8} lg={8} md={12} sm={24} xs={24}>
                        <Form.Item
                            label="Categorías"
                            name="rewardCategoryId"
                            rules={[
                                {
                                    required: true,
                                    message: '*Archivo requerido',
                                }
                            ]}
                        >
                            <Select
                                name="rewardCategoryId"
                                placeholder="Selecciona una categoría"
                                size="large"
                                onChange={handleSelectCategory}
                            >
                                {catalogs.rewardCategory.map((item,i) => (
                                    <Option key={i+i} value={parseInt(item.value)}>{item.label}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xl={8} lg={8} md={12} sm={24} xs={24}>
                        <Form.Item
                            label="Proveedor"
                            name="rewardProviderId"
                            rules={[
                                {
                                    required: true,
                                    message: '*Archivo requerido'
                                }
                            ]}
                        >
                            <Select
                                name="rewardProviderId"
                                placeholder="Selecciona un proveedor"
                                size="large"
                                onChange={handleSelectProvider}
                            >
                                {catalogs.rewardProvider.map((item,i) => (
                                    <Option key={i} value={parseInt(item.value)}>{item.label}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xl={8} lg={12} md={12} sm={24} xs={24}>
                        <Row>
                            <Col sm={24}>
                                <Form.Item
                                    label="Nombre"
                                    rules={[
                                        {
                                            required: true,
                                            message: '*Campo requerido'
                                        }
                                    ]}
                                    name="title"
                                >
                                    <Input prefix={<i className="site-form-item-icon icon-gif"></i>}
                                           type="text" name="title"
                                           size="large"
                                           onChange={handleInputValue}
                                    />
                                </Form.Item>
                            </Col>
                            <Col sm={24} xs={24}>
                                <Form.Item
                                    label="Descripcion"
                                    rules={[
                                        {
                                            required: true,
                                            message: '*Campo requerido'
                                        }
                                    ]}
                                    name="description"
                                >
                                    <Input.TextArea
                                        prefix={<i className="site-form-item-icon icon-gif"></i>}
                                        name="description"
                                        rows={5}
                                        onChange={handleInputValue}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col xl={8} lg={12} md={12} sm={24} xs={24}>
                        <Row gutter={16}>
                            <Col sm={24} xs={24}>
                                <Form.Item
                                    label="Titulo Administrador"
                                    rules={[
                                        {
                                            required: true,
                                            message: '*Campo requerido'
                                        }
                                    ]}
                                    name="titleAdmin"
                                >
                                    <Input
                                        prefix={<FaUser className="site-form-item-icon icon-gif"/>}
                                        type="text"
                                        name="titleAdmin"
                                        size="large"
                                        onChange={handleInputValue}
                                    />
                                </Form.Item>
                            </Col>
                            <Col sm={24} xs={24}>
                                <Form.Item
                                    label="Imagen de sitio (Dimensiones obligadas: 600x600)"
                                    rules={[
                                        {
                                            required: (!reward.urlImage),
                                            message: '*Archivo requerido'
                                        }
                                    ]}
                                    name="fileImage"
                                >
                                    <div className="container-file">
                                        <label htmlFor="uploadFileimage">Subir imagen <FaUpload /></label>
                                        <Input id="uploadFileimage" ref={fileImage} type="File" name="fileImage" accept='image/*' onChange={handleInputFileImage} />
                                        {
                                            (reward.urlImage && reward.urlImage) ?
                                                <Image
                                                    width={200}
                                                    src={reward.urlImage}
                                                /> : ''
                                        }
                                    </div>
                                </Form.Item>
                            </Col>
                            <Col sm={24} xs={24}>
                                <Form.Item
                                    label="Cupón PDF"
                                    rules={[
                                        {
                                            required: (!reward.urlPdf),
                                            message: '*Archivo requerido'
                                        }
                                    ]}
                                    name="filePDF"
                                >
                                    <div className="container-file">
                                        <label htmlFor="uploadFilePdf">Subir PDF <FaUpload /></label>
                                        <Input id="uploadFilePdf" type="File" name="filePDF" accept='.PDF' onChange={handleInputFile} />
                                        {
                                            (reward.urlPdf && reward.urlPdf) ?
                                                <div className="container-file-overlay">
                                                    <a href={reward.urlPdf} download className="download">
                                                        <img className="pdf" src={imgPdf} alt={reward.title} />
                                                    </a>
                                                    <div className="overlay">
                                                        <div className="text">
                                                            <FaDownload />
                                                            <span>Descargar</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                : ''
                                        }
                                    </div>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col xl={8} lg={24} md={24} sm={24} xs={24}>
                        <Row gutter={16}>
                            <Col xl={24} lg={16} md={12} sm={24} xs={24}>
                                <Form.Item
                                    label="Texto del aviso"
                                    rules={[
                                        {
                                            required: true,
                                            message: '*Campo requerido'
                                        }
                                    ]}
                                    name="popUpText"
                                >
                                    <Input
                                        prefix={<i className="site-form-item-icon icon-gif"></i>}
                                        type="text"
                                        name="popUpText"
                                        size="large"
                                        onChange={handleInputValue}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xl={24} lg={8} md={12} sm={24} xs={24}>
                                <Row gutter={16}>
                                    <Col md={24} sm={12} xs={12}>
                                        <Form.Item label="Código de barras">
                                            <Switch checked={reward.isBarCode} onChange={handleBarcode} checkedChildren="Sí" unCheckedChildren="No" />
                                        </Form.Item>
                                    </Col>
                                    <Col md={24} sm={12} xs={12}>
                                        <Form.Item label="Mostrar folio">
                                            <Switch checked={reward.isVisible} onChange={handleVisible} checkedChildren="Sí" unCheckedChildren="No" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24}>
                                        <Form.Item
                                            label="Posición X"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '*Campo requerido'
                                                }
                                            ]}
                                            name="posX"
                                            maxLength={5}
                                            minLength={1}
                                        >
                                            <InputNumber name="posX" size="large" onChange={handleInputPosX} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24}>
                                        <Form.Item
                                            label="Posición Y"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '*Campo requerido'
                                                }
                                            ]}
                                            name="posY"
                                            maxLength={5}
                                            minLength={1}
                                        >
                                            <InputNumber name="posY" size="large" onChange={handleInputPosY} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24}>
                                        <Form.Item
                                            label="Mínimo de unidades"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '*Campo requerido'
                                                }
                                            ]}
                                            name="lowStock"
                                            maxLength={5}
                                            minLength={1}
                                        >
                                            <InputNumber name="lowStock" size="large" onChange={handleInputLowStock} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24}>
                                        <Form.Item
                                            label="Tamaño de la fuente"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '*Campo requerido'
                                                }
                                            ]}
                                            name="fontSize"
                                            maxLength={5}
                                            minLength={1}
                                        >
                                            <InputNumber name="fontSize" size="large" onChange={handleInputFontSize} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24}>
                        <div className="flex-end">
                            <Button type="primary" htmlType="submit">
                                Guardar
                                <i className="icon-save"></i>
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}   
