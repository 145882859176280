import React from "react";
import { useSelector } from "react-redux";
import { Modal } from "reactstrap";
import "./blockScreen.scss";

export const BlockScreen = () => {
  const blocking = useSelector((state) => state.blocking);

  return (
    <Modal
      isOpen={blocking}
      className="modal_blocking"
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingRight: "0 !important",
        border: "0 !important",
      }}
    >
      <div className="loader">
        <div className="loader-inner">
          <div className="loader-line-wrap">
            <div className="loader-line"></div>
          </div>
          <div className="loader-line-wrap">
            <div className="loader-line"></div>
          </div>
          <div className="loader-line-wrap">
            <div className="loader-line"></div>
          </div>
          <div className="loader-line-wrap">
            <div className="loader-line"></div>
          </div>
          <div className="loader-line-wrap">
            <div className="loader-line"></div>
          </div>
          <div className="loader-line-wrap">
            <div className="loader-line"></div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
