import React, { useEffect, useState } from "react";
import { categoryServiceService } from "../../services/categoryService.service";
import "./registerServices.scss";
import { FormGroup, Label, Modal, ModalBody } from "reactstrap";
import { BsInfoCircle } from "react-icons/bs";
import { FaChevronRight, FaTimes } from "react-icons/fa";

import { Form, Input, Row, Col, Select, Button } from "antd";
import Swal from "sweetalert2";

export function FormPersonal(props) {
  let {
    categoryServiceId,
    contactImage,
    directoryContacts,
    fullName,
    location,
    subCategoryServiceId
  } = props.data;

  const { Option } = Select;
  const [datos, setDatos] = useState({
    nombre: "",
    descripcion: "",
    correo: "",
    contacto1: null,
    contacto1DirectoryContactId: null,
    contacto2: null,
    contacto2DirectoryContactId: null,
    categoria: "",
    subcategoria: "",
    location: "",
    imagen: [],
  });

  const [band, setBand] = useState(false);
  const [categoryFilter, setCategoryFilter] = useState([]);
  const [subCategoryFilter, setSubCategoryFilter] = useState([]);
  const [indexImagen, setIndexImagen] = useState();

  useEffect(() => {
    categoryServiceService.getCategoryCatalog().then((categories) => {
      if (categories != null) setCategoryFilter(categories);
    });
  }, []);

  const handleInputStateValue = (e) => {
    if (e.target.name === "nombre")
      setDatos({ ...datos, nombre: e.target.value });
    if (e.target.name === "correo")
      setDatos({ ...datos, correo: e.target.value });
    if (e.target.name === "contacto1")
      setDatos({ ...datos, contacto1: e.target.value });
    if (e.target.name === "contacto2")
      setDatos({ ...datos, contacto2: e.target.value });
    if (e.target.name === "location")
      setDatos({ ...datos, location: e.target.value });
    if (e.target.name === "descripcion")
      setDatos({ ...datos, descripcion: e.target.value });

    //if(e.target.name !== "imagen") setDatos({...datos, [e.target.name]: e.target.value,})
  };

  const handleValidateCategory = (value) => {
    if (value === 0) {
      setSubCategoryFilter([]);
      setDatos({ ...datos, subcategoria: value });
      return;
    }

    categoryServiceService
      .getSubCategoriesCatalogByCatId(value, process.env.REACT_APP_COMPANY)
      .then((subs) => {
        setSubCategoryFilter(subs.sort((a, b) => a[1] - b[1]));
        setDatos({
          ...datos,
          categoria: subs[0].categoryServiceId,
          subcategoria: subs[0].subCategoryServiceId,
        });
      });
  };

  const handleSelectSubCategory = (value) => {
    setDatos({ ...datos, subcategoria: value });
  };

  useEffect(() => {
    if (props.register.personal.hasOwnProperty("nombre")) {
      let reg = props.register.personal;
      setDatos({
        nombre: reg.nombre,
        correo: reg.correo,
        contacto1: reg.contacto1,
        contacto2: reg.contacto2,
        categoria: reg.categoria,
        subcategoria: reg.subcategoria,
        location: reg.location,
        imagen: reg.imagen,
        descripcion: reg.descripcion
      });
    } else {
      setDatos({
        nombre: fullName,
        correo: "",
        contacto1:
          directoryContacts !== undefined && directoryContacts.length > 0
            ? directoryContacts[0].contactValue
            : null,
        contacto1DirectoryContactId:
          directoryContacts !== undefined && directoryContacts.length > 0
            ? directoryContacts[0].directoryContactId
            : null,
        contacto2:
          directoryContacts !== undefined && directoryContacts.length > 1
            ? directoryContacts.filter((x) => x.contactTypeId == 1).length > 1
              ? directoryContacts.filter((x) => x.contactTypeId == 1)[1]
                .contactValue
              : null
            : null,
        contacto1optDirectoryContactId:
          directoryContacts !== undefined && directoryContacts.length > 1
            ? directoryContacts.filter((x) => x.contactTypeId == 1).length > 1
              ? directoryContacts.filter((x) => x.contactTypeId == 1)[1]
                .directoryContactId
              : null
            : null,
        contacto2DirectoryContactId:
          directoryContacts !== undefined && directoryContacts.length > 1
            ? directoryContacts.filter((x) => x.contactTypeId == 2).length > 0
              ? directoryContacts.filter((x) => x.contactTypeId == 2)[0]
                .directoryContactId
              : null
            : null,
        categoria: categoryServiceId,
        subcategoria: subCategoryServiceId,
        location: location,
        imagen: contactImage,
      });
    }

    if (
      props.data &&
      props.data?.categoryServiceId !== null &&
      props.data?.categoryServiceId != undefined &&
      props.data?.categoryServiceId !== 0
    ) {
      categoryServiceService
        .getSubCategoriesCatalogByCatId(
          props.data.categoryServiceId,
          process.env.REACT_APP_COMPANY
        )
        .then((subs) => {
          setSubCategoryFilter(subs);

          setDatos({
            ...datos,
            categoria: props.data.categoryServiceId,
            subcategoria: props.data.subCategoryServiceId,
            nombre: props.data.fullName,
            descripcion: props.data.directoryDescription,
            location: props.data.location,
            //Concatacto1
            contacto1: props.data.directoryContacts[0].contactValue,
            contacto1DirectoryContactId: props.data.directoryContacts[0].directoryContactId,
            //Correo
            correo: props.data.directoryContacts.filter((x) => x.contactTypeId == 2)[0].contactValue,
            contacto2DirectoryContactId: props.data.directoryContacts.filter(
              (x) => x.contactTypeId === 2
            )[0].directoryContactId,
            //Contaco2
            contacto2:  (props.data.directoryContacts[1]?.contactTypeId === 1 && props.data.directoryContacts[1]?.contactTypeName === 'Teléfono' && props.data.directoryContacts[1]?.contactValue !== 'null')
                ? props.data.directoryContacts[1].contactValue
                : '',
            contacto1optDirectoryContactId:
              props.data.directoryContacts.filter((x) => x.contactTypeId == 1)
                .length > 1
                ? props.data.directoryContacts.filter(
                  (x) => x.contactTypeId == 1
                )[1].directoryContactId
                : null,
            imagen: props.data.contactImage,
          });
        });
    }
  }, [props.data]);


  const updateImage = (e) => {
    const maxFiles = props.quantityImage;
    if (e.target.files.length > maxFiles) {
      Swal.fire({
        text: `Solo se admitiran ${maxFiles} imagenes.`,
        icon: "info",
      });
    }

    const files = Array.from(e.target.files).slice(0, maxFiles);
    const blobs = [];

    files.forEach((file) => {
      const reader = new FileReader();
      const url = reader.readAsDataURL(file);
      blobs.push(file);  // Agregar el blob al arreglo

      reader.onloadend = function () {
        setDatos((prevDatos) => ({
          ...prevDatos,
          imagen: Array.isArray(prevDatos.imagen) ? [...prevDatos.imagen, reader.result] : [reader.result],
        }));
      };
    });
    props.setFile(blobs);
  }

  function deleteImg(index) {
    let arrayIamge = [];
    if (Array.isArray(datos.imagen))
      arrayIamge = datos.imagen;
    else
      arrayIamge.push(datos.imagen);

    let newImagen = arrayIamge;
    newImagen.splice(index, 1);
    setDatos({ ...datos, imagen: newImagen });

    props.deleteImage(index);

    setBand(false);
  }

  const [modal, setModal] = useState(false);
  const toggle = (index) => {
    setIndexImagen(index);
    setModal(!modal);
  };

  const onFinish = () => {
    if (!datos.categoria || datos.subcategoria === "") {
      return Swal.fire({
        text: "Falta seleccionar un Categoría, revísalo por favor",
        icon: "error",
      });
    }

    props.Datepersonal(datos, "personal");
    props.toggleView();
  };
  const onFinishFailed = (errorInfo) => false;

  const PrintImage = () => {
    let arrayIamge = [];
    if (Array.isArray(datos.imagen)) arrayIamge = datos.imagen;
    else arrayIamge.push(datos.imagen);

    return arrayIamge.map((item, index) => (
      <div className="box_cnt_picture" key={index}>
        <button
          className="btn-close-file"
          type="button"
          onClick={() => deleteImg(index)}
        >
          <FaTimes />
        </button>
        <img
          alt="Imagen destacada en directorio de PrideMx"
          src={item}
          onClick={() => toggle(index)}
        />
      </div>));
  }

  return (
    <Form
      className="form-pride"
      autoComplete="off"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      fields={[
        {
          name: ["nombre"],
          value: datos.nombre,
        },
        {
          name: ["correo"],
          value: datos.correo,
        },
        {
          name: ["contacto1"],
          value: datos.contacto1,
        },
        {
          name: ["contacto2"],
          value: datos.contacto2,
        },
        {
          name: ["location"],
          value: datos.location,
        },
        {
          name: ["descripcion"],
          value: datos.descripcion,
        },
        {
          name: ["categoria"],
          value: datos.categoria,
        },
        {
          name: ["subcategoria"],
          value: datos.subcategoria,
        },
      ]}
    >
      <div className="title">
        <h3>Información personal</h3>
      </div>
      <Row gutter={16}>
        <Col md={12} sm={12} xs={24}>
          <Form.Item
            label="Nombre del servicio"
            name="nombre"
            rules={[
              {
                required: true,
                message: "* Campo requerido",
              },
              {
                min: 3,
                message: "Se requieren mínimo 3 carácteres",
              },
            ]}
            initialValue={datos.nombre}
            hasfeedback
          >
            <Input
              placeholder="Nombre del servicio"
              type="text"
              name="nombre"
              size="large"
              minLength={3}
              onChange={handleInputStateValue}
            />
          </Form.Item>
        </Col>
        <Col md={12} sm={12} xs={24}>
          <Form.Item
            label="Correo electrónico"
            name="correo"
            rules={[
              {
                required: true,
                type: "email",
                message: "* Formato incorrecto",
              },
            ]}
            initialValue={datos.correo}
            hasfeedback
          >
            <Input
              placeholder="Correo electrónico"
              maxLength={500}
              type="email"
              size="large"
              name="correo"
              onChange={handleInputStateValue}
              defaultValue={datos.correo}
            />
          </Form.Item>
        </Col>
        <Col md={12} sm={12} xs={24}>
          <Row>
            <Col xs={24}>
              <Form.Item
                label="Teléfono de contacto"
                name="contacto1"
                rules={[
                  {
                    required: true,
                    message: "* Solo se permiten números",
                  },
                  {
                    max: 10,
                    min: 10,
                    message: "Se requieren 10 dígitos",
                  },
                ]}
                initialValue={datos.contacto1}
                hasfeedback
              >
                <Input
                  placeholder="Teléfono de contacto"
                  maxLength={10}
                  size="large"
                  name="contacto1"
                  type="text"
                  onChange={handleInputStateValue}
                  onKeyPress={(e) => {
                    e = e || window.e;
                    const charCode = e.keyCode || e.which;
                    const charStr = String.fromCharCode(charCode);
                    if (isNaN(charStr)) e.preventDefault();
                  }}
                  defaultValue={
                    datos.contacto1 !== null ? datos.contacto1 : null
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                label="Teléfono de contacto 2"
                htmlFor={'contacto2'}
                name={'contacto2'}
                hasfeedback
              >
                <Input
                  placeholder="Teléfono de contacto 2"
                  maxLength={10}
                  size="large"
                  name="contacto2"
                  onKeyPress={(e) => {
                    e = e || window.e;
                    const charCode = e.keyCode || e.which;
                    const charStr = String.fromCharCode(charCode);
                    if (isNaN(charStr)) e.preventDefault();
                  }}
                  onChange={handleInputStateValue}
                  defaultValue={
                    datos.contacto2 !== null ? datos.contacto2 : null
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                label="Ubicación"
                name="location"
                rules={[
                  {
                    required: true,
                    message: "* Campo requerido",
                  },
                  {
                    min: 3,
                    message: "Se requieren mínimo 3 carácteres",
                  },
                ]}
                initialValue={datos.location}
                hasfeedback
              >
                <Input
                  placeholder="Ubicación"
                  type="text"
                  size="large"
                  minLength={3}
                  name="location"
                  onChange={handleInputStateValue}
                  defaultValue={datos.location}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col md={12} sm={12} xs={24}>
          <Form.Item
            label="Descripción corta del servicio *"
            rules={[
              {
                required: true,
                message: "* Campo requerido",
              },
              {
                max: 255,
                message: "Solo se permiten máximo 255 carácteres",
              },
            ]}
            name="descripcion"
            initialValue={datos.descripcion}
            hasfeedback
          >
            <Input.TextArea
              placeholder="..."
              type="email"
              size="large"
              maxLength={255}
              showCount
              name="descripcion"
              onChange={handleInputStateValue}
              defaultValue={datos.descripcion}
              rows={8}
            />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <h3>Información profesional</h3>
        </Col>
        <Col md={12} sm={12} xs={24}>
          <Form.Item
            label="Categoría"
            name="categoria"
            rules={[
              {
                required: true,
                message: "*Campo Requerido",
              },
            ]}
            initialValue={datos.categoria}
            hasfeedback
          >
            <Select
              size="large"
              placeholder="Selecciona una categoría"
              onChange={handleValidateCategory}
              name="categoria"
              value={datos.categoria}
              rules={[
                {
                  require: true,
                  message: "*Campo Requerido",
                },
              ]}
              hasfeedback
            >
              {categoryFilter &&
                categoryFilter.map((item, index) => {
                  return (
                    <Option key={index} value={item.categoryServiceId}>
                      {item.categoryName}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>
        <Col sm={12} xs={24}>
          <Form.Item
            label="Subcategoría"
            name="subcategoria"
            rules={[
              {
                required: true,
                message: "*Campo Requerido",
              },
            ]}
            initialValue={datos.subcategoria}
          >
            <Select
              size="large"
              placeholder="Selecciona una subcategoría"
              disabled={!datos.subcategoria}
              onChange={handleSelectSubCategory}
              name="subcategoria"
              value={datos.subcategoria}
              rules={[
                {
                  require: true,
                  message: "Campo Requerido",
                },
              ]}
              hasfeedback
            >
              {subCategoryFilter.map((subCategoryItem, index) => {
                return (
                  <Option
                    key={index}
                    value={subCategoryItem.subCategoryServiceId}
                  >
                    {subCategoryItem.subCategoryName}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24}>
          <FormGroup className="form-dashboard-group-file">
            {datos && datos.imagen && datos.imagen.length > 0 ? (
              <>
                <p>Previsualización</p>
                <div className="info-uploaded">
                  {PrintImage()}
                </div>
              </>
            ) : (
              <>
                <p className="text-start">Imagen destacada </p>
                <div className="info-upload">
                  <input
                    type="file"
                    id="file-upload"
                    placeholder="Imagen destacada"
                    accept="image/*"
                    name="imagen"
                    onChange={(e) => updateImage(e)}
                    multiple={props.quantityImage > 1}
                  />
                  <Label className="btn-upload-file" for="file-upload">
                    <i className="icon-upload-file"></i> Subir archivo
                  </Label>
                  <div className="extra">
                    <span>
                      <BsInfoCircle />
                    </span>
                    <p>
                      El tamaño recomendado de la imagen es de <b>1280 x 720</b>{" "}
                      pixeles.
                    </p>
                  </div>
                </div>
              </>
            )}
          </FormGroup>
        </Col>
        <Col xs={24}>
          <div className="form-pride-footer">
            <Button htmlType="submit" className="green" type={'primary'}>
              <p>Siguiente</p>
              <FaChevronRight />
            </Button>
          </div>
        </Col>
      </Row>
      <Modal isOpen={modal} toggle={toggle} style={{ paddingTop: "8rem" }}>
        <ModalBody style={{ padding: 0, position: "relative" }}>
          <Button className="btn-outline-dark close" onClick={toggle}>
            <FaTimes />
          </Button>
          <div className="modal-img">
            {indexImagen >= 0 ? (
              <img
                alt="Imagen destacada en directorio de PrideMx"
                src={datos.imagen[indexImagen]}
              />) : ''}
          </div>
        </ModalBody>
      </Modal>
    </Form>
  );
}
