import React from "react";
import "moment/locale/es";
import { Link } from "react-router-dom";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "./carouselMainHome.scss";

const typePublicationRoute = [
  { value: 0, route: "/our-company/1/" },
  { value: 1, route: "/blog-company/gallery/" },
];

export function CarouselMainHome({ ourWorld }) {
  return (
    <div className="prideWorld-container">
      <h3 className="ttls_in_muro">
        <Link to="/blog-company">
          Mundo Pride 
        </Link>
      </h3>
      <Splide
        options={{
          perPage: 4,
          autoplay: true,
          arrows: true,
          pagination: false,
          perMove: 2,
          width: "100%",
          rewindByDrag: true,
          rewind: true,
          drag: true,
          pauseOnHover: true,
          speed: 500,
          gap: 2,
          breakpoints: {
           
            640: {
              perPage:3,
            },
            490: {
              perPage: 2,
            },
          },
        }}
      >
        {ourWorld &&
          ourWorld.map((item, index) => (
            <SplideSlide key={index}>
              <Link
                to={`${
                  typePublicationRoute.find((f) => f.value === item.postTypeId)
                    .route
                }${item.companyPostId}`}
                className="card-worldpride"
                key={item.id}
              >
                <div className="card-worldpride-content">
                  <div className="overlay-img">
                    <img src={item.urlImage} alt="" />
                  </div>
                  <div className="info">
                    <h5>
                      {item.title
                        ? item.title.length <= 60
                          ? item.title
                          : item.title.substring(0, 60) + "..."
                        : ""}
                    </h5>
                    <p>{item.description}</p>
                  </div>
                </div>
              </Link>
            </SplideSlide>
          ))}
      </Splide>
    </div>
  );
}
