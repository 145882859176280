import React, { useState } from 'react';
import {useHistory, useParams} from 'react-router-dom';
import { SearchSurveyScale } from './searchSurveyScale';
import {TitleAdmin} from "../components/titleAdmin/titleAdmin";
import {HeadingAdmin} from "../components/headingAdmin/headingAdmin";

export function AdminSurveyScale() {
    const [refresh, setRefresh] = useState(0);
    const { surveyId } = useParams();
    const history = useHistory();

    return (
        <section className="dashboard-admin-container">
            <TitleAdmin
                title={'Escalas de valoración'}
                subtitle={'Listado de escalas de valoración'}
                event={() => history.push(`/admin/details-survey/${surveyId}`)}
            />
            <HeadingAdmin
                text={'Agregar escala'}
                event={() => history.push(`/admin/details-survey-scale/${surveyId}/0`)}
            />
            <SearchSurveyScale refresh={refresh} />
        </section>
    );
}
