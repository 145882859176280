import React, { useState } from "react";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Form, ListGroup } from "reactstrap";

export function EditorCommentsBox(props) {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  async function save(e) {
    e.preventDefault();
    const blocks = convertToRaw(editorState.getCurrentContent()).blocks;
    const value = blocks
      .map((block) => (!block.text.trim() && "\n") || block.text)
      .join("\n");
    if (props.id) {
      props.clickSave(value, props.id);
    } else {
      props.clickSave(value);
    }
    setEditorState(EditorState.createEmpty());
  }
  const blocks = convertToRaw(editorState.getCurrentContent()).blocks;

  return (
    <Form onSubmit={save} className="comment_new_box">
      <Editor
        editorState={editorState}
        onEditorStateChange={setEditorState}
        wrapperClassName="comments__box"
        editorClassName="comments__editor"
        toolbarClassName="comments__toolbar"
        toolbar={{
          options: ["emoji"],
        }}
      />
      {blocks[0].text.length === 0 || blocks[0].text.trim() === "" ? (
        <button type="submit" className="btn-send-comment disabled" disabled>
          <i className="fas fa-paper-plane"></i>
        </button>
      ) : (
        <button type="submit" className="btn-send-comment">
          <i className="fas fa-paper-plane"></i>
        </button>
      )}
    </Form>
  );
}
