import React, { useState, useEffect } from "react";
import { Button, Collapse } from "reactstrap";
import {
  BiChevronDown,
  BiChevronRight,
  BiChevronUp,
  BiMinus,
  BiPlus,
} from "react-icons/bi";
import dayjs from "dayjs";

export const ItemMorePost = ({ post, setCurrentFilter, key }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [yearsCounting, setYearCounting] = useState([]);

  const toggle = () => setIsOpen(!isOpen);

  useEffect(() => {
    const anio = new Date().getFullYear();
    if (anio === parseInt(post.year)) setIsOpen(true);
    countingPostsByYear();
  }, []);

  const countingPostsByYear = () => {
    let counter = 0;
    post.months.map((month) => (counter += month.count));
    setYearCounting(counter);
  };

  const selectActiveItem = (item) => {
    item.activeItem = false;
    setCurrentFilter(dayjs(item.filterMonthDate).format("YYYY-MM-DD"));
  };

  return (
    <div key={key}>
      <Button onClick={toggle} className="btn-publication-white">
        <p>
          {post.year} <span>{yearsCounting}</span>
        </p>
        <BiChevronDown />
      </Button>
      <Collapse className="box_collapse" isOpen={isOpen}>
        <ul>
          {post.months.map((item, i) => (
            <li key={i}>
              <Button
                className={`btn-publication-white ${
                  item.activeItem ? "active" : ""
                }`}
                onClick={() => selectActiveItem(item)}
              >
                <p>
                  {item.month}
                  {post.months.map((month, ind) => {
                    return month.month === item.month ? (
                      <span key={ind}>{month.count}</span>
                    ) : (
                      ""
                    );
                  })}
                </p>
                <BiChevronRight />
              </Button>
            </li>
          ))}
        </ul>
      </Collapse>
    </div>
  );
};
