import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ecommerceService } from "../../services/ecommerce.service";
import "./caringCommunity.scss";
import {Col, Row} from "antd";

export const ItemCaring = () => {
  const [CommunitiesData, setCommunitiesData] = useState([]);

  useEffect(() => {
    getCommunities();
  }, []);

  async function getCommunities() {
    ecommerceService.getProviders(14).then((result) => setCommunitiesData(result));
  }

  return (
    <Row gutter={16}>
        {CommunitiesData.map((community) => (
            <Col xl={6} lg={8} md={12} sm={24} xs={24}>
              <div className="card-caring">
                <div className="card-caring-content">
                  <a href={community.urlSite} target="_blank" rel="noopener noreferrer">
                    <h5>{community.name}</h5>
                  </a>
                  <div className="card-caring-content-info">
                    <Link to={"/caring-community/" + community.id}>
                      <div className="container-img">
                        <img src={community.providerLogo} alt={community.name} />
                      </div>
                    </Link>
                    <Link to={"/caring-community/" + community.id} className="text-description">
                      {(community.info.length > 65) ?
                          community.info.substring(0,65)+' ...'
                          :
                          community.info
                      }
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
        ))}
    </Row>
  );
}
