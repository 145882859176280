import React from "react";
import {useHistory} from "react-router-dom";
import {Button} from "antd";

export function NoticeMessage ({title, subtitle,message, success}) {
    const history = useHistory();

    return (
        <div className="register__successfull">
            <div className="">
                {parseInt(success) === 1 ?
                    <svg className="checkmark" viewBox="0 0 52 52">
                        <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                        <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                    </svg>
                    :
                    <svg className="cross" viewBox="0 0 52 52">
                        <circle className="cross__circle" cx="26" cy="26" r="25" fill="none"/>
                        <path className="cross__path cross__path--right" fill="none" d="M16,16 l20,20" />
                        <path className="cross__path cross__path--right" fill="none" d="M16,36 l20,-20" />
                    </svg>
                }
                <h4 className={`${(parseInt(success) === 1) ? '' : 'error'}`}>{title}</h4>
            </div>
            <div className="-form-light">
                <h5>{subtitle}</h5>
                <p>{message}</p>
                <div className="-form-light__footer">
                    <Button
                        htmlType={'button'}
                        className="outline-dark"
                        onClick={() => history.push('/')}
                    >
                        Iniciar sesión
                    </Button>
                </div>
            </div>
        </div>
    );
}
