import { callApi, callApiFileStorage } from "./service";
import swal from "sweetalert";
import { welcomeMessage } from "./welcomeMessage.service";
import { metaPixelService } from "./metaPixel.service";
import { setToken } from "../hooks/authHook";

export const userService = {
    login,
    logout,
    forgotPassword,
    resetPassword,
    editProfile,
    updateStyle,
    validateEmailAccount,
    register,
    verifyAccount
};

/**
 * Login
 */
async function login(params) {
  return callApi("/Auth/Login", "POST", params, {
    SkipValidationToken: true,
  }).then((result) => {
    if (result.responseCode === "00") setToken(result.data);

    return result;
  });
}

/**
 * Logout
 */
async function logout() {
  localStorage.removeItem("_rtpride");
  localStorage.removeItem("_tpride");
  localStorage.removeItem("userId");
  localStorage.removeItem("roleName");
  localStorage.removeItem("isFirstTime"); //use for notifications
}

/*
 Send email to recovery password
 */
async function forgotPassword(params) {
  return callApi("/Auth/ForgotPassword", "POST", params, {
    SkipValidationToken: true,
  }).then((result) => {
    if (result.responseCode === "00") {
      swal({
        text: "Se ha enviado un link a tu correo para reestablecer tu contraseña",
        icon: "success",
      }).then(() => (window.location.href = "/login"));
    } else {
      swal({ text: result.responseMessage, icon: "error" });
    }
  });
}

async function validateEmailAccount(params) {
  return callApi("/Auth/ValidateEmailAccount", "POST", params, {
    SkipValidationToken: true,
  }).then((result) => {
    if (result.responseCode === "00") {
      return result.data;
    } else {
      swal({ text: result.responseMessage, icon: "error" });
    }
  });
}

/*
 Set the new user password
 */
async function resetPassword(params) {
  return callApi("/Auth/ResetPassword", "POST", params, {
    SkipValidationToken: true,
  }).then((result) => {
    if (result.responseCode === "00") {
      swal({
        text: "Tu contraseña ha sido restablecida.",
        icon: "success",
      }).then(() => (window.location.href = "/login"));
    } else {
      swal({ text: result.responseMessage, icon: "error" });
    }
  });
}

async function editProfile(data, companyId) {
  const formData = new FormData();
  formData.append("CompanyId", companyId);
  formData.append(
    "ProfileImage",
    data.ProfileImage != null ? data.ProfileImage[0] : null
  );
  if (data.GenderIdentityId && data.GenderIdentityId != 0) {
    formData.append("GenderIdentityId", data.GenderIdentityId);
    formData.append("GenderIdentityFlag", data.GenderIdentityFlag);
  }
  if (data.nickname != null && data.nickname != "undefined") {
    if (data.nickname.trim() == "") {
      formData.append("nickname", "a5cdc20f-c8fe-48df-be43-fc56f43a198a");
    } else {
      formData.append("nickname", data.nickname);
    }
  }
  if (data.additionalEmail != null && data.additionalEmail != "undefined") {
    if (data.additionalEmail.trim() == "") {
      formData.append(
        "additionalEmail",
        "a5cdc20f-c8fe-48df-be43-fc56f43a198a"
      );
    } else {
      formData.append("additionalEmail", data.additionalEmail);
    }
  }

  if (data.Notification != null) {
    formData.append("Notification", data.Notification );
  }

  if (data.Preferences != null)
    formData.append("Preferences", data.Preferences);

  if (data.Mobile != null) formData.append("Mobile", data.Mobile);
  return callApiFileStorage(
    "/Account/EditProfile",
    "POST",
    formData,
    false
  ).then((result) => {
    if (result.responseCode === "00") {
      return swal({
        text: "El perfil se actualizó correctamente",
        icon: "success",
      }).then((value) => (window.location.href = "/profile"));
    } else {
      swal({ text: result.responseMessage, icon: "error" });
      return false;
    }
  });
}

async function updateStyle(data){

    return callApi('/Account/UpdateColor', 'POST', data,{
        SkipValidationToken: true,
      })
        .then((result) => {
            if (result.responseCode === "00") {                
                return true;
            } else {
                swal({ text: result.responseMessage, icon: "error" });
                return false;
            }
        });
}
function currentURL() {
  return window.location.href
}
function getUserAgent() {
  return window.navigator.userAgent;
}

async function register(model) {
  // window.location.href = "/successfull-register";
  return callApi("/Auth/Register", "POST", model, {
    SkipValidationToken: true,
  }).then((result) => {
    if (result.responseCode === "00") {
      // welcomeMessage.sendMessage(result.data); 
      var pixelUserInfoObj = JSON.parse(result.data);
      var registerPixelUserInfo = pixelUserInfoObj.RegisterPixelUserInfo;
      registerPixelUserInfo.client_user_agent = getUserAgent();
      var ped = { content_name: "Registro" };
      var pixelData = { pixelUserInfo: registerPixelUserInfo, pixelCustomData: ped };
      metaPixelService.trackEvent('track','CompleteRegistration', pixelData, currentURL());

      setTimeout(() => {
        window.location.href = "/successfull-register"
      }, 2300);
    } else {
      swal({ text: result.responseMessage, icon: "error" });
    }
  });
}

async function verifyAccount(params) {
  return callApi("/Auth/VerifyAccount", "POST", params, {
    SkipValidationToken: true,
  }).then((result) => {
    //WelcomeMessage();
    return result;
  });
}
