import React, {useEffect, useRef, useState} from 'react';
import Moment from "react-moment";
import { useSurvey } from '../../services/survey.service';
import {Space, Table, Button, Input, Tag} from "antd";
import {FaDownload, FaEdit, FaPowerOff, FaTrash} from "react-icons/fa";
import Highlighter from "react-highlight-words";
import moment from "moment";
import {SearchOutlined} from "@ant-design/icons";
import MySwal from "sweetalert2";

export function SearchSurvey(props) {
	const [surveysData, setSurveysData] = useState({ surveys: [] });
	const [searchText, setSearchText] = useState('');
	const [searchedColumn, setSearchedColumn] = useState('');
	const searchInput = useRef(null);

	const handleDelete = (data) => {
		MySwal.fire({
			title:"¿Deseas desactivar encuesta?",
			text: "No podrás revertir esta acción",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#FF002A',
			confirmButtonText: 'Eliminar',
			cancelButtonText: 'Cancelar'
		}).then((result) => {
			if(result.isConfirmed)  useSurvey.deleteSurvey(data).then((result) => { getSurveys(); });
		})
	};

	const handleDownload = (surveyId) => useSurvey.downloadCSV(surveyId);

	async function getSurveys() {
		useSurvey.getSurveys().then((result) => {
			if (result) setSurveysData({ surveys: result });
		});
	}

	const goToSurvey = (id) => window.location.href = '/admin/details-survey/'+id;

	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	};

	const handleReset = (clearFilters) => {
		clearFilters();
		setSearchText('');
	};

	const getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
			<div style={{padding: 8,}} onKeyDown={(e) => e.stopPropagation()}>
				<Input
					ref={searchInput}
					placeholder={`Buscar`}
					value={selectedKeys[0]}
					onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
					style={{
						marginBottom: 8,
						display: 'block',
					}}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined />}
						size="small"
						style={{
							width: 90,
						}}
					>
						Buscar
					</Button>
					<Button
						onClick={() => clearFilters && handleReset(clearFilters)}
						size="small"
						style={{
							width: 90,
						}}
					>
						Limpiar
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered) => (
			<SearchOutlined
				style={{
					color: filtered ? '#1677ff' : undefined,
				}}
			/>
		),
		onFilter: (value, record) =>
			record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
		onFilterDropdownOpenChange: (visible) => {
			if (visible) {
				setTimeout(() => searchInput.current?.select(), 100);
			}
		},
		render: (text) =>
			searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{
						backgroundColor: '#ffc069',
						padding: 0,
					}}
					searchWords={[searchText]}
					autoEscape
					textToHighlight={text ? text.toString() : ''}
				/>
			) : (
				text
			),
	});


	const columnsSurveys = [
		{
			title: 'ID',
			dataIndex: 'id',
			key: 'id',
			fixed: 'left',
			width: 80,
			defaultSortOrder: 'descend',
			sorter: (a, b) => a.id - b.id,
			sortDirections: ['descend','ascend'],
		},
		{
			title: 'Titulo de encuesta',
			dataIndex: 'title',
			key: 'title',
			...getColumnSearchProps('title'),
		},
		{
			title: 'No. Encuestados',
			dataIndex: 'no',
			key: 'no',
			sorter: (a, b) => a.no - b.no,
			sortDirections: ['descend','ascend'],
		},
		{
			title: 'Tot. Respuestas',
			dataIndex: 'total',
			key: 'total',
			sorter: (a, b) => a.total - b.total,
			sortDirections: ['descend','ascend'],
		},
		{
			title: 'Fecha límite',
			dataIndex: 'date',
			key: 'date',
			sorter: (a, b) => moment(a.date.props.children).unix() - moment(b.date.props.children).unix()
		},
		{
			title: 'Estatus',
			dataIndex: 'status',
			key: 'status',
			sorter: (a, b) => {
				if(a.status < b.status) {
					return -1;
				}
				if(a.status > b.status) {
					return 1;
				}
				return 0;
			},
			sortDirections: ['descend','ascend'],
		},

		{
			title: 'Acciones',
			dataIndex: 'options',
			key: 'options',
		},
	];


	const dataSurveys = surveysData.surveys.map(survey => {
		return {
			key: survey.surveyId,
			id: survey.surveyId,
			title: survey.title,
			no: survey.surveyedCount,
			total: survey.answeredCount,
			date: <Moment locale="es" format="DD/MM/YYYY">{survey.endDate}</Moment>,
			status: <Tag color={survey.estatus === 'Terminada' ? 'success' : 'warning'}>{survey.estatus}</Tag>,
			options: <><Space>
				<Button type="primary" htmlType={'button'} className="edit" onClick={() => goToSurvey(survey.surveyId)}>
					<FaEdit />
				</Button>
				<Button type="primary" htmlType={'button'} className="edit" onClick={() => handleDownload(survey.surveyId)}>
					<FaDownload />
				</Button>
				<Button type="primary" htmlType={'button'} className="delete" onClick={() => handleDelete(survey)} disabled={(!survey.active === true)}>
					<FaPowerOff />
				</Button>
			</Space></>
		};
	})

	useEffect(() => {
		getSurveys();
	}, []);

	return (
		<div className='wpr_quest_search'>
			<Table
				className="table-admin"
				columns={columnsSurveys}
				dataSource={dataSurveys}
				scroll={{ x: '992px', y: 800 }}
				locale={{
					triggerDesc: 'Ordenamiento descendente',
					triggerAsc: 'Ordenamiento ascedente',
					cancelSort: 'Cancelar ordenamiento',
					emptyText: 'Sin registros',
				}}
			/>
		</div>

	);
}
