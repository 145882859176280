import React from "react";
import './cardTokenizationResult.scss';
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Link } from "react-router-dom/cjs/react-router-dom";
import {FaUser} from "react-icons/fa";

export function CardTokenizationResult() {
    const { isSuccess } = useParams();
    console.log(isSuccess);
    return (
        <>
            <div class="container-msg">
                <div class="result-card">
                    {isSuccess == "true" ?
                        <>
                            <svg class="result-svg" viewBox="0 0 130.2 130.2">
                                <circle class="path circle" fill="#2EBF19" cx="65.1" cy="65.1" r="62.1" />
                                <polyline class="path check" fill="none" stroke="#ffffff" stroke-width="12" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                            </svg>
                            <div class="result-card-body">
                                <h2 class="card-title">Tarjeta verificada con éxito</h2>
                            </div>
                        </>
                        :
                        <>
                            <svg class="result-svg" viewBox="0 0 130.2 130.2">
                                <circle class="path circle" fill="#FF002A" cx="65.1" cy="65.1" r="62.1" />
                                <line class="path line" fill="none" stroke="#FFFFFF" stroke-width="12" stroke-linecap="round" stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
                                <line class="path line" fill="none" stroke="#FFFFFF" stroke-width="12" stroke-linecap="round" stroke-miterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
                            </svg>
                            <div class="result-card-body">
                                <h2 class="card-title">Tarjeta rechazada</h2>
                                <p>Los datos son inválidos o el saldo es insuficiente.</p>
                            </div>
                        </>
                    }
                    <Link to="/profile" className="btn-dark return-button">
                        <p>Ir a Mi Perfil</p>
                        <FaUser />
                    </Link>
                </div>
            </div>
        </>
    );
}
