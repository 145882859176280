import React from "react";

export function AvatarChats({ img, titleImg, flagClass }) {
  return (
    <div className="flag-container">
      {flagClass && flagClass ? (<div className={`bg rotating ${flagClass}`}></div>) : ("")}
      <div className="flag-container-avatar">
          <img src={img ? img : "https://storagervadmindev.blob.core.windows.net/ctnprimecontentmx/BaseImage/Default-avatar.jpg"} alt="Usuarios en Kuimby" title={titleImg}/>
      </div>
    </div>
  );
}
{
  /* ====================
     CLASES COLOR STATUS
     ======================
    .active
    .occupied
    .disconnected */
}
{
  /*<span className="status_color active"></span>*/
}
