import React, { useEffect, useState } from "react";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { useHistory, useLocation } from "react-router-dom";
import "./footer.scss";

import { userService } from "../../services/user.service";
import { useSelector } from "react-redux";
import { backendService } from "../../services/backend.service";
import {
  isPushNotificationSupported,
  sendNotification,
  initializePushNotifications,
} from "./footer.notification";
import { WrapperChats } from "../wrapperChats/wrapperChats";
import { ButtonToolbar, Dropdown } from "rsuite";
//import {messageService} from "../../services/message.service";

export function Footer({ windowDimensions, messages }) {
  const history = useHistory();
  const roleName = useSelector((state) => state.userSettings.roleName);
  const [notificationInfo, setNotificationInfo] = useState({
    notificationInfoData: [],
  });
  const [activeLink, setActiveLink] = useState("");
  const [pathname, setPathname] = useState(useLocation());
  const companyLogo = useSelector((state) => state.userSettings.companyLogo);
  const [connection, setConnection] = useState(null);

  /* states for dropdowns */
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  /* end states for dropdowns */
  const logout = () => {
    const style = document.getElementsByTagName("style");
    document
      .getElementsByTagName("head")[0]
      .removeChild(style[style.length - 1]);
    userService.logout().then(() => {
      history.push("/");
    });
  };

  const miPerfil = () => history.push("profile");

  useEffect(() => {
    if (performance.navigation.type !== 1) {
      let isFirstTime = localStorage["isFirstTime"];
      if (isFirstTime === undefined) {
        localStorage["isFirstTime"] = true;
        if (isPushNotificationSupported()) {
          initializePushNotifications().then(function (dataConsent) {
            if (dataConsent === "granted") GetnotificationApi(true, false);
          });
        }
      }
    }
    GetnotificationApi(false, true);
  }, []);

  const GetnotificationApi = (ShowPush, ShowNotif) => {
    backendService.GetNotificationData().then((result) => {
      if (result) {
        if (ShowPush) {
          sendNotification(result);
        }
        if (ShowNotif) {
          setNotificationInfo({ notificationInfoData: result });
        }
      }
    });
  };

  useEffect((url) => {
    const connection = new HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_HUB_URL}/notification`, {
        headers: { NoBlocking: true },
      })
      .withAutomaticReconnect()
      .build();

    setConnection(connection);
  }, []);

  function getWidthDimensions() {
    const { innerWidth: width } = window;
    return width;
  }

  const [widthDimensions, setWidthDimensions] = useState(getWidthDimensions());

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {
          connection.on("beNotified", (notification) => {
            const listaEventos = [];
            listaEventos.push(notification);
            sendNotification(listaEventos);
            GetnotificationApi(false, true);
          });
        })
        .catch((error) => console.log(error));
    }
    getCurrentRoute();
    const handleResize = () => setWidthDimensions(getWidthDimensions());
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);

    if (widthDimensions >= 768) setMenuMobile(!menuMobile);
  }, [connection, widthDimensions]);

  const goHome = () => (window.location.href = "/home");
  const goAdminDashboard = () => {
    setActiveLink("admin");
    window.location.href = "/admin";
  };

  const [menuMobile, setMenuMobile] = useState(false);

  const showMenu = () => setMenuMobile(!menuMobile);

  const getCurrentRoute = () => {
    if (
      pathname.pathname === "/audience" ||
      pathname.pathname == "/course-view" ||
      pathname.pathname === "/pride-music" ||
      pathname.pathname === "/blog-company" ||
      pathname.pathname == "/stories-pride"
    ) {
      setActiveLink("categoriesPride");
    }
    if (
      // pathname.pathname === "/messages" ||
      pathname.pathname == "/rewards" ||
      pathname.pathname == "/profile" ||
      // pathname.pathname == "/diary" ||
      pathname.pathname == "/surveys"
    ) {
      setActiveLink("userInfo");
    }
    if (
      pathname.pathname === "/interest-group" ||
      pathname.pathname == "/caring-community" ||
      pathname.pathname == "/discount" ||
      pathname.pathname == "/job-bank" ||
      pathname.pathname == "/register-job" ||
      pathname.pathname == "/directory"
    ) {
      setActiveLink("communityPride");
    }
    if (pathname.pathname === "/home") setActiveLink("home");
    if (pathname.pathname === "/admin") setActiveLink("admin");
  };

  useEffect(() => {
    getCurrentRoute();
  }, [pathname.pathname]);

  const [widgets, setWidgets] = useState({
    surveyWidget: {},
    messageWidget: {},
    rewardWidget: {},
    courseWidget: {},
    providerWidget: [{ id: 0, logoCrls: "" }],
  });
  // const [messages,setMessages] = useState([]);

  useEffect(() => {
    backendService.widgets().then((data) => {
      if (data.surveyWidget != null) {
        let date = new Date();
        date.setDate(date.getDate() + data.surveyWidget.expirationDays);
        data.surveyWidget.expirationDate = date;
      }
      setWidgets(data);
    });
    // messageService
    //   .getUnreadCount()
    //   .then((data) => setMessages(data));
  }, []);

  return (
    <footer className="dashboard-footer">
      {windowDimensions <= 992 ? (
        <div className="dashboard-footer-menu">
          <ul>
            <li>
              <ButtonToolbar
                onClick={goHome}
                className={`btn-menu dashboard ${
                  activeLink === "home" ? "dashboardLink" : ""
                }`}
              >
                <i className="icon-home"></i>
              </ButtonToolbar>
            </li>
            <li>
              <ButtonToolbar className="btn-menu user">
                <Dropdown
                  className={`btn-menu user ${
                    activeLink === "userInfo" ? "userLink" : ""
                  }`}
                  trigger={["click"]}
                  title={
                    <>
                      <i className="icon-profile"></i>
                      {widgets.surveyWidget ||
                      widgets.rewardWidget ? (
                        <div className="circle-notification"></div>
                      ) : (
                        ""
                      )}
                    </>
                  }
                >
                  <Dropdown.Item className="user" href="/profile">
                    <div className="sublinks">
                      <i className="icon-user"></i>
                      <p> Mi Perfil</p>
                    </div>
                  </Dropdown.Item>
                  {/* <Dropdown.Item href="/messages" className="user">
                    <div className="sublinks">
                      <span>
                        <i className="icon-envelope"></i>
                      </span>
                      <p>
                        Mensajes
                        {messages ? (
                          <span className="notification">
                            {messages.unreadCount}
                          </span>
                        ) : (
                          ""
                        )}
                      </p>
                    </div>
                  </Dropdown.Item> */}
                  {/* <Dropdown.Item href="/diary" className="user">
                    <div className="sublinks">
                      <span>
                        <i className="icon-calendar-time"></i>
                      </span>
                      <p>Calendario</p>
                    </div>
                  </Dropdown.Item> */}
                  <Dropdown.Item href="/rewards" className="user">
                    <div className="sublinks">
                      <span>
                        <i className="icon-gif"></i>
                      </span>
                      <p>
                        Rewards
                        {widgets.rewardWidget ? (
                          <span className="notification">
                            {widgets.rewardWidget.quantity}
                          </span>
                        ) : (
                          ""
                        )}
                      </p>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item href="/surveys" className="user">
                    <div className="sublinks">
                      <span>
                        <i className="icon-opinion"></i>
                      </span>
                      <p>
                        Tu Opinión
                        {widgets.surveyWidget ? (
                          <span className="notification">
                            {widgets.surveyWidget.quantity}
                          </span>
                        ) : (
                          ""
                        )}
                      </p>
                    </div>
                  </Dropdown.Item>
                </Dropdown>
              </ButtonToolbar>
            </li>
            <li>
              <ButtonToolbar className="btn-menu categories">
                <Dropdown
                  className={`btn-menu categories ${
                    activeLink === "categoriesPride" ? "categoriesLink" : ""
                  }`}
                  title={
                    <>
                      <i className="icon-categories"></i>
                      {widgets.courseWidget ? (
                        <div className="circle-notification"></div>
                      ) : (
                        ""
                      )}
                    </>
                  }
                  trigger={["click"]}
                >
                  <Dropdown.Item href="/blog-company" className="categories">
                    <div className="sublinks">
                      <i className="icon-pride-world"></i>
                      <p>Mundo Pride</p>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item href="/stories-pride" className="categories">
                    <div className="sublinks">
                      <i className="icon-pride"></i>
                      <p>Orgullo LGBTQ+</p>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item href="/course-view" className="categories">
                    <div className="sublinks">
                      <i className="icon-billboard"></i>
                      <p>
                        Cartelera Pride
                        {widgets.courseWidget ? (
                          <span className="notification">
                            {widgets.courseWidget.quantity}
                          </span>
                        ) : (
                          ""
                        )}
                      </p>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item href="/audience" className="categories">
                    <div className="sublinks">
                      <i className="icon-pride-talks"></i>
                      <p>PrideTalks</p>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item href="/pride-music" className="categories">
                    <div className="sublinks">
                      <i className="icon-rewards"></i>
                      <p>Pride Music</p>
                    </div>
                  </Dropdown.Item>
                </Dropdown>
              </ButtonToolbar>
            </li>
            <li>
              <ButtonToolbar className="btn-menu community">
                <Dropdown
                  className={`btn-menu community ${
                    activeLink === "communityPride" ? "communityLink" : ""
                  }`}
                  title={<i className="icon-community"></i>}
                  trigger={["click"]}
                >
                  <Dropdown.Item href="/directory" className="community">
                    <div className="sublinks">
                      <i className="icon-entrepreneurs"></i>
                      <p>Economía Incluyente</p>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item className="community" href="/interest-group">
                    <div className="sublinks">
                      <i className="icon-interest-group"></i>
                      <p>Grupos de Interés</p>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item href="/discount" className="community">
                    <div className="sublinks">
                      <i className="icon-discounts"></i>
                      <p>Beneficios</p>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item href="/caring-community" className="community">
                    <div className="sublinks">
                      <i className="icon-hand-heart"></i>
                      <p style={{ fontSize: ".75rem" }}>Comunidad Solidaria</p>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item href="/job-bank" className="community">
                    <div className="sublinks">
                      <i className="icon-jobs"></i>
                      <p>Bolsa de trabajo</p>
                    </div>
                  </Dropdown.Item>
                </Dropdown>
              </ButtonToolbar>
            </li>
            {
              {
                "Recursos Humanos": (
                  <li>
                    <ButtonToolbar
                      onClick={goAdminDashboard}
                      className={`btn-menu admin ${
                        activeLink === "admin" ? "adminLink" : ""
                      }`}
                    >
                      <span>
                        <i className="icon-user-admin"></i>
                      </span>
                    </ButtonToolbar>
                  </li>
                ),
              }[roleName]
            }
          </ul>
        </div>
      ) : (
        ""
      )}
      <div className="container-chat">
        <WrapperChats windowDimensions={windowDimensions} />
      </div>
      <script src="/service-worker.js" />
    </footer>
  );
}
