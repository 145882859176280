import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Input, Space } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { contactUsService } from '../../services/contactUs.service';
// import imgContactUS from "../../assets/imgs/contact_us.jpg";
import imgContactUS from "../../assets/imgs/contact_us.jpg";
import Swal from "sweetalert2";
import "./modalContact.scss";



export const ModalContact = (props) => {
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const [submittable, setSubmittable] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [contact, setContact] = useState({
    subject: "",
    email: "",
    textMessage: "",
  });
  const submitMyForm = async (contact) => {
    setSubmittable(false);
    if (!contact.subject == ""  &&  !contact.email == "" && !contact.textMessage == "") 
    {
      
      await contactUsService.sendContactMessage(contact)
      .then((result) => {
        if (result) {
          form.resetFields();
          setOpenModal(!openModal);
        }
      });
      
      console.log("Mensaje enviado");
    }
  };

  useEffect(() => {
    form.validateFields({
        validateOnly: true,
      })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, values]);
  

  const showContact = () => {
    setOpenModal(!openModal);
  };

  const handleInputChange = (e) => setContact({...contact, [e.target.name]: e.target.value})

  return (
    <>
      <button className="link_footer" onClick={showContact}>
        Contacto
      </button>
      <Modal
        className="wrapper_modal_contact"
        open={openModal}
        centered
        width={820}
      >
        <div className="contact_poster">
          <img
            src={imgContactUS}
            alt="Contácta al Equipo de PrideMx para más información"
            title="Contacto PrideMx"
            className="frm_contct_img_blur"
          />
          <img
            src={imgContactUS}
            alt="Contácta al Equipo de PrideMx para más información"
            title="Contacto PrideMx"
            className="frm_contct_img"
          />
          {/* <div className="box_mnj_contacto">
            <h2 className="form_contact_ttl xbold ">
              El poder de la comunidad
            </h2>
            <h2 className="form_contact_ttl xthin">
              <i>en un solo espacio</i>
            </h2>
          </div> */}
        </div>
        <div className="contact_form">
          <div className="contact_pts">
            <div className="contact_pt_a">
              <h2 className="form_contact_ttl xthin">Necesito</h2>
              <h2 className="form_contact_ttl xbold">más información</h2>
            </div>
            <Form
              form={form}
              name="validateOnly"
              layout="vertical"
              autoComplete="off"
              className="is_form_contact"
            >
              <Form.Item label={"Asunto"} name={"subject"} rules=
                {[
                  {
                    required: true,
                    message: "*Campo requerido",
                  },
                ]}
              >
                <Input name="subject" onChange={handleInputChange} />
              </Form.Item>

              <Form.Item label="Correo electrónico" name={"email"} rules=
                {[
                  {
                    type: "email",
                    required: true,
                    message: "*Ingresa un correo electrónico valido",
                  },
                ]}
              >
                <Input name="email" onChange={handleInputChange} />
              </Form.Item>

              <Form.Item label="Mensaje" name={"textMessage"} rules=
                {[
                  {
                    required: true,
                    message: "*Ingresa un mensaje de al menos 10 caracteres.",
                    min: 10, 
                  },
                ]}
              >
                <TextArea rows={2} name= "textMessage" onChange={handleInputChange} />
              </Form.Item>

              <Form.Item>
                <Space>
                  <Button type="primary" htmlType="button"  onClick={()=>submitMyForm(contact)} disabled={!submittable} >
                    Enviar mensaje
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </div>
        </div>

        <Button
          shape="circle"
          icon={<CloseOutlined />}
          size="small"
          onClick={showContact}
          className="btn_closed"
        ></Button>
      </Modal>
    </>
  );
};
