import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./audience.scss";
import "moment/locale/es";
import { BoxListAudience } from "./boxListAudience";
import { audienceService } from "../../services/audience.service";
import { AdvertisingSection } from "../../components/advertisingSection/advertisingSection";
import { SideAdvertising } from "../../components/sideAdvertising/sideAdvertising";
import { Button } from "rsuite";
import { useCompanyHoliday } from "../../services/companyHoliday.service";
import { FooterHome } from "../../components/footer/footerHome";
import {Col, Flex, Modal, Row} from "antd";
import {LastItemAudience} from "./lastItemAudience";
import useWindowViewport from "../../hooks/useWindowViewport";
import {metaPixelService} from "../../services/metaPixel.service";

export const Audience = ( ) => {
  const [activeAsideSurvey, setStateAsideSurvey] = useState("");
  const [listAudienceP, setListAudienceP] = useState([]);
  const [listAudienceO, setListAudienceO] = useState([]);
  const [disabledHasMore, setDisabledHasMore] = useState(false);
  const itemsPerPage = 8;
  const [lastItemPublished,setLastItemPublished] = useState([]);
  const [banner, setBanner] = useState(false);
  const [sliderItems, setSliderItems] = useState([]);
  const { windowWidth, windowHeight } = useWindowViewport();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [videoWidth, setVideoWidth] = useState(1280);
  const [videoHeight, setVideoHeight] = useState(720);
  const [settingsVideo, setSettingsVideo] = useState({
    titleVideo: '',
    urlVideo: ''
  });
  

  const AsidePurchaseBox = () => setStateAsideSurvey(activeAsideSurvey === "" ? " is-show" : "");

  async function getPending() {
    audienceService.getPending().then((result) => {
      if (result) {
        setListAudienceP(result.sort((a, b) => a.data.conferenceDate > b.data.conferenceDate ? 1 : -1).map((item) => item))
      }
    });
  }
  const { idAudience } = useParams();

  async function getComplete() {
    audienceService.updateComplete(0, 0, itemsPerPage).then((result) => {
      if (result) {
        if (idAudience){
          let itemToOpenInModal = result.slice(0, 1);
          handleLinkConference(itemToOpenInModal[0], true, true)
        }
        setListAudienceO(result);
        setLastItemPublished(result.slice(0, 1))
      }
    });
  }

  async function updateComplete(date, id) {
    audienceService.updateComplete(date, id, itemsPerPage).then((result) => {
      if (result && result.length > 0) setListAudienceO([...listAudienceO, ...result]);
      else setDisabledHasMore(true)
    });
  }

  const getCompanyHolidays = async () => {
    useCompanyHoliday.getCompanyHolidayFront(4).then((result) => {
      if (result) setBanner(true)
    });
  }

  async function getCompanyHolidaysSide() {
    useCompanyHoliday.getCompanyHolidayFront(3).then((result) => {
      if (result) setSliderItems(result.map((ch) => {
          return {
            key: ch.companyHolidayId.toString(),
            file: ch.imagePath,
            description: ch.description,
            isVideo: ch.isVideo,
            link: ch.iframe
          };
        }));
    });
  }

  const showModal = (child) => {
    setSettingsVideo({
      titleVideo: child.title,
      urlVideo: child.data.conferenceUrl.split('=')[1]
    })
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setSettingsVideo({
      titleVideo: '',
      urlVideo: ''
    })
    setIsModalOpen(false);
  };

  const handleLinkConference = (child, onLive, publishedItems) => {
    if(publishedItems || onLive) {
      const data = {
        content_name: child.data.title,
        content_type: "product",
        contents: [
          {
            id: child.data.auditoriumId,
            quantity: 1,
            link: child.data.conferenceUrl,
          },
        ],
      };
      metaPixelService.trackEvent(
          "track",
          "ViewContent",
          data
      );
      //window.open(child.data.conferenceUrl,'_blank');
      showModal(child);
    }
  }

  useEffect(() => {
    getPending();
    getComplete();
    getCompanyHolidays();
    getCompanyHolidaysSide();
  }, []);

  useEffect(() => {
    if(windowWidth > 1320) {
      setVideoWidth(1280)
      setVideoHeight(720)
    }
    if(windowWidth < 1320) {
      setVideoWidth(854)
      setVideoHeight(480)
    }
    if(windowWidth < 992) {
      setVideoWidth(640)
      setVideoHeight(360)
    }
    if(windowWidth < 680) {
      setVideoWidth(426)
      setVideoHeight(240)
    }
    if(windowWidth < 456) {
      setVideoWidth(300)
      setVideoHeight(200)
    }
  }, [windowWidth])


  return (
      <section className="dashboard-container-page">
        <article className="audience-container">
          <Row gutter={[16,0]}>
            <Col xs={24}>
              <Flex justify={'space-between'} align={'center'}>
                <h2>PrideTalks</h2>
                {(sliderItems && sliderItems.length > 0) &&
                    <Button
                        className="dots"
                        onClick={AsidePurchaseBox}
                    >
                      <i className="icon-dots-v"></i>
                    </Button>
                }
              </Flex>
            </Col>
            {(banner) && <Col xs={24}>
                  <AdvertisingSection HolType={4}/>
                </Col>
            }

            {lastItemPublished.length > 0 && <Col xs={24}>
                <LastItemAudience
                    item={lastItemPublished[0]}
                    showModal={showModal}
                    handleLinkConference={handleLinkConference}
                />
              </Col>
            }
            {listAudienceO.length > 0 && (
              <Col
                  lg={sliderItems.length > 0 ? 20 : 24}
                  md={sliderItems.length > 0 ? 24 : 24}
                  sm={sliderItems.length > 0 ? 24 : 24}
                  xs={sliderItems.length > 0 ? 24 : 24}
              >
                <BoxListAudience
                    listAudienceP={listAudienceP}
                    listAudienceO={listAudienceO}
                    updateComplete={updateComplete}
                    disabledHasMore={disabledHasMore}
                    showModal={showModal}
                    handleLinkConference={handleLinkConference}
                />
              </Col>)
            }
            {sliderItems.length > 0 &&
                <Col lg={4} md={24} sm={24} xs={24}>
                  <aside className={`aside-lateral ${activeAsideSurvey}`}>
                    <Button className="btn-close" onClick={AsidePurchaseBox}><i className="icon-close"></i></Button>
                    <SideAdvertising sliderItems={sliderItems} />
                  </aside>
                </Col>
            }
            {
              (settingsVideo.urlVideo !== '') ?
                  <Modal
                      open={isModalOpen}
                      footer={null}
                      onCancel={handleCancel}
                      width={'max-content'}
                      className={'modal-iframe'}
                      keyboard={true}
                  >
                    <iframe
                        width={videoWidth}
                        height={videoHeight}
                        src={`https://www.youtube-nocookie.com/embed/${settingsVideo.urlVideo}?autoplay=1&rel=0`}
                        title={settingsVideo.titleVideo}
                        frameBorder="0"
                        allow="autoplay; encrypted-media"
                        allowFullScreen></iframe>
                  </Modal> : null
            }
            <Col xs={24}>
              <FooterHome />
            </Col>
          </Row>
        </article>
      </section>
  );
}
