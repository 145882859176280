import React, { useState } from 'react';
import { FormGroup, Label, Input } from "reactstrap";
import swal from "sweetalert"
import {FaCloudUploadAlt } from "react-icons/fa";

export function GeneralUpload(props) {

    const [files, setFiles] = useState([]);
    const [isMultiple] = useState(props.IsMultipe != null ? props.IsMultipe : false);

    const maxSize = process.env.REACT_APP_MAX_FILESIZE;

    const handleOnChangeFile = (event) => {

        let fileList = [];
        if (event.target.files != null && event.target.files.length > 0) {

            var targetfiles = event.target.files;

            for (var i = 0; i < targetfiles.length; i++) {
                var file = targetfiles[i];
                var size = file.size / 1024 / 1024;
                if (size < maxSize) {
                    fileList = [...fileList, file];
                } else {
                    swal({ text: 'Los archivos debe ser menores a ' + maxSize + ' MB.', icon: "error" });
                    event.target.value = null;
                    break;
                }

            }
            setFiles(fileList);
        }
        if (props.handleOnChangeFiles) props.handleOnChangeFiles(fileList);
    }

    return (
        <FormGroup className="form-dashboard-group-upload">
            <Label for={props.TitleLabel}><FaCloudUploadAlt /> {props.TitleLabel}</Label>
            <Input id={props.TitleLabel} type="file" onChange={handleOnChangeFile} multiple={isMultiple} accept={props.accept} {...{ required: props.required && "required" }} disabled={props.disabled}/>
        </FormGroup>
    );
}
