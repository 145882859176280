import React  from "react";
import { Link } from "react-router-dom";
import LogoCompany from "../../assets/imgs/R_C.png";
import "./register.scss";
import { useSelector } from "react-redux";
import { ImgCache } from "../../components/CustonHooks/imgCache"
import {Col, Row} from "reactstrap";
import {Legals} from "../../components/legals/legals";
import {FaHome} from "react-icons/fa";

export function MembershipPayment(props) {
    const companyLogo = useSelector(state => state.userSettings.companyLogo);
    return (
        <section className="membership-wrapper">
            <div className="membership-bg">
                <div className="membership-container">
                    <div className="membership-title">
                        <h2>Pago de Membresía </h2>
                        <ImgCache src={companyLogo ? companyLogo : LogoCompany} alt="Logo oficial Kuimby Social" />
                    </div>
                    <div className="membership-content">
                        {props.location.pathname == "/error-membership"
                            ? <div className="membership-content-pay pay-cancel">
                                <div className="form-membership">
                                    <Row>
                                        <Col sm={12}>
                                            <div className="icon">
                                                <svg className="cross" viewBox="0 0 52 52">
                                                    <circle className="cross-circle" cx="26" cy="26" r="25" fill="none"/>
                                                    <path className="cross-path cross-path-right" fill="none" d="M16,16 l20,20"/>
                                                    <path className="cross-path cross-path-right" fill="none" d="M16,36 l20,-20"/>
                                                </svg>
                                                <h2>¡Ocurrió un problema con el pago, ha sido cancelada o rechazada por el banco!</h2>
                                                <p>Puedes actualizar tu método de pago e intentarlo de nuevo.</p>
                                            </div>
                                        </Col>
                                        <Col sm={12}>
                                            <div className="form-membership-footer">
                                                <Link to="/login" className="btn-dark">
                                                    <p>Iniciar sesión</p>
                                                    <FaHome />
                                                </Link>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            : <div className="membership-content-pay pay-success">
                                <div className="form-membership">
                                    <Row>
                                        <Col sm={12}>
                                            <div className="icon">
                                                <svg className="checkmark" viewBox="0 0 52 52">
                                                    <circle className="checkmark-circle" cx="26" cy="26" r="25" fill="none"/>
                                                    <path className="checkmark-check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                                                </svg>
                                                <h2>¡Pago exitoso!</h2>
                                            </div>
                                        </Col>
                                        <Col sm={12}>
                                            <div className="form-membership-footer">
                                                <Link className="btn-dark" to="/login">
                                                    <p>Iniciar sesión</p>
                                                    <FaHome />
                                                </Link>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="membership-footer">
                        <Legals />
                    </div>
                </div>
            </div>
        </section>
    );
}
