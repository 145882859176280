import React, { useEffect, useState } from "react";
import { chatService } from "./../../services/chat.service";
import { Searches } from "./searches";
import { TtlsChats } from "./ttlsChats";
import { AvatarChats } from "./avatarChats";
import { NewGroup } from "./newGroup";
import {BsPersonAdd} from "react-icons/bs";
import {Button, Space} from "antd";

export function ListGroups({ showChat }) {
  const [groups, setGroups] = useState([]);
  const [groupsSearch, setGroupsSearch] = useState([]);
  const [updateGroup, setUpdateGroup] = useState(null);
  const [openGroup, setOpenGroup] = useState("");
  const fOpenGroup = () => setOpenGroup(openGroup === "" ? "open_new_group" : "");

  useEffect(() => {
    if (openGroup === "") {
      chatService.getGroups().then((result) => {
        setGroups(result);
        setGroupsSearch(result);
      });
      setUpdateGroup(null);
    }
  }, [openGroup]);

  const handleSearch = (text) => {
    if (text) {
      text = text.removeAccents().toUpperCase();
      let groupFiltered = groups.filter((item) =>
        item.name.removeAccents().toUpperCase().includes(text)
      );
      if (groupFiltered.length > 0) {
        let groupsFiltered = Object.assign([], groupFiltered);
        setGroupsSearch(groupsFiltered);
        return;
      }

      setGroupsSearch([]);
    } else setGroupsSearch(groups);
  };

  const handleOpenChat = (group) => {
    const chat = {
      contactId: group.id,
      name: group.name,
      image: group.image,
      readOnly: false,
    };
    showChat(chat);
  };

  const handleUpdateGroup = (group) => {
    if (openGroup === "") setOpenGroup('open_new_group')
    setUpdateGroup(group);
    setOpenGroup('')
  };

  return (
    <div className="sections-chat active list-chats-container list-users">
      <div className="list-users-options">
        <TtlsChats title="Grupos" />
        <button className="btn-new-group" onClick={() => handleUpdateGroup()}>
          <BsPersonAdd /> {" "}
          <span>Crear grupo</span>
        </button>
      </div>
      <Searches placeHolder="Buscar grupo" handleSearch={handleSearch} />
      <div className="overflow_wpr">
        {groupsSearch.map((group) => (
          <div className="item" key={group.id}>
            <AvatarChats img={group.image} />
            <div className="item-chat">
              <div className="name">{group.name}</div>
              <Space>
                <Button className="btn-msg sm" onClick={() => handleUpdateGroup(group)}>
                  <i className="icon-edit"></i>
                </Button>
                <Button className="btn-msg sm" onClick={() => handleOpenChat(group)}>
                  <i className="icon-message"></i>
                </Button>
              </Space>
            </div>
          </div>
        ))}
      </div>
      <NewGroup
        isOpenGroup={openGroup}
        lastOpenGroup={fOpenGroup}
        updateGroup={updateGroup}
      />
    </div>
  );
}
