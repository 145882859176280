import React, {useEffect, useState, forwardRef, useImperativeHandle} from 'react';
import { useGenericSection } from '../../services/genericSection.service';
import {Button, Pagination, Space, Table} from "antd";
import {FaEdit, FaTrash} from "react-icons/fa";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import {HeadingAdmin} from "../components/headingAdmin/headingAdmin";

const SearchAdminGenericSection = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({ refreshDataGrid }));
    const [dataGrid, setDataGrid] = useState([]);
    const [tableConfig, setTableConfig] = useState({
        totalPages: 1,
        activePage: 1,
        section: {
            sectionName: "",
            sectionId: 0
        }
    });
    const [pageSize, setPageSize] = useState(10);

    const handleDelete = (event, data) => {
        Swal.fire({
            title: 'Estas a punto de eliminar la publicación',
            text: "No podrás revertir esta acción",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#FF002A',
            confirmButtonText: 'Eliminar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if(result.isConfirmed)
                useGenericSection.deleteItem(data.genericSectionItemId).then((result) => {
                    Swal.fire({
                        text: "Publicación eliminada con éxito",
                        icon: "success"
                    });
                    getAll();
                });
        })
    };

    async function getAll() {
        useGenericSection.getGenericItemByCompany(1, pageSize)
            .then(res => {
                if (res) {
                    setDataGrid(res.list);
                    setTableConfig({
                        section: {
                            sectionName: "",
                            sectionId: 0
                        },
                        totalPages: res.totalPages,
                        activePage: 1
                    });
                }
            })
    }

    const handleSelectPage = (page, pageS) => {
        useGenericSection.getGenericItemByCompany(page, pageSize, tableConfig.section.sectionId)
            .then(res => {
                if (res) {
                    setDataGrid(res.list);
                    setTableConfig({
                        ...tableConfig,
                        totalPages: res.totalPages,
                        activePage: page
                    });
                }
            });
    };

    const refreshDataGrid = () => getAll();

    const filterItems = (section) => {
        useGenericSection.getGenericItemByCompany(1, pageSize, section.genericSectionTypeId)
            .then(res => {
                if (res) {
                    setDataGrid(res.list);
                    setTableConfig({
                        activePage: 1,
                        totalPages: res.totalPages,
                        section: {
                            sectionId: section.genericSectionTypeId,
                            sectionName: section.sectionName
                        }
                    });
                }
            });
    }

    const handleMenuClick = (e) => console.log('');

    const items = props.sections.map((section, index) => {
        return {
            label: <Button onClick={() => {
                props.handleGenericItems(section.genericSectionTypeId, section.sectionName);
                filterItems(section)
            }}>
                {section.sectionName}
            </Button>,
            key: index+index,
        }
    });

    const menuProps = {
        items,
        onClick: handleMenuClick,
    };

    const columns = [
        {
            title: 'Sección',
            dataIndex: 'section',
            key: 'section',
        },
        {
            title: 'Título del evento',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Subtítulo del evento',
            dataIndex: 'subtitle',
            key: 'subtitle',
        },
        {
            title: 'Fecha de Creación',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Acciones',
            dataIndex: 'options',
            key: 'options',
        },
    ];

    const data = dataGrid.map((item, i) => {
        return {
            key: i+'-'+i,
            section: item.genericSectionName,
            title: item.title,
            subtitle: item.subtitle,
            date: dayjs(item.createDate).format('DD/MMM/YYYY'),
            options: <Space>
                {item.active ?
                    <>
                        <Button
                            type="primary"
                            htmlType={'button'}
                            className="edit"
                            onClick={() =>  props.handleRowSelect(item)}
                        >
                            <FaEdit />
                        </Button>
                        <Button
                            type="primary"
                            htmlType={'button'}
                            className="delete"
                            onClick={(e) => handleDelete(e, item)}
                        >
                            <FaTrash />
                        </Button>
                    </>
                 : null }
            </Space>
        };
    })

    useEffect(() => {
        getAll();
    }, []);

    return (
        <>
            <HeadingAdmin
                btnAdd={false}
                isDropdown={true}
                menuDropdown={menuProps}
                btnDropdownName={`${tableConfig.section.sectionName.length > 0 ? tableConfig.section.sectionName : 'Seleccionar sección'}`}
            />
            <Table
                className={'table-admin'}
                columns={columns}
                dataSource={data}
                pagination={false}
                locale={{
                    triggerDesc: 'Ordenamiento descendente',
                    triggerAsc: 'Ordenamiento ascedente',
                    cancelSort: 'Cancelar ordenamiento',
                    emptyText: 'Sin registros',
                }}
                scroll={{ x: '992px', y: 800 }}
            />
            <div className="table-admin-footer">
                <Pagination
                    current={tableConfig.activePage}
                    pageSize={1}
                    total={tableConfig.totalPages}
                    onChange={handleSelectPage}
                    simple={true}
                />
            </div>
        </>
    );

});

export default SearchAdminGenericSection;
