import React from 'react';
import { EditProductFilter } from './editProductFilter'
import {TitleAdmin} from "../components/titleAdmin/titleAdmin";
import {HeadingAdmin} from "../components/headingAdmin/headingAdmin";
import {useHistory, useParams} from "react-router-dom";

export function DetailsProductFilter(props) {
    const history = useHistory()
    let { id } = useParams();
    return (
        <div className="dashboard-admin-container">
            <TitleAdmin
                title={'Administración de Cupones'}
                subtitle={parseInt(id) !== 0 ? "Edición de Cupón" : "Nuevo Cupón"}
                event={() => history.push(parseInt(id) !== 0 || parseInt(id) === 0 ? "/admin/product-filter" : "/admin")}
            />
            {
                (parseInt(id) !== 0) ?
                    <HeadingAdmin
                            text={"Nuevo filtro"}
                            event={() => history.push("/admin/detail-product-filter/0")}
                    /> : null
            }

            <EditProductFilter rewardProductFilterId={parseInt(id)} />
        </div>
    );
}
