import React, { useEffect, useState } from "react";
import "./registerServices.scss";
import {FaChevronRight, FaExclamation} from "react-icons/fa";
import { Col, Form, Input, Row, Select, Button } from "antd";

export function FormAdditional(props) {

  const activeTab3 = props.activeTab3;
  const [currentSocialMedia, setCurrentSocialMedia] = useState(0)
  const [datos, setDatos] = useState({
    website: null,
    websiteId: null,
    facebook: null,
    facebookId: null,
    instagram: null,
    instagramId: null,
    linkedin: null,
    linkedinId: null,
    twitter: null,
    twitterId: null,
    tiktok: null,
    tiktokId: null
  })

  useEffect(() => {
    let Data = {};
    if (props.register.additional.hasOwnProperty("facebook")) {
      let reg = props.register.additional;
      Data = {
        website: reg.website,
        facebook: reg.facebook,
        instagram: reg.instagram,
        linkedin: reg.linkedin,
        twitter: reg.twitter,
        tiktok: reg.tiktok,
      };
      setDatos(Data);
    }

    if (props.data?.directoryContacts) {
      let directoryContacts = props.data.directoryContacts;
      directoryContacts.forEach(element => {
        switch (element.contactTypeId) {
          case 3: Data.website = element.contactValue;
            Data.websiteId = element.directoryContactId;
            break;
          case 4: Data.linkedin = element.contactValue;
            Data.linkedinId = element.directoryContactId;
            break;
          case 5: Data.facebook = element.contactValue;
            Data.facebookId = element.directoryContactId;
            break;
          case 6: Data.twitter = element.contactValue;
            Data.twitterId = element.directoryContactId;
            break;
          case 7: Data.instagram = element.contactValue;
            Data.instagramId = element.directoryContactId;
            break;
          case 8: Data.tiktok = element.contactValue;
            Data.tiktokId = element.directoryContactId;
            break;
          default: break;
        }
      });
      setDatos(Data);
    }
  }, [props.data]);

  const onFinish = () => {
    props.Datepersonal(datos, "additional");
    props.toggleView();
  }

  const onFinishFailed = (errorInfo) => false;

  const { Option } = Select;

  const handleInputChange = (e) => setDatos({ ...datos, [e.target.name]: e.target.value });

  return (
    <Form
      className="form-pride"
      style={{padding: '.5rem !important'}}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      layout="vertical"
      autoComplete="off"
      fields={[
        {
          "name": ["website"],
          "value": datos.website
        },
        {
          "name": ["facebook"],
          "value": datos.facebook
        },
        {
          "name": ["linkedin"],
          "value": datos.linkedin
        },
        {
          "name": ["twitter"],
          "value": datos.twitter
        },
        {
          "name": ["tiktok"],
          "value": datos.tiktok
        },
        {
          "name": ["instagram"],
          "value": datos.instagram
        }
      ]}
    >
      <Row gutter={16}>
        <Col xs={24}>
          <div className="d-flex-between">
            <h3>Información adicional</h3>
            <Button className="btn_to_back" onClick={props.toBack}>
              <i className="icon-chevron-left"></i>{" "}Regresar
            </Button>
          </div>
        </Col>
        <Col xs={24}>
          <div className="badge-pride blue">
            <span><FaExclamation /></span>
            <p>En redes solo agrega tu nombre de usuario</p>
          </div>
        </Col>
        <Col md={12} sm={24} xs={24}>
          <Form.Item name="website" initialValue={datos.website} label="Sitio web" style={{marginBottom: '0'}}>
            <Input
              onFocus={() => setCurrentSocialMedia(1)}
              onBlur={() => setCurrentSocialMedia(0)}
              type="text"
              name="website"
              size="large"
              onChange={handleInputChange}
            />
          </Form.Item>
          <p className={`p-smedia ${(currentSocialMedia === 1) ? "show" : ''}`}>https://{datos.website}</p>
        </Col>
        <Col md={12} sm={24} xs={24}>
          <Form.Item name="facebook" initialValue={datos.facebook} label="Facebook" style={{marginBottom: '0'}}>
            <Input
              onFocus={() => setCurrentSocialMedia(2)}
              onBlur={() => setCurrentSocialMedia(0)}
              type="text"
              name="facebook"
              size="large"
              onChange={handleInputChange}
            />
          </Form.Item>
          <p className={`p-smedia ${(currentSocialMedia === 2) ? "show" : ''}`}>https://www.facebook.com/{datos.facebook}</p>
        </Col>
        <Col md={12} sm={24} xs={24}>
          <Form.Item name="instagram" initialValue={datos.instagram} label="Instagram" style={{marginBottom: '0'}}>
            <Input
              onFocus={() => setCurrentSocialMedia(3)}
              onBlur={() => setCurrentSocialMedia(0)}
              type="text"
              name="instagram"
              size="large"
              onChange={handleInputChange}
            />
          </Form.Item>
          <p className={`p-smedia ${(currentSocialMedia === 3) ? "show" : ''}`}>https://www.instagram.com/{datos.instagram}</p>
        </Col>
        <Col md={12} sm={24} xs={24}>
          <Form.Item name="linkedin" initialValue={datos.linkedin} label="Linkedin" style={{marginBottom: '0'}}>
            <Input
              onFocus={() => setCurrentSocialMedia(4)}
              onBlur={() => setCurrentSocialMedia(0)}
              type="text"
              name="linkedin"
              size="large"
              onChange={handleInputChange}
            />
          </Form.Item>
          <p className={`p-smedia ${(currentSocialMedia === 4) ? "show" : ''}`}>https://www.linkedin.com/in/{datos.tiktok}</p>
        </Col>
        <Col md={12} sm={24} xs={24}>
          <Form.Item name="twitter" initialValue={datos.twitter} label="Twitter" style={{marginBottom: '0'}}>
            <Input
              onFocus={() => setCurrentSocialMedia(5)}
              onBlur={() => setCurrentSocialMedia(0)}
              type="text"
              name="twitter"
              size="large"
              onChange={handleInputChange}
            />
          </Form.Item>
          <p className={`p-smedia ${(currentSocialMedia === 5) ? "show" : ''}`}>https://www.twitter.com/{datos.twitter}</p>
        </Col>
        <Col md={12} sm={24} xs={24}>
          <Form.Item name="tiktok" initialValue={datos.tiktok} label="TikTok" style={{marginBottom: '0'}}>
            <Input
              onFocus={() => setCurrentSocialMedia(6)}
              onBlur={() => setCurrentSocialMedia(0)}
              type="text"
              name="tiktok"
              size="large"
              onChange={handleInputChange}
            />
          </Form.Item>
          <p className={`p-smedia ${(currentSocialMedia === 6) ? "show" : ''}`}>https://www.tiktok.com/@{datos.tiktok}</p>
        </Col>
        <Col xs={24}>
          <div className="form-pride-footer">
            <Button htmlType="submit" className="green" type={'primary'}>
              <p>{activeTab3 === false ? "Guardar" : "Siguiente"}</p>
              <FaChevronRight />
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
}
