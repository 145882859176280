import { callApi } from './service'
import swal from "sweetalert"

export const contactUsService = {
    sendContactMessage
};  

async function sendContactMessage(params) {
    return callApi('/Site/PrideMxContactUs', 'POST', params, {"SkipValidationToken" : true}).then((result) => {
        if (result.responseCode == '00') {
            swal({ text: result.responseMessage, icon: "success" })
            return result.responseMessage;
        } else {
            swal({ text: result.responseMessage, icon: "error" })
            return null;
        }
    });
}




