import React, { useState, useEffect } from "react";
import { userProfileService } from "../../services/backoffice/userProfile.service";
import "./users.scss";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { FaChevronRight, FaSave } from "react-icons/fa";
import { TitleAdmin } from "../components/titleAdmin/titleAdmin";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Tag,
} from "antd";
import { Checkbox } from "../components/checkbox/checkbox";
import dayjs from "dayjs";
import moment from "moment";

export const UserDetails = (props) => {
  const companyId = useSelector((state) => state.userSettings.companyId);
  const [catalogs, setCatalogs] = useState({
    aspNetRolesCatalog: [],
    branchOfficeCatalog: [],
    departmentCatalog: [],
    jobRoleCatalog: [],
  });
  const [IsConfirmed, setIsConfirmed] = useState(false);
  const history = useHistory();
  const [user, setUser] = useState({
    roleId: "",
    roleName: "",
    email: "",
    departmentId: 0,
    branchOfficeId: 0,
    employeeNo: "",
    jobRoleId: 0,
    firstName: "",
    middleName: "",
    lastName: "",
    birthday: "",
    hiringDate: "",
    gender: "",
    mobile: "",
    phoneNumber: "",
    password: "",
    extension: "",
    active: false,
    nickname: "",
    additionalEmail: "",
    emailConfirmed: false,
    userId: "",
    urlProfileImage: "",
    preferences: "",
    notification: false,
    companyId: companyId,
    IsCorporateUser: false,
  });
  const { id } = useParams();

  const getById = (id) => {
    userProfileService.getById(id).then((result) => {
      setUser({
        roleId: result.roleId,
        roleName: result.roleName,
        userName: result.userName,
        email: result.email,
        departmentId: result.departmentId,
        branchOfficeId: result.branchOfficeId,
        employeeNo: result.employeeNo,
        jobRoleId: result.jobRoleId,
        firstName: result.firstName,
        middleName: result.middleName,
        lastName: result.lastName,
        birthday: result.birthday,
        hiringDate: result.hiringDate,
        gender: result.gender,
        mobile: result.mobile,
        phoneNumber: result.phoneNumber,
        extension: result.extension,
        active: result.active,
        nickname: result.nickname,
        additionalEmail: result.additionalEmail,
        emailConfirmed: result.emailConfirmed,
        userId: result.aspNetUserId,
        urlProfileImage: result.urlProfileImage,
        preferences: result.preferences,
        notification: result.notification,
        companyId: result.companyId,
        IsCorporateUser: result.IsCorporateUser,
      });
      setIsConfirmed(result.emailConfirmed);
      //reset(result);
    });
  };

  const fnEmailConfirm = () => {
    userProfileService.VerifyAccount(props.match.params.id).then((result) => {
      if (result) setIsConfirmed(result.succeded);
    });
  };

  const onSubmit = () => {
    user.birthday = moment(user.birthday).format("YYYY-MM-DD");
    user.hiringDate = moment(user.hiringDate).format("YYYY-MM-DD");

    if (id === "new") userProfileService.create(user);
    else {
      if (user.additionalEmail) user.isCorporateUser = true;
      userProfileService.edit(user);
    }
  };

  const handleCheck = (value) => setUser({ ...user, active: value });

  const handleSelectRoleId = (value) => setUser({ ...user, roleId: value });

  const handleInputValue = (e) =>
    setUser({ ...user, [e.target.name]: e.target.value });

  const handleSelectGender = (value) => setUser({ ...user, gender: value });

  const handleSelectJobRole = (value) => setUser({ ...user, jobRoleId: value });

  const handleDateHiring = (date, dateStr) =>
    setUser({ ...user, hiringDate: dateStr });

  const handleSelectBranchOffice = (value) =>
    setUser({ ...user, branchOfficeId: value });

  const handleSelectDepartment = (value) =>
    setUser({ ...user, departmentId: value });

  const handleBirthday = (date, dateStr) =>
    setUser({ ...user, birthday: dateStr });

  useEffect(() => {
    if (id !== "new") {
      getById(id);
      setUser({ ...user, active: true });
    }
  }, [id]);

  useEffect(() => {
    if (id === "new") setUser({ ...user, active: true });
    userProfileService.getCatalogs().then((result) => setCatalogs(result));
  }, []);

  return (
    <section className="dashboard-admin-container">
      <TitleAdmin
        title={"Usuarios"}
        subtitle={id === "new" ? "Usuario nuevo" : "Detalle del usuario"}
        event={() => history.push("/admin/users")}
      />
      <Form
        onFinish={onSubmit}
        className="form-backoffice"
        layout={"vertical"}
        fields={[
          {
            name: ["roleId"],
            value: user.roleId,
          },
          {
            name: ["email"],
            value: user.email,
          },
          {
            name: ["employeeNo"],
            value: user.employeeNo,
          },
          {
            name: ["firstName"],
            value: user.firstName,
          },
          {
            name: ["middleName"],
            value: user.middleName,
          },
          {
            name: ["lastName"],
            value: user.lastName,
          },
          {
            name: ["gender"],
            value: user.gender,
          },
          {
            name: ["birthday"],
            value: user.birthday ? dayjs(user.birthday) : "",
          },
          {
            name: ["mobile"],
            value: user.mobile,
          },
          {
            name: ["phoneNumber"],
            value: user.phoneNumber,
          },
          {
            name: ["extension"],
            value: user.extension,
          },
          {
            name: ["hiringDate"],
            value: user.hiringDate ? dayjs(user.hiringDate) : "",
          },
          {
            name: ["additionalEmail"],
            value: user.additionalEmail,
          },
          {
            name: ["branchOfficeId"],
            value: user.branchOfficeId,
          },
          {
            name: ["departmentId"],
            value: user.departmentId,
          },
          {
            name: ["jobRoleId"],
            value: user.jobRoleId,
          },
        ]}
      >
        <Row gutter={16}>
          <Col xs={24}>
            {IsConfirmed ? (
              <Divider dashed={true}>
                <Tag color={"success"}>Cuenta confirmada</Tag>
              </Divider>
            ) : null}
          </Col>
          <Col xs={24}>
            <h3>Datos de usuario</h3>
          </Col>
          <Col lg={8} md={12} sm={24} xs={24}>
            <Form.Item label={"Activo"}>
              <Checkbox
                disabled={id === "new"}
                text={"Activo"}
                label={"active"}
                name={user.active}
                handleValueCheck={handleCheck}
              />
            </Form.Item>
          </Col>
          <Col lg={8} md={12} sm={24} xs={24}>
            <Form.Item
              label={"Perfil"}
              htmlFor={"roleId"}
              name={"roleId"}
              rules={[
                {
                  required: true,
                  message: "*Campo Requerido",
                },
              ]}
            >
              <Select onChange={handleSelectRoleId} name={"roleId"}>
                {catalogs.aspNetRolesCatalog.map((item) => (
                  <Select.Option key={item.value} value={item.value}>
                    {" "}
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={8} md={12} sm={24} xs={24}>
            <Form.Item
              label={"Correo electrónico"}
              htmlFor={"email"}
              name={"email"}
              rules={[
                {
                  type: "email",
                  message: "*Formato incorrecto",
                },
                {
                  required: true,
                  message: "*Campo Requerido",
                },
              ]}
            >
              <Input name={"email"} type="email" onChange={handleInputValue} />
            </Form.Item>
          </Col>
          {id === "new" ? (
            <Col lg={8} md={12} sm={24} xs={24}>
              <Form.Item
                label={"Password"}
                htmlFor={"password"}
                name={"password"}
                rules={[
                  {
                    required: true,
                    message: "*Campo Requerido",
                  },
                ]}
              >
                <Input.Password
                  name={"password"}
                  type="password"
                  onChange={handleInputValue}
                />
              </Form.Item>
            </Col>
          ) : null}
          <Col lg={8} md={12} sm={24} xs={24}>
            <Form.Item
              label={"No. Usuario"}
              htmlFor={"employeeNo"}
              name={"employeeNo"}
              rules={[
                {
                  required: true,
                  message: "*Campo Requerido",
                },
                {
                  pattern: new RegExp(/^\S/),
                  message: "No se aceptan espacios en blanco al inicio",
                },
              ]}
            >
              <Input
                name={"employeeNo"}
                type="text"
                onChange={handleInputValue}
              />
            </Form.Item>
          </Col>
          <Col lg={8} md={12} sm={24} xs={24}>
            <Form.Item
              label={"Nombre(s)"}
              name={"firstName"}
              htmlFor={"firstName"}
              rules={[
                {
                  required: true,
                  message: "*Campo Requerido",
                },
                {
                  pattern: new RegExp(/^\S/),
                  message: "No se aceptan espacios en blanco al inicio",
                },
              ]}
            >
              <Input
                type="text"
                name={"firstName"}
                onChange={handleInputValue}
              />
            </Form.Item>
          </Col>
          <Col lg={8} md={12} sm={24} xs={24}>
            <Form.Item
              label={"Apellido paterno"}
              htmlFor={"middleName"}
              name={"middleName"}
              rules={[
                {
                  required: true,
                  message: "*Campo Requerido",
                },
                {
                  pattern: new RegExp(/^\S/),
                  message: "No se aceptan espacios en blanco al inicio",
                },
              ]}
            >
              <Input
                type="text"
                name={"middleName"}
                onChange={handleInputValue}
              />
            </Form.Item>
          </Col>
          <Col lg={8} md={12} sm={24} xs={24}>
            <Form.Item
              label={"Apellido materno"}
              htmlFor={"lastName"}
              name={"lastName"}
              rules={[
                {
                  required: true,
                  message: "*Campo Requerido",
                },
                {
                  pattern: new RegExp(/^\S/),
                  message: "No se aceptan espacios en blanco al inicio",
                },
              ]}
            >
              <Input
                type="text"
                name={"lastName"}
                onChange={handleInputValue}
              />
            </Form.Item>
          </Col>
          <Col lg={8} md={12} sm={24} xs={24}>
            <Form.Item
              label={"Género"}
              htmlFor={"gender"}
              name={"gender"}
              rules={[
                {
                  required: true,
                  message: "*Campo Requerido",
                },
              ]}
            >
              <Select name={"gender"} onChange={handleSelectGender}>
                <Select.Option value="M">Masculino</Select.Option>
                <Select.Option value="F">Femenino</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col lg={8} md={12} sm={24} xs={24}>
            <Form.Item
              label={"Fecha de nacimiento"}
              htmlFor={"birthday"}
              name={"birthday"}
              rules={[
                {
                  required: true,
                  message: "*Campo Requerido",
                },
              ]}
            >
              <DatePicker
                format={"DD/MMM/YYYY"}
                type="date"
                placeholder={"Seleccionar fecha"}
                name={"birthday"}
                showToday={false}
                onChange={handleBirthday}
              />
            </Form.Item>
          </Col>
          <Col lg={8} md={12} sm={24} xs={24}>
            <Form.Item
              label={"Tel. Personal"}
              htmlFor={"mobile"}
              name={"mobile"}
              rules={[
                {
                  required: true,
                  message: "*Campo Requerido",
                },
                {
                  pattern: new RegExp(/^\S/),
                  message: "No se aceptan espacios en blanco al inicio",
                },
              ]}
            >
              <Input type="text" name={"mobile"} onChange={handleInputValue} />
            </Form.Item>
          </Col>
          <Col lg={8} md={12} sm={24} xs={24}>
            <Form.Item
              label={"Tel. Oficina"}
              name={"phoneNumber"}
              htmlFor={"phoneNumber"}
            >
              <Input
                type="text"
                name={"phoneNumber"}
                onChange={handleInputValue}
              />
            </Form.Item>
          </Col>
          <Col lg={8} md={12} sm={24} xs={24}>
            <Form.Item label={"Ext."} name={"extension"}>
              <Input
                type="text"
                name={"extension"}
                onChange={handleInputValue}
              />
            </Form.Item>
          </Col>
          <Col lg={8} md={12} sm={24} xs={24}>
            <Form.Item
              label={"Fecha de ingreso"}
              htmlFor={"hiringDate"}
              name={"hiringDate"}
              rules={[
                {
                  required: true,
                  message: "*Campo Requerido",
                },
              ]}
            >
              <DatePicker
                format={"DD/MMM/YYYY"}
                type="date"
                placeholder={"Seleccionar fecha"}
                name={"hiringDate"}
                showToday={false}
                onChange={handleDateHiring}
              />
            </Form.Item>
          </Col>
          <Col lg={8} md={12} sm={24} xs={24}>
            <Form.Item
              label={"Correo adicional"}
              htmlFor={"additionalEmail"}
              name={"additionalEmail"}
            >
              <Input
                type="text"
                name={"additionalEmail"}
                onChange={handleInputValue}
              />
            </Form.Item>
          </Col>
          <Col lg={8} md={12} sm={24} xs={24}>
            <Form.Item
              label={"Sucursal"}
              htmlFor={"branchOfficeId"}
              name={"branchOfficeId"}
              rules={[
                {
                  required: true,
                  message: "*Campo Requerido",
                },
              ]}
            >
              <Select
                name={"branchOfficeId"}
                onChange={handleSelectBranchOffice}
              >
                {catalogs.branchOfficeCatalog.map((item) => (
                  <Select.Option key={item.value} value={parseInt(item.value)}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={8} md={12} sm={24} xs={24}>
            <Form.Item
              label={"Grupo"}
              htmlFor={"departmentId"}
              name={"departmentId"}
              rules={[
                {
                  required: true,
                  message: "*Campo Requerido",
                },
              ]}
            >
              <Select name={"departmentId"} onChange={handleSelectDepartment}>
                {catalogs.departmentCatalog.map((item) => (
                  <Select.Option key={item.value} value={parseInt(item.value)}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={8} md={12} sm={24} xs={24}>
            <Form.Item
              label={"Categoría"}
              htmlFor={"jobRoleId"}
              name={"jobRoleId"}
              rules={[
                {
                  required: true,
                  message: "*Campo Requerido",
                },
              ]}
            >
              <Select name={"jobRoleId"} onChange={handleSelectJobRole}>
                {catalogs.jobRoleCatalog.map((item) => (
                  <Select.Option key={item.value} value={parseInt(item.value)}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24}>
            <div className="form-backoffice-footer">
              {!IsConfirmed ? (
                <Button
                  htmlType="button"
                  onClick={() => fnEmailConfirm()}
                  type={"primary"}
                >
                  <p>Confirmar Cuenta</p>
                  <FaChevronRight />
                </Button>
              ) : null}
              <Button type="primary" htmlType={"submit"}>
                <p>Guardar</p>
                <FaSave />
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </section>
  );
};
