import React from 'react';
import { EditSurvey } from './editSurvey'
import {useHistory, useParams} from "react-router-dom";
import {TitleAdmin} from "../components/titleAdmin/titleAdmin";

export const DetailsSurvey = () => {

    const history = useHistory();
    const {surveyId} = useParams();

    return (
        <div className="dashboard-admin-container">
            <TitleAdmin
                title={'Tu Opinión'}
                subtitle={surveyId !== "0" ? "Editar encuesta" : "Nueva encuesta"}
                event={() => history.push("/admin/AdminSurvey")}
            />
            <EditSurvey surveyId={surveyId} />
        </div>
    );
}
