import React, {useEffect, useRef, useState} from 'react';
import { useCompanyHoliday } from '../../../services/companyHoliday.service';
import {FaEdit, FaTrash} from "react-icons/fa";
import {Button, Input, Space, Table, Image} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import moment from "moment";
import MySwal from "sweetalert2";

export const ListNotice = (props) => {

    const [CompanyHolidaysData, setCompanyHolidaysData] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const handleDelete = (data) => {
        MySwal.fire({
            title: '¿Estás seguro?',
            text: "No podrás revertir esta acción",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Eliminar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if(result.isConfirmed)
                useCompanyHoliday.deleteCompanyHoliday(data).then((result) => getCompanyHolidays());
        })
    };

    useEffect(() => {
        getCompanyHolidays();
    }, [props.refresh]);

    async function getCompanyHolidays() {
        useCompanyHoliday.getCompanyHolidays().then((result) => {
            if (result) {
                setCompanyHolidaysData(result);
            }
        });
    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{padding: 8,}} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Buscar`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{marginBottom: 8, display: 'block',}}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{width: 90,}}
                    >
                        Buscar
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{width: 90,}}
                    >
                        Limpiar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{color: filtered ? '#1677ff' : undefined,}}/>
        ),
        onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) setTimeout(() => searchInput.current?.select(), 100);
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{backgroundColor: '#ffc069', padding: 0,}}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (text),
    });

    const columnsNotice = [
        {
            title: 'Imagen',
            dataIndex: 'image',
            key: 'image',
        },
        {
            title: 'Aviso',
            dataIndex: 'notice',
            key: 'notice',
            ...getColumnSearchProps('notice'),
        },
        {
            title: 'Fecha de evento',
            dataIndex: 'date',
            key: 'date',
            defaultSortOrder: 'descend',
            sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
        },
        {
            title: 'Desde',
            dataIndex: 'startDate',
            key: 'startDate',
            sorter: (a, b) => moment(a.startDate).unix() - moment(b.startDate).unix()
        },
        {
            title: 'Hasta',
            dataIndex: 'endDate',
            key: 'endDate',
            sorter: (a, b) => moment(a.endDate).unix() - moment(b.endDate).unix()
        },
        {
            title: 'Acciones',
            dataIndex: 'options',
            key: 'options',
        },
    ];

    const dataNotice = CompanyHolidaysData.map(item => {
        return {
            key: item.companyHolidayId,
            image: <Image src={item.imagePath} width={65} />,
            notice: item.description,
            date: moment(item.date).format('DD/MMM/YYYY'),
            startDate: moment(item.startDate).format('DD/MMM/YYYY'),
            endDate: moment(item.endDate).format('DD/MMM/YYYY'),
            options: <Space>
                <Button
                    type="primary"
                    htmlType={'button'}
                    className="edit"
                    onClick={() => props.onSelectCompanyHoliday(item)}
                >
                    <FaEdit />
                </Button>
                <Button
                    type="primary"
                    htmlType={'button'}
                    className="delete"
                    onClick={() => handleDelete(item)}
                >
                    <FaTrash />
                </Button>
            </Space>
        };
    })

    return (
        <>
            <Table
                className="table-admin"
                columns={columnsNotice}
                dataSource={dataNotice}
                scroll={{ x: '992px', y: 800 }}
                locale={{
                    triggerDesc: 'Ordenamiento descendente',
                    triggerAsc: 'Ordenamiento ascedente',
                    cancelSort: 'Cancelar ordenamiento',
                    emptyText: 'Sin registros',
                }}
            />
        </>

    )
}
