import React, {useEffect, useState} from 'react';

import './checkbox.scss'

export function Checkbox({text, label, name, disabled = false, handleValueCheck}) {

    const [checkStatus, setCheckStatus] = useState(name);

    const handleCheck = () => {
        if(checkStatus) setCheckStatus(false)
        else setCheckStatus(true)
    };

    useEffect(() => {
        handleValueCheck(checkStatus);
    }, [checkStatus]);

    useEffect(() => {
        setCheckStatus(name)
    }, [name]);

    return (
        <label className={`toggler-wrapper dark ${disabled ? 'disabled' : ''}`} htmlFor={label}>
            <input
                type="checkbox"
                id={label}
                checked={checkStatus}
                disabled={disabled}
                onChange={handleCheck}
            />
            <div className="toggler-slider">
                <div className="toggler-knob"></div>
            </div>
            <p>{text}</p>
        </label>
    );
}
