import React, { useEffect, useState } from "react";
import { useCompanyHoliday } from "../../../services/companyHoliday.service";
import ReactPlayer from "react-player";
import { FaChevronRight, FaInfoCircle, FaSave } from "react-icons/fa";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Tabs,
  Select,
  Tag,
  Modal,
} from "antd";
import dayjs from "dayjs";
import { InputFile } from "../../components/inputFile/inputFile";
import { Checkbox } from "../../components/checkbox/checkbox";
import MySwal from "sweetalert2";
import videoYT from "../../../assets/imgs/video-yt.jpg";
import ImgInfog from "../../../assets/imgs/imgInfografia.jpg";

export const EditNotice = (props) => {
  const [files, setFiles] = useState([]);
  const [urlVideo, setUrlVideo] = useState("");
  const [filesVideo, setFilesVideo] = useState([]);
  const [holidayType, setHolidayType] = useState([]);
  const [selectedTypeId, setSelectedTypeId] = useState(1);
  const [activeTab, setActiveTab] = useState("1");
  const [CompanyHoliday, setCompanyHoliday] = useState({
    CompanyHolidayId: 0,
    Description: "",
    Date: "",
    WorkingDay: false,
    StartDate: "",
    EndDate: "",
    TypeId: 0,
    IsVideo: false,
    Iframe: "",
    videoPath: [],
  });
  const [dimensionsImage, setDimensionsImage] = useState({
    width: 0,
    height: 0,
  });
  const typesNotice = [
    {
      label: "Agenda",
      value: 1,
    },
    {
      label: "Aviso",
      value: 2,
    },
    {
      label: "Publicidad",
      value: 3,
    },
    {
      label: "PrideTalks",
      value: 4,
    },
    {
      label: "Tienda en línea",
      value: 5,
    },
    /*{
          label: 'Comunidad Solidaria',
          value: 6
        },*/
    {
      label: "Bolsa de trabajo",
      value: 7,
    },
    {
      label: "Cartelera Pride",
      value: 8,
    },
    {
      label: "Tu Opinión",
      value: 9,
    },
  ];
  const [fileUpdated, setFileUpdated] = useState(false);
  const [videoUpdated, setVideoUpdated] = useState(false);
  const [form] = Form.useForm();
  const { Option } = Select;
  const [selectBeforeOption, setSelectBeforeOption] = useState("https://");
  const [myModal, setMyModal] = useState(false);
  const toggleMyModal = () => setMyModal(myModal ? false : true);
  const [showVideo, setShowVideo] = useState(false);

  const onSave = () => {
    let localfiles = [];

    if (files.length > 0) {
      if (!fileUpdated) localfiles.push(files[0]);
    }

    if (filesVideo.length > 0) {
      if (!videoUpdated) localfiles.push(filesVideo[0]);
    }

    if (
      (files.length === 0 && CompanyHoliday.IsVideo) ||
      (files.length === 0 && !CompanyHoliday.IsVideo)
    )
      return MySwal.fire({
        title: "¡Falta una imagen para tu publicación!",
        icon: "error",
        confirmButtonText: "Confirmar",
      });

    if (
      (filesVideo.length === 0 &&
        CompanyHoliday.IsVideo &&
        activeTab === "1") ||
      (CompanyHoliday.Iframe === "" &&
        CompanyHoliday.IsVideo &&
        activeTab === "2")
    )
      return MySwal.fire({
        title: "¡Falta un video para tu publicación!",
        icon: "error",
        confirmButtonText: "Confirmar",
      });
    const model = {
      CompanyHolidayId: parseInt(CompanyHoliday.CompanyHolidayId),
      Description: CompanyHoliday.Description,
      Date: dayjs(CompanyHoliday.Date).format("YYYY-MM-DD"),
      WorkingDay: CompanyHoliday.WorkingDay,
      EndDate: dayjs(CompanyHoliday.EndDate).format("YYYY-MM-DD"),
      StartDate: dayjs(CompanyHoliday.StartDate).format("YYYY-MM-DD"),
      TypeId: selectedTypeId,
      IsVideo: CompanyHoliday.IsVideo,
      Iframe:
        CompanyHoliday.IsVideo &&
        selectedTypeId === 2 &&
        filesVideo.length === 0
          ? CompanyHoliday.Iframe
          : CompanyHoliday.IsVideo &&
            selectedTypeId === 2 &&
            filesVideo.length > 0
          ? ""
          : selectBeforeOption + CompanyHoliday.Iframe,
    };
    if (CompanyHoliday.CompanyHolidayId > 0) {
      useCompanyHoliday
        .updateCompanyHoliday(model, localfiles)
        .then((result) => {
          if (props.onSave) {
            props.onSave(result);
            MySwal.fire({
              title: "¡Aviso actualizado exitosamente!",
              icon: "success",
              confirmButtonText: "OK",
            });
          }
        });
    } else {
      useCompanyHoliday
        .createCompanyHoliday(model, localfiles)
        .then((result) => {
          if (props.onSave) {
            props.onSave(result);
            MySwal.fire({
              title: "¡Se creó correctamente!",
              icon: "success",
              confirmButtonText: "Confirmar",
            });
          }
        });
    }
  };

  const handleOptionChange = (value) => {
    handleSelectTypeId(value);
    setSelectedTypeId(value);
    setCompanyHoliday({ ...CompanyHoliday, TypeId: value });
    if (value !== 2) {
      setFiles([]);
      setUrlVideo("");
      setFilesVideo([]);
      setActiveTab("1");
      setCompanyHoliday({ ...CompanyHoliday, IsVideo: false });
    }
  };

  const handleValueIframe = (e) => {
    setCompanyHoliday({ ...CompanyHoliday, Iframe: e.target.value });
    setFilesVideo([]);
  };

  const handleSelectTypeId = (value) => {
    if (value === 1 || value === 9)
      setDimensionsImage({ width: 850, height: 350 });
    if (value === 2) setDimensionsImage({ width: 2200, height: 950 });
    if (value === 3) setDimensionsImage({ width: 800, height: 1800 });
    if (value === 4 || value === 5 || value === 6 || value === 7 || value === 8)
      setDimensionsImage({ width: 1980, height: 850 });
  };

  async function getCompanyHolidaysType() {
    useCompanyHoliday.getCompanyHolidayFront(0).then((result) => {
      if (result) {
        console.log(result, "result");
        const temp = [];
        result.map((ch) => {
          temp.push({
            key: ch.companyHolidayId.toString(),
            file: ch.imagePath,
            description: ch.description,
            isVideo: ch.isVideo,
            link: ch.iframe,
          });
        });
        setHolidayType(temp);
      }
    });
  }

  const handleInputDate = (date, strDate, name) =>
    setCompanyHoliday({ ...CompanyHoliday, [name]: strDate });

  const onChangeIsVideo = (value) =>
    setCompanyHoliday({ ...CompanyHoliday, IsVideo: value });

  const handleInputChange = (e) =>
    setCompanyHoliday({ ...CompanyHoliday, [e.target.name]: e.target.value });

  const onChangeTabs = (key) => setActiveTab(key);

  const handleVideoUpload = (files) => {
    if (CompanyHoliday.CompanyHolidayId !== 0) {
      setVideoUpdated(false);
      setUrlVideo("");
    }
    setFilesVideo(files);
    setCompanyHoliday({ ...CompanyHoliday, Iframe: "" });
  };

  const handleVideoRemove = (files) => setFilesVideo(files);

  const deleteImage = (files) => setFiles(files);

  const handleFiles = (value) => {
    setFiles(value);
    if (CompanyHoliday.CompanyHolidayId !== 0) setFileUpdated(false);
  };

  const disabledDate = (current) => {
    return current && current < dayjs().startOf("day");
  };

  const handleGridItem = () => {
    setCompanyHoliday({
      ...CompanyHoliday,
      CompanyHolidayId: parseInt(props.currentCompanyHoliday.companyHolidayId),
      Description: props.currentCompanyHoliday.description,
      Date: props.currentCompanyHoliday.date,
      WorkingDay: props.currentCompanyHoliday.workingDay,
      EndDate: props.currentCompanyHoliday.endDate,
      StartDate: props.currentCompanyHoliday.startDate,
      TypeId: parseInt(props.currentCompanyHoliday.typeId),
      IsVideo: Boolean(props.currentCompanyHoliday.isVideo),
      Iframe:
        props.currentCompanyHoliday.isVideo &&
        parseInt(props.currentCompanyHoliday.typeId) === 2
          ? props.currentCompanyHoliday.iframe
          : props.currentCompanyHoliday.iframe.split("//")[1],
      imagePath: props.currentCompanyHoliday.imagePath,
      videoPath: props.currentCompanyHoliday.videoPath,
    });
    setSelectedTypeId(parseInt(props.currentCompanyHoliday.typeId));
    handleSelectTypeId(parseInt(props.currentCompanyHoliday.typeId));

    if (
      !props.currentCompanyHoliday.isVideo &&
      parseInt(props.currentCompanyHoliday.typeId) !== 2
    ) {
      setSelectBeforeOption(
        props.currentCompanyHoliday.iframe.split("//")[0] + "//"
      );
      form.setFieldValue(
        "Iframe",
        props.currentCompanyHoliday.iframe.split("//")[1]
      );
    } else form.setFieldValue("Iframe", props.currentCompanyHoliday.iframe);

    if (props.currentCompanyHoliday.imagePath !== null) {
      setFileUpdated(true);
      const file = new File(
        [props.currentCompanyHoliday.imagePath],
        "img_edit_notice.png",
        {
          type: "image/png",
        }
      );
      setFiles([file]);
    }

    if (props.currentCompanyHoliday.videoPath !== null) {
      setActiveTab("1");
      setVideoUpdated(true);
      setUrlVideo(props.currentCompanyHoliday.videoPath);
      const fileVideo = new File(
        [props.currentCompanyHoliday.videoPath],
        "video_edit_notice.mp4",
        { type: "video/mp4" }
      );
      setTimeout(() => {
        setFilesVideo([fileVideo]);
      }, 500);
    } else {
      setActiveTab("2");
      setShowVideo(props.currentCompanyHoliday.typeId === 2);
    }
  };

  const handleSelectBefore = (value) => setSelectBeforeOption(value);

  useEffect(() => {
    if (props.currentCompanyHoliday) {
      handleGridItem();
    } else {
      setActiveTab("1");
      handleSelectTypeId(1);
    }
  }, [props.currentCompanyHoliday]);

  useEffect(() => {
    if (!props.currentCompanyHoliday) {
      if (
        dayjs(CompanyHoliday.Date) < dayjs(CompanyHoliday.StartDate) ||
        dayjs(CompanyHoliday.Date) <= dayjs(CompanyHoliday.EndDate)
      ) {
        setCompanyHoliday({ ...CompanyHoliday, StartDate: "", EndDate: "" });
        form.setFields([
          {
            name: "StartDate",
            value: "",
          },
          {
            name: "EndDate",
            value: "",
          },
        ]);
      }
    }
  }, [CompanyHoliday.Date]);

  return (
    <>
      <Form
        onFinish={onSave}
        className="form-backoffice"
        layout={"vertical"}
        fields={[
          {
            name: ["Description"],
            value: CompanyHoliday.Description,
          },
          {
            name: ["Date"],
            value: CompanyHoliday.Date ? dayjs(CompanyHoliday.Date) : "",
          },
          {
            name: ["StartDate"],
            value:
              CompanyHoliday.StartDate !== ""
                ? dayjs(CompanyHoliday.StartDate)
                : "",
          },
          {
            name: ["EndDate"],
            value:
              CompanyHoliday.EndDate !== ""
                ? dayjs(CompanyHoliday.EndDate)
                : "",
          },
          {
            name: ["TypeId"],
            value: selectedTypeId,
          },
          {
            name: ["IsVideo"],
            value: CompanyHoliday.IsVideo,
          },
        ]}
        form={form}
      >
        <Row gutter={16}>
          <Col md={16} sm={12} xs={24}>
            <Form.Item
              label={"Descripción"}
              htmlFor={"Description"}
              name="Description"
              rules={[
                {
                  required: true,
                  message: "*Campo Requerido",
                },
                {
                  pattern: new RegExp(/^\S/),
                  message: "No se aceptan espacios en blanco al inicio",
                },
              ]}
            >
              <Input
                type="text"
                name="Description"
                onChange={handleInputChange}
              />
            </Form.Item>
          </Col>
          <Col md={8} sm={12} xs={24}>
            <Form.Item
              label={"Tipo"}
              htmlFor={"TypeId"}
              rules={[
                {
                  required: true,
                  message: "*Campo Requerido",
                },
              ]}
              name={"TypeId"}
            >
              <Select
                onChange={handleOptionChange}
                name={"TypeId"}
                options={typesNotice}
              />
            </Form.Item>
          </Col>
          <Col md={8} sm={10} xs={24}>
            <Form.Item
              label={"Fecha de evento"}
              htmlFor={"Date"}
              name="Date"
              rules={[
                {
                  required: true,
                  message: "*Campo Requerido",
                },
              ]}
            >
              <DatePicker
                placeholder={"Seleccionar fecha"}
                name="Date"
                format={"DD/MMM/YYYY"}
                onChange={(_, date) => handleInputDate(_, date, "Date")}
                showToday={false}
                allowClear={false}
                disabledDate={disabledDate}
              />
            </Form.Item>
          </Col>
          <Col md={8} sm={12} xs={24}>
            <Form.Item
              label={"Fecha de inicio"}
              required
              tooltip="Fecha en que comienza a publicarse el aviso"
              htmlFor={"StartDate"}
              name="StartDate"
              rules={[
                {
                  required: true,
                  message: "*Campo Requerido",
                },
              ]}
            >
              <DatePicker
                name="StartDate"
                placeholder={"Inicia"}
                onChange={(_date, strDate) =>
                  handleInputDate(_date, strDate, "StartDate")
                }
                format={"DD/MMM/YYYY"}
                allowClear={false}
                showToday={false}
                minDate={dayjs()}
                maxDate={dayjs(CompanyHoliday.Date, "DD/MMM/YYYY")}
                disabled={CompanyHoliday.Date === ""}
              />
            </Form.Item>
          </Col>
          <Col md={8} sm={12} xs={24}>
            <Form.Item
              label={"Fecha de finalización"}
              required
              tooltip="Fecha en que termina de publicarse el aviso"
              htmlFor={"EndDate"}
              name="EndDate"
              rules={[
                {
                  required: true,
                  message: "*Campo Requerido",
                },
              ]}
            >
              <DatePicker
                name="EndDate"
                placeholder={"Finaliza"}
                onChange={(_date, strDate) =>
                  handleInputDate(_date, strDate, "EndDate")
                }
                format={"DD/MMM/YYYY"}
                allowClear={false}
                showToday={false}
                minDate={dayjs(CompanyHoliday.StartDate, "DD/MMM/YYYY")}
                disabled={CompanyHoliday.StartDate === ""}
              />
            </Form.Item>
          </Col>
          {CompanyHoliday.IsVideo ? null : (
            <Col md={12} sm={24} xs={24}>
              <Form.Item
                label={"Link"}
                htmlFor={"Iframe"}
                name="Iframe"
                rules={[
                  {
                    required: true,
                    message: "*Campo Requerido",
                  },
                ]}
                initialValue={CompanyHoliday.Iframe}
              >
                <Input
                  name="Iframe"
                  addonBefore={
                    <Select
                      onChange={handleSelectBefore}
                      name={"SelectBefore"}
                      value={selectBeforeOption}
                    >
                      <Option value="https://">https://</Option>
                      <Option value="http://">http://</Option>
                    </Select>
                  }
                  onChange={handleValueIframe}
                />
              </Form.Item>
            </Col>
          )}
          {selectedTypeId === 2 ? (
            <>
              <Col md={12} sm={24} xs={24} style={{ marginTop: "1.5rem" }}>
                <Form.Item htmlFor={"Tipo de aviso"} name="IsVideo">
                  <Checkbox
                    text={"¿Es un video?"}
                    label={"IsVideo"}
                    name={CompanyHoliday.IsVideo}
                    handleValueCheck={onChangeIsVideo}
                  />
                </Form.Item>
              </Col>
            </>
          ) : null}
          <Col xs={24}>
            <Form.Item label={"Imagen miniatura"}>
              <InputFile
                onFilesSelected={handleFiles}
                width={dimensionsImage.width}
                height={dimensionsImage.height}
                edit={props.currentCompanyHoliday?.CompanyHolidayId !== 0}
                valueFile={files}
                nameFile={"thumbNotice"}
                accept={["image"]}
                deleteImage={deleteImage}
              />
            </Form.Item>
          </Col>
          {CompanyHoliday.IsVideo ? (
            <Col xs={24}>
              <Tabs
                defaultActiveKey={activeTab}
                items={[
                  {
                    key: "1",
                    label: "Videos en mi PC",
                    children: (
                      <>
                        <Row gutter={[16, 0]}>
                          <Col
                            md={{
                              span: urlVideo === "" ? 24 : 12,
                            }}
                            sm={{
                              span: urlVideo === "" ? 24 : 12,
                            }}
                            xs={{
                              span: 24,
                            }}
                          >
                            <InputFile
                              onFilesSelected={handleVideoUpload}
                              edit={CompanyHoliday.CompanyHolidayId !== 0}
                              valueFile={filesVideo}
                              nameFile={"thumbVideo"}
                              accept={["video"]}
                              deleteImage={(files) => handleVideoRemove(files)}
                            />
                          </Col>
                          {urlVideo === "" ? null : (
                            <Col
                              md={{
                                span: 12,
                              }}
                              sm={{
                                span: 12,
                              }}
                              xs={{
                                span: 24,
                              }}
                            >
                              <ReactPlayer
                                url={urlVideo}
                                width="100%"
                                height="100%"
                                controls={true}
                              />
                            </Col>
                          )}
                        </Row>
                      </>
                    ),
                  },
                  {
                    key: "2",
                    label: "Videos externos",
                    children: (
                      <>
                        <Row gutter={16}>
                          <Col md={12} xs={24}>
                            <Form.Item
                              label={`Iframe para video (Youtube)`}
                              htmlFor={"Iframe"}
                              name="Iframe"
                              initialValue={CompanyHoliday.Iframe}
                            >
                              <Input.TextArea
                                rows="4"
                                name="Iframe"
                                onChange={handleValueIframe}
                              ></Input.TextArea>
                            </Form.Item>
                          </Col>
                          <Col md={12} xs={24}>
                            <div className={"iframe-youtube-info"}>
                              <Tag
                                icon={<FaInfoCircle />}
                                color="#cd201f"
                                onClick={toggleMyModal}
                                className={"info-yt"}
                              >
                                <p>
                                  ¿Cómo crear un iframe en youtube?{" "}
                                  <FaChevronRight />
                                </p>
                              </Tag>
                              <img
                                className="yt"
                                src={videoYT}
                                alt="Video Youtube"
                              />
                            </div>
                          </Col>
                          <Col md={12} xs={24}>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: CompanyHoliday.Iframe,
                              }}
                            />
                          </Col>
                        </Row>
                      </>
                    ),
                  },
                ]}
                onChange={onChangeTabs}
              />
            </Col>
          ) : null}
          <Col xs={24}>
            <div className="form-backoffice-footer">
              <Button htmlType={"submit"} type="primary">
                <p>Guardar</p>
                <FaSave />
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
      <Modal
        open={myModal}
        footer={(_, { OkBtn, CancelBtn }) => <></>}
        onCancel={toggleMyModal}
      >
        <img
          src={ImgInfog}
          width="100%"
          style={{ width: "100%", borderRadius: "1rem", objectFit: "contain" }}
          alt="Infografía Kuimby"
        />
      </Modal>
    </>
  );
};
