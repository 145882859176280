import React, { useState } from "react";
import { ListGroup, ListGroupItem } from "reactstrap";
import Moment from "react-moment";
import "moment/locale/es";
import { useSelector } from "react-redux";
import { Button } from "rsuite";

export function ListSurveys({ surveys, onSelectSurvey, handleTempQuestions }) {
  const [surveyActive, setSurveyActive] = useState(0);

  const calendarStrings = useSelector((state) => state.calendarStrings);

  return (
    <>
      {surveys.map((survey) => ( 
        <div
          className={` list-group-item ${surveyActive == survey.surveyId ? "active" : ""}`}
          key={survey.surveyUserProfileId}
          onClick={() => {
            onSelectSurvey(survey);
            setSurveyActive(survey.surveyId);
            handleTempQuestions(handleTempQuestions)
          }}
        >
          <div>
            <h6>{survey.title}</h6>
            <p className="question">{survey.questionCount} reactivos</p>
            <p>
              Vence <Moment calendar={calendarStrings}>{survey.endDate}</Moment>
            </p>
          </div>
          <button className="btn_next">
            <i className="icon-chevron-right"></i>
          </button>
        </div>
      ))}
    </>
  );
}
