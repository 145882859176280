import React, { useEffect, useState } from 'react';
import { companyCustomizeService } from '../../../services/backoffice/companyCustomize.service';
import { FaSave } from "react-icons/fa";
import {Button, Col, Form, Input, Row, ColorPicker} from "antd";
import {Checkbox} from "../../components/checkbox/checkbox";
import {InputFile} from "../../components/inputFile/inputFile";
import {InputAdd} from "../../components/inputAdd/inputAdd";
import Swal from "sweetalert2";

export const CompanyCustomize = (props) => {

	const [values, setValues] = useState({
		CompanyLogo: [],
		CompanyCustomizeId: 0,
		CompanyId: props.CompanyId,
		PrimaryColor: "",
		SecondaryColor: "",
		NoPost: 0,
		NoDaysGrace: 0,
		Amount: 0,
		AmountToVerify: 0,
		FreePost: false
	});
	const [logoSaved, setLogoSaved] = useState(null);
	const [customize, setCustomize] = useState({CustomizeValues: null,});

	const onSave = () => {

		if(values.CompanyLogo.length === 0) {
			Swal.fire({
				icon: 'error',
				title: 'Ups...',
				text: 'No hay un logotipo para el sitio',
			})
			return;
		}

		customize.CompanyId = values.CompanyId;
		customize.CompanyCustomizeId = values.CompanyCustomizeId;
		customize.CustomizeValues = JSON.stringify({
			"CompanyLogo": logoSaved ? logoSaved : '',
			"PrimaryColor": values.PrimaryColor,
			"SecondaryColor": values.SecondaryColor,
			"NoPost": String(values.NoPost),
			"NoDaysGrace": String(values.NoDaysGrace),
			"Amount": String(values.Amount),
			"AmountToVerify": String(values.AmountToVerify),
			"FreePost": values.FreePost
		})
		if(logoSaved === null) customize.CompanyLogoFile = values.CompanyLogo[0];

		if (values.CompanyCustomizeId && values.CompanyCustomizeId > 0)
			companyCustomizeService.edit(customize)
				.then((result) => {
					if (result) window.location.reload()
				});
		else
			companyCustomizeService.create(customize)
				.then((result) => {
					if (result) window.location.reload()
				});
	};

	const handleFreePost = (value) => setValues({...values, FreePost: value})

	const handleSelectedImage = (file) =>  {
		setValues({...values, CompanyLogo: file})
		setLogoSaved(null);
	}

	const handleDeleteImage = () => setValues({...values, CompanyLogo: []});

	const handleQuantity = (value,name) => setValues({...values, [name]: value});

	useEffect(() => {
		if (props.CompanyId !== 0) {
			companyCustomizeService.getByCompanyId(props.CompanyId).then((result) => {
				const object = result && result.customizeValues !== "" ? JSON.parse(result.customizeValues) : null;

				const logoFile = new File([object.CompanyLogo], `img_logo_${result.companyCustomizeId}.png`, {
					type: 'image/png'
				})


				if(logoFile) setLogoSaved(object.CompanyLogo)

				if (object !== '')
					setValues({
						...values,
						CompanyCustomizeId: result.companyCustomizeId,
						CompanyId: props.CompanyId,
						CompanyLogo: [logoFile],
						PrimaryColor: object.PrimaryColor,
						SecondaryColor: object.SecondaryColor,
						NoPost: parseInt(object.NoPost),
						NoDaysGrace: parseInt(object.NoDaysGrace),
						Amount: parseInt(object.Amount),
						AmountToVerify: parseInt(object.AmountToVerify),
						FreePost: (object.FreePost)
					});
			});
		}
	}, [props.CompanyId]);

	return (
		<>
			<Form
				onFinish={onSave}
				className="form-backoffice"
				layout={'vertical'}
				fields={[
					{
						"name": ['PrimaryColor'],
						"value": values.PrimaryColor
					},
					{
						"name": ['SecondaryColor'],
						"value": values.SecondaryColor
					},
					{
						"name": ['NoPost'],
						"value": values.NoPost
					},
					{
						"name": ['NoDaysGrace'],
						"value": values.NoDaysGrace
					},
					{
						"name": ['FreePost'],
						"value":(values.FreePost)
					},
					{
						"name": ['Amount'],
						"value": values.Amount
					},
					{
						"name": ['AmountToVerify'],
						"value": values.AmountToVerify
					},
				]}
			>
				<h3>Personalización</h3>
				<Row gutter={16}>
					<Col md={12} sm={24} xs={24}>
						<Form.Item
							label={'Color Primario'}
							htmlFor={'PrimaryColor'}
							name="PrimaryColor"
							rules={[
								{
									required: true,
									message: '*Campo Requerido'
								}
							]}
						>
							<ColorPicker
								size="large"
								format="hex"
								value={values.PrimaryColor}
								onChange={(color, hex) => setValues({...values, PrimaryColor: hex})}
								showText
							/>
						</Form.Item>
					</Col>
					<Col md={12} sm={24} xs={24}>
						<Form.Item
							label={'Color Secundario'}
							htmlFor={'SecondaryColor'}
							name="SecondaryColor"
							rules={[
								{
									required: true,
									message: '*Campo Requerido'
								}
							]}
						>
							<ColorPicker
								size="large"
								format="hex"
								value={values.SecondaryColor}
								onChange={(color,hex) => setValues({...values, SecondaryColor: hex})}
								showText
							/>
						</Form.Item>
					</Col>
					<Col xs={24}>
						<Form.Item
							label={props.TitleLabel}
							htmlFor={'CompanyLogo'}
							name="CompanyLogo"
						>
							<InputFile
								onFilesSelected={handleSelectedImage}
								edit={(values.CompanyId !== 0)}
								valueFile={values.CompanyLogo}
								width={300}
								height={150}
								nameFile={'companyLogo'}
								accept={['image']}
								deleteImage={handleDeleteImage}
							/>
						</Form.Item>
					</Col>
					<Col md={12} sm={24} xs={24}>
						<Form.Item
							label={'Número de publicaciones gratis'}
							htmlFor={'NoPost'}
							name="NoPost"
						>
							<InputAdd
								maxLength={3}
								maxQuantity={999}
								minQuantity={0}
								valueInt={values.NoPost}
								handleCurrentNumber={(value) => handleQuantity(value,'NoPost')}
							/>
						</Form.Item>
					</Col>
					<Col md={12} sm={24} xs={24}>
						<Form.Item
							label={'Número de días de gracia'}
							htmlFor={'NoDaysGrace'}
							name="NoDaysGrace"
						>
							<InputAdd
								maxLength={3}
								maxQuantity={999}
								minQuantity={0}
								valueInt={values.NoDaysGrace}
								handleCurrentNumber={(value) => handleQuantity(value,'NoDaysGrace')}
							/>
						</Form.Item>
					</Col>
					<Col md={12} sm={24} xs={24}>
  						<Form.Item
							label={'Publicaciones'}
							htmlFor={'FreePost'}
							name="FreePost"
						>
							<Checkbox
								text={'¿Publicaciones gratuitas?'}
								label={'FreePost'}
								name={(values.FreePost)}
								handleValueCheck={handleFreePost}
							/>
  						</Form.Item>
					</Col>
					<Col md={12} sm={24} xs={24}>
						<Form.Item
							label={'Costo de publicación en Economía incluyente'}
							htmlFor={'Amount'}
							name="Amount"
						>
							<InputAdd
								maxLength={4}
								maxQuantity={9999}
								minQuantity={0}
								valueInt={values.Amount}
								handleCurrentNumber={(value) => handleQuantity(value,'Amount')}
							/>
						</Form.Item>
					</Col>
					<Col md={12} sm={24} xs={24}>
						<Form.Item
							label={'Costo para verificar tarjeta'}
							htmlFor={'AmountToVerify'}
							name="AmountToVerify"
						>
							<InputAdd
								maxLength={4}
								maxQuantity={9999}
								minQuantity={1}
								valueInt={values.AmountToVerify}
								handleCurrentNumber={(value) => handleQuantity(value,'AmountToVerify')}
							/>
						</Form.Item>
					</Col>
					<Col xs={24}>
						<div className="form-backoffice-footer">
							<Button type="primary" htmlType={'submit'}>
								<p>Guardar</p>
								<FaSave />
							</Button>
						</div>
					</Col>
				</Row>
			</Form>
		</>
	);
}   
