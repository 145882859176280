import React from "react";
import {Tooltip, Whisper} from "rsuite";

export function ItemSocial(props) {
    const tooltip = (
        <Tooltip id="button-tooltip" {...props.socialIcoName}>
            {props.socialIcoName}
        </Tooltip>
    );

    return (
        <Whisper placement="top" controlId="button-tooltip" trigger="hover" speaker={tooltip} >
          <a href={props.socialUrl} target="_blank" className="a_ico_social">
            <ion-icon name={props.socialIco}></ion-icon>
          </a>
        </Whisper>
    );
}
