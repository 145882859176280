import React, { useState, useEffect } from "react";
import { Header } from "./header/header";
import { backendService } from "../services/backend.service";
import { useDispatch } from "react-redux";
import { setUserSettings, setAccountSettings } from "../store/actions";
import { BlockScreen } from "./blockScreen/blockScreen";
import { InstallPWA } from "./pwa/installPWA";
import { Footer } from "./footer/footer";
import { messageService } from "../services/message.service";
import { Menu, Button } from "antd";
import { FaBars, FaChevronLeft, FaSignOutAlt, FaUser } from "react-icons/fa";
import { IoBusiness } from "react-icons/io5";
import { BiBuilding } from "react-icons/bi";
import {
  AiFillDatabase,
  AiFillSetting,
  AiOutlineLeft,
  AiOutlineRight,
} from "react-icons/ai";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import LogoCompany from "../assets/imgs/R_C.png";
import { userService } from "../services/user.service";
import { ImgCache } from "./CustonHooks/imgCache";
import { FooterHome } from "./footer/footerHome";
import { CiMenuBurger } from "react-icons/ci";

export const LayoutFull = (props) => {
  const dispatch = useDispatch();
  const [activeMenu, setStateMenu] = useState("");
  const [profile, setProfileInfo] = useState();
  const [messages, setMessages] = useState([]);

  const MenuPurchaseBox = () =>
    setStateMenu(activeMenu === "" ? " is-show" : "");

  const [activeBtn, setStateBtn] = useState("");
  const BtnSet = () => setStateBtn(activeBtn === "" ? " visible_menu" : "");

  const MyFun = () => {
    BtnSet();
    MenuPurchaseBox();
  };
  const getProfileInfo = () =>
    backendService.userProfile().then((data) => setProfileInfo(data));

  useEffect(() => {
    backendService.userSettings().then((result) => {
      dispatch(setUserSettings(result));
      getProfileInfo();
    });

    messageService.getUnreadCount().then((data) => setMessages(data));
  }, []);

  const getWidthDimensions = () => window.innerWidth;
  const [windowDimensions, setWindowDimensions] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowDimensions(getWidthDimensions());
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const sampleLocation = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [sampleLocation]);

  return (
    <>
      <BlockScreen />
      <Header
        MyFun={() => MyFun()}
        activeBtn={activeBtn}
        history={props.history}
        userProfile={profile}
        windowDimensions={windowDimensions}
        messages={messages}
      />
      <main className="dashboard-wrapper">
        <div className="dashboard-container pridemx">{props.children}</div>
      </main>
      <Footer
        MyFun={() => MyFun()}
        activeBtn={activeBtn}
        history={props.history}
        userProfile={profile}
        windowDimensions={windowDimensions}
        messages={messages}
      />
      <InstallPWA />
    </>
  );
};

export const LayoutLess = (props) => {
  localStorage.removeItem("_rtpride");
  const dispatch = useDispatch();

  useEffect(() => {
    backendService
      .getCompanyCustomize(process.env.REACT_APP_COMPANY)
      .then((result) => {
        if (result) {
          const customizeValues = JSON.parse(result.customizeValues);
          dispatch(
            setUserSettings({ companyLogo: customizeValues.CompanyLogo })
          );
          dispatch(setAccountSettings(JSON.parse(result.accountSettings)));
        }
      });
  }, []);

  const sampleLocation = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [sampleLocation]);

  return (
    <section>
      <BlockScreen />
      {props.children}
      <InstallPWA />
    </section>
  );
};

export const LayoutOperator = (props) => {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [activeMenu, setActiveMenu] = useState(false);
  const [currentRoute, setCurrentRoute] = useState([]);
  const [currentSubMenu, setCurrentSubmenu] = useState([]);
  const [windowDimensions, setWindowDimensions] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const toggleCollapsed = () => {
    if (!collapsed) {
      localStorage.removeItem("sidenavOperator");
      localStorage.setItem("sidenavOperator", "true");
    }
    if (collapsed) {
      localStorage.removeItem("sidenavOperator");
      localStorage.setItem("sidenavOperator", "false");
    }
    setCollapsed(!collapsed);
  };

  const toggleMenuMobile = () => setActiveMenu(!activeMenu);

  const dataRoutes = [
    {
      id: 1,
      routeName: "/operator",
      parent: "",
      key: "1",
    },
    {
      id: 2,
      routeName: "/operator/AdminCompany",
      parent: "",
      key: "2",
    },
    {
      id: 3,
      routeName: "/operator/controlreward/1",
      parent: "3",
      key: "3-1",
    },
    {
      id: 4,
      routeName: "/operator/providers/1",
      parent: "3",
      key: "3-2",
    },
    {
      id: 5,
      routeName: "/operator/categories/1",
      parent: "3",
      key: "3-3",
    },
  ];

  const getCurrentRoute = () => {
    dataRoutes.map((route) => {
      if (route.routeName === location.pathname) {
        setCurrentRoute([route.key]);
        currentSubMenu.push(route.parent);
      }
      return [currentRoute, currentSubMenu];
    });
  };

  const logout = () => {
    const style = document.getElementsByTagName("style");
    document
      .getElementsByTagName("head")[0]
      .removeChild(style[style.length - 1]);
    userService.logout().then(() => {
      window.location.href = "/";
    });
  };

  const getItem = (label, key, icon, children, type) => {
    return { key, icon, children, label, type };
  };

  const list = [
    window.innerWidth >= 768
      ? {
          label: "Cuenta",
          key: "1",
          icon: <AiFillSetting />,
          children: [
            {
              key: "1-1",
              label: (
                <Button htmlType="button" onClick={logout}>
                  Cerrar sesión
                </Button>
              ),
              icon: <FaSignOutAlt />,
            },
          ],
        }
      : null,
    window.innerWidth <= 768
      ? {
          label: (
            <Link to="/operator" className="logo-link">
              <img src={LogoCompany} alt="" className="logo" />
            </Link>
          ),
          key: "3",
        }
      : null,
    window.innerWidth <= 768
      ? {
          label: (
            <Button
              className={`btn-bars ${activeMenu ? "active" : ""}`}
              onClick={toggleMenuMobile}
            >
              {activeMenu ? <FaChevronLeft /> : <FaBars />}
            </Button>
          ),
          key: "2",
        }
      : null,
  ];

  const items = [
    window.innerWidth <= 768
      ? getItem("Cuenta", "4", <AiFillSetting />, [
          getItem(
            <Button htmlType="button" onClick={logout}>
              Cerrar sesión
            </Button>,
            "4-1",
            <FaSignOutAlt />
          ),
        ])
      : null,
    getItem(
      <Link to="/operator">Mi Tablero</Link>,
      "1",
      <i className="icon-home"></i>
    ),
    getItem(
      <Link to="/operator/AdminCompany">Empresas</Link>,
      "2",
      <BiBuilding />
    ),
    getItem("Control Rewards", "3", <AiFillSetting />, [
      getItem(
        <Link to="/operator/controlreward/1">Recompensas</Link>,
        "3-1",
        <i className="icon-gif"></i>
      ),
      getItem(
        <Link to="/operator/providers/1">Proveedores</Link>,
        "3-2",
        <IoBusiness />
      ),
      getItem(
        <Link to="/operator/categories/1">Categorías</Link>,
        "3-3",
        <AiFillDatabase />
      ),
    ]),
  ];

  const closeSidenav = () =>
    window.innerWidth <= 768 ? toggleMenuMobile() : null;

  useEffect(() => {
    const collapsedStorage = JSON.parse(
      localStorage.getItem("sidenavOperator")
    );
    const handleResize = () => setWindowDimensions(window.innerWidth);
    window.addEventListener("resize", handleResize);

    if (windowDimensions <= 992) setCollapsed(true);
    if (windowDimensions >= 992)
      setCollapsed(collapsedStorage === true ? collapsedStorage : false);
    if (windowDimensions <= 768) {
      setCollapsed(false);
      localStorage.removeItem("sidenavOperator");
      localStorage.setItem("sidenavOperator", "false");
    }
    if (windowDimensions >= 768)
      setActiveMenu(collapsedStorage === true ? collapsedStorage : false);

    return () => window.removeEventListener("resize", handleResize);
  }, [windowDimensions]);

  useEffect(() => {
    setCollapsed(JSON.parse(localStorage.getItem("sidenavOperator")));
  }, []);

  useEffect(() => {
    getCurrentRoute();
  }, [location.pathname]);

  return (
    <>
      <BlockScreen />
      <main
        className={`dashboard-operator ${
          window.innerWidth <= 768 ? "mobile" : ""
        }`}
      >
        <aside
          className={`dashboard-operator-aside ${collapsed ? "mobile" : ""} ${
            activeMenu ? "active" : ""
          }`}
        >
          {window.innerWidth >= 768 ? (
            <>
              <Link to="/operator" className="logo-link">
                <img src={LogoCompany} alt="" className="logo" />
              </Link>
              <Button
                type="primary"
                htmlType="button"
                icon={<FaChevronLeft />}
                shape="circle"
                onClick={toggleCollapsed}
              ></Button>
            </>
          ) : null}
          <Menu
            defaultOpenKeys={currentSubMenu}
            selectedKeys={currentRoute}
            mode="inline"
            items={items}
            inlineCollapsed={collapsed}
            className="mobile"
            onClick={closeSidenav}
          />
        </aside>
        <section className={`dashboard-operator-content`}>
          <Menu mode="horizontal" items={list} />
          {props.children}
        </section>
      </main>
      <footer className="dashboard-operator-footer">
        <ul>
          <li>
            <Link to="/ambienteseguro.pdf" target="_blank">
              Decálogo
            </Link>
          </li>
          <li>
            <Link to="/avisoprivacidad.pdf" target="_blank">
              Aviso de Privacidad
            </Link>
          </li>
          <li>
            <Link to="/terminosycondiciones.pdf" target="_blank">
              Términos y Condiciones
            </Link>
          </li>
        </ul>
      </footer>
      <InstallPWA />
    </>
  );
};

export const LayoutBackoffice = (props) => {
  const location = useLocation();
  const history = useHistory();
  const [collapsed, setCollapsed] = useState(false);
  const [activeMenu, setActiveMenu] = useState(false);
  const [currentRoute, setCurrentRoute] = useState([]);
  const [currentSubMenu, setCurrentSubmenu] = useState([]);
  const dispatch = useDispatch();
  const { id, surveyId } = useParams();
  const [color, setColor] = useState("#000");
  const [companyLogo, setCompanyLogo] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const toggleCollapsed = () => {
    if (!collapsed) setCollapsed(true);
    else setCollapsed(false);
  };

  const toggleMenuMobile = () => setActiveMenu(!activeMenu);

  const dataRoutes = [
    {
      id: 1,
      routeName: "/admin",
      parent: "",
      key: "1",
    },
    {
      id: 2,
      routeName: "/admin/admin-diary",
      parent: "",
      key: "2",
    },
    {
      id: 3,
      routeName: "/admin/course",
      parent: "",
      key: "3",
    },
    {
      id: 3,
      routeName: "/admin/product-filter",
      parent: "",
      key: "4",
    },
    {
      id: 4,
      routeName: "/admin/AdminSurvey",
      parent: "",
      key: "5",
    },
    {
      id: 5,
      routeName: `/admin/AdminSurveyScale/${surveyId}`,
      parent: "",
      key: "5",
    },
    {
      id: 5,
      routeName: `/admin/details-survey/${id}`,
      parent: "",
      key: "5",
    },
    {
      id: 6,
      routeName: `/admin/control`,
      parent: "",
      key: "6",
    },
    {
      id: 7,
      routeName: `/admin/company-post`,
      parent: "",
      key: "7",
    },
    {
      id: 8,
      routeName: `/admin/admin-audience`,
      parent: "",
      key: "8",
    },
    {
      id: 9,
      routeName: `/admin/users`,
      parent: "",
      key: "9",
    },

    {
      id: 10,
      routeName: `/admin/generic-section`,
      parent: "",
      key: "10",
    },
  ];

  const getCurrentRoute = () => {
    dataRoutes.map((route) => {
      if (route.routeName === location.pathname) {
        setCurrentRoute([route.key]);
        currentSubMenu.push(route.parent);
      }
      return [currentRoute, currentSubMenu];
    });
  };

  const logout = () => {
    const style = document.getElementsByTagName("style");
    document
      .getElementsByTagName("head")[0]
      .removeChild(style[style.length - 1]);
    userService.logout().then(() => history.push("/"));
  };

  const [windowDimensions, setWindowDimensions] = useState(window.innerWidth);

  const getItem = (label, key, icon, children, type) => {
    return { key, icon, children, label, type };
  };

  const setCustom = (customValues) => {
    setColor(customValues.primaryColor);
    const style = document.createElement("style");
    style.type = "text/css";
    style.innerHTML = `:root {--primaryColor: ${customValues.primaryColor} !important; --secondaryColor: ${customValues.secondaryColor} !important;}`;
    document.getElementsByTagName("head")[0].appendChild(style);
  };

  const list = [
    {
      label: (
        <a href={"/"} className={"btn-primary"}>
          <i className={"icon-home"}></i>
          <p>Muro Pride</p>
        </a>
      ),
      key: "4",
    },
    window.innerWidth <= 1280
      ? {
          label: (
            <div style={{ display: "flex", gap: "1rem" }}>
              <a href="/admin" className="logo-link">
                <ImgCache src={companyLogo} alt="Kuimby Social" />
              </a>
            </div>
          ),
          key: "3",
        }
      : null,
    window.innerWidth <= 992
      ? {
          label: (
            <Button
              className={`btn-bars ${activeMenu ? "active" : ""}`}
              onClick={toggleMenuMobile}
            >
              {activeMenu ? <FaChevronLeft /> : <CiMenuBurger />}
            </Button>
          ),
          key: "2",
        }
      : null,
    window.innerWidth >= 992
      ? {
          label: "Cuenta",
          key: "1",
          icon: <AiFillSetting />,
          children: [
            {
              key: "1-1",
              label: (
                <Button htmlType="button" onClick={logout}>
                  Cerrar sesión
                </Button>
              ),
              icon: <FaSignOutAlt />,
            },
          ],
        }
      : null,
  ];

  const items = [
    window.innerWidth <= 992
      ? {
          label: "Cuenta",
          key: "12",
          icon: <AiFillSetting />,
          children: [
            {
              label: (
                <Button htmlType="button" onClick={logout}>
                  Cerrar sesión
                </Button>
              ),
              key: "12-1",
              icon: <FaSignOutAlt />,
            },
          ],
        }
      : null,
    {
      label: <Link to="/admin">Dashboard</Link>,
      key: "1",
      icon: <i className={"icon-home"}></i>,
      title: "Dashboard",
    },
    // {
    //   label: <Link to="/admin/admin-diary">Calendario</Link>,
    //   key: "2",
    //   icon: <i className={"icon-calendar-dots"}></i>,
    //   title: "Calendario",
    // },
    {
      label: <Link to="/admin/course">Cartelera</Link>,
      key: "3",
      icon: <i className={"icon-billboard"}></i>,
      title: "Cartelera",
    },
    {
      label: <Link to="/admin/product-filter">Recompensas</Link>,
      key: "4",
      icon: <i className={"icon-gif"}></i>,
      title: "Recompensas",
    },
    {
      label: <Link to="/admin/AdminSurvey">Tu Opinión</Link>,
      key: "5",
      icon: <i className={"icon-opinion"}></i>,
      title: "Tu Opinión",
    },
    {
      label: <Link to="/admin/control">Control</Link>,
      key: "6",
      icon: <i className={"icon-docs-published"}></i>,
      title: "Control",
    },
    {
      label: <Link to="/admin/company-post">Mundo Pride</Link>,
      key: "7",
      icon: <i className={"icon-pride-world"}></i>,
      title: "Mundo Pride",
    },
    {
      label: <Link to="/admin/admin-audience">PrideTalks</Link>,
      key: "8",
      icon: <i className={"icon-pride-talks"}></i>,
      title: "PrideTalks",
    },
    {
      label: <Link to="/admin/users">Usuarios</Link>,
      key: "9",
      icon: <i className={"icon-user"}></i>,
      title: "Usuarios",
    },
    {
      label: <Link to="/admin/generic-section">Publicaciones Genéricas</Link>,
      key: "10",
      icon: <i className={"icon-docs-published"}></i>,
      title: "Publicaciones Genéricas",
    },
  ];

  const closeSidenav = () =>
    window.innerWidth <= 992 ? toggleMenuMobile() : null;

  useEffect(() => {
    getCurrentRoute();
  }, [location.pathname]);

  useEffect(() => {
    backendService.userSettings().then((result) => {
      dispatch(setUserSettings(result));
      setCompanyLogo(result.companyLogo);
      setCustom(result);
    });
    if (windowDimensions <= 1280) setCollapsed(true);
    if (windowDimensions >= 1280) setCollapsed(false);
    if (windowDimensions <= 992) {
      setCollapsed(false);
      setActiveMenu(false);
    }
    if (windowDimensions >= 992) setActiveMenu(true);
  }, []);

  useEffect(() => {
    const handleResize = () => setWindowDimensions(window.innerWidth);
    window.addEventListener("resize", handleResize);

    if (windowDimensions <= 1280) setCollapsed(true);
    if (windowDimensions >= 1280) setCollapsed(false);
    if (windowDimensions <= 992) {
      setCollapsed(false);
      setActiveMenu(false);
    }
    if (windowDimensions >= 992) setActiveMenu(true);

    return () => window.removeEventListener("resize", handleResize);
  }, [windowDimensions]);

  return (
    <>
      <BlockScreen />
      <main
        className={`dashboard-admin ${
          window.innerWidth <= 992 ? "mobile" : ""
        }`}
      >
        <aside
          className={`dashboard-admin-aside ${collapsed ? "mobile" : ""} ${
            activeMenu ? "active" : ""
          }`}
        >
          {window.innerWidth >= 1280 ? (
            <>
              <a href="/admin" className="logo-link">
                <ImgCache src={companyLogo} alt="Kuimby Social" />
              </a>
              <Button
                type="primary"
                htmlType="button"
                icon={collapsed ? <AiOutlineRight /> : <AiOutlineLeft />}
                shape="circle"
                onClick={toggleCollapsed}
              ></Button>
            </>
          ) : null}
          <Menu
            defaultOpenKeys={currentSubMenu}
            selectedKeys={currentRoute}
            mode="inline"
            items={items}
            inlineCollapsed={collapsed}
            className="mobile"
            onClick={closeSidenav}
          />
        </aside>
        <section className={`dashboard-admin-content`}>
          <nav className={"nav-content"}>
            <div className={"dashboard-admin-container"}>
              <Menu mode="horizontal" items={list} />
            </div>
          </nav>
          <div className={"dashboard-admin-body"}>{props.children}</div>
          <FooterHome />
        </section>
      </main>
      <InstallPWA />
    </>
  );
};
