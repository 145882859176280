import React, { useEffect, useState } from "react";
import { Button, Typography } from "antd";
import "./cardUser.scss";
import { backendService } from "../../services/backend.service";

const { Text } = Typography;

export function CardUser() {
  const [profile, setProfileInfo] = useState({});

  async function getProfileInfo() {
    backendService.userProfile().then((data) => {
      setProfileInfo(data);
    });
  }

  useEffect(() => {
    backendService.userSettings().then((result) => {
      getProfileInfo();
    });
  }, []);

  return (
    <div className="card-user">
      <div className="card-user-avatar">
        <div className={`rotating ${profile.genderIdentityFlag}`}></div>
        <img alt="Usuario PrideMx" src={profile.urlProfileImage} />
      </div>
      <h3>&#128075; Hola, {profile.firstName}</h3>
      <p>
        <Button type="text" href="/profile">
          <Text type="secondary">Mi perfil</Text>
        </Button>
      </p>
    </div>
  );
}
