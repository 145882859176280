import React, { useState, useEffect } from "react";
import { directoryInfoService } from "../../services/directoryService.service";
import { categoryServiceService } from "../../services/categoryService.service";
import { metaPixelService } from "../../services/metaPixel.service";
import { ItemDirectory } from "./itemDirectory";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./directory.scss";
import { BsArrowRight, BsFilter } from "react-icons/bs";
import { FooterHome } from "../../components/footer/footerHome";
import { FaChevronRight } from "react-icons/fa";
import { Button } from "rsuite";
import { companyCustomizeService } from "../../services/backoffice/companyCustomize.service";
import {Col, Row} from "antd";
import { backendService } from "../../services/backend.service";

export function Directory() {
  const roleName = useSelector((state) => state.userSettings.roleName);
  const [sectionOn, setSectionOn] = useState(true);
  const [dataCompany, setDataCompany] = useState({});
  const fSectionOn = () => {
    setSectionOn(!sectionOn);
  };

  const trigger = undefined;
  const [isAdmin, setIsAdmin] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [directoryInfo, setDirectoryInfo] = useState([]);
  const [directoryInfoToAuthorize, setdirectoryInfoToAuthorize] = useState([]);
  const [locationFilter, setLocationFilter] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState([]);
  const [subCategoryFilter, setSubCategoryFilter] = useState([]);
  const [profile, setProfileInfo] = useState(0);
  const [profileData, setProfileData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedFilter, setSelectedFilter] = useState({
    selectLocation: "",
    selectCategory: 0,
    selectSubCategory: 0,
    txtFilter: "",
  });
  const [filteredDirectory, setFilteredDirectory] = useState([]);

  const getProfileInfo = () => {
    let id = localStorage["userId"];
    setProfileInfo(id);
    backendService.userProfile().then((data) => setProfileData(data));
  };

  const handleSelectFilter = (event) => {
    if ([event.target.name] == "selectCategory") {
      if (event.target.value != "0") {
        categoryServiceService
          .getSubCategoriesFilterByCategory(
            event.target.value,
            process.env.REACT_APP_COMPANY
          )
          .then((subs) => {
            setSubCategoryFilter(subs);
          });
      } else {
        setSubCategoryFilter([]);
      }
    }
    setSelectedFilter({
      ...selectedFilter,
      [event.target.name]: event.target.value,
    });
  };
  const getInitialValues = (roleName) => {
    getListos();
  };

  useEffect(() => {
    getProfileInfo();
  }, []);

  useEffect(() => {
    getDataCompany();
  }, [sectionOn]);

  useEffect(() => {
    setIsAdmin(() => {
      return roleName == "Recursos Humanos";
    });
    if (roleName == "Recursos Humanos") getPending();
  }, [roleName]);

  useEffect(() => {
    //if (roleName == "Recursos Humanos") setIsAdmin(true)
    getInitialValues(roleName);
  }, [trigger]);

  const getListos = () => {
    directoryInfoService
      .getAllByCompany(process.env.REACT_APP_COMPANY)
      .then((data) => {
        if (data != null) {
          try {
            let result = convertToArray(data);
            setDirectoryInfo(result);
            setFilteredDirectory(result);
            const locations = [...new Set(result.map((data) => data.location))];
            setLocationFilter(locations);
            categoryServiceService
              .getCategoryCatalogFilterByCompany(process.env.REACT_APP_COMPANY)
              .then((categories) => {
                if (categories != null) {
                  setCategoryFilter(categories);
                }
              });
          } catch (error) {
            console.error(error);
          }
        }
      })
      .catch((message) => {});
  };

  const getPending = () => {
    directoryInfoService
      .getAllByCompany(process.env.REACT_APP_COMPANY, 1)
      .then((dataToAuth) => {
        setdirectoryInfoToAuthorize(convertToArray(dataToAuth));
      });
  };

  const convertToArray = (array) => {
    array.forEach((obj) => {
      obj.contactImage =
        obj.contactImage != undefined ? obj.contactImage.split(",") : [""];
    });

    return array;
  };

  useEffect(() => {
    updateFilterResult(directoryInfo);
    setSearchTerm(selectedFilter.txtFilter);
  }, [selectedFilter]);

  const updateFilterResult = (directoryInfo) => {
    let result = directoryInfo;
    if (selectedFilter.selectLocation) {
      setIsFiltered(true);
      result = directoryInfo.filter((directory) => {
        return directory.location == selectedFilter.selectLocation;
      });
    }
    if (
      selectedFilter.selectCategory !== 0 &&
      selectedFilter.selectCategory !== "0"
    ) {
      setIsFiltered(true);
      result = result.filter((directory) => {
        return directory.categoryServiceId == selectedFilter.selectCategory;
      });
    }

    if (
      selectedFilter.selectSubCategory !== 0 &&
      selectedFilter.selectSubCategory !== "0"
    ) {
      setIsFiltered(true);
      result = result.filter((directory) => {
        return (
          directory.subCategoryServiceId == selectedFilter.selectSubCategory
        );
      });
    }

    if (selectedFilter.txtFilter.length >= 2) {
      let word = selectedFilter.txtFilter
        .toUpperCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      setIsFiltered(true);

      result = result.filter((directory) => {
        return (
          directory.fullName
            .toUpperCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .includes(word) ||
          directory.location
            .toUpperCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .includes(word) ||
          directory.subCategoryServiceName
            .toUpperCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .includes(word) ||
          directory.categoryServiceName
            .toUpperCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .includes(word) ||
          (directory.directoryContacts.filter((x) => x.contactTypeId == 1)
            .length > 0
            ? directory.directoryContacts
                .filter((x) => x.contactTypeId == 1)[0]
                .contactValue.toUpperCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .includes(word)
            : false) || //Telefono
          (directory.directoryContacts.filter((x) => x.contactTypeId == 2)
            .length > 0
            ? directory.directoryContacts
                .filter((x) => x.contactTypeId == 2)[0]
                .contactValue.toUpperCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .includes(word)
            : false)
        ); //Email
      });
    }
    setFilteredDirectory(result);
  };

  function getCurrentURL() {
    return window.location.href
  }
  function getUserAgent() {
    return window.navigator.userAgent;
  }
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      let word = searchTerm
        .toUpperCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      setIsFiltered(true);
      if (searchTerm.length >= 5) {
        var user = {
          em: profileData.email,
          fn: profileData.firstName,
          ln: profileData.lastName,
          db: profileData.birthday,
          zp: profileData.postalCode,
          ph: profileData.mobile,
          client_user_agent: getUserAgent()
        }
        var ped = {
          content_name: "Directorio",
          content_type: "Directory",
          search_string: word,
        };
        var pixelData = { pixelUserInfo: user, pixelCustomData: ped };
        metaPixelService.trackEvent("track", "Search", pixelData, getCurrentURL());
      }
    }, 2345);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const cleanFilter = () => {
    setIsFiltered(false);
    setSelectedFilter({
      selectLocation: "",
      selectCategory: 0,
      selectSubCategory: 0,
      txtFilter: "",
    });
  };

  const data = locationFilter.map((item, i) => ({ label: item, value: item }));
  const categoriesData = locationFilter.map((item) => ({
    label: item,
    value: item,
  }));
  const subCategoriesData = locationFilter.map((item) => ({
    label: item,
    value: item,
  }));

  const getDataCompany = () => {
    companyCustomizeService
      .getByCompanyId(process.env.REACT_APP_COMPANY)
      .then((result) => {
        if (result && result != null) {
          var object = JSON.parse(result.customizeValues);
          setDataCompany({
            NoPost: object.NoPost,
            NoDaysGrace: object.NoDaysGrace,
            FreePost: object.FreePost,
          });
        }
      });
  };

  const [filterBlock, setFilterBlock] = useState(false);
  const handlFilterBlock = () => setFilterBlock(!filterBlock);

  return (
    <section className="dashboard-container-page wrapper_directory">
      <Row gutter={16}>
        <Col xs={24}>
          <h2>Economía Incluyente</h2>
        </Col>
        <Col xl={6} lg={8} md={24} sm={24} xs={24}>
          <aside className="directory-aside">
            <div className="directory-card-ad">
              <div>
                <h3>¿Quieres llegar a más clientes de la comunidad?</h3>
                <p className="label__ftn">Tenemos un espacio para ti</p>
              </div>
              <div className="d-flex-center">
                <Link
                  className="btn-outline-white green"
                  to="/register-services"
                >
                  Publica tus servicios <BsArrowRight />
                </Link>
              </div>
            </div>
            {sectionOn ? (
              <div className="directory box_filters">
                <div className="form-dashboard">
                  <h5>
                    <BsFilter /> Filtros
                  </h5>
                  <div
                    className={`box__form_dashboard ${
                      filterBlock ? "is_block" : ""
                    }`}
                  >
                    <div className="form-dashboard-group mb-4">
                      <label>Ubicación:</label>
                      <select
                        id="selectLocation"
                        name="selectLocation"
                        onChange={handleSelectFilter}
                        value={selectedFilter.selectLocation}
                      >
                        <option value={""} defaultValue>
                          Todas
                        </option>
                        {locationFilter &&
                          locationFilter.map((locationItem) => (
                            <option key={locationItem} value={locationItem}>
                              {locationItem}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="form-dashboard-group mb-4">
                      <label>Categorías:</label>
                      <select
                        id="selectCategory"
                        name="selectCategory"
                        onChange={handleSelectFilter}
                        value={selectedFilter.selectCategory}
                      >
                        <option value={0} defaultValue>
                          Todas
                        </option>
                        {categoryFilter &&
                          categoryFilter.map((categoryItem) => (
                            <option
                              key={categoryItem.categoryServiceId}
                              value={categoryItem.categoryServiceId}
                            >
                              {categoryItem.categoryName}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="form-dashboard-group mb-4">
                      <label>Subcategorías:</label>
                      <select
                        id="selectSubCategory"
                        name="selectSubCategory"
                        onChange={handleSelectFilter}
                        value={selectedFilter.selectSubCategory}
                      >
                        <option value={0} defaultValue>
                          Todas
                        </option>
                        {subCategoryFilter &&
                          subCategoryFilter.map((subCategoryItem) => (
                            <option
                              key={subCategoryItem.SubCategoryServiceId}
                              value={subCategoryItem.subCategoryServiceId}
                            >
                              {subCategoryItem.subCategoryName}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="form-dashboard-group ">
                      <label>Búsqueda:</label>
                      <input
                        type="text"
                        placeholder="Búsqueda"
                        name="txtFilter"
                        id="txtFilter"
                        onChange={handleSelectFilter}
                        value={selectedFilter.txtFilter}
                      ></input>
                    </div>
                  </div>
                </div>
                {isFiltered ? (
                  <Button
                    className="btn-outline-yellow btn_clean_filter"
                    onClick={cleanFilter}
                  >
                    Limpiar filtros
                  </Button>
                ) : (
                  ""
                )}
                <span
                  className={`caret_in_down trans ${
                    filterBlock ? "in_rotate" : ""
                  }`}
                  onClick={handlFilterBlock}
                >
                  <i className="fa-solid fa-chevron-down"></i>
                </span>
              </div>
            ) : (
              ""
            )}
          </aside>
        </Col>
        <Col xl={18} lg={16} md={24} sm={24} xs={24}>
          {isAdmin ? (
            <div className="flex-end" style={{ marginBottom: ".5rem", marginTop: '1rem' }}>
              <Button className="btn-green" onClick={fSectionOn}>
                {sectionOn && directoryInfoToAuthorize ? (
                  <p>
                    Por autorizar <span>{directoryInfoToAuthorize.length}</span>
                  </p>
                ) : (
                  <p>Autorizados</p>
                )}
                <FaChevronRight />
              </Button>
            </div>
          ) : (
            ""
          )}

          <div className="directory-container-wrapper">
            {sectionOn ? (
              <>
                {filteredDirectory.map((item) => (
                  <ItemDirectory
                    key={item.directoryInfoId}
                    directoryItem={item}
                    isAdmin={isAdmin}
                    sectionOn={sectionOn}
                    profileId={profile}
                  />
                ))}
              </>
            ) : isAdmin ? (
              <div className="wrapper_authorize">
                {directoryInfoToAuthorize.map((toAuthorize) => (
                  <ItemDirectory
                    directoryItem={toAuthorize}
                    isAdmin={isAdmin}
                    setdirectoryInfoToAuthorize={setdirectoryInfoToAuthorize}
                    convertToArray={convertToArray}
                    profileId={profile}
                    sectionOn={sectionOn}
                    updateListP={getPending}
                    updateListA={getListos}
                    noPost={dataCompany.NoPost}
                    noDaysGrace={dataCompany.NoDaysGrace}
                  />
                ))}
              </div>
            ) : (
              ""
            )}
          </div>
        </Col>
        <FooterHome />
      </Row>
    </section>
  );
}
