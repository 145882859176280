import React, { useEffect, useState } from "react";
import { Badge } from "reactstrap";
import { Link } from "react-router-dom";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "./../cardSecondaryHome/cardSecondaryHome.scss";
import { directoryInfoService } from "../../services/directoryService.service";

export function CardSecondaryHome() {
  const [filteredDirectory, setFilteredDirectory] = useState([]);
  const [showArrows, setShowArrows] = useState(true);

  const getDirectory = () => {
    directoryInfoService
      .getDirectoryLatest(process.env.REACT_APP_COMPANY)
      .then((data) => {
        if (data != null) {
          data.forEach((el) => (el.contactImage = el.contactImage.split(",")));
          setFilteredDirectory(data);
        }
      })
      .catch((message) => {
        console.log(`Hubo un error: ${message}`);
      });
  };

  useEffect(() => {
    getDirectory();
  }, []);

  useEffect(() => {
    filteredDirectory.map((item) => {
      if (item.contactImage.length === 1) setShowArrows(false);
      else setShowArrows(true);
      return true;
    });
  }, [filteredDirectory]);

  return (
    <>
      {filteredDirectory.map((item, i) => {
        return (
          <div
            key={i + i}
            className="itemcard_post_muro economia"
           
          >
            <h3 className="ttls_in_muro mb-1">
              <Link  to={`/directory`}><span>Economía Incluyente</span></Link>
              
            </h3>
            <div className="tag">
              <Badge className="badge t_g">Publicación reciente</Badge>
            </div>

            <div className="itemcard_content">
              <div className="itemcard_content_picture">
                <Splide
                  options={{
                    pagination: false,
                    arrows: showArrows,
                    autoplay: true,
                    rewind: true,
                    perMove: 1,
                    perPage: 1,
                    rewindByDrag: true,
                    speed: 900,
                    drag: true,
                  }}
                >
                  {item.contactImage.map((image, j) => {
                    return (
                      <>
                        <SplideSlide>
                          <div className="group-item">
                            <div
                              className="img-item-blur"
                              style={{
                                backgroundImage: `url(${image})`,
                              }}
                            ></div>
                            <img className={"img-item-thumb"} src={image} />
                          </div>
                        </SplideSlide>
                      </>
                    );
                  })}
                </Splide>
              </div>

              <div className="itemcard_content_info">
                <h6 className="itemcard_content_title">
                  <Link
                    to={`/details-directory/${item.directoryInfoId}`}
                  >
                    {" "}
                    {item.fullName.length <= 55
                      ? item.fullName
                      : `${item.fullName.substring(0, 55)}...`}
                  </Link>
                </h6>

                {item.directoryDescription ? (
                  <p className="itemitemcard_content_description">
                    {item.directoryDescription.length <= 60
                      ? item.directoryDescription
                      : `${item.directoryDescription.substring(0, 60)}...`}
                  </p>
                ) : (
                  <p className="item_part_p">No hay descripción disponible</p>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}
