import React  from 'react';
import { EditRewardCategory } from './editRewardCategory'
import {Header} from "../header";

export function DetailsRewardCategory({rewardCategoryId}) {

    const buttons = [
        {
            "id": 1,
            "nameButton": "Agregar proveedor",
            "link": "/operator/details-rewardProvider/0"
        }
    ];

    return (
        <div className="dashboard-operator-body">
            <Header
                title={(parseInt(rewardCategoryId) !== 0) ? "Editar categoría" : "Nueva categoría"}
                description=""
                buttons={buttons}
                btnBack={[
                    {
                        "id": 2,
                        "nameButton": "Regresar",
                        "link": "/operator/categories/1"
                    }
                ]}
            />
            <EditRewardCategory />
        </div>
    );
}   
