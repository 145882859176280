import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import AsidePurchase from "../../components/asidePurchase/asidePurchase";
import { cartHook } from "../../hooks/cart.hook";
import "./toolDiscount.scss";

export function ToolDiscount({ isCaringCommunity }) {
  const cartQuantity = useSelector((state) => state.cartQuantity);
  const [activeAside, setStateAside] = useState("");
  const [cart, setCart] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    cartHook.assembleCart(isCaringCommunity);
  }, []);

  const AsidePurchaseBox = () => {
    setStateAside(activeAside === "" ? " is-show" : "");

    if (activeAside === "") {
      const cpride = cartHook.getCart();
      setTotal(cpride.total);
      setCart(cpride.cart);
    }
  };

  return (
    <>
      <ul className="discount-btns">
       <li>
         <Link className="btn-square green" to={isCaringCommunity ? "/caring-community" : "/discount"}>
           <span><i className="icon-store"></i></span>
           <p>{isCaringCommunity ? "Fundaciones" : "Tiendas"}</p>
         </Link>
       </li>
        <li>
          {!isCaringCommunity && (
              <Link className="btn-square green" to="/shopping-history">
                  <span><i className="icon-docs-postuleds"></i></span>
                <p>Mis compras</p>
              </Link>
          )}
        </li>
        <li>
          <Link to="#" className="btn-square green" onClick={AsidePurchaseBox}>
            <span><i className="icon-cart"></i></span>
            <p>Carrito ({cartQuantity})</p>
          </Link>
        </li>
      </ul>
      <AsidePurchase
        activeAside={activeAside}
        onCloseClick={() => setStateAside("")}
        cart={cart}
        total={total}
        setCart={setCart}
        setTotal={setTotal}
        isCaringCommunity={isCaringCommunity}
      />
    </>
  );
}
