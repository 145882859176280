import React, { useEffect, useState } from 'react';
import { useSurveyScale } from '../../services/surveyScale.service';
import {FaSave} from "react-icons/fa";
import {Col, Form, Input, Row, Button} from "antd";

export function EditSurveyScaleAnswer(props) {
    const onSave = () => {
        if (answer.SurveyScaleAnswerId && answer.SurveyScaleAnswerId > 0)
            useSurveyScale.updateSurveyScaleAnswer({
                SurveyScaleAnswerId: parseInt(answer.SurveyScaleAnswerId),
                SurveyScaleId: parseInt(answer.SurveyScaleId),
                Description: answer.Description, ScaleValue: parseInt(answer.ScaleValue),
                AnswerOrder: parseInt(answer.AnswerOrder)
            }).then((result) => {
                if (props.onSave) props.onSave();
            });
        else
            useSurveyScale.createSurveyScaleAnswer({
                SurveyScaleId: parseInt(answer.SurveyScaleId),
                Description: answer.Description,
                ScaleValue: parseInt(answer.ScaleValue),
                AnswerOrder: parseInt(answer.AnswerOrder)
            }).then((result) => {
                if (props.onSave) props.onSave();
            });
    };

    const [answer, setAnswer] = useState({
        SurveyScaleAnswerId: "",
        SurveyScaleId: "",
        Description: "",
        ScaleValue: "",
        AnswerOrder: ""
    });

    useEffect(() => {
        if (props.currentAnswer) {
            setAnswer({
                SurveyScaleAnswerId: props.currentAnswer.SurveyScaleAnswerId.toString(),
                SurveyScaleId: props.currentAnswer.SurveyScaleId.toString(),
                Description: props.currentAnswer.Description,
                ScaleValue: props.currentAnswer.ScaleValue.toString(),
                AnswerOrder: props.currentAnswer.AnswerOrder.toString()
            });
        }
        else
            setAnswer({
                SurveyScaleAnswerId: "",
                SurveyScaleId: props.surveyScaleId,
                Description: "", ScaleValue: "",
                AnswerOrder: ""
            });
    }, [props.currentQuestion]);

    const handleInputValue = (e) => setAnswer({...answer, [e.target.name]: e.target.value})

    return (
        <>
            <Form
                onFinish={onSave}
                className={'form-backoffice'}
                layout={'vertical'}
            >
                <Row gutter={16}>
                    <Col xs={24}>
                        <Form.Item
                            label={'Respuesta'}
                            htmlFor={'Description'}
                            name="Description"
                            rules={[
                                {
                                    required: true,
                                    message: '*Campo requerido'
                                }
                            ]}
                        >
                            <Input
                                type="text"
                                name="Description"
                                onChange={handleInputValue}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item
                            label={'Orden'}
                            htmlFor={'AnswerOrder'}
                            name="AnswerOrder"
                            rules={[
                                {
                                    required: true,
                                    message: '*Campo requerido'
                                }
                            ]}
                        >
                            <Input
                                type="number"
                                name="AnswerOrder"
                                onChange={handleInputValue}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item
                            label={'Valor'}
                            htmlFor={'ScaleValue'}
                            name="ScaleValue"
                            rules={[
                                {
                                    required: true,
                                    message: '*Campo requerido'
                                }
                            ]}
                        >
                            <Input
                                type="number"
                                name="ScaleValue"
                                onChange={handleInputValue}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <div className="form-backoffice-footer">
                            <Button type={'primary'} htmlType={'submit'}>
                                <p>Guardar</p>
                                <FaSave/>
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </>

    );
}
