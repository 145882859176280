import { useEffect, useState } from "react";

export default function useWindowViewport() {
    const [windowWidth, setWindowWidth] = useState(0);
    const [windowHeight, setWindowHeight] = useState(0);
    useEffect(() => {
        function updateWindowViewport() {
            setWindowWidth(window.innerWidth);
            setWindowHeight(window.innerHeight);
        }
        window.addEventListener("resize", updateWindowViewport);
        updateWindowViewport();
        return () => window.removeEventListener("resize", updateWindowViewport);
    }, []);
    return { windowWidth, windowHeight };
}
