import React, { useState } from 'react';
import { AdminReward } from './adminReward'
import { AdminRewardCategory } from './adminRewardCategory'
import { AdminRewardProvider } from './adminRewardProvider'
import {Header} from "../header";
import {Tabs} from "antd";

export function ControlReward(props) {
	const [activeTab, setActiveTab] = useState(props.match.params.tap);

	const toggle = tab => {
		if (activeTab !== tab) setActiveTab(tab);
	}

	const buttons = [
		{
			"id": 1,
			"nameButton": "Agregar recompensa",
			"link": "/operator/details-reward/0"
		}
	];

	const onChange = (key) => {
		console.log(key);
	};

	const items = [
		{
			key: '1',
			label: `Productos`,
			children: <AdminReward />,
		},
		{
			key: '2',
			label: `Proveedores`,
			children: <AdminRewardProvider />,
		},
		{
			key: '3',
			label: `Categorías`,
			children: <AdminRewardCategory />,
		},
	];

	return (
		<section className="dashboard-operator-body">
			<Header
				title="Control Recompensas"
				description=""
				buttons={buttons}
			/>
			<Tabs defaultActiveKey="1" items={items} onChange={onChange} />
		</section>
	)
}
