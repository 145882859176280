import { callApi, callApiFileStorage } from "./service";

export const useGenericSection = {
    getGenericItemByCompanyAndSection,
    getGenericItemByCompany,
    getGenericItemSectionsByCompany,
    getGenericItemContactTypes,
    createGenericSectionItem,
    getGenericItemImageById,
    deleteContactInfo,
    deleteItem,
    createContactInfos,
    updateGenericSectionItem
};

async function getGenericItemByCompanyAndSection(sectionId, id = 0, elements = 10) {
    return callApi(`/backoffice/GetGenericSectionItemByCompanyAndSection?companyId=${process.env.REACT_APP_COMPANY}&genericSectionTypeId=${sectionId}&id=${id}&elements=${elements}`, "GET")
        .then((result) => {
            return handleResponse(result);
        });
}

async function getGenericItemImageById(genericItemId) {
    return callApi('/backoffice/GenericSectionItem/WithImages/' + genericItemId, 'GET').then((result) => handleResponse(result));
}

async function createGenericSectionItem(genericItem, files) {
    var data = new FormData();
    if (files) {
        for (var i = 0; i < files.length; i++) {
            var f = files[i];
            data.append('files', f, f.name);
        }
    }

    data.append("model", JSON.stringify(genericItem));
    return callApiFileStorage(`/backoffice/GenericSectionItem`, 'POST', data, false).then((result) => {
        return handleResponse(result);
    });
}

async function updateGenericSectionItem(genericItem, files) {
    const data = new FormData();
    if (files) {
        for (let i = 0; i < files.length; i++) {
            const f = files[i];
            data.append('files', f, f.name);
        }
    }

    data.append("model", JSON.stringify(genericItem));
    return callApiFileStorage(`/backoffice/GenericSectionItem`, 'PUT', data, false).then((result) => {
        return handleResponse(result);
    });
}

async function createContactInfos(contactInfos) {
    return callApi(`/backoffice/GenericSectionItem/ContactInfo`, 'POST', contactInfos).then((result) => {
        return handleResponse(result);
    });
}

async function getGenericItemByCompany(pageIndex, elements, sectionId = 0) {
    return callApi(`/backoffice/GetGenericSectionItemByCompany?companyId=${process.env.REACT_APP_COMPANY}&pageIndex=${pageIndex}&elements=${elements}&sectionId=${sectionId}`, "GET")
        .then((result) => {
            return handleResponse(result);
        });
}

async function getGenericItemSectionsByCompany() {
    return callApi(`/backoffice/GetGenericItemSections?companyId=${process.env.REACT_APP_COMPANY}`, "GET")
        .then((result) => {
            return handleResponse(result);
        });
}

async function getGenericItemContactTypes() {
    return callApi(`/backoffice/GetGenericItemContactTypes`, "GET")
        .then((result) => {
            return handleResponse(result);
        });
}

async function deleteItem(id) {
    return callApi(`/backoffice/GenericSectionItem/${id}`, "DELETE")
        .then((result) => {
            return handleResponse(result);
        });
}

async function deleteContactInfo(id) {
    return callApi(`/backoffice/GenericSectionItem/ContactInfo/${id}`, "DELETE")
        .then((result) => {
            return handleResponse(result);
        });
}

function handleResponse(result) {
    if (result.responseCode === "00") return result.data;
    else {
        alert(result.responseMessage);
        return null;
    }
}
