import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { JobBankService } from "../../services/jobBank.service";
import "./jobBank.scss";
import { Col, Form, Input, Row, Button, Select } from "antd";

import bannerBolsaTrabajo from "../../assets/imgs/banner-bolsatrabajo.png";
import { FaSearch } from "react-icons/fa";
import { AsideCategoriesJobs } from "./asideCategoriesJobs";

export function JobBankHeader({ title, setFilters, filters, searchPage }) {
  const roleName = useSelector((state) => state.userSettings.roleName);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    GetJobsToApprove();
  }, []);

  const GetJobsToApprove = () => {
    JobBankService.GetJobsToApprove(1, 8).then((result) => {
      if (!result) return;
      setTotalCount(result.totalCount);
    });
  };

  const onChangeLocation = (e) =>
    setFilters({ ...filters, locationFilter: e.target.value });

  const onChangePosition = (e) =>
    setFilters({ ...filters, occupationFilter: e.target.value });

  return (
    <Row gutter={8}>
      <Col xs={24}>
        <h2>{title}</h2>
      </Col>
      <Col xl={12} lg={14} md={12} sm={24} xs={24}>
        <Row>
          <Col xs={24}>
            <AsideCategoriesJobs />
          </Col>
          <Col md={{ span: 0 }} sm={{ span: 24 }} xs={24}>
            <article className="job-bank-header">
              <div className="job-bank-header-thumb">
                <img
                  className="drawer__bg__img"
                  src={bannerBolsaTrabajo}
                  alt="Ser parte de la comunidad PrideMx te da estos beneficios"
                />
              </div>
            </article>
          </Col>
          <Col xs={24}>
            <article className="job-bank-header">
              <div className="job-bank-header-info">
                <h4>
                  Te ayudamos a encontrar el empleo ideal para ti de forma
                  rápida
                </h4>
                <Form layout="vertical" className="form-pride">
                  <Row gutter={16}>
                    <Col lg={9} md={24} sm={24} xs={24}>
                      <Form.Item label="Ubicación" onChange={onChangeLocation}>
                        <Input
                          type="text"
                          name="location"
                          prefix={<i className="icon-location"></i>}
                          size="large"
                          placeholder="Ubicación"
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={9} md={24} sm={24} xs={24}>
                      <Form.Item label="Puesto" onChange={onChangePosition}>
                        <Input
                          type="text"
                          size="large"
                          name="position"
                          prefix={<i className="icon-jobs"></i>}
                          placeholder="Puesto, profesión, cargo, etc."
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={6} md={24} sm={24} xs={24}>
                      <div className="footer-btn">
                        <Button
                          size="large"
                          htmlType="submit"
                          className="btn-searcher"
                          onClick={() => searchPage()}
                        >
                          Buscar <FaSearch />
                        </Button>
                      </div>
                    </Col>
                    <Col lg={9} md={24} sm={24} xs={24}>
                      <Form.Item label="Ordenar por" className="job__select">
                        <Select
                          size="large"
                          onChange={(value) => searchPage(1, value)}
                        >
                          <Select.Option value="Fecha">Fecha</Select.Option>
                          <Select.Option value="Salario">Salario</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
            </article>
          </Col>
        </Row>
      </Col>
      <Col xl={12} lg={10} md={12} sm={{ span: 0 }} xs={{ span: 0 }}>
        <article className="job-bank-header">
          <div className="job-bank-header-thumb">
            <img
              className="drawer__bg__img"
              src={bannerBolsaTrabajo}
              alt="Ser parte de la comunidad PrideMx te da estos beneficios"
            />
          </div>
        </article>
        <div className="job-bank-btns">
          <Row gutter={8}>
            {roleName === "Recursos Humanos" && (
              <Col lg={8} md={12} sm={12} xs={24}>
                <Link className="btn-gradient-green" to="/list-new-jobs">
                  <span>
                    {totalCount !== 0 ? (
                      <span style={{ marginRight: ".5rem" }}>{totalCount}</span>
                    ) : (
                      <span style={{ marginRight: ".5rem" }}>0</span>
                    )}
                    por autorizar
                  </span>
                  <span className="min">Administrador</span>
                </Link>
              </Col>
            )}
            <Col lg={roleName === "Recursos Humanos" ? 8 : 12} md={12}>
              <Link className="btn-gradient-green" to="/jobs-published">
                <span>Mis empleos</span>
                <span className="min">Empresas</span>
              </Link>
            </Col>
            <Col
              lg={roleName === "Recursos Humanos" ? 8 : 12}
              md={roleName === "Recursos Humanos" ? 24 : 12}
            >
              <div className="d-flex-center" style={{ height: "100%" }}>
                <Link className="btn--default" to="/register-job">
                  Publicar empleo 
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Col>
      <Col md={{ span: 0 }} sm={24} xs={24}>
        <div className="job-bank-btns">
          <Row gutter={8}>
            {roleName === "Recursos Humanos" && (
              <Col sm={12} xs={24}>
                <Link className="btn-gradient-green" to="/list-new-jobs">
                  <span>
                    {totalCount !== 0 ? (
                      <span style={{ marginRight: ".5rem" }}>{totalCount}</span>
                    ) : (
                      <span style={{ marginRight: ".5rem" }}>0</span>
                    )}
                    por autorizar
                  </span>
                  <span className="min">Administrador</span>
                </Link>
              </Col>
            )}
            <Col sm={12} xs={24}>
              <Link className="btn-gradient-green" to="/jobs-published">
                <span>Mis empleos</span>
                <span className="min">Empresas</span>
              </Link>
            </Col>
            <Col sm={roleName === "Recursos Humanos" ? 24 : 12} xs={24}>
              <div className="d-flex-center" style={{ height: "100%" }}>
                <Link className="btn-outline-dark" to="/register-job">
                  Publicar empleo
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
}
