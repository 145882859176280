import React from "react";
import {ItemMorePostSecondary} from "./itemMorePostSecondary";

export function AsideBlogCompanySecondary({setCurrentFilter,filters}) {

    return (
      <div className="publications-list">
          {filters.map((item,i) => (
              <ItemMorePostSecondary
                  post={item}
                  setCurrentFilter={setCurrentFilter}
                  key={i}
              />
          ))}
      </div>
    );
}
