import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AsideCategoriesJobs } from "./asideCategoriesJobs";
import { JobBankHeader } from "./jobBankHeader";
import { Link, useParams } from "react-router-dom";
import { JobBankService } from "../../services/jobBank.service";
import "./jobBank.scss";
import { AiOutlineFileText } from "react-icons/ai";
import { postulanteService } from "../../services/postulantJob.service";
import Moment from "react-moment";
import Swal from "sweetalert2";
import {FaCheck, FaChevronLeft, FaTimes} from "react-icons/fa";
import { Button } from "rsuite";
import { Col, Row, Table, Space } from "antd";
import { FooterHome } from "../../components/footer/footerHome";

export function CandidatesNominated() {
  const history = useHistory();
  let { id } = useParams();
  const [job, setJob] = useState({});
  const [candidates, setCandidates] = useState([]);

  useEffect(() => {
    JobBankService.getJobById(id).then((result) => {
      setJob(result);
    });

    postulanteService.getPostulants(id).then((result) => {
      setCandidates(result);
    });
  }, []);

  const approvePostulant = (postulantId, companyName, fullName) => {
    Swal.fire({
      text: "¿Desea seleccionar a " + fullName + "?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.value) {
        postulanteService
          .approvePostulant(postulantId, companyName)
          .then((result) => {
            Swal.fire({
              text: "Postulante aprobado",
              icon: "success",
              allowOutsideClick: false,
            });
            postulanteService.getPostulants(id).then((result) => {
              setCandidates(result);
            });
          });
      }
    });
  };

  const rejectPostulant = (postulantId, companyName, fullName) => {
    Swal.fire({
      text: "¿Desea rechazar a " + fullName + "?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.value) {
        postulanteService
          .rejectPostulant(postulantId, companyName)
          .then((result) => {
            Swal.fire({
              text: "Postulante rechazado",
              icon: "success",
              allowOutsideClick: false,
            });
            postulanteService.getPostulants(id).then((result) => {
              setCandidates(result);
            });
          });
      }
    });
  };

  const columns = [
    {
        title: '#',
        dataIndex: 'id',
        key: 'id',
        fixed: 'left',
        width: 80
    },
    {
        title: 'Nombre completo',
        dataIndex: 'fullName',
        key: 'fullName',
    },
    {
        title: 'Fecha de postulación',
        dataIndex: 'postulationDate',
        key: 'postulationDate',
    },
    {
        title: 'Adjuntos',
        dataIndex: 'urlCurriculum',
        key: 'urlCurriculum',
    },
    {
        title: 'Estatus',
        dataIndex: 'status',
        key: 'status',
    },
    {
        title: 'Acciones',
        dataIndex: 'options',
        key: 'options',
    },
];

const dataSource = candidates?.map((item,i) => {
    return {
        key: i + 1,
        id: i + 1,
        fullName: item.fullName,
        postulationDate: <Moment locale="es" format="DD/MMMM/YYYY">
          {item.postulationDate}
        </Moment>,
        urlCurriculum: <a
          href={item.urlCurriculum}
          target="_blank"
          rel="noopener noreferrer"
          download={
            item.urlCurriculum !== "" ? true : false
          }
        >
          <AiOutlineFileText></AiOutlineFileText>
        </a>,
        status: item.status,
        options: <><Space>
          {item.status === "EnProceso" && (
            <>
              <Button
                className="btn-icon-table edit"
                title="Aprobar"
                onClick={() =>
                  approvePostulant(
                    item.postulantId,
                    job.companyName,
                    item.fullName
                  )
                }
              >
                <FaCheck />
              </Button>
              <Button
                className="btn-icon-table delete"
                title="Rechazar"
                onClick={() =>
                  rejectPostulant(
                    item.postulantId,
                    job.companyName,
                    item.fullName
                  )
                }
              >
                <FaTimes />
              </Button>
            </>
            )}
        </Space>
        </>
    };
})

  return (
    <section className="dashboard-container-page">
      <JobBankHeader tituloInJobs="Bolsa de trabajo" />
      <section className="contain_gral_jobbank">
        <>
          <Row>
            <Col xs={24}>
              <div className="admin-heading">
                <div></div>
                <Button onClick={() => history.goBack()} className="btn-back">
                  <FaChevronLeft />
                  <p>Regresar</p>
                </Button>
              </div>

              <div className="box_items_jobs">

                <article className="item_card_jobs card_shadows" style={{boxShadow: 'none'}}>
                  <div className="card_info_jobs">
                    <h5 className="text fnt_medium name_vacant">{job.title}</h5>
                    <div className="name_business">
                      <ion-icon name="business-outline"></ion-icon>{" "}
                      {job.companyName}
                    </div>
                    <div className="city_jobs mb-3">
                      <ion-icon name="location"></ion-icon> {job.location}
                    </div>
                    <div className="publication_date">
                      Publicado{" "}
                      <Moment locale="es" format="DD [de] MMMM YYYY">
                        {job.publicationDate}
                      </Moment>
                    </div>
                  </div>
                  <div className="card_options">
                    <img
                      src={job.companyImage}
                      alt={"Logo empresa " + job.companyName}
                    />
                    {job.salary > 0 && (
                      <div className="box_salary mt-4">
                        <p className="text-muted m-0">Oferta salarial</p>
                        <div className="monto_salary text fnt_medium">
                          ${job.salary} MNX
                        </div>
                      </div>
                    )}
                  </div>
                </article>

                <div className="card mt-4 p-3">
                  <h5 className="text fnt_medium mb-4">Lista de candidatos</h5>
                  <Table className="table-admin" scroll={{ x: '992px', y: 800 }} columns={columns} dataSource={dataSource}
                         locale={{
                             triggerDesc: 'Ordenamiento descendente',
                             triggerAsc: 'Ordenamiento ascedente',
                             cancelSort: 'Cancelar ordenamiento',
                             emptyText: 'Sin registros',
                         }}
                  />
                </div>
              </div>

            </Col>
            <Col xs={24}>
                <FooterHome />
            </Col>
          </Row>
        </>

        {/* <ApplicantForm /> */}
      </section>
    </section>
  );
}
