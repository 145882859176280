import React, { useState } from 'react';
import { SearchProductFilter } from './searchProductFilter';

import './rewardProductFilter.scss'
import {useHistory} from 'react-router-dom';
import {TitleAdmin} from "../components/titleAdmin/titleAdmin";
import {HeadingAdmin} from "../components/headingAdmin/headingAdmin";

export function AdminProductFilter() {
    const [refresh, setRefresh] = useState(0);
    const history = useHistory();

    return (
        <section className="dashboard-admin-container">
            <TitleAdmin
                title={'Administración de Cupones'}
                subtitle={'Listado de cupones'}
                event={() => history.push("/admin")}
            />
            <HeadingAdmin
                text={'Agregar filtro'}
                event={() => history.push("/admin/detail-product-filter/0")}
            />
            <SearchProductFilter refresh={refresh} />
        </section>
    );
}
