import React, { useState, useEffect } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorCommentsBox } from "./editorCommentsBox";
import Moment from "react-moment";
import "moment/locale/es";
import { Button } from "antd";

export function ItemsComment(props) {
  const [data, setData] = useState({});

  const [showAnswerComment, setShowAnswerComment] = useState("");
  const [showParentAnswer, setShowParentAnswer] = useState("hidden");
  const handleAnswerComment = () => {
    setShowParentAnswer(showAnswerComment === "" ? "" : "hidden");
    setShowAnswerComment(showAnswerComment === "" ? "active" : "");
  };

  useEffect(() => {
    if (props.contenido) {
      setData(props.contenido);
      setShowAnswerComment("");
    }
  }, [props]);

  const SubComment = ({ dataArray }) => {
    return (
      <>
        <div className="box_items_sub_comment">
          {dataArray.map((item) => (
            <div className="items_sub_comment">
              <div className="user_avatar_comment">
                <div
                  className={" rotating " + item.userProfile.identityFlagName}
                ></div>
                <div className="avatar">
                  <img src={item.userProfile.urlProfileImage} alt="PMX" />
                </div>
              </div>
              <div className="user_comment_mns">
                <div className="comment_mns">
                  <p className="author_name">
                    {item.userProfile && item.userProfile.nickname
                      ? item.userProfile.nickname
                      : item.userProfile
                      ? item.userProfile.firstName +
                        (item.userProfile.middleName
                          ? " " + item.userProfile.middleName
                          : "") +
                        " " +
                        item.userProfile.lastName
                      : ""}
                  </p>
                  <p className="user_mns">{item.comment}</p>
                </div>
                <div className="comment_tool_options">
                  <span className="date">
                    <Moment locale="es" format=" DD MMMM YYYY, h:mm:ss a">
                      {item && item.createDate}
                    </Moment>
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className={`new_comment_answer ${showAnswerComment}`}>
          <EditorCommentsBox
            profileUser={props.profileUser}
            clickSave={props.clickSave}
            id={data.companyPostCommentId}
          />
        </div>
      </>
    );
  };

  return (
    <article>
      <div className="item_initial_comment">
        <div className="user_avatar_comment">
          <div
            className={
              "rotating " +
              (data.userProfile ? data.userProfile.identityFlagName : "")
            }
          ></div>
          <div className="avatar">
            <img
              src={data.userProfile ? data.userProfile.urlProfileImage : ""}
              alt="PMX"
            />
          </div>
        </div>
        <div className="user_comment_mns">
          <div className="comment_mns">
            <p className="author_name">
              {data.userProfile && data.userProfile.nickname
                ? data.userProfile.nickname
                : data.userProfile
                ? data.userProfile.firstName +
                  (data.userProfile.middleName
                    ? " " + data.userProfile.middleName
                    : "") +
                  " " +
                  data.userProfile.lastName
                : ""}
            </p>
            <p className="user_mns">{data ? data.history : ""} </p>
          </div>

          <div className="comment_tool_options">
            {/* {props.btnBack ? ( */}
            <Button
              className="btn_answer_comment"
              size="small"
              onClick={handleAnswerComment}
            >
              Responder
            </Button>
            {/* // ) : (
            //   ""
            // )} */}

            <span className="date">
              <Moment locale="es" format=" DD MMMM YYYY, h:mm:ss a">
                {data && data.createDate}
              </Moment>
            </span>
            {data.isReport ? (
              <div className="btnx-group no-block">
                <span
                  className="dropdown-toggle"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="fas fa-ellipsis-v"></i>
                </span>

                <div className="dropdown-menu">
                  {data.isReport ? (
                    <span
                      className="dropdown-item"
                      onClick={() => props.eliminar(data.companyPostCommentId)}
                    >
                      Eliminar Mensaje
                    </span>
                  ) : (
                    <span
                      className="dropdown-item"
                      onClick={() => props.report(data.companyPostCommentId)}
                    >
                      Reportar
                    </span>
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {data.comments ? <SubComment dataArray={data.comments} /> : ""}
    </article>
  );
}
