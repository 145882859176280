import swal from "sweetalert";
import { callApi } from "./service";

export const useSurveyScale = {
    getSurveyScales,
    deleteSurveyScale,
    getSurveyScaleById,
    deleteSurveyScaleAnswer,
    getSurveyScaleAnswers,
    createSurveyScaleAnswer,
    updateSurveyScaleAnswer,
    createSurveyScale,
    updateSurveyScale,
    getScaleAnswers
}

async function createSurveyScale(params) {
    return callApi('/backoffice/SurveyScale', 'POST', params).then((result) => {
        if (result.responseCode === '00') {
            swal({ text: "Escala creada correctamente", icon: "success" })
            return result.data;
        } else {
            console.log(result.responseMessage);
            swal({ text: "Error al crear escala", icon: "error" });
            return null;
        }
    });
}

async function updateSurveyScale(params) {
    return callApi('/backoffice/SurveyScale', 'PUT', params).then((result) => {
        if (result.responseCode === '00') {
            swal({ text: "Escala actualizada correctamente", icon: "success" })
            return result.data;
        } else {
            console.log(result.responseMessage);
            swal({ text: "Error al actualizar escala", icon: "error" });
            return null;
        }
    });
}

async function getSurveyScales() {
    return callApi('/backoffice/SurveyScale', 'GET').then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            alert(result.responseMessage);
            return null;
        }
    });
}

async function getSurveyScaleAnswers(scaleId) {
    return callApi(`/backoffice/SurveyScaleAnswer/${scaleId}`, 'GET').then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            alert(result.responseMessage);
            return null;
        }
    });
}

async function getSurveyScaleById(id) {
    return callApi(`/backoffice/SurveyScale/${id}`, 'GET').then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            swal({ text: result.responseMessage, icon: "error" }).then((value) => {
                window.location.href = '/AdminSurveyScale'
            });
            return null;
        }
    });
}

async function deleteSurveyScale(params) {
    return callApi('/backoffice/SurveyScale', 'DELETE', params).then((result) => {
        if (result.responseCode === '00') {
            swal({ text: "Escala desactivada correctamente", icon: "success" })
            return result.data;
        } else {
            console.log(result.responseMessage);
            swal({ text: "Error al desactivar escala", icon: "error" });
            return null;
        }
    });
}

async function deleteSurveyScaleAnswer(params) {
    return callApi('/backoffice/SurveyScaleAnswer', 'DELETE', params).then((result) => {
        if (result.responseCode === '00') {
            swal({ text: "Respuesta desactivada correctamente", icon: "success" })
            return result.data;
        } else {
            console.log(result.responseMessage);
            swal({ text: "Error al desactivar respuesta", icon: "error" });
            return null;
        }
    });
}

async function createSurveyScaleAnswer(params) {
    return callApi('/backoffice/SurveyScaleAnswer', 'POST', params).then((result) => {
        if (result.responseCode === '00') {
            swal({ text: "Respuesta creada correctamente", icon: "success" })
            return result.data;
        } else {
            console.log(result.responseMessage);
            swal({ text: "Error al crear respuesta", icon: "error" });
            return null;
        }
    });
}

async function updateSurveyScaleAnswer(params) {
    return callApi('/backoffice/SurveyScaleAnswer', 'PUT', params).then((result) => {
        if (result.responseCode === '00') {
            swal({ text: "Respuesta actualizada correctamente", icon: "success" })
            return result.data;
        } else {
            console.log(result.responseMessage);
            swal({ text: "Error al actualizar respuesta", icon: "error" });
            return null;
        }
    });
}

async function getScaleAnswers(scaleId) {
    return callApi(`/backoffice/SurveyScaleAnswer/${scaleId}`, 'GET').then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            alert(result.responseMessage);
            return null;
        }
    });
}