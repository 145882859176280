import React, { useEffect, useState } from 'react';
import { useAnswerConfig } from '../../services/surveyAnswerConfig.service'
import {Button, Col, Form, Input, Row, Select} from 'antd';
import {FaSave} from "react-icons/fa";
import {Checkbox} from "../components/checkbox/checkbox";
import Swal from "sweetalert2";

export function EditAnswer(props) {
    const [answer, setAnswer] = useState({
        SurveyAnswerConfigId: 0,
        SurveyQuestionId: parseInt(props.surveyQuestionId),
        AnswerOrder: 0,
        Answer: "",
        IsRight: false,
        IsImage: false,
        StepAnswer: 0
    });
    const [stepOptions, setStepOptions] = useState([]);

    const onSaveAnswer = () => {
        if (answer.SurveyAnswerConfigId > 0)
            useAnswerConfig.updateAnswerConfig({
                SurveyAnswerConfigId: answer.SurveyAnswerConfigId,
                SurveyQuestionId: answer.SurveyQuestionId,
                AnswerOrder: parseInt(answer.AnswerOrder),
                Answer: answer.Answer,
                IsRight: answer.IsRight,
                IsImage: answer.IsImage,
                StepAnswer: answer.StepAnswer
            }).then((result) => {
                if (props.onSave) {
                    props.onSave();
                    Swal.fire({
                        icon: "success",
                        confirmButtonColor: "#80d96b",
                        title: "La respuesta se actualizó correctamente",
                        showConfirmButton: false,
                        timer: 1500
                    });
                }
            });
        else
            useAnswerConfig.createAnswerConfig({
                SurveyQuestionId: parseInt(props.surveyQuestionId),
                AnswerOrder: answer.AnswerOrder,
                Answer: answer.Answer,
                IsRight: answer.IsRight,
                IsImage: answer.IsImage,
                StepAnswer: answer.StepAnswer
            }).then((result) => {
                if (props.onSave) {
                    props.onSave();
                    Swal.fire({
                        icon: "success",
                        confirmButtonColor: "#80d96b",
                        title: "La respuesta se creó correctamente",
                        showConfirmButton: false,
                        timer: 1500
                    });
                }
            });

        setAnswer({
            SurveyAnswerConfigId: 0,
            SurveyQuestionId: parseInt(props.surveyQuestionId),
            AnswerOrder: 0,
            Answer: "",
            IsRight: false,
            IsImage: false,
            StepAnswer: 0
        })
    };

    useEffect(() => {
        if ((props.currentAnswer)) {
            setAnswer({
                SurveyAnswerConfigId: props.currentAnswer.SurveyAnswerConfigId,
                SurveyQuestionId: props.currentAnswer.SurveyQuestionId,
                AnswerOrder: props.currentAnswer.AnswerOrder,
                Answer: props.currentAnswer.Answer,
                IsRight: props.currentAnswer.IsRight,
                IsImage: props.currentAnswer.IsImage,
                StepAnswer: props.currentAnswer.StepAnswer
            });
        }
    }, [props.currentAnswer]);

    useEffect(() => {
        const filterData = props.surveyQuestions.filter(item => item.surveyQuestionId > props.surveyQuestionId);
        setStepOptions(filterData)
    }, [props.surveyQuestionId]);

    const handleSelectState = (value) => setAnswer({ ...answer, StepAnswer: value });

    const handleCheck = (value) => setAnswer({...answer, IsImage: value});

    const handleInputChange = (e) => setAnswer({...answer, [e.target.name]: e.target.value});

    return (
        <Col xs={24}>
            <Form
                onFinish={onSaveAnswer}
                className="form-backoffice"
                layout={'vertical'}
                fields={[
                    {
                        "name": ["AnswerOrder"],
                        "value": answer.AnswerOrder
                    },
                    {
                        "name": ["Answer"],
                        "value": answer.Answer
                    },
                    {
                        "name": ["StepAnswer"],
                        "value": answer.StepAnswer
                    },
                ]}
            >
                <Row gutter={16}>
                    <Col md={12} sm={24} xs={24}>
                        <Form.Item
                            label={'Orden'}
                            htmlFor={'AnswerOrder'}
                            rules={[
                                {
                                    required: true,
                                    message: '*Campo requerido'
                                }
                            ]}
                            name="AnswerOrder"
                        >
                            <Input
                                type="number"
                                name="AnswerOrder"
                                min="1"
                                onChange={handleInputChange}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={12} sm={24} xs={24}>
                        <Form.Item
                            label={'Respuesta'}
                            htmlFor={'Answer'}
                            rules={[
                                {
                                    required: true,
                                    message: '*Campo requerido'
                                }
                            ]}
                            name="Answer"
                        >
                            <Input
                                type="text"
                                name="Answer"
                                onChange={handleInputChange}
                            />
                        </Form.Item>
                    </Col>
                        <Col xs={24}>
                            <Form.Item
                                label={'¿La respuesta es solo una imagen?'}
                                htmlFor={'StepAnswer'}
                                name={answer.IsImage}
                            >
                                <Checkbox
                                    text={'¿Es imagen?'}
                                    label={'IsImage'}
                                    name={answer.IsImage}
                                    handleValueCheck={handleCheck}
                                />
                            </Form.Item>
                        </Col>
                        {
                            props.isMultipleOption && <>
                                {
                                    stepOptions.length > 0 &&
                                    <Col md={16} sm={24} xs={24}>
                                        <Form.Item
                                            label={'Salto'}
                                            htmlFor={'StepAnswer'}
                                            name={'StepAnswer'}
                                        >
                                            <Select
                                                name="StepAnswer"
                                                placeholder="Seleccione una opción"
                                                onChange={handleSelectState}
                                            >
                                                {
                                                    stepOptions.map(item =>
                                                        <Select.Option key={item.surveyQuestionId} value={item.surveyQuestionId}> {item.question}</Select.Option>
                                                    )
                                                }
                                                <Select.Option value={-1}>[Finalizar Encuesta]</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                }
                            </>
                        }
                    <Col xs={24}>
                        <div className="form-backoffice-footer">
                            <Button type={'primary'} htmlType="submit">
                                <p>Guardar</p>
                                <FaSave />
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Col>
    );
}
