import React, { useEffect, useState } from "react";
import { useJobRole } from "../../../services/jobRole.service";
import { FaSave } from "react-icons/fa";
import { Button, Col, Form, Input, Row } from "antd";
import Swal from "sweetalert2";

export const EditJobRole = (props) => {
  const [survey, setSurvey] = useState({
    JobRoleId: 0,
    Description: "",
    Name: "",
  });

  const onSave = () => {
    if (survey.JobRoleId && survey.JobRoleId > 0)
      useJobRole
        .updateJobRole({
          JobRoleId: survey.JobRoleId,
          Name: survey.Name,
          Description: survey.Description,
        })
        .then((result) => {
          if (props.onSave) props.onSave();
          Swal.fire({
            text: "Se ha actualizado exitosamente una nueva categoría",
            icon: "success",
            showCloseButton: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
        });
    else
      useJobRole
        .createJobRole({
          Name: survey.Name,
          Description: survey.Description,
        })
        .then((result) => {
          if (props.onSave) props.onSave();
          Swal.fire({
            text: "Se ha creado exitosamente una nueva categoría",
            icon: "success",
            showCloseButton: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
        });
  };

  const handleInputChange = (e) =>
    setSurvey({ ...survey, [e.target.name]: e.target.value });

  useEffect(() => {
    if (props.currentJobRole) {
      setSurvey({
        JobRoleId: parseInt(props.currentJobRole.JobRoleId),
        Description: props.currentJobRole.Description,
        Name: props.currentJobRole.Name,
      });
    } else {
      setSurvey({
        JobRoleId: 0,
        Description: "",
        Name: "",
      });
    }
  }, [props.currentJobRole]);

  return (
    <Form
      onFinish={onSave}
      layout={"vertical"}
      className={"form-backoffice"}
      fields={[
        {
          name: ["Name"],
          value: survey.Name,
        },
        {
          name: ["Description"],
          value: survey.Description,
        },
      ]}
    >
      <h3>{survey.JobRoleId === 0 ? "Nueva categoría" : "Editar categoría"}</h3>

      <Row gutter={16}>
        <Col md={12} sm={24}>
          <Form.Item
            label={"Nombre"}
            name="Name"
            htmlFor={"Name"}
            rules={[
              {
                required: true,
                message: "*Campo Requerido",
              },
              {
                pattern: new RegExp(/^\S/),
                message: "No se aceptan espacios en blanco al inicio",
              },
            ]}
          >
            <Input type="text" name="Name" onChange={handleInputChange} />
          </Form.Item>
        </Col>
        <Col md={12} sm={24}>
          <Form.Item
            label={"Descripción"}
            name="Description"
            htmlFor={"Description"}
            rules={[
              {
                required: true,
                message: "*Campo Requerido",
              },
              {
                pattern: new RegExp(/^\S/),
                message: "No se aceptan espacios en blanco al inicio",
              },
            ]}
          >
            <Input
              type="text"
              name="Description"
              onChange={handleInputChange}
            />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <div className="form-backoffice-footer">
            <Button htmlType={"submit"} type="primary">
              <p>Guardar</p>
              <FaSave />
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
