import React, { useEffect, useState } from 'react';

import { Button } from "rsuite";
import { useSurveyScale } from '../../services/surveyScale.service';
import {Space,Table} from "antd";
import {FaTrash} from "react-icons/fa";
import MySwal from "sweetalert2";

export function SearchSurveyScaleAnswer(props) {
    const [surveyScaleAnswers, setSurveyScaleAnswers] = useState([]);
    const handleDelete = (data) => {
        MySwal.fire({
            title:"¿Estás seguro?",
            text: "No podrás revertir esta acción",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#FF002A',
            confirmButtonText: 'Eliminar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if(result.isConfirmed) useSurveyScale.deleteSurveyScaleAnswer(data).then((result) => getSurveyScaleAnswers(props.surveyScale));
        })
    };
    const handleSelectAnswer = (data) => {
        if (props.onSelectAnswer)
            props.onSelectAnswer(data);
    };

    useEffect(() => {
        getSurveyScaleAnswers(props.surveyScale);
    }, [props.refresh]);

    async function getSurveyScaleAnswers(surveyScale) {
        if (surveyScale.surveyScaleId != "")
            useSurveyScale.getSurveyScaleAnswers(surveyScale.surveyScaleId).then((result) => {
                if (result) {
                    setSurveyScaleAnswers(result);
                }
            });
    }

    const columns = [
        {
            title: 'Respuesta',
            dataIndex: 'answer',
            key: 'answer',
        },
        {
            title: 'Valor',
            dataIndex: 'value',
            key: 'value',
        },
        {
            title: 'Acciones',
            dataIndex: 'options',
            key: 'options',
        },
    ];

    const dataSource = surveyScaleAnswers?.map((scaleAnswer, i) => {
        return {
            key: i+i,
            answer: scaleAnswer.description,
            value: scaleAnswer.scaleValue,
            options: <><Space>
                <Button type="button" className="btn-icon-table delete" onClick={() => handleDelete(scaleAnswer)}>
                    <FaTrash />
                </Button>
            </Space></>
        };
    })

    return (
        <div className="wpr_quest_search">
            <Table className="table-admin" columns={columns} dataSource={dataSource}
                   locale={{
                       triggerDesc: 'Ordenamiento descendente',
                       triggerAsc: 'Ordenamiento ascedente',
                       cancelSort: 'Cancelar ordenamiento',
                       emptyText: 'Sin registros',
                   }}
            />
        </div>

    );
}
