import React from "react";

export function Legals() {
  return (
    <article className="login-footer-legals">
      <ul>
          <li><a target="_blank" href="/ambienteseguro.pdf">Decálogo</a></li>
          <li><a target="_blank" href="/avisoprivacidad.pdf">Aviso de Privacidad</a></li>
          <li><a target="_blank" href="/terminosycondiciones.pdf">Términos y Condiciones</a></li>
      </ul>
    </article>
  );
}
