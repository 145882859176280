import React, { useEffect, useRef, useState } from "react";
import { useQuestion } from "../../services/surveyQuestion.service";
import { Button, Col, Dropdown, Input, Space, Table, Tag } from "antd";
import { FaEdit, FaTrash } from "react-icons/fa";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { FaEllipsisVertical } from "react-icons/fa6";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
const MySwal = withReactContent(Swal);

export const SearchQuestion = (props) => {
  const [questionsData, setQuestionsData] = useState({ questions: [] });
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleDelete = (data) => {
    if (props.isSurveyAlreadyStarted) return;

    MySwal.fire({
      title: "¿Estás seguro?",
      text: "No podrás revertir esta acción",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed)
        useQuestion
          .deleteQuestion(data)
          .then((result) => getQuestions(props.survey));
    });
  };
  const handleSelectQuestion = (data) => {
    if (props.onSelectQuestion) props.onSelectQuestion(data);
  };

  useEffect(() => {
    getQuestions(parseInt(props.survey));
  }, [props.survey, props.refresh]);

  const getQuestions = async (id) => {
    if (id !== 0)
      useQuestion.getQuestions(id).then((result) => {
        if (result) {
          setQuestionsData({ questions: result });
          props.setSurveyQuestions(result);
        }
      });
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Buscar`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Limpiar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columnsQuestions = [
    {
      title: "Pregunta",
      key: "question",
      dataIndex: "question",
      fixed: "left",
      width: 200,
      ...getColumnSearchProps("question"),
    },
    {
      title: "Tipo de pregunta",
      key: "typeQuestion",
      dataIndex: "typeQuestion",
      width: 60,
      sorter: (a, b) => {
        if (a.typeQuestion < b.typeQuestion) {
          return -1;
        }
        if (a.typeQuestion > b.typeQuestion) {
          return 1;
        }
        return 0;
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Estatus",
      key: "status",
      dataIndex: "status",
      width: 80,
    },
    {
      title: "Opciones",
      key: "options",
      dataIndex: "options",
      width: 50,
    },
  ];

  let items;

  const handleItems = (item) => {
    let i = 1;
    items = [
      {
        key: item.surveyQuestionId,
        label: (
          <Button
            type="primary"
            htmlType={"button"}
            className="edit"
            onClick={() => handleSelectQuestion(item)}
          >
            <FaEdit /> Editar
          </Button>
        ),
      },
      {
        key: item.surveyQuestionId + (i + 1),
        label: (
          <Button
            type="primary"
            htmlType={"button"}
            className="delete"
            onClick={() => handleDelete(item)}
          >
            <FaTrash /> Eliminar
          </Button>
        ),
      },
    ];
  };

  const questions = questionsData.questions.map((item) => {
    handleItems(item);
    return {
      key: item.surveyQuestionId,
      question: item.question,
      typeQuestion: item.answerType,
      status: (
        <Tag color={item.estatus === "Activa" ? "success" : "blue"}>
          {item.estatus}
        </Tag>
      ),
      options: (
        <Space wrap>
          <Dropdown
            menu={{ items }}
            placement="bottomRight"
            arrow={{
              pointAtCenter: true,
            }}
          >
            <Button type={"primary"} htmlType={"button"} className={"dots"}>
              <FaEllipsisVertical />
            </Button>
          </Dropdown>
        </Space>
      ),
    };
  });

  return (
    <div className="wpr_quest_search">
      <Table
        dataSource={questions}
        columns={columnsQuestions}
        className="table-admin "
        locale={{
          triggerDesc: "Ordenamiento descendente",
          triggerAsc: "Ordenamiento ascedente",
          cancelSort: "Cancelar ordenamiento",
          emptyText: "Sin registros",
        }}
      />
    </div>
  );
};
