import React, { useState } from 'react';
import { EditJobRole } from './editJobRole';
import { SearchJobRole } from './searchJobRoles';
import {FaTimesCircle} from "react-icons/fa";
import {TitleAdmin} from "../../components/titleAdmin/titleAdmin";
import {HeadingAdmin} from "../../components/headingAdmin/headingAdmin";
import {PlusCircleOutlined} from "@ant-design/icons";

export function AdminJobRoles() {
	const [showEdit, setShowEdit] = useState(false);
	const [selectedJobRole, setSelectedJobRole] = useState();
	const [refresh, setRefresh] = useState(0);

	const handleOnSelected = (jobRole) => {
		setShowEdit(true);
		setSelectedJobRole({ JobRoleId: jobRole.jobRoleId, Description: jobRole.description, Name: jobRole.name });
	};
	const handleOnNew = () => {
		setSelectedJobRole(null);
		setShowEdit(true);
	};
	const handleOnCancelEdit = () => {
		setSelectedJobRole(null);
		setShowEdit(false);
	}
	const handleOnSaveEdit = () => {
		setRefresh(prev => prev + 1);
		setShowEdit(false);
	}
	return (
		<>
			<TitleAdmin
				title={'Lista de categorías'}
				subtitle={'Listado y alta de sucursales'}
				btnBack={false}

			/>
			<HeadingAdmin
				text={!(showEdit) ? 'Agregar categoría' : 'Cancelar'}
				icon={!(showEdit) ? <PlusCircleOutlined /> : <FaTimesCircle />}
				event={() => !(showEdit) ? handleOnNew() : handleOnCancelEdit()}
			/>
			{(showEdit) ?
				<EditJobRole
					currentJobRole={selectedJobRole}
					onSave={handleOnSaveEdit}
				/>
				:
				<SearchJobRole
					onSelectJobRole={handleOnSelected}
					refresh={refresh}
				/>
			}
		</>
	);
}
