import React, {useEffect, useState, forwardRef, useImperativeHandle, useRef} from 'react';
import { courseService } from '../../services/course.service';
import {Space, Table, Pagination, Button, Dropdown, Input} from "antd";
import {FaChevronDown, FaEdit, FaTrash} from "react-icons/fa";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
const MySwal = withReactContent(Swal);

const SearchCourse = forwardRef((props, ref) => {
    const [dataGrid, setDataGrid] = useState([]);
    const [tableConfig, setTableConfig] = useState({});
    const [types, setTypes] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    useImperativeHandle(ref, () => ({
        refreshDataGrid: refreshDataGrid
    }));

    const handleDelete = (data) => {
        MySwal.fire({
            title: '¿Estás seguro?',
            text: "No podrás revertir esta acción",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Eliminar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if(result.isConfirmed) courseService.deleteCourse(data).then((result) => {
                MySwal.fire({
                    title: "¡Se eliminó correctamente!",
                    icon: "success",
                    confirmButtonText: "Confirmar"
                });
                getCourse();
            })
        })
    }

    async function getCourse() {
        courseService.getCoursesPaginated(1, 10).then((result) => {
            if (result) {
                setDataGrid(result.list);
                setTableConfig({
                    totalPages: result.totalPages,
                    activePage: 1,
                    type: {
                        courseMediaTypeId: 0,
                        courseMediaTypeName: ""
                    }
                });
            }
        });
    }

    const getTypes = () => {
        courseService.getTypes().then((result) => {
            if (result) setTypes(result);
        });
    }

    const handleSelectPage = (page,pageSize) => {
        courseService.getCoursesPaginated(page, 10, tableConfig.type.courseMediaTypeId).then((result) => {
            if (result) {
                setDataGrid(result.list);
                setTableConfig({
                    ...tableConfig,
                    totalPages: result.totalPages,
                    activePage: page
                });
            }
        });
    };

    const filterItems = (type) => {
        courseService.getCoursesPaginated(1, 10, type.courseMediaTypeId).then((result) => {
            if (result) {
                setDataGrid(result.list);
                setTableConfig({
                    totalPages: result.totalPages,
                    activePage: 1,
                    type: {
                        courseMediaTypeId: type.courseMediaTypeId,
                        courseMediaTypeName: type.name
                    }
                });
            }
        });
    };

    const refreshDataGrid = () => getCourse();

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{padding: 8,}} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Buscar`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{width: 90,}}
                    >
                        Buscar
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Limpiar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const columnsCourses = [
        {
            title: 'Tipo de capacitación',
            key: 'id',
            dataIndex: 'id',
            fixed: 'left',
            width: 120,
        },
        {
            title: 'Nombre',
            key: 'name',
            dataIndex: 'name',
        },
        {
            title: 'Descripción',
            key: 'description',
            dataIndex: 'description'
        },
        {
            title: 'Opciones',
            key: 'options',
            dataIndex: 'options',
        },
    ];

    const dataCourses = dataGrid.map(item => {
        return (
            {
                "key": item.courseId,
                id: item.courseMediaTypeName,
                name: item.name,
                description: (item.description.length > 0) ? item.description.substring(0, 80)+'...' : '',
                options: <Space size="middle">
                    <Button type="primary" htmlType={'button'} className="edit" onClick={() => props.handleRowSelect(item)}>
                        <FaEdit />
                    </Button>
                    <Button type="primary" htmlType={'button'} className="delete" disabled={(!item.active)} onClick={() => handleDelete(item)}>
                        <FaTrash />
                    </Button>
                </Space>
            }
        );
    });

    const items = types?.map((type, index) => {
        return {
            key: index,
            label: (
                <>
                    <Button className={'btn-item-dropdown'} onClick={() => filterItems(type)} key={index}>
                        {type?.name}
                    </Button>
                </>
            ),
        }
    })

    items.push({
        key: 99,
        label: (
            <>
                <Button onClick={refreshDataGrid}>
                    Limpiar filtro
                </Button>
            </>
        ),
    })

    useEffect(() => {
        getCourse();
        getTypes();
    }, []);

    return (
        <>
            <Dropdown
                menu={{items,}}
                placement="bottomLeft"
                arrow={{
                    pointAtCenter: true,
                }}
            >
                <Button className={'btn-dropdown'}>Tipos de capacitación <FaChevronDown /></Button>
            </Dropdown>
            <Table
                className="table-admin"
                columns={columnsCourses}
                dataSource={dataCourses}
                pagination={false}
                scroll={{ x: '992px', y: 800 }}
                locale={{
                    triggerDesc: 'Ordenamiento descendente',
                    triggerAsc: 'Ordenamiento ascedente',
                    cancelSort: 'Cancelar ordenamiento',
                    emptyText: 'Sin registros',
                }}
            />
            <div className={'table-admin-footer'}>
                <Pagination
                    style={{marginTop: '1rem'}}
                    current={tableConfig.activePage}
                    total={tableConfig.totalPages}
                    pageSize={1}
                    responsive={true}
                    simple={true}
                    onChange={handleSelectPage}
                />
            </div>
        </>
    );

});

export default SearchCourse;
