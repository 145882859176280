import React, { useRef, useState } from "react";
import { SelectUsers } from "../../components/Helpers/selectUsers";
import { AudienceUsers } from "../../components/Helpers/audienceUsers";
import { adminAudienceService } from "../../services/adminAudience.service";
import SearchAdminAudience from "./searchAdminAudience";
import { companyEventTypeService } from "../../services/companyEventType.service";
import { useEffect } from "react";
import { EventTypes } from "../../enums/eventTypes";
import {FaSave, FaTimesCircle} from "react-icons/fa";
import {useHistory} from "react-router-dom";
import {TitleAdmin} from "../components/titleAdmin/titleAdmin";
import Swal from "sweetalert2";
import {PlusCircleOutlined} from "@ant-design/icons";
import {HeadingAdmin} from "../components/headingAdmin/headingAdmin";
import {Col, DatePicker, Form, Input, Row, Select, TimePicker, Button} from "antd";
import {Checkbox} from "../components/checkbox/checkbox";
import {InputFile} from "../components/inputFile/inputFile";
import dayjs from "dayjs";
import {base64ToBlob} from "../../utils/ConvertBase64ToBlob";
import {InputAdd} from "../components/inputAdd/inputAdd";

export const AdminAudience = () => {
  const [templateShow, setTemplateShow] = useState("1");
  const [show, setShow] = useState(false);
  const [localfiles, setLocalFiles] = useState([]);
  const [userSelec, setUserSelec] = useState([]);
  const [isAudienceAddressed, setIsAudienceAddressed] = useState(false);
  const [createEventCalendar, setCreateEventCalendar] = useState(false);
  const gridRef = useRef();
  const horas = [1, 2, 3, 4, 5, 6];
  const history = useHistory();
  const [audience, setAudience] = useState({
    AudienceId: 0,
    Title: "",
    Reward: 0,
    ConferenceUrl: "",
    StartDate: "",
    StartTime: "",
    Duracion: 0,
    PubDate: "",
    JobRoleId: 0,
    ListUser: []
  });
  const [fileUpdated, setFileUpdated] = useState(false);
  const [fileSaved, setFileSaved] = useState('');
  const [form] = Form.useForm();

  const toggleTemplate = (val) => setTemplateShow(val);

  const onSave = () => {
    if (isAudienceAddressed && userSelec.length <= 0) {
      return Swal.fire({
        text: "Debes elegir al menos un usuario",
        icon: "error"
      });
    }

    if (localfiles.length === 0) {
      return Swal.fire({
        text: "Debes subir una imagen para el evento",
        icon: "error"
      });
    }

    const conferenceDate =  dayjs(audience.PubDate).format('YYYY-MM-DD')+'T'+audience.StartTime;
    const StartDate = dayjs(audience.StartDate).format('YYYY-MM-DDTHH:mm');
    const convertedCD = dayjs(conferenceDate).format('YYYY-MM-DDTHH:mm')
    const EndDate = dayjs(convertedCD).add(audience.Duracion, 'hour');

    audience.AuditoriumId = audience.AudienceId;
    audience.StartDate = StartDate;
    audience.EndDate = dayjs(EndDate).format("YYYY-MM-DDTHH:mm");
    audience.ConferenceDate = conferenceDate;
    audience.ListUser = userSelec;
    audience.JobRoleId = 0;
    delete audience.StartTime;
    delete audience.PubDate;

    if (audience.AudienceId === 0) adminAudienceService.create(audience, localfiles, createEventCalendar)
        .then((result) => {
          toggleTemplate("1");
          gridRef.current.refreshDataGrid();
          setUserSelec([]);
          setLocalFiles([]);
          Swal.fire({
            text: "Se creó exitosamente el evento",
            title:'¡Guardado!',
            icon: "success"
          });
        }).catch(err => Swal.fire({
          text: "Ocurrió un error",
          title:'¡Ups!',
          icon: "error"
        }));
    else {
      audience.EndDate = dayjs(EndDate).format("YYYY-MM-DDTHH:mm");
      audience.ConferenceDate = dayjs(audience.ConferenceDate).format("YYYY-MM-DDTHH:mm")
      adminAudienceService.update(audience, fileUpdated ? localfiles : fileSaved)
        .then((result) => {
          toggleTemplate("1");
          gridRef.current.refreshDataGrid();
          setUserSelec([]);
          setLocalFiles([]);
          Swal.fire({
            text: "Se actualizó exitosamente el evento",
            title:'¡Guardado!',
            icon: "success"
          });
        }
      );
    }
  }

  const handleRowSelect = (gridItem) => {
    toggleTemplate("2");
    setShow(true)
    const minutePubDate = dayjs(gridItem.conferenceDate).minute();
    const hourPubDate = dayjs(gridItem.conferenceDate).hour();
    const date1 = dayjs(gridItem.endDate).hour();
    const hours = (date1 - hourPubDate);

    setAudience({
      AudienceId: gridItem.auditoriumId,
      Title: gridItem.title,
      Reward: parseInt(gridItem.reward),
      ConferenceUrl: gridItem.conferenceUrl,
      StartDate: dayjs(gridItem.startDate),
      StartTime: `${hourPubDate}:${minutePubDate}`,
      Duracion: hours,
      PubDate: dayjs(gridItem.conferenceDate),
    });

    adminAudienceService.getAudiceImageById(gridItem.auditoriumId).then((result) => {
      if (result.files.length > 0) {
        setLocalFiles(result.files);
        const file = base64ToBlob(result.files[0].file, 'application/png', result.files[0].fileName);
        setFileSaved([file]);
        setFileUpdated(false);
      }
    });
  };

  const getCompanyEventType = () => {
    companyEventTypeService.getByCompanyAndEventType(process.env.REACT_APP_COMPANY, EventTypes.TALK)
        .then((result) => {
          if (result) setIsAudienceAddressed(result.isAudienceAddressed);
        });
  };

  const clearObjAudience = () => setAudience({
    AudienceId: 0,
    Title: "",
    Reward: 0,
    ConferenceUrl: "",
    StartDate: "",
    StartTime: "",
    Duracion: 0,
    PubDate: "",
  })

  const handleOpenForm = () => toggleTemplate('2')

  const handleCloseForm = () => {
    form.resetFields();
    clearObjAudience();
    setUserSelec([]);
    setLocalFiles([]);
    toggleTemplate('1');
  }

  const handleInputChange = (e) => setAudience({...audience, [e.target.name]: e.target.value})

  const handleChangeDuration = (value) => setAudience({...audience, Duracion: value})

  const handleDateEvent = (date, name) =>   setAudience({...audience, [name]: dayjs(date)})

  const onChangeTime = (_, timeStr) => setAudience({...audience, StartTime: timeStr})

  const handleReward = (value) => setAudience({...audience, Reward: value})

  const handleImageUpdated = (files) =>  {
    setLocalFiles(files);
    if(audience.AudienceId) setFileUpdated(true);
    else setFileUpdated(false);
  }

  const handleDeleteImage = () => setLocalFiles([]);

  const onFailedSave = (errorFields) => {
    const errorField = errorFields.errorFields.map(item => item.name[0])
    if(errorField.length > 0) form.scrollToField(errorField[0])
  }

  useEffect(() => {
    getCompanyEventType();
  }, []);

  useEffect(() => {
    if(templateShow === '2') {
      if(dayjs(audience.StartDate) > dayjs(audience.PubDate)) {
        setAudience({...audience, PubDate: ''})
        form.setFieldValue('PubDate', '')
      }
    }
  }, [audience.StartDate]);

  return (
      <section className="dashboard-admin-container">
        <TitleAdmin
            title={'PrideTalks'}
            subtitle={'Lista de publicaciones'}
            event={() => history.push('/admin')}
        />
        <HeadingAdmin
            text={(templateShow === '1') ? 'Agregar publicación' : 'Cancelar'}
            icon={(templateShow === '1') ? <PlusCircleOutlined /> : <FaTimesCircle />}
            event={() => (templateShow === '1') ? handleOpenForm() : handleCloseForm()}
        />
        {
          {
            1: (<SearchAdminAudience handleRowSelect={handleRowSelect} ref={gridRef} />),
            2: (<Form
                    className="form-backoffice"
                    onFinish={onSave}
                    onFinishFailed={onFailedSave}
                    layout={'vertical'}
                    form={form}
                    fields={[
                      {
                        name: ['Title'],
                        value: audience.Title ? audience.Title : ''
                      },
                      {
                        name: ['ConferenceUrl'],
                        value: audience.ConferenceUrl ? audience.ConferenceUrl : ''
                      },
                      {
                        name: ['StartDate'],
                        value: audience.StartDate !== '' ? dayjs(audience.StartDate) : ''
                      },
                      {
                        name: ['PubDate'],
                        value: audience.PubDate !== '' ? dayjs(audience.PubDate) : ''
                      },
                      {
                        name: ['StartTime'],
                        value: audience.StartTime !== '' ? dayjs(audience.StartTime,'HH:mm') : ''
                      },
                      {
                        name: ['Duracion'],
                        value: audience.Duracion === 0 ? '' : audience.Duracion
                      },
                      {
                        name: ['CreateEventCalendar'],
                        value: (createEventCalendar)
                      },
                    ]}
                >
                  <Row gutter={16}>
                    <Col md={12} sm={24} xs={24}>
                      <Form.Item
                          label={'Título de evento'}
                          htmlFor={'Title'}
                          name="Title"
                          rules={[
                            {
                              required: true,
                              message: '*Campo Requerido'
                            },
                            {
                              pattern: new RegExp(/^\S/),
                              message: "No se aceptan espacios en blanco al inicio",
                            },
                          ]}
                      >
                        <Input
                            name="Title"
                            onChange={handleInputChange}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={12} sm={24} xs={24}>
                      <Form.Item
                          label={'URL del evento'}
                          htmlFor={'ConferenceUrl'}
                          name="ConferenceUrl"
                          rules={[
                            {
                              required: true,
                              message: '*Campo Requerido'
                            },
                            {
                              pattern: new RegExp(/^\S/),
                              message: "No se aceptan espacios en blanco al inicio",
                            },
                          ]}
                      >
                        <Input
                            type="text"
                            name="ConferenceUrl"
                            onChange={handleInputChange}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Form.Item label={'Imagen del evento'}>
                        <InputFile
                            onFilesSelected={handleImageUpdated}
                            edit={(audience.AudienceId !== 0)}
                            valueFile={localfiles}
                            width={800}
                            height={600}
                            nameFile={'localfiles'}
                            accept={['image']}
                            deleteImage={handleDeleteImage}
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={8} md={12} sm={24} xs={24}>
                      <Form.Item
                          label={'Inicio de Publicación'}
                          htmlFor={'StartDate'}
                          name="StartDate"
                          rules={[
                            {
                              required: true,
                              message: '*Campo Requerido'
                            }
                          ]}
                      >
                        <DatePicker
                            format="DD/MMM/YYYY"
                            allowEmpty={false}
                            allowClear={false}
                            placeholder={'Seleccionar fecha'}
                            onChange={(_,date) => handleDateEvent(date,'StartDate')}
                            name="StartDate"
                            showToday={false}
                            disabledDate={(current) => current && current < dayjs().startOf('day') }
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={8} md={12} sm={24} xs={24}>
                      <Form.Item
                          label={'Fecha de Evento'}
                          htmlFor={'PubDate'}
                          name="PubDate"
                          rules={[
                            {
                              required: true,
                              message: '*Campo Requerido'
                            }
                          ]}
                      >
                        <DatePicker
                            name="PubDate"
                            format="DD/MMM/YYYY"
                            allowEmpty={false}
                            allowClear={false}
                            placeholder={'Seleccionar fecha'}
                            onChange={(_,date) => handleDateEvent(date,'PubDate')}
                            showToday={false}
                            disabled={(audience.StartDate === '')}
                            disabledDate={(current) => current && current < dayjs(audience.StartDate)}
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={8} md={12} sm={24} xs={24}>
                      <Form.Item
                          label={'Horario'}
                          htmlFor={'StartTime'}
                          name="StartTime"
                          rules={[
                            {
                              required: true,
                              message: '*Campo Requerido'
                            }
                          ]}
                          initialValue={audience.StartTime ? dayjs(audience.StartTime,'HH:mm') : ''}
                      >
                        <TimePicker
                            placeholder={'Seleccionar hora'}
                            name="StartTime"
                            format="HH:mm"
                            allowClear={false}
                            needConfirm={false}
                            showNow={false}
                            onChange={onChangeTime}
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={8} md={12} sm={24} xs={24}>
                      <Form.Item
                          label={'Duracion'}
                          htmlFor={'Duracion'}
                          name="Duracion"
                          rules={[
                            {
                              required: true,
                              message: '*Campo Requerido'
                            }
                          ]}
                      >
                        <Select
                            name="Duracion"
                            onChange={handleChangeDuration}
                        >
                          {horas.map((item,i) => <Select.Option key={i} value={item}>{item}</Select.Option>)}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col lg={8} md={12} sm={24} xs={24}>
                      <Form.Item
                          label={'Reward'}
                          htmlFor={'Reward'}
                          name="Reward"
                      >
                        <InputAdd
                            maxLength={3}
                            maxQuantity={999}
                            minQuantity={0}
                            valueInt={audience.Reward}
                            handleCurrentNumber={handleReward}
                        />
                      </Form.Item>
                    </Col>
                    {/* {parseInt(audience.AudienceId) === 0 && <Col lg={8} md={12} sm={24} xs={24}>
                      <Form.Item
                          label={'Crear evento de calendario'}
                          htmlFor={'CreateEventCalendar'}
                          name="CreateEventCalendar"
                      >
                        <Checkbox
                            text={''}
                            label={'CreateCalendarEvent'}
                            name={createEventCalendar}
                            handleValueCheck={(val) => setCreateEventCalendar(val)}
                        />
                      </Form.Item>
                    </Col>} */}
                    <Col xs={24}>
                      {
                          isAudienceAddressed &&
                          (audience.AudienceId > 0 ?
                              <AudienceUsers
                                  targetId={audience.AudienceId}
                                  eventType="Auditorio"
                                  selectUser={setUserSelec}
                                  showBtn={show}
                              />
                              :
                              <SelectUsers
                                  selectUser={setUserSelec}
                                  title={"Evento dirigido a:"}
                              />
                          )
                      }
                    </Col>
                    <Col xs={24}>
                      <div className="form-backoffice-footer">
                        <Button htmlType={'submit'} type="primary">
                          <p>Guardar</p>
                          <FaSave />
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
            ),
          }[templateShow]
        }
      </section>
  );
}
