import React, { useEffect, useState } from "react";
import { SelectUsers } from "../../components/Helpers/selectUsers";
import { AudienceUsers } from "../../components/Helpers/audienceUsers";
import { useSurvey } from "../../services/survey.service";
import { SearchQuestion } from "./searchQuestion";
import { EditQuestionAnswer } from "./editQuestionAnswer";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import { AlertResult } from "../components/alertResult";
import { FaChevronRight, FaSave, FaTimes } from "react-icons/fa";
import { companyEventTypeService } from "../../services/companyEventType.service";
import { EventTypes } from "../../enums/eventTypes";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import { Col, DatePicker, Form, Row, Button, Input } from "antd";
import { InputAdd } from "../components/inputAdd/inputAdd";
import withReactContent from "sweetalert2-react-content";
import { EditQuestion } from "./editQuestion";
import { PlusCircleOutlined } from "@ant-design/icons";
import { SurveyTypes } from "../../enums/surveyTypes";
const MySwal = withReactContent(Swal);

export const EditSurvey = () => {
  const [userSelec, setUserSelec] = useState([]);
  const [refreshQuestions, setRefreshQuestions] = useState(0);
  const [showEditQuestion, setShowEditQuestion] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState();
  const [surveyQuestions, setSurveyQuestions] = useState([]);
  const [isAudienceAddressed, setIsAudienceAddressed] = useState(false);
  const [survey, setSurvey] = useState({
    SurveyId: 0,
    Title: "",
    StartDate: "",
    EndDate: "",
    Reward: 0,
    IsFullAnswered: false,
    Active: true,
  });
  const { RangePicker } = DatePicker;
  const { id } = useParams();
  const [showEditAnswer, setShowEditAnswer] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [disabledSubmit, setDisabledSubmit] = useState(false);

  const onSave = () => {
    setDisabledSubmit(true);
    if (isAudienceAddressed && userSelec.length <= 0) {
      return MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: "Debes elegir al menos un usuario",
      });
    }
    survey.Reward = parseInt(survey.Reward);
    survey.ListUser = userSelec;
    if (!(survey.SurveyId && survey.SurveyId > 0)) {
      useSurvey.createSurvey(survey);
      clearObj();
    } else {
      useSurvey.updateSurvey(survey);
      clearObj();
    }
  };

  const clearObj = () => {
    setSurvey({
      SurveyId: 0,
      Title: "",
      StartDate: "",
      EndDate: "",
      Reward: 0,
      IsFullAnswered: false,
      Active: true,
    });
  };

  const handleOnSelectQuestion = (question) => {
    setShowEditQuestion(true);
    setSelectedQuestion({
      SurveyQuestionId: parseInt(question.surveyQuestionId),
      SurveyId: parseInt(question.surveyId),
      SurveyAnswerTypeId: parseInt(question.surveyAnswerTypeId),
      Question: question.question,
      SurveyScaleId: parseInt(question.surveyScaleId),
    });
  };
  const handleOnNewQuestion = () => {
    setSelectedQuestion(null);
    setShowEditQuestion(true);
  };
  const handleOnCancelEditQuestion = (selectedQuestion, editQuestion) => {
    setSelectedQuestion(selectedQuestion);
    setShowEditQuestion(editQuestion);
  };
  const handleOnSaveEditQuestion = () => {
    setRefreshQuestions((prev) => prev + 1);
    setShowEditQuestion(false);
  };

  const getCompanyEventType = () => {
    companyEventTypeService
      .getByCompanyAndEventType(
        process.env.REACT_APP_COMPANY,
        EventTypes.SURVEY
      )
      .then((result) => {
        if (result) setIsAudienceAddressed(result.isAudienceAddressed);
      });
  };

  const disabledDate = (current) => {
    return current && current < dayjs().startOf("day");
  };

  const onChangeDate = (date, dateString) => {
    const startDate = moment(dateString[0]).format("YYYY-MM-DD");
    const endDate = moment(dateString[1]).format("YYYY-MM-DD");
    setSurvey({ ...survey, StartDate: startDate, EndDate: endDate });
  };

  const handleCurrentSurvey = (value) =>
    setSurvey({ ...survey, Reward: value });

  const handleInputValue = (e) =>
    setSurvey({ ...survey, [e.target.name]: e.target.value });

  const handleOnNewAnswer = (value) => setShowEditAnswer(value);

  const handleOnCancelEditAnswer = (editAnswer, selectedAnswer) => {
    setShowEditAnswer(editAnswer);
    setSelectedAnswer(selectedAnswer);
  };

  useEffect(() => {
    getCompanyEventType();
    if (parseInt(id) !== 0) {
      useSurvey.getSurveysById(id).then((result) => {
        setSurvey({
          SurveyId: result.surveyId,
          Title: result.title,
          StartDate: result.startDate,
          EndDate: result.endDate,
          Reward: result.reward,
          IsFullAnswered: result.isFullAnswered,
          Active: result.active,
          IsAlreadyStarted: result.isAlreadyStarted,
          Estatus: result.estatus,
        });
        if (result.isAlreadyStarted && String(result.estatus) === "Activa") {
          MySwal.fire({
            title: "Upps!",
            text: "Algunos usuarios ya han comenzado la encuesta, por lo que ya no es posible añadir o eliminar preguntas",
            icon: "warning",
          });
        }
      });
      setDisabledSubmit(false);
    }

    setRefreshQuestions((prev) => prev + 1);
    setSelectedQuestion(null);
    setShowEditQuestion(false);
  }, [id]);

  return (
    <div className="dashboard-container-page">
      <Form
        layout={"vertical"}
        onFinish={onSave}
        className="form-backoffice"
        fields={[
          {
            name: ["Title"],
            value: survey.Title,
          },
          {
            name: ["Date"],
            value: survey.StartDate
              ? [dayjs(survey.StartDate), dayjs(survey.EndDate)]
              : "",
          },
        ]}
      >
        <Row gutter={16}>
          <Col md={12} sm={24} xs={24}>
            <Form.Item
              label={"Título de encuesta"}
              htmlFor={"Title"}
              className="form-dashboard-group"
              name="Title"
              rules={[
                {
                  required: true,
                  message: "*Campo requerido",
                },
                {
                  pattern: new RegExp(/^\S/),
                  message: "No se aceptan espacios en blanco al inicio",
                },
              ]}
              onChange={handleInputValue}
            >
              <Input type="text" name="Title" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col md={12} sm={24} xs={24}>
            <Form.Item
              label={"Fecha de inicio y finalización"}
              htmlFor={"Date"}
              rules={[
                {
                  required: true,
                  message: "*Campo requerido",
                },
              ]}
              name="Date"
            >
              <RangePicker
                name="Date"
                placeholder={["Inicia", "Termina"]}
                onChange={onChangeDate}
                format={"DD/MMM/YYYY"}
                disabledDate={disabledDate}
              />
            </Form.Item>
          </Col>
          <Col md={12} sm={24} xs={24}>
            <Form.Item label={"Reward"} htmlFor={"Reward"}>
              <InputAdd
                maxLength={6}
                maxQuantity={99999}
                minQuantity={-99999}
                valueInt={survey.Reward}
                handleCurrentNumber={handleCurrentSurvey}
              />
            </Form.Item>
          </Col>
        </Row>
        {isAudienceAddressed &&
          (parseInt(id) > 0 ? (
            <AudienceUsers
              targetId={id}
              eventType="Encuesta"
              selectUser={setUserSelec}
            />
          ) : (
            <SelectUsers
              selectUser={setUserSelec}
              title={"Encuesta dirigida a:"}
            />
          ))}
        <Row>
          <Col xs={24}>
            <div className="form-backoffice-footer">
              <Button
                htmlType={"submit"}
                type="primary"
                disabled={disabledSubmit}
              >
                <p>Guardar</p>
                <FaSave />
              </Button>
              {survey.IsFullAnswered ? (
                <Link
                  to={"/admin/survey-results/" + id}
                  className={"btn-secondary"}
                >
                  <p>Ver Resultados</p>
                  <FaChevronRight />
                </Link>
              ) : null}
            </div>
          </Col>
        </Row>
      </Form>
      {parseInt(id) !== 0 ? (
        <Row gutter={16} style={{ marginTop: "1rem" }}>
          <Col lg={12} md={24} sm={24} xs={24}>
            <div className="admin-heading mobile">
              <h4>Lista de preguntas</h4>
              {!showEditQuestion ? (
                <Button
                  disabled={
                    survey.IsAlreadyStarted &&
                    String(survey.Estatus) === "Activa"
                  }
                  onClick={handleOnNewQuestion}
                  type={"primary"}
                  htmlType={"button"}
                >
                  <p>Agregar pregunta</p>
                  <PlusCircleOutlined />
                </Button>
              ) : (
                <Button
                  disabled={
                    survey.IsAlreadyStarted &&
                    String(survey.Estatus) === "Activa"
                  }
                  type={"primary"}
                  htmlType={"button"}
                  onClick={handleOnCancelEditQuestion}
                >
                  <p>Cancelar </p>
                  <FaTimes />
                </Button>
              )}
            </div>
            <Row gutter={16}>
              {showEditQuestion && (
                <EditQuestion
                  surveyId={id}
                  isSurveyAlreadyStarted={
                    survey.IsAlreadyStarted &&
                    String(survey.Estatus) === "Activa"
                  }
                  currentQuestion={selectedQuestion}
                  onSaveParent={handleOnSaveEditQuestion}
                  edit={showEditQuestion}
                />
              )}
              <SearchQuestion
                setSurveyQuestions={setSurveyQuestions}
                survey={id}
                isSurveyAlreadyStarted={
                  survey.IsAlreadyStarted && String(survey.Estatus) === "Activa"
                }
                onSelectQuestion={handleOnSelectQuestion}
                refresh={refreshQuestions}
              />
            </Row>
          </Col>
          <Col lg={12} md={24} sm={24} xs={24}>
            <div className="admin-heading mobile">
              {selectedQuestion?.SurveyAnswerTypeId != SurveyTypes.MULTIMEDIA &&
              selectedQuestion?.SurveyAnswerTypeId != SurveyTypes.OPEN ? (
                <>
                  <h4>Lista de Respuestas</h4>
                  {showEditQuestion ? (
                    <Button
                      disabled={
                        survey.IsAlreadyStarted &&
                        String(survey.Estatus) === "Activa"
                      }
                      onClick={() =>
                        !showEditAnswer
                          ? handleOnNewAnswer(true)
                          : handleOnCancelEditAnswer(false)
                      }
                      type={"primary"}
                      htmlType={"button"}
                    >
                      <p>
                        {!showEditAnswer ? "Agregar respuesta" : "Cancelar"}
                      </p>
                      {!showEditAnswer ? <PlusCircleOutlined /> : <FaTimes />}
                    </Button>
                  ) : null}
                </>
              ) : null}
            </div>
            {showEditQuestion ? (
              <EditQuestionAnswer
                surveyQuestions={surveyQuestions}
                editAnswer={showEditAnswer}
                //isSurveyAlreadyStarted={survey.IsAlreadyStarted && String(survey.Estatus) === "Activa"}
                surveyId={id}
                setSelectedAnswer={setSelectedAnswer}
                selectedAnswer={selectedAnswer}
                currentQuestion={selectedQuestion}
                onCancelEdit={handleOnCancelEditAnswer}
                onSave={handleOnSaveEditQuestion}
              />
            ) : (
              <AlertResult
                title={"¡Ups!"}
                subTitle={"Debes seleccionar una pregunta"}
                status={"warning"}
              />
            )}
          </Col>
        </Row>
      ) : null}
    </div>
  );
};
