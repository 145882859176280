import React from "react";
import { ItemMorePost } from "./itemMorePost";

export const AsideOurCompany = ({ setCurrentFilter, filters }) => {
  return (
    <div className="blog-worldpride-aside-publications">
      <h5>Otras Publicaciones</h5>
      <div className={"publications-list"}>
        {filters.map((item, i) => (
          <ItemMorePost
            post={item}
            setCurrentFilter={setCurrentFilter}
            key={i}
          />
        ))}
      </div>
    </div>
  );
};
