import React from "react";
import { RegisterAside } from "./registerAside";
import { NoticeMessage } from "./noticeMessage";
import { RegisterHeader } from "./registerHeader";
import { FooterFull } from "../../components/footer/footerFull";
import "./register.scss";

export const SuccessfulReg = () => {
  return (
    <section className="register__container">
      <article className="register__form">
        <div className="-form-light">
          <RegisterHeader
            title={"¡Te has registrado!"}
            subtitle={"exitosamente"}
          />
          <NoticeMessage
            title="Registro Exitoso"
            subtitle="¡Ya eres parte de nuestra comunidad!"
            message="Te enviamos un link de confirmación a tu correo electrónico, por favor verifícalo para iniciar sesión"
            success={1}
          />
        </div>
        <RegisterAside />
      </article>
      <FooterFull />
    </section>
  );
};
