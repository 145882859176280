import React, { useEffect } from 'react'

export function SearchLevel(props) {
	const { levels, setSelectLevel, setShowEditLevel, deleteLevel, refresh, setRefresh } = { ...props }

	useEffect(() => {
		setRefresh(false)
	}, [refresh, setRefresh])

	return (
		<div className="table-responsive">
			<table className="wrapper_table table table-hover table-sm">
				<thead>
					<tr>
						<th>Titulo del Nivel</th>
						<th>Puntos requeridos (mínimo)</th>
						<th>Icono</th>
						<th>Status</th>
						<th>Acciones</th>
					</tr>
				</thead>
				<tbody>
					{levels.map((level, index) => (
						<tr key={index}>
							<td>{level.description}</td>
							<td>{level.noAcknowledgment}</td>
							<td><img src={level.urlLogo} style={{ width: '40px', height: '40px', marginRight: '10px', borderRadius: '50%' }} /></td>
							<td>{level.active ? 'Active' : 'Inactive'}</td>
							<td className="text-center">
								<span className="icon_tables" onClick={() => deleteLevel(level)}>
									<i className="icon-trash"></i>
								</span>
								<span className="icon_tables" onClick={() => { setSelectLevel(level); setShowEditLevel(true) }}>
									<i className="icon-edit"></i>
								</span>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
}
