import React, { useEffect, useState, useRef } from "react";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { ListChats } from "./listChats";
import { ListGroups } from "./listGroups";
import { ListContact } from "./listContact";
import { ChatConversations } from "./chatConversations";
import { ProfileChat } from "./profileChat";
import { useSelector } from "react-redux";
import $ from "jquery";
import "./wrapperChats.scss";
import {
  FaBook,
  FaUsers
} from "react-icons/fa";
import {
  BsArrowsAngleContract,
  BsArrowsAngleExpand
} from "react-icons/bs";
export function WrapperChats(props, { windowDimensions }) {
  const roleName = useSelector((state) => state.userSettings.roleName);
  const [activeSec, setActiveSec] = useState("1");

  const toggle = (sec) => {
    if (activeSec !== sec) setActiveSec(sec);
  };

  const [openChat, setOpenChat] = useState("");
  const [icoOpen, setIcoOpen] = useState("resize-outline");
  const toogleOpenChat = () => {
    setOpenChat(openChat === "" ? "open" : "");
    setIcoOpen(icoOpen === "resize-outline" ? "remove-outline" : "resize-outline");
    $("body").toggleClass("noScroll");
  };

  const [btnMenu, setBtnMenu] = useState("");
  const [showNavbar, setShowNavbar] = useState("");
  const funtMenu = () => {
    setBtnMenu(btnMenu === "" ? "visible_menu" : "");
    setShowNavbar(showNavbar === "" ? "show-navbar" : "");
  };

  const [chat, setChat] = useState({});
  const [notifications, setNotifications] = useState([]);
  const [newMessageReceived, setNewMessageReceived] = useState({});
  const [chatNotification, setChatNotification] = useState({});
  const [conversationNotification, setConversationNotification] = useState("");

  const chatRef = useRef(null);
  chatRef.current = { activeSec, openChat, chat, notifications };

  const showChat = (selectedChat) => {
    toggle("5");
    setChat(selectedChat);
  };

  const receiveMessage = (message) => {
    if (chatRef.current.activeSec == "5") {
      if (message.contactId == chatRef.current.chat.contactId) {
        setNewMessageReceived(message);
        if (chatRef.current.openChat) return;
      }
    } else if (chatRef.current.activeSec == "1")
      setChatNotification({
        contactId: message.contactId,
        name: message.senderName,
        image: "",
        unread: 1,
      });

    let updateNotifications = chatRef.current.notifications.filter(
      (item) => item != message.contactId
    );
    updateNotifications.push(message.contactId);
    setNotifications(updateNotifications);
  };

  const notifyReading = (notification) => {
    if (chatRef.current.activeSec == "5" && notification.isSender) {
      if (
        notification.contactId == chatRef.current.chat.contactId &&
        notification.isSender
      ) {
        setConversationNotification(notification.messageId);
        if (chatRef.current.openChat) return;
      }
    } else if (chatRef.current.activeSec == "1" && !notification.isSender)
      setChatNotification({ contactId: notification.contactId, unread: 0 });

    if (!notification.isSender) {
      let updateNotifications = chatRef.current.notifications.filter(
        (item) => item != notification.contactId
      );
      setNotifications(updateNotifications);
    }
  };

  const blockedContact = (notification) => {
    if (chatRef.current.activeSec == "5" && notification == chatRef.current.chat.contactId) {
      const timestamp = Math.floor(Date.now() / 1000);
      setConversationNotification(`blockedContact ${timestamp}`);
    }
  };

  useEffect(() => {
    const connection = new HubConnectionBuilder()
        .withUrl(`${process.env.REACT_APP_HUB_URL}/chat`, {headers: { NoBlocking: true },})
        .withAutomaticReconnect()
        .build();

    connection
      .start()
      .then(() => {
        console.log("Connected!");
        connection.on("ReceiveMessage", receiveMessage);
        connection.on("NotifyReading", notifyReading);
        connection.on("BlockedContact", blockedContact);
      })
      .catch((e) => console.log("Connection failed: ", e));
  }, []);

  return (
    <aside className={`chat-container ${openChat}`}>
      <section className="chat-container-header">
        <article className="button-chat">
          <div className="part1">
            <span className={`btn-menu-chat ${btnMenu}`} onClick={funtMenu}>
              <span></span>
              <span></span>
              <span></span>
            </span>
          </div>
          <div className="part2" onClick={toogleOpenChat}>
            <p onClick={props.noScroll}>
              <i className="icon-dots-vertical"></i> <span>Chats</span>
            </p>
            {notifications.length > 0 && (<span className="dots-notifications">{notifications.length}</span>)}
            <div>
              <span className="btn-expand">
                {
                  (openChat) ?  <BsArrowsAngleContract /> : <BsArrowsAngleExpand />
                }
              </span>
            </div>
          </div>
        </article>
      </section>
      <section className="chat-container-content">
        <article className={`chat-container-content-chats ${showNavbar}`}>
          {
            {
              1: (
                <ListChats
                  showChat={showChat}
                  chatNotification={chatNotification}
                  setNotifications={setNotifications}
                />
              ),
              2: <ListGroups showChat={showChat} />,
              3: <ListContact showChat={showChat} />,
              4: <ProfileChat />,
              5: (
                <ChatConversations
                  currentChat={chat}
                  newMessageReceived={newMessageReceived}
                  notification={conversationNotification}
                  toggle={toggle}
                  openChat={openChat}
                />
              ),
            }[activeSec]
          }
        </article>
        <article className={`chat-container-content-navbar ${showNavbar}`}>
          <nav className="chat">
            <div className={`chat-item ${activeSec === "1" ? "active" : ""}`} onClick={() => {toggle("1");funtMenu();}}>
              <i className="icon-dots-vertical"></i>
              <span className="name">CHATS</span>
            </div>
            {roleName === "Recursos Humanos" && (
                <div className={`chat-item ${activeSec === "2" ? "active" : ""}`} onClick={() => {toggle("2");funtMenu();}}>
                  <FaUsers />
                  <span className="name">GRUPOS</span>
                </div>
            )}
            <div className={`chat-item ${activeSec === "3" ? "active" : ""}`} onClick={() => {toggle("3");funtMenu();}}>
              <FaBook />
              <span className="name">CONTACTOS</span>
            </div>
          </nav>
        </article>
      </section>
    </aside>
  );
}
