import React from 'react';
import { EditCompany } from './editCompany'
import {Header} from "../header";
import {useParams} from "react-router-dom";

export function DetailsCompany(props) {

    const {id} = useParams();

    return (
        <div className="dashboard-operator-body">
            <Header
                title={parseInt(id) !== 0 ? "Editar empresa" : "Nueva empresa"}
                description=""
                buttons={[
                    {
                        "id": 1,
                        "nameButton": "Agregar empresa",
                        "link": "/operator/details-company/0"
                    }
                ]}
                btnBack={[
                    {
                        "id": 2,
                        "nameButton": "Regresar",
                        "link": "/operator/AdminCompany"
                    }
                ]}
            />
            <EditCompany companyId={parseInt(id)} />
        </div>
    );
}   
