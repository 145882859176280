import React, { useEffect, useState } from "react";
import "./blogCompany.scss";
import { Col, Row } from "reactstrap";
import "./blogCompany.scss";
import imgEmpty from "../../assets/imgs/no-image.jpeg";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import { companyPostViewService } from "../../services/compayPostView.service";
import Moment from "react-moment";
import { AsideBlogCompanySecondary } from "../companyPost/asideBlogCompanySecondary";
import { FooterHome } from "../../components/footer/footerHome";
import dayjs from "dayjs";

export const BlogCompanySecondary = () => {
  const [posts, setPosts] = useState([]);
  const [selectedPost, setSelectedPost] = useState({});
  const [currentFilter, setCurrentFilter] = useState("");
  const [filter, setFilter] = useState([]);

  const clearVariable = () => {
    setSelectedPost({});
    setPosts([]);
  };

  const getPosts = async () => {
    clearVariable();
    companyPostViewService.getByFilters(currentFilter, 2).then((result) => {
      if (result && result.length > 0) {
        handleSelectPost(result[0]);
        setPosts(result);
        const lastMonthConverted = dayjs(result[0].publishDate).format(
          "YYYY-MM"
        );
        setCurrentFilter(lastMonthConverted);
        setFilter(
          filter.map((item) => {
            return {
              year: item.year,
              months: item.months.map((child) => {
                return {
                  activeItem:
                    dayjs(child.filterMonthDate).format("YYYY-MM") ===
                    lastMonthConverted,
                  count: child.count,
                  filterMonthDate: child.filterMonthDate,
                  month: child.month,
                };
              }),
            };
          })
        );
      }
    });
  };

  const handleSelectPost = (item) => {
    if (!item.isRead) {
      companyPostViewService
        .InsertRead({ CompanyPostId: item.companyPostId })
        .then((result) => {
          if (result) {
            setSelectedPost(result);
            if (posts.length > 0) {
              posts.map((m) => {
                if (m.companyPostId === item.companyPostId) m.isRead = true;
                return m;
              });
            }
          }
        });
    } else {
      getPost(item.companyPostId);
    }
  };

  const getPost = (id) => {
    companyPostViewService.getById(id).then((result) => {
      if (result.postType === 0) setSelectedPost(result);
      if (result.postType === 1) result.iconPath = result.urlImage;
    });
  };

  const getFilters = async () => {
    companyPostViewService.getDateFilters(0, 2).then((result) => {
      if (result) {
        if (result && result.length > 0) {
          const lastItem = result[0];
          const lastMonth = lastItem.months[0];
          const lastMonthConverted = dayjs(lastMonth.filterMonthDate).format(
            "YYYY-MM-DD"
          );
          setCurrentFilter(lastMonthConverted);
          setFilter(
            result.map((item) => {
              return {
                year: item.year,
                months: item.months.map((child) => {
                  return {
                    activeItem:
                      dayjs(child.filterMonthDate).format("YYYY-MM-DD") ===
                      lastMonthConverted,
                    count: child.count,
                    filterMonthDate: child.filterMonthDate,
                    month: child.month,
                  };
                }),
              };
            })
          );
        }
      }
    });
  };

  useEffect(() => {
    getPosts();
  }, [currentFilter]);

  useEffect(() => {
    getFilters();
  }, []);

  return (
    <article className="dashboard-container-page">
      <Row>
        <Col sm={12}>
          <h2>Orgullo LGBTQ+</h2>
        </Col>
        <Col lg={9}>
          {posts.slice(0, 1).map((card, i) => {
            return (
              <Link
                key={i}
                to={`/stories-pride/1/${card.companyPostId}`}
                className="blog-secondary-main-card"
              >
                <div className="thumb">
                  {card.urlImage ? (
                    <img src={card.urlImage} alt={card.title} name={card.title} />
                  ) : (
                    <img src={imgEmpty} alt="Orgullo Pride" name="Mundo Pride" />
                  )}
                </div>
                <div className="content">
                  {card.title.length <= 120 ? (
                    <h3>{card.title}</h3>
                  ) : (
                    <h3>{card.title.substring(0, 120)}...</h3>
                  )}
                  <h6>
                    <Moment
                      date={card.publishDate}
                      format="dddd D [de] MMMM [del] YYYY"
                    />
                  </h6>
                  <div className="flex-end">
                    <Link
                      to={`/stories-pride/1/${card.companyPostId}`}
                      className="btn-next"
                    >
                      Leer <BsArrowRight />{" "}
                    </Link>
                  </div>
                </div>
              </Link>
            );
          })}
          <div className="blog-secondary-cards">
            <Row>
              <Col sm={12}>
                <h3>Publicaciones del mes</h3>
              </Col>
              <Col sm={12}>
                <div className="card-posts-secondary">
                  {posts.slice(1).map((card, i) => {
                    return (
                      <Link
                        key={i}
                        to={`/stories-pride/1/${card.companyPostId}`}
                        className="card-post-secondary"
                      >
                        <div className="card-post-thumb">
                          {card.urlImage ? (
                            <img src={card.urlImage} alt="" />
                          ) : (
                            <img src={imgEmpty} alt="" />
                          )}
                        </div>
                        <div className="card-post-content">
                          <div>
                            {card.title.length <= 60 ? (
                              <h3>{card.title}</h3>
                            ) : (
                              <h3>{card.title.substring(0, 60)}...</h3>
                            )}
                            <h6>
                              <Moment
                                date={card.publishDate}
                                format="dddd D [de] MMMM [del] YYYY"
                              />
                            </h6>
                          </div>
                          <div className="flex-end">
                            <Link
                              to={`/stories-pride/1/${card.companyPostId}`}
                              className="btn-next"
                            >
                              Leer <BsArrowRight />
                            </Link>
                          </div>
                        </div>
                      </Link>
                    );
                  })}
                  {posts.slice(1).length === 0 ? (
                    <div className="empty">
                      <i className="icon-empty"></i>
                      <p>No hay más publicaciones</p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col lg={3}>
          <div className={`blog-secondary-aside`}>
            <h3>Otras Publicaciones</h3>
            <AsideBlogCompanySecondary
              setCurrentFilter={setCurrentFilter}
              currentFilter={currentFilter}
              filters={filter}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <FooterHome />
        </Col>
      </Row>
    </article>
  );
};
