import { callApi } from './service'
import swal from "sweetalert"
import Swal from "sweetalert2";

export const useQuestion = {
    createQuestion,
    updateQuestion,
    deleteQuestion,
    getQuestions,
    getFrontQuestions, 
    getFrontQuestionsList,
    getQuestionAnswerList,
};
async function createQuestion(params) {
    return callApi('/backoffice/SurveyQuestion', 'POST', params).then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            alert(result.responseMessage);
            return null;
        }
    });
}
async function getQuestions(surveyId) {
    return callApi('/backoffice/SurveyQuestion/' + surveyId, 'GET').then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            Swal.fire({
                text: result.responseMessage,
                icon: "error"
            }).then((value) => {
                window.location.href = '/AdminSurvey'
              });
              return null;
        }
    });
}

async function getQuestionAnswerList(questionId) {
    return callApi('/backoffice/GetAnsweredListByQuestion/' + questionId, 'GET').then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            alert(result.responseMessage);
            return null;
        }
    });
}

async function getFrontQuestions(params) {
    return callApi('/backend/SurveyQuestion/' + params.surveyId, 'GET').then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            swal({ text: result.responseMessage, icon: "error" }).then((value) => {
                window.location.href = '/AdminSurvey'
              });
              return null;
        }
    });
}

async function getFrontQuestionsList(surveyId) {
    return callApi('/backend/SurveyQuestion/' + surveyId, 'GET').then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            alert(result.responseMessage);
            return null;
        }
    });
}
async function updateQuestion(params) {
    return callApi('/backoffice/SurveyQuestion', 'PUT', params).then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            alert(result.responseMessage);
            return null;
        }
    });
}
async function deleteQuestion(params) {
    return callApi('/backoffice/SurveyQuestion', 'DELETE', params).then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            alert(result.responseMessage);
            return null;
        }
    });
}
