import React, { useEffect, useState } from "react";
import { ItemJobPublished } from "./itemJobPublished";
import { JobBankService } from "../../services/jobBank.service";
import { JobBankHeader } from "./jobBankHeader";
import "./jobBank.scss";
import { Col, Row } from "antd";
import { FooterHome } from "../../components/footer/footerHome";

export function JobBank() {
  const [jobs, setJobs] = useState([]);
  const [pages, setPages] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [order, setOrder] = useState("Fecha");
  const [filters, setFilters] = useState({
    occupationFilter: "",
    locationFilter: "",
  });

  useEffect(() => {
    searchPage();
  }, []);

  const searchPage = (page = 1, order = "Fecha") => {
    setOrder(order);
    JobBankService.getJobAll(
      page,
      12,
      order,
      filters.occupationFilter,
      filters.locationFilter
    ).then((result) => {
      if (!result) return;

      setJobs(result.list || []);
      setTotalCount(result.totalCount);
      setCurrentPage(page);
      setTotalPages(result.totalPages);

      let rows = [];
      for (let index = 1; index <= result.totalPages; index++) {
        rows.push(
          <li
            className={"page-item" + (page === index ? " active" : "")}
            key={index}
          >
            <a className="page-link" onClick={() => searchPage(index, order)}>
              {index}
            </a>
          </li>
        );
      }
      setPages(rows);
    });
  };

  const returnPreviousPage = () => {
    if (currentPage > 1) searchPage(currentPage - 1);
  };

  const advanceNextPage = () => {
    if (currentPage < totalPages) searchPage(currentPage + 1);
  };

  return (
    <section className="dashboard-container-page">
      <JobBankHeader
        title="Bolsa de trabajo"
        setFilters={setFilters}
        filters={filters}
        searchPage={searchPage}
      />
      <Row>
        <Col xs={24}>
          <div className="job-bank-counter">
            <p>
              <span>{totalCount}</span> empleos disponibles
            </p>
          </div>
        </Col>
      </Row>
      <Row gutter={16}>
        {jobs.length === 0 ? (
          <div className="empty">
            <i className="icon-empty"></i>
            <p>No hay empleos disponibles</p>
          </div>
        ) : (
          jobs.map((job, index) => (
            <Col xl={6} lg={8} md={12} sm={12} xs={24}>
              <ItemJobPublished
                jobPublished={false}
                key={job.jobId}
                job={job}
                index={index}
                misEmpl={false}
              />
            </Col>
          ))
        )}
      </Row>
      {jobs.length <= 0 ? (
        ""
      ) : (
        <div className="job-bank-pagination">
          <nav className="pagination-jobs">
            <ul className="pagination">
              <li className="page-item">
                <a
                  className="page-link"
                  onClick={returnPreviousPage}
                  aria-label="Previous"
                >
                  <span aria-hidden="true">&laquo;</span>
                  <span className="sr-only">Previous</span>
                </a>
              </li>
              {pages}
              <li className="page-item">
                <a
                  className="page-link"
                  onClick={advanceNextPage}
                  aria-label="Next"
                >
                  <span aria-hidden="true">&raquo;</span>
                  <span className="sr-only">Next</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      )}
      <Row>
        <Col xs={24}>
          <FooterHome />
        </Col>
      </Row>
    </section>
  );
}
