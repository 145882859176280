import React, {useEffect, useRef, useState} from 'react';

import {Row, Col, Button, Table, Tooltip, Switch, Input, Space, Pagination} from "antd";
import {FaDownload, FaPen, FaTrash, FaUpload} from "react-icons/fa";
import {rewardProductService} from "../../services/control/rewardProduct.service";
import {Header} from "../header";
import {CheckOutlined, CloseOutlined, SearchOutlined} from "@ant-design/icons";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Highlighter from "react-highlight-words";
const MySwal = withReactContent(Swal);

export function AdminReward() {
    const [rewardsData, setRewardsData] = useState({ rewards: [] });
    const handleDelete = (data) => {
        MySwal.fire({
            title: '¿Estás seguro?',
            text: "No podrás revertir esta acción",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#FF002A',
            confirmButtonText: 'Eliminar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if(result.isConfirmed) {
                rewardProductService.deleteRewardProduct(data).then((result) => getRewardProducts());
            }
        })
    };

    useEffect(() => {
        getRewardProducts();
    }, []);
    async function getRewardProducts() {
        rewardProductService.get().then((result) => {
            if (result) setRewardsData({
                rewards: result.sort((a,b) => {
                    return b.rewardProductId - a.rewardProductId
                })
            });
        });
    }

    const getPreview = async (id) => {
        await rewardProductService.getTestCoupon(id);
    }

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Buscar`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Buscar
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Limpiar
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        Cerrar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            defaultSortOrder: 'descend',
            sortDirections: ['descend','ascend'],
            showSorterTooltip: false,
            sorter: (a, b) => a.id - b.id,
            width: 100,
            fixed: 'left'
        },
        {
            title: 'Producto',
            dataIndex: 'product',
            key: 'product',
            sortDirections: ['descend','ascend'],
            showSorterTooltip: false,
            sorter: (a, b) => {
                if(a.product < b.product) {
                    return -1;
                }
                if(a.product > b.product) {
                    return 1;
                }
                return 0;
            },
            ...getColumnSearchProps('product')
        },
        {
            title: 'Mostrar folio',
            dataIndex: 'showFolio',
            key: 'showFolio',
            width: 150,
        },
        {
            title: 'Código de barras',
            dataIndex: 'barcode',
            key: 'barcode',
            width: 150,
        },
        {
            title: 'Puntos',
            dataIndex: 'points',
            key: 'points',
            sortDirections: ['descend','ascend'],
            showSorterTooltip: false,
            sorter: (a, b) => a.points - b.points,
            width: 200,
        },
        {
            title: 'Folios disponibles',
            dataIndex: 'availableFolio',
            key: 'availableFolio',
            width: 120,
        },
        {
            title: 'Opciones',
            dataIndex: 'options',
            key: 'options',
            width: 150,
        },
    ];

    const data = rewardsData.rewards.map(reward => {
        return {
            key: reward.rewardProductId,
            id: reward.rewardProductId,
            product: reward.title,
            showFolio:  <>
                <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    defaultChecked={!!(reward.isVisible)}
                    disabled={true}
                />
                <p>{reward.isVisible}</p>
            </>,
            barcode: <>
                <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    defaultChecked={!!(reward.isBarCode)}
                    disabled={true}
                />
                <p>{reward.isBarCode}</p>
            </>,
            points: reward.points,
            availableFolio: <Tooltip placement="top" title={`${(reward.isVisible) ? 'Cargar folios': 'No Disponible'}`}>
                <Button
                    type="primary"
                    className="dark"
                    htmlType="button"
                    onClick={() => window.location.href = "/operator/load-coupons/" + reward.rewardProductId}
                    icon={<FaUpload />}
                />
            </Tooltip>,
            options: <>
                <Button
                    type="primary"
                    className="dark"
                    htmlType="button"
                    onClick={() => window.location.href=`/operator/details-reward/${reward.rewardProductId}`}
                    style={{marginRight: '.5rem'}}
                    icon={<FaPen />}
                >
                </Button>
                <Tooltip placement="top" title={`${(reward.urlPdf) ? 'Descargar Cupón': 'No Disponible'}`}>
                    <Button
                        type="primary"
                        className="dark"
                        htmlType="button"
                        onClick={() => getPreview(reward.rewardProductId)}
                        icon={<FaDownload />}
                        style={{marginRight: '.5rem'}}
                        disabled={!(reward.urlPdf)}
                    >
                    </Button>
                </Tooltip>
                <Tooltip placement="top" title="Eliminar">
                    <Button
                        type="primary"
                        className="red"
                        htmlType="button"
                        onClick={() => handleDelete(reward.rewardProductId)}
                        icon={<FaTrash />}
                    >
                    </Button>
                </Tooltip>
            </>
        }
    });

    const buttons = [
        {
            "id": 1,
            "nameButton": "Agregar recompensa",
            "link": "/operator/details-reward/0"
        }
    ];

    const [currentPage, setCurrentPage] = useState(1)
    const [sizesPages, setSizesPages] = useState(10)

    const onChangePage = (page,pageSize) => {
        setCurrentPage(page) ;
        setSizesPages(pageSize);
    }

    return (
        <section className="dashboard-operator-body">
            <Header
                title="Control de Recompensas"
                description=""
                buttons={buttons}
                btnBack={[]}
            />
            <Row>
                <Col sm={24}>
                    <Table columns={columns}
                           dataSource={data.slice((currentPage - 1) * sizesPages, currentPage * sizesPages)}
                           scroll={{ x: '992px', y: 800 }}
                           pagination={false}
                           locale={{
                               triggerDesc: 'Ordenamiento descendente',
                               triggerAsc: 'Ordenamiento ascedente',
                               cancelSort: 'Cancelar ordenamiento',
                               emptyText: 'Sin registros',
                           }}
                    />
                    <Pagination
                        total={data.length}
                        defaultPageSize={sizesPages}
                        responsive={true}
                        defaultCurrent={1}
                        current={currentPage}
                        hideOnSinglePage={true}
                        onChange={onChangePage}
                        simple={true}
                    />
                </Col>
            </Row>
        </section>
    );
}
