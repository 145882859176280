import React, { useState } from "react";
import { Tabs } from "antd";
import { ListOldItems } from "./listOldItems";
import { ListItems } from "./listItems";

export function BoxListAudience({
  disabledHasMore,
  listAudienceP,
  listAudienceO,
  updateComplete,
  handleLinkConference,
}) {
  const [activeKey, setActiveKey] = useState("1");

  const items = [
    {
      key: "1",
      label: `Temporadas`,
      children: activeKey === "1" && (
        <ListOldItems
          updateComplete={updateComplete}
          items={listAudienceO}
          disabledHasMore={disabledHasMore}
          handleLinkConference={handleLinkConference}
        />
      ),
    },
    {
      key: "2",
      label: `Próximamente`,
      children: activeKey === "2" && (
        <ListItems
          items={listAudienceP}
          handleLinkConference={handleLinkConference}
        />
      ),
    },
  ];

  return (
    <Tabs
      activeKey={activeKey}
      items={items}
      onChange={(key) => setActiveKey(key)}
    />
  );
}
