import React from "react";
import { BsApple, BsFacebook, BsLink, BsLinkedin, BsSoundwave, BsTiktok, BsTwitter, BsYoutube, BsInstagram } from "react-icons/bs";
import {FaXTwitter} from "react-icons/fa6";
import {Tooltip} from "antd";

export function ItemSocial({ contactTypeName, contactValue }) {
  return (
    <li>
        <Tooltip placement="bottom" title={contactTypeName}>
            <a
            href={contactValue}
            target="_blank"
            className={contactTypeName.toLowerCase()}
          >
            {contactTypeName == "Instagram" && <BsInstagram />}
            {contactTypeName == "Facebook" && <BsFacebook />}
            {contactTypeName == "LinkedIn" && <BsLinkedin />}
            {contactTypeName == "Twitter" && <FaXTwitter />}
            {contactTypeName == "YoutubeChannel" && <BsYoutube />}
            {contactTypeName == "Web" && <BsLink />}
            {contactTypeName == "Apple" && <BsApple />}
            {contactTypeName == "SoundCloud" && <BsSoundwave />}
            {contactTypeName == "LinkThree" && <BsLink />}
            {contactTypeName == "TikTok" && <BsTiktok />}
          </a>
        </Tooltip>
    </li>
  );
}
