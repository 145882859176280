import React from 'react';
import {Result} from "antd";

export function AlertResult({title,html = '', status,subTitle = ''}) {
    return (
        <Result
            status={status}
            title={title}
            extra={html}
            subTitle={subTitle}
            className={'table-empty'}
        />
    );
}
