import React from 'react';

import './headingAdmin.scss'
import {Button, Dropdown, Space} from "antd";
import {PlusCircleOutlined} from "@ant-design/icons";
import {FaChevronDown} from "react-icons/fa";

export const HeadingAdmin = (
    {
        btnAdd = true,
        text = '',
        event = () => '',
        icon = false,
        disabled = false,
        isDropdown = false,
        menuDropdown  = {},
        btnDropdownName = 'Seleccionar'}) =>
{

    return (
        <div className="admin-heading">
            {
                (isDropdown)
                    ?
                    <Dropdown menu={menuDropdown}>
                        <Button  className={'btn-dropdown'}>
                            {btnDropdownName}
                            <FaChevronDown />
                        </Button>
                    </Dropdown>
                    : <div></div>
            }
            {
                (btnAdd) ?
                    <Button type="primary" htmlType={'button'} onClick={event} disabled={disabled}>
                        <p>{text}</p>
                        {
                            (icon) ?
                                icon
                                :
                                <PlusCircleOutlined />
                        }
                    </Button>
                    : <div></div>
            }
        </div>
    );
}
