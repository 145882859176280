import React, {useEffect, useRef, useState} from 'react';

import {Button, Col, Input, Pagination, Row, Space, Table, Tooltip} from "antd";
import {Header} from "../header";
import {FaPen, FaTrash} from "react-icons/fa";
import {rewardCategoryService} from "../../services/control/rewardCategory.service";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";

const MySwal = withReactContent(Swal)

export function AdminRewardCategory() {
    const [rewardsData, setRewardsData] = useState({ rewards: [] });
    const handleDelete = (id) => {
        MySwal.fire({
            title: '¿Estás seguro?',
            text: "No podrás revertir esta acción",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#FF002A',
            confirmButtonText: 'Eliminar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if(result.isConfirmed) {
                rewardCategoryService.deleteCategory(id).then((result) =>  getRewardcategories())
            }
        })
    };

    useEffect(() => {
        getRewardcategories();
    }, []);

    async function getRewardcategories() {
        rewardCategoryService.get().then((result) => {
            if (result) {
                setRewardsData({
                    rewards: result.sort((a,b) => {
                        return b.rewardCategoryId - a.rewardCategoryId
                    })
                });
            }
        });
    }

    const buttons = [
        {
            "id": 1,
            "nameButton": "Agregar categoría",
            "link": "/operator/details-rewardCategory/0"
        }
    ];

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Buscar`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Buscar
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Limpiar
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        Cerrar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            showSorterTooltip: false,
            defaultSortOrder: 'descend',
            sortDirections: ['descend','ascend'],
            sorter: (a, b) => a.id - b.id,
            width: 100,
            fixed: 'left',
        },
        {
            title: 'Descripción',
            dataIndex: 'description',
            key: 'description',
            showSorterTooltip: false,
            sortDirections: ['descend','ascend'],
            sorter: (a, b) => {
                if(a.description < b.description) {
                    return -1;
                }
                if(a.description > b.description) {
                    return 1;
                }
                return 0;
            },
            ...getColumnSearchProps('description')
        },
        {
            title: 'Ícono',
            dataIndex: 'icon',
            key: 'icon',
            showSorterTooltip: false,
            sortDirections: ['descend','ascend'],
            sorter: (a, b) => {
                if(a.icon < b.icon) {
                    return -1;
                }
                if(a.icon > b.icon) {
                    return 1;
                }
                return 0;
            },
        },
        {
            title: 'Opciones',
            showSorterTooltip: false,
            dataIndex: 'options',
            key: 'options',
            width: 150,
        }
    ];

    const data = rewardsData.rewards.map(reward => {
        return {
            key: reward.rewardCategoryId,
            id: reward.rewardCategoryId,
            description: reward.description,
            icon: reward.urlIcon,
            options:
                <>
                    <Button
                        type="primary"
                        className="dark"
                        htmlType="button"
                        onClick={() => window.location.href=`/operator/details-rewardCategory/${reward.rewardCategoryId}`}
                        style={{marginRight: '.5rem'}}
                        icon={<FaPen />}
                    >
                    </Button>
                    <Tooltip placement="top" title="Eliminar">
                        <Button
                            type="primary"
                            className="red"
                            htmlType="button"
                            onClick={() => handleDelete(reward.rewardCategoryId)}
                            icon={<FaTrash />}
                        >
                        </Button>
                    </Tooltip>
                </>
        }
    });

    const [currentPage, setCurrentPage] = useState(1)
    const [sizesPages, setSizesPages] = useState(10)

    const onChangePage = (page,pageSize) => {
        setCurrentPage(page) ;
        setSizesPages(pageSize);
    }

    return (
        <section className="dashboard-operator-body">
            <Header
                title="Control de Categorías"
                description=""
                buttons={buttons}
                btnBack={[]}
            />
            <Row>
                <Col xs={24}>
                    <Table columns={columns}
                           dataSource={data.slice((currentPage - 1) * sizesPages, currentPage * sizesPages)}
                           scroll={{ x: '992px', y: 800 }}
                           pagination={false}
                           locale={{
                               triggerDesc: 'Ordenamiento descendente',
                               triggerAsc: 'Ordenamiento ascedente',
                               cancelSort: 'Cancelar ordenamiento',
                               emptyText: 'Sin registros',
                           }}
                    />
                    <Pagination
                        total={data.length}
                        defaultPageSize={sizesPages}
                        responsive={true}
                        defaultCurrent={1}
                        current={currentPage}
                        hideOnSinglePage={true}
                        onChange={onChangePage}
                        simple={true}
                    />
                </Col>
            </Row>
        </section>
    );
}
