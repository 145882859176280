import React, { useEffect, useState } from 'react';
import { rewardCouponService } from '../../services/control/rewardCoupon.service';
import moment from 'moment';
import {Form, Col, Row, Input, Button,Select,DatePicker} from "antd";
import {Header} from "../header";
import locale from 'antd/es/date-picker/locale/es_ES';
import {useParams} from "react-router-dom";
import imgPdf from "../../assets/imgs/pdf.png";
import {FaDownload} from "react-icons/fa";

export function BulkLoadReward() {

    const {id} = useParams();
    const {RangePicker} = DatePicker;
    const [catalogs, setCatalogs] = useState({
        rewardProduct: [],
        company: []
    });
    const [reward, setReward] = useState({
        rewardProductId: parseInt(id),
        companyId: "",
        startDate: "",
        endDate: "",
        couponData: null,
    });

    const onSave = () => rewardCouponService.create(reward);

    useEffect(() => () => {
        rewardCouponService.getCatalogs().then((result) => setCatalogs(result));
    }, [reward]);

    const handleInputFileChange = (e) => setReward({...reward, [e.target.name]: e.target.files[0]})

    const handleInputDate = (date,dateStrings) => {
        const convertedDateInitial= moment(dateStrings[0], "DD/MMM/YYYY").format('YYYY-MM-DD');
        const convertedDateFinal= moment(dateStrings[1], "DD/MMM/YYYY").format('YYYY-MM-DD');
        setReward({
            ...reward,
            startDate: convertedDateInitial,
            endDate: convertedDateFinal,
        })
    }

    const handleSelectCompany = (id) => setReward({...reward, companyId: id})

    return (
        <section className="dashboard-operator-body">
                <Header
                    title="Carga de folios"
                    description="Desde aquí carga tus folios, revisa tus fechas de vigencia y que tu archivo sea .txt"
                    buttons={[
                        {
                            "id": 1,
                            "nameButton": "Agregar recompensa",
                            "link": "/operator/details-reward/0"
                        }
                    ]}
                    btnBack={[
                        {
                            "id": 2,
                            "nameButton": "Regresar",
                            "link": "/operator/controlreward/1"
                        }
                    ]}
                />
                <Form
                    onFinish={onSave}
                    className="form-pride"
                    layout="vertical"
                    autoComplete="off"
                    fields={[
                        {
                            "name": ["rewardProductId"],
                            "value": (reward.startDate) ? String(reward.rewardProductId) : ''
                        },
                    ]}
                >
                    <Row gutter={16}>
                        <Col lg={12} md={12} sm={24}>
                            <Form.Item
                                label="Seleccionar archivo"
                                name="couponData"
                                rules={[
                                    {
                                        required: true,
                                        message: '*Campo requerido'
                                    }
                                ]}
                            >
                                <div className="container-file-overlay">
                                    <Input
                                        type="File"
                                        name="couponData"
                                        accept='.txt,.csv,text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                                        size="large"
                                        onChange={handleInputFileChange}
                                    />
                                    {
                                        (reward.couponData && reward.couponData) ?
                                            <div className="container-file-overlay">
                                                <a href={reward.couponData} download className="download">
                                                    <img className="pdf" src={imgPdf} alt={reward.rewardProductId} />
                                                </a>
                                                <div className="overlay">
                                                    <div className="text">
                                                        <FaDownload />
                                                        <span>Descargar</span>
                                                    </div>
                                                </div>
                                            </div>

                                            : ''
                                    }
                                </div>
                            </Form.Item>
                        </Col>
                        <Col lg={6} md={12} sm={24}>
                            <Form.Item
                                label="Beneficio"
                                name="rewardProductId"
                            >
                                <Select
                                    disabled={true}
                                    name="rewardProductId"
                                    size="large"
                                    options={catalogs.rewardProduct.map(item => {
                                        return {
                                            value: item.value,
                                            label: item.label
                                        }
                                    })}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                            <Col lg={8} md={12} sm={24} xs={24}>
                                <Form.Item
                                    label="Vigencia"
                                    name="date"
                                    rules={[
                                        {
                                            required: true,
                                            message: '*Campo requerido'
                                        }
                                    ]}
                                >
                                    <RangePicker
                                        onChange={handleInputDate}
                                        name="endDate"
                                        format="DD/MMM/YYYY"
                                        size="large"
                                        locale={locale}
                                    />
                                </Form.Item>
                            </Col>
                            <Col lg={6} md={12} sm={24}>
                                <Form.Item
                                    label="Empresa"
                                    rules={[
                                        {
                                            required: true,
                                            message: '*Campo requerido'
                                        }
                                    ]}
                                    name="companyId"
                                >
                                    <Select
                                        disabled={!reward.startDate}
                                        name="companyId"
                                        placeholder="Selecciona una opción"
                                        onChange={handleSelectCompany}
                                        size="large"
                                        showSearch={true}
                                        options={catalogs.company.map(item => {
                                            return {
                                                value: item.value,
                                                label: item.label
                                            }
                                        })}
                                        optionFilterProp="children"
                                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24}>
                                <div className="flex-end">
                                    <Button htmlType="submit" type="primary">Guardar<i className="icon-save"></i></Button>
                                </div>
                            </Col>
                        </Row>
                </Form>
            </section>
    );
}   
