import React, { useEffect, useState } from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import { JobBankService } from "../../services/jobBank.service";
import useInputForm from "../../components/CustonHooks/FormHook";
import CreatableSelect from "react-select/creatable";
import { ValidacionEmail } from "../../utils/ValidacionMail";
import Swal from "sweetalert2";
import {
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
} from "reactstrap";
import { Button } from "rsuite";
import { GeneralUpload } from "../../components/Helpers/generalUpload";
import { FaChevronRight, FaTimes } from "react-icons/fa";
import { Col, Row } from "antd";

export function FormJob({ datos, setDatos, toggleView, setJob }) {
  const [editorState, setEditorState] = useState();
  const [selectedOption, setSelectedOption] = useState([]);
  const [options, setOptions] = useState([]);
  const [image, setImage] = useState(null);

  useEffect(() => {
    JobBankService.getCategories().then((result) => {
      if (!result) return;
      setOptions(
        result.map((x) => ({ value: x.jobCategoryId, label: x.name }))
      );
    });
  }, []);

  function updateImage(e) {
    const file = e.target.files[0];
    inputs.imagen = file;
    setImage(e.target.files[0]);
  }

  const handleOnChangeFile = (files) => {
    // Se agrega imagen
    inputs.imagen = files[0];
    setImage(URL.createObjectURL(files[0]));
  };

  const onSave = () => {
    inputs.descripcion = editorState;
    inputs.categoria = selectedOption;
    let error = ValidacionEmail(inputs.mail);
    if (error === false) {
      Swal.fire({
        text: "Correo incorrecto, por favor revise.",
        icon: "error",
      });
      return;
    }
    if (inputs.categoria.length === 0) {
      Swal.fire({ text: "Seleccion al menos una categoría", icon: "error" });
      return;
    }
    if (inputs.descripcion === "") {
      Swal.fire({ text: "Ingrese una descripción", icon: "error" });
      return;
    }
    insertJob();
  };

  async function insertJob() {
    //Agarrar datos del input y ponerlo en este formData

    let formData = new FormData();
    formData.append("CompanyId", process.env.REACT_APP_COMPANY);
    formData.append("Title", inputs.title);

    //Intentar agarrar lo que pone el editor y ponerlo un string

    formData.append("Description", inputs.descripcion);
    formData.append("Currency", "MXN");
    formData.append("Salary", inputs.infoSalarial);
    formData.append("Location", inputs.location);
    formData.append("Direction", inputs.ubicacion);
    formData.append("CompanyName", inputs.titleCompany);

    //En CompanyImage debería ir el logo de la empresa

    formData.append("CompanyImage", inputs.imagen);
    formData.append("PhoneNumber", inputs.phoneNumber);
    formData.append("Email", inputs.mail);

    selectedOption.forEach((element, index) => {
      formData.append(
        `JobCategory[${index}].JobCategoryId`,
        element?.__isNew__ ? 0 : element.value
      );
      formData.append(`JobCategory[${index}].Name`, element.label);
    });

    JobBankService.insertJob(formData).then((result) => {
      setJob(result);
      Swal.fire({
        text: "Se ha registrado la oferta de trabajo",
        icon: "success",
        showCloseButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((result) => {
        toggleView();
      });
      //clean inputs

      setDatos({
        title: "",
        categoria: [],
        ubicacion: "",
        location: "",
        titleCompany: "",
        imagen: null,
        infoSalarial: "",
        descripcion: "",
        phoneNumber: "",
        mail: "",
      });
      setEditorState(EditorState.createEmpty());
      setSelectedOption([]);
    });
  }

  const { inputs, handleInputChange, handleSubmit, setInputs } = useInputForm(
    onSave,
    datos
  );

  const onEditorStateChange = (editorState) => {
    setEditorState(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  const getFileImage = async (url) => {
    let fileName = url.substring(0, url.lastIndexOf("."));
    let file = await fetch(url)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], fileName, blob);
        return file;
      });
    return file;
  };

  const handleImageUploadRemove = () => setImage(null);

  const [modal, setModal] = useState(false);
  const toggle = (img) => {
    setImageModal(image);
    setModal(!modal);
  };

  const [imgModal, setImageModal] = useState("");

  return (
    <form onSubmit={handleSubmit}>
      <article className="steps">
        <div className="item active">
          <span>1</span>
        </div>
        <div className="lines"></div>
        <div className="item">
          <span>2</span>
        </div>
      </article>
      <article>
        <Row gutter={16}>
          <Col xs={24}>
            <h4>Información de la vacante</h4>
          </Col>
          <Col md={6} sm={24} xs={24}>
            <div className="form-dashboard-group">
              <label>Título de la vacante</label>
              <Input
                maxLength={50}
                type="text"
                name="title"
                required={true}
                onChange={handleInputChange}
              />
            </div>
          </Col>
          <Col md={6} sm={24} xs={24}>
            <div className="form-dashboard-group">
              <Label for="categoria">Categoría</Label>
              <CreatableSelect
                closeMenuOnSelect={false}
                value={selectedOption}
                required={true}
                onChange={setSelectedOption}
                isMulti
                className="basic-multi-select"
                classNamePrefix="select"
                options={options}
                id="categoria"
              />
            </div>
          </Col>
          <Col md={12} sm={24} xs={24}>
            <div className="form-dashboard-group">
              <Label for="ubicacion">Dirección</Label>
              <Input
                maxLength={50}
                type="text"
                name="ubicacion"
                errormessage=""
                onChange={handleInputChange}
                required={true}
              />
            </div>
          </Col>
          <Col md={6} sm={24} xs={24}>
            <div className="form-dashboard-group">
              <Label for="location">Localidad</Label>
              <Input
                maxLength={50}
                type="text"
                name="location"
                errormessage=""
                onChange={handleInputChange}
                required={true}
              />
            </div>
          </Col>
          <Col md={6} sm={24} xs={24}>
            <div className="form-dashboard-group">
              <Label for="titleCompany">Nombre de la empresa</Label>
              <Input
                maxLength={50}
                type="text"
                name="titleCompany"
                onChange={handleInputChange}
                required={true}
              />
            </div>
          </Col>
          <Col md={6} sm={24} xs={24}>
            <div className="form-dashboard-group">
              <Label for="mail">Correo</Label>
              <Input
                type="text"
                name="mail"
                onChange={handleInputChange}
                required={true}
              />
            </div>
          </Col>
          <Col md={6} sm={24} xs={24}>
            <div className="form-dashboard-group">
              <Label>Número de contacto</Label>
              <input
                name="phoneNumber"
                maxLength={10}
                minLength={10}
                onKeyPress={(e) => {
                  // Solo se admiten números
                  e = e || window.e;
                  const charCode = e.keyCode || e.which;
                  const charStr = String.fromCharCode(charCode);
                  if (isNaN(charStr)) e.preventDefault();
                }}
                type="text"
                onChange={handleInputChange}
                required={true}
              />
            </div>
          </Col>
          <Col md={6} sm={12} xs={24}>
            {image !== null ? (
              <div className="form-dashboard-group-upload">
                <div className="uploaded-file">
                  <img
                    src={image}
                    alt=""
                    className="img-file"
                    onClick={() => toggle(image)}
                  />
                  <Button
                    className="btn-remove-file"
                    onClick={handleImageUploadRemove}
                  >
                    <i className="icon-close"></i>
                  </Button>
                </div>
              </div>
            ) : (
              <GeneralUpload
                accept="image/*"
                isMultiple="false"
                TitleLabel={"Subir imagen"}
                handleOnChangeFiles={handleOnChangeFile}
              />
            )}
          </Col>
          <Col xs={24}>
            <br />
            <h4>Información salarial</h4>
          </Col>
          <Col md={6} sm={24} xs={24}>
            <InputGroup className="group-append">
              <InputGroupText>
                Salario bruto
                <Input type="radio" name="exampleRadios" />
              </InputGroupText>
              <Input
                type="number"
                min={0}
                step="0.01"
                name="infoSalarial"
                onChange={handleInputChange}
                required={true}
              />
            </InputGroup>
          </Col>
          <Col xs={24}>
            <br />
            <h4>Descripción de la empresa</h4>
          </Col>
          <Col xs={24}>
            <div className="content__editor_in_jobs">
              <Editor
                onEditorStateChange={onEditorStateChange}
                wrapperClassName="wrapper__editor_text"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                toolbar={{
                  options: [
                    "inline",
                    "colorPicker",
                    "blockType",
                    "fontSize",
                    // "fontFamily",
                    "list",
                    "textAlign",
                    // "image",
                    "link",
                    "remove",
                    "history",
                  ],
                  inline: {
                    options: ["bold", "italic", "underline", "strikethrough"],
                  },
                  list: { inDropdown: true },
                  textAlign: { inDropdown: true },
                  link: { inDropdown: true },
                  history: { inDropdown: true },
                }}
              />
            </div>
          </Col>
        </Row>

        <div className="form-dashboard-footer">
          <Button className="btn-dark" type="submit">
            <p>Continuar</p>
            <FaChevronRight />
          </Button>
        </div>
        <Modal
          isOpen={modal}
          toggle={toggle}
          centered={true}
          className="modal-images"
        >
          <ModalBody>
            <Button className="btn-close-modal" onClick={toggle}>
              <FaTimes />
            </Button>
            <img
              src={imgModal}
              width="100%"
              style={{ maxHeight: "300px", objectFit: "contain" }}
              alt=""
            />
          </ModalBody>
        </Modal>
      </article>
    </form>
  );
}
