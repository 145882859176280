import React, { useState } from "react";
import { useEffect } from "react";
import "./register.scss";
import { userService } from "../../services/user.service";
import { Legals } from "../../components/legals/legals";
import { BoxSocialNetworks } from "../../components/boxSocialNetworks";
import { RegisterAside } from "./registerAside";
import { NoticeMessage } from "./noticeMessage";
import { ImgCache } from "../../components/CustonHooks/imgCache";
import { Flex } from "antd";
import { RegisterHeader } from "./registerHeader";
import { SocialMedia } from "./socialMedia";
import { RegisterFooter } from "./registerFooter";
import { FooterFull } from "../../components/footer/footerFull";

export function VerifyAccount(props) {
  const [verify, setVerify] = useState({});
  useEffect(() => {
    userService
      .verifyAccount({
        UserId: props.location.search.split("userId=")[1].split("&")[0],
        Code: props.location.search.split("code=")[1],
      })
      .then((result) => setVerify(result));
  }, [props.location.search]);

  return (
    <section className="register__container">
      <article className="register__form">
        <div className="-form-light verificacion">
          <RegisterHeader
            title={`${
              verify.responseCode == "00" ? "¡Te has registrado" : "¡Error al"
            }`}
            subtitle={`${
              verify.responseCode == "00"
                ? "exitosamente!"
                : "verificar cuenta!"
            }`}
          />
          {verify && (
            <NoticeMessage
              title={`${
                verify.responseCode == "00"
                  ? "¡Verificación exitosa!"
                  : "¡Error al verificar cuenta!"
              }`}
              subtitle={`${verify.responseCode == "00" ? "" : "¡Ups!"}`}
              message={`${
                verify.responseCode == "00" ? "" : verify.responseMessage
              }`}
              backLink="login"
              success={`${verify.responseCode == "00" ? 1 : 0}`}
            />
          )}
        </div>
        <RegisterAside />
      </article>
      <FooterFull />
    </section>
  );
}
