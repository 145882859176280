import React, { useEffect, useState } from "react";

import "./blogCompany.scss";
import { Button, Col, Row } from "reactstrap";
import { Link } from "react-router-dom";

import "./blogCompany.scss";

import bgGallery from "../../assets/imgs/icon-images.png";
import imageEmpty from "../../assets/imgs/no-image.jpeg";
import { companyPostViewService } from "../../services/compayPostView.service";
import "moment/locale/es";
import Moment from "react-moment";
import { AsideOurCompany } from "../companyPost/asideOurCompany";
import { FooterHome } from "../../components/footer/footerHome";
import dayjs from "dayjs";

export const BlogCompanyPrimary = () => {
  const [posts, setPosts] = useState([]);
  const [selectedPost, setSelectedPost] = useState({});
  const [currentFilter, setCurrentFilter] = useState("");
  const [filter, setFilter] = useState([]);

  const clearVariable = () => {
    setSelectedPost({});
    setPosts([]);
  };

  const getPosts = async () => {
    clearVariable();
    companyPostViewService.getByFilters(currentFilter, 1).then((result) => {
      if (result && result.length > 0) {
        handleSelectPost(result[0]);
        setPosts(result);
        const lastMonthConverted = dayjs(result[0].publishDate).format(
          "YYYY-MM"
        );
        setCurrentFilter(lastMonthConverted);
        setFilter(
          filter.map((item) => {
            return {
              year: item.year,
              months: item.months.map((child) => {
                return {
                  activeItem:
                    dayjs(child.filterMonthDate).format("YYYY-MM") ===
                    lastMonthConverted,
                  count: child.count,
                  filterMonthDate: child.filterMonthDate,
                  month: child.month,
                };
              }),
            };
          })
        );
      }
    });
  };

  const handleSelectPost = (item) => {
    if (!item.isRead) {
      companyPostViewService
        .InsertRead({ CompanyPostId: item.companyPostId })
        .then((result) => {
          if (result) {
            setSelectedPost(result);
            if (posts.length > 0) {
              let items = posts.map((m) => {
                if (m.companyPostId === item.companyPostId) m.isRead = true;
                return m;
              });
            }
          }
        });
    } else {
      getPost(item.companyPostId);
    }
  };

  const getPost = (id) => {
    companyPostViewService.getById(id).then((result) => {
      if (result.postType === 0) setSelectedPost(result);
      if (result.postType === 1) result.iconPath = result.urlImage;
    });
  };

  const getFilters = async () => {
    companyPostViewService.getDateFilters(0, 1).then((result) => {
      if (result) {
        if (result && result.length > 0) {
          const lastItem = result[0];
          const lastMonth = lastItem.months[0];
          const lastMonthConverted = dayjs(lastMonth.filterMonthDate).format(
            "YYYY-MM-DD"
          );
          setCurrentFilter(lastMonthConverted);
          setFilter(
            result.map((item) => {
              return {
                year: item.year,
                months: item.months.map((child) => {
                  return {
                    activeItem:
                      dayjs(child.filterMonthDate).format("YYYY-MM-DD") ===
                      lastMonthConverted,
                    count: child.count,
                    filterMonthDate: child.filterMonthDate,
                    month: child.month,
                  };
                }),
              };
            })
          );
        }
      }
    });
  };

  useEffect(() => {
    getPosts();
  }, [currentFilter]);

  useEffect(() => {
    getFilters();
  }, []);

  const goToGallery = () => (window.location.href = `/blog-company/gallery`);

  return (
    <article className="dashboard-container-page">
      <Row>
        <Col sm={12}>
          <h2>Mundo Pride</h2>
        </Col>
      </Row>
      <Row className="d-flex-column">
        <Col lg={9} md={8} sm={12}>
          <div className="blog-company-main-card">
            {posts.slice(0, 1).map((card, index) => {
              return (
                <>
                  <div className="thumb" key={index}>
                    {card.urlImage ? (
                      <img src={card.urlImage} alt="" />
                    ) : (
                      <img src={imageEmpty} alt="" />
                    )}
                  </div>
                  <div className="content">
                    <h6>
                      <Moment
                        date={card.publishDate}
                        format="dddd D [de] MMMM [del] YYYY"
                      />
                    </h6>
                    <h3>{card.title}</h3>
                    <div className="flex-end">
                      <Link
                        to={`/our-company/1/${card.companyPostId}`}
                        className="btn-show-more"
                      >
                        Ver más <span></span>
                      </Link>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
          <div className="blog-company-cards">
            <Row>
              <Col sm={12}>
                <h3>Éste mes</h3>
              </Col>
              <Col lg={12}>
                {posts.slice(1).map((card, index) => {
                  return (
                    <div className="card-post" key={index + index}>
                      <div className="thumb">
                        {card.urlImage ? (
                          <img src={card.urlImage} alt="" />
                        ) : (
                          <img src={imageEmpty} alt="" />
                        )}
                      </div>
                      <div className="content">
                        <h6>
                          <Moment
                            date={card.publishDate}
                            format="dddd D [de] MMMM [del] YYYY"
                          />
                        </h6>
                        <h4>{card.title}</h4>
                        <div className="flex-end">
                          <Link
                            to={`/our-company/1/${card.companyPostId}`}
                            className="btn-show-more"
                          >
                            Ver más <span></span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                })}
                {posts.slice(0, -1).length === 0 ? (
                  <div className="card-empty">
                    <p>No hay publicaciones de éste mes.</p>
                  </div>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </div>
        </Col>
        <Col lg={3} md={4} sm={12}>
          <aside className="aside_gallery">
            <Link to={`/blog-company/gallery`} className="gallery_content_link">
              <div className="bg-gradient-pride"></div>
              <img src={bgGallery} alt="Galeria Mundo Pride" />
              <p>Mundo Pride</p>
              <h2 className="mb-4">Galería </h2>
              <Button onClick={goToGallery} className="btn">
                Ir a Galería
              </Button>
            </Link>
            <AsideOurCompany
              setCurrentFilter={setCurrentFilter}
              currentFilter={currentFilter}
              filters={filter}
            />
          </aside>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <FooterHome />
        </Col>
      </Row>
    </article>
  );
};
