import React, { useState, useEffect } from "react";
import { WrapperCategoriesRewards } from "./wrapperCategoriesRewards";
import { HeadRewards } from "./headRewards";
import "./rewards.scss";
import { rewardsService } from "../../services/rewards.service";
import { FooterHome } from "../../components/footer/footerHome";
import { Col, Row } from "reactstrap";

export function Rewards() {
  const [reward, setReward] = useState([]);
  const [rewardCategory, setRewardCategory] = useState([]);
  const [rewardProducts, setRewardProducts] = useState([]);

  useEffect(() => {
    rewardsService.rewardCategories().then((result) => {
      setRewardCategory(result);
      getRewardProducts(0);
    });
  }, []);

  const getRewardProducts = async (rewardCategory) => {
    rewardsService.rewardProducts(rewardCategory).then((result) => {
      setReward(result.reward);
      setRewardProducts(result.items);
    });
  };

  return (
    <article className="dashboard-container-page page__rewards">
      <HeadRewards reward={reward} />
      <WrapperCategoriesRewards
        reward={reward}
        rewardCategory={rewardCategory}
        rewardProducts={rewardProducts}
        getRewardProducts={getRewardProducts}
      />
      <FooterHome />
    </article>
  );
}
