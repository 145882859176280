import React, { useState } from "react";
import { ListNotice } from "./listNotice";
import { EditNotice } from "./editNotice";
import { TitleAdmin } from "../../components/titleAdmin/titleAdmin";
import { HeadingAdmin } from "../../components/headingAdmin/headingAdmin";
import { PlusCircleOutlined } from "@ant-design/icons";
import { FaTimesCircle } from "react-icons/fa";

export function Notice() {
  const [showEdit, setShowEdit] = useState(false);
  const [selectedCompanyHoliday, setSelectedCompanyHoliday] = useState();
  const [refresh, setRefresh] = useState(0);

  const handleOnCompanyHolidaySelected = (CompanyHoliday) => {
    setShowEdit(true);
    setSelectedCompanyHoliday(CompanyHoliday);
  };
  const handleOnNewCompanyHoliday = () => {
    setSelectedCompanyHoliday(null);
    setShowEdit(true);
  };
  const handleOnCancelEdit = () => {
    setSelectedCompanyHoliday(null);
    setShowEdit(!showEdit);
  };

  const handleOnSaveEdit = (result) => {
    setRefresh((prev) => prev + 1);
    if (result) setShowEdit(false);
  };

  return (
    <>
      <TitleAdmin
        title={"Avisos"}
        subtitle={"Listado y alta de avisos"}
        btnBack={false}
      />
      <HeadingAdmin
        text={!showEdit ? "Agregar aviso" : "Cancelar"}
        icon={!showEdit ? <PlusCircleOutlined /> : <FaTimesCircle />}
        event={() =>
          !showEdit ? handleOnNewCompanyHoliday() : handleOnCancelEdit()
        }
      />
      {showEdit ? (
        <EditNotice
          currentCompanyHoliday={selectedCompanyHoliday}
          onSave={handleOnSaveEdit}
        />
      ) : (
        <ListNotice
          onSelectCompanyHoliday={handleOnCompanyHolidaySelected}
          refresh={refresh}
        />
      )}
    </>
  );
}
