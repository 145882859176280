import React from 'react';

import { Link } from 'react-router-dom';
import {FaChevronLeft} from "react-icons/fa";

export function Header({title, description, buttons, btnBack}) {

    return (
        <header className="header">
            <div className="header-title">
                <h2>{title}</h2>
                {
                    (btnBack && btnBack.length !== 0) ?
                        <Link to={btnBack[0].link} className="link-back">
                            <FaChevronLeft />
                            <p>{btnBack[0].nameButton}</p>
                        </Link>
                        : null
                }
            </div>
            <div className="header-content"><p>{description}</p></div>
            {
                (buttons) ?
                    <div className="header-footer">
                        {
                            (buttons && buttons.map((item,i) => {
                                return (
                                    <Link key={i} className="btn-dark" to={item.link}>
                                        <p>{item.nameButton}</p>
                                        <div className="circle">
                                            <i className="icon-add"></i>
                                        </div>
                                    </Link>
                                );
                            }))
                        }
                    </div>
                    : null
            }
        </header>
    );
}
