import React, {useEffect, useState, forwardRef, useImperativeHandle, useRef} from 'react';
import { deparmentService } from '../../services/deparment.service';
import '../admin/admin.scss';
import {Button, Input, Space, Table, Tag} from "antd";
import MySwal from "sweetalert2";
import {SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {FaEdit, FaTrash} from "react-icons/fa";


const SearchDepartment = forwardRef((props, ref) => {

	const [dataGrid, setDataGrid] = useState([]);
	const [searchText, setSearchText] = useState('');
	const [searchedColumn, setSearchedColumn] = useState('');
	const searchInput = useRef(null);

	const handleDelete = (data) => {
		MySwal.fire({
			title:"¿Deseas desactivar este grupo?",
			text: "No podrás revertir esta acción",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Eliminar',
			cancelButtonText: 'Cancelar'
		}).then((result) => {
			if(result.isConfirmed)
				deparmentService.deletetDeparment(data).then((result) => getDeparment());
		})
	};

	async function getDeparment() {
		deparmentService.getDeparment()
			.then((result) => {
				if (result) setDataGrid(result);
			});
	}

	const refreshDataGrid = () => getDeparment();

	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	};

	const handleReset = (clearFilters) => {
		clearFilters();
		setSearchText('');
	};

	const getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
			<div style={{padding: 8,}} onKeyDown={(e) => e.stopPropagation()}>
				<Input
					ref={searchInput}
					placeholder={`Buscar`}
					value={selectedKeys[0]}
					onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
					style={{marginBottom: 8, display: 'block',}}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined />}
						size="small"
						style={{width: 90,}}
					>
						Buscar
					</Button>
					<Button
						onClick={() => clearFilters && handleReset(clearFilters)}
						size="small"
						style={{width: 90,}}
					>
						Limpiar
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered) => (
			<SearchOutlined style={{color: filtered ? '#1677ff' : undefined,}}/>
		),
		onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
		onFilterDropdownOpenChange: (visible) => {
			if (visible) setTimeout(() => searchInput.current?.select(), 100);
		},
		render: (text) =>
			searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{backgroundColor: '#ffc069', padding: 0,}}
					searchWords={[searchText]}
					autoEscape
					textToHighlight={text ? text.toString() : ''}
				/>
			) : (text),
	});

	const columnsDepartment = [
		{
			title: 'Grupo',
			dataIndex: 'name',
			key: 'name',
			...getColumnSearchProps('name'),
		},
		{
			title: 'Estatus',
			dataIndex: 'status',
			key: 'status',
			sorter: (a, b) => {
				if(a.status < b.status) {
					return -1;
				}
				if(a.status > b.status) {
					return 1;
				}
				return 0;
			},
			sortDirections: ['descend','ascend'],
		},

		{
			title: 'Acciones',
			dataIndex: 'options',
			key: 'options',
		},
	];

	const dataDepartment = dataGrid.map(department => {
		return {
			key: department.departmentId,
			name: department.departmentName,
			status: <Tag color={department.estatus ? 'success' : 'danger'}>{department.estatus}</Tag>,
			options: <Space>
				<Button
					type="primary"
					htmlType={'button'}
					className="edit"
					onClick={() => props.handleRowSelect(department)}
				>
					<FaEdit />
				</Button>
				<Button
					type="primary"
					htmlType={'button'}
					className="delete"
					onClick={() => handleDelete(department)}
				>
					<FaTrash />
				</Button>
			</Space>
		};
	})

	useEffect(() => {
		getDeparment();
	}, []);

	useImperativeHandle(ref, () => ({
		refreshDataGrid: refreshDataGrid
	}));

	return (
		<Table
			className="table-admin"
			columns={columnsDepartment}
			dataSource={dataDepartment}
			scroll={{ x: '992px', y: 800 }}
			locale={{
				triggerDesc: 'Ordenamiento descendente',
				triggerAsc: 'Ordenamiento ascedente',
				cancelSort: 'Cancelar ordenamiento',
				emptyText: 'Sin registros',
			}}
		/>
	);

});

export default SearchDepartment;
