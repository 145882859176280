import { callApi } from '../service'
import Swal from "sweetalert2";

export const companyService = {
    create,
    get,
    getById,
    getByDomain,
    edit,
    deleteCompany,
    getRVOL
};

async function create(data) {
    return callApi('/BackOffice/Company', 'POST', data)
        .then((result) => {
            if (result.responseCode === '00') {
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Se guardó correctamente',
                    showConfirmButton: true,
                    confirmButtonColor: '#00AD42',
                    timer: 3000
                }).then(() => window.location.href = "/operator/AdminCompany")
            } else {
                console.log(result.responseMessage);
                Swal.fire({
                    icon: 'error',
                    title: 'Ups...',
                    text: result.responseMessage,
                    showConfirmButton: true,
                    confirmButtonColor: '#FF002A',
                })
            }
        });
}

async function get(status) {
    return await callApi('/BackOffice/Company?status=' + status, 'GET')
        .then(result => {
            if (result.responseCode == "00")
                return result.data;
            else
                return [];
        })
        .catch(error => {
            console.log('error', error);
            return []
        });
}

async function getById(id) {
    return callApi('/BackOffice/Company/' + id, 'GET').then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            alert(result.responseMessage);
            return null;
        }
    });
}

async function getByDomain(domain) {
    return callApi('/BackOffice/Company/GetByDomain/' + domain, 'GET').then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            alert(result.responseMessage);
            return null;
        }
    });
}

async function edit(data) {
    return callApi('/BackOffice/Company', 'PUT', data)
        .then((result) => {
            if (result.responseCode === "00") {
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Se actualizó correctamente',
                    showConfirmButton: true,
                    confirmButtonColor: '#00AD42',
                    timer: 3000
                }).then(() => window.location.href = "/operator/AdminCompany")
                return true;
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Ups...',
                    text: result.responseMessage,
                    showConfirmButton: true,
                    confirmButtonColor: '#FF002A',
                })
                return false;
            }
        });
}

async function deleteCompany(id) {
    return callApi('/BackOffice/Company/' + id, 'DELETE', null).then((result) => {
        if (result.responseCode === '00') {
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Se desactivó correctamente',
                showConfirmButton: true,
                confirmButtonColor: '#00AD42',
                timer: 3000
            })
            return result.data;
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Ups...',
                text: "Error al desactivar la compañía",
                showConfirmButton: true,
                confirmButtonColor: '#FF002A',
            })
            return null;
        }
    });
}

async function getRVOL() {
    return await callApi('/Ecommerce/Company', 'GET')
        .then(result => {
            if (result.responseCode == "00")
                return result.data;
            else
                return [];
        })
        .catch(error => {
            console.log('error', error);
            return []
        });
}


