import React, { useState, useEffect } from "react";
import { Col, Row, Card, Button, Tag, Result } from "antd";
import { FooterHome } from "../../components/footer/footerHome";
import { useHistory } from "react-router-dom";
import { EventApproveModal } from "./eventApproveModal";
import { useSelector } from "react-redux";
import moment from "moment";
import MySwal from "sweetalert2";
import { HeaderCourse } from "./headerCourse";
import { ticketsEventService } from "../../services/ticketsEvent.service";

export const EventApprove = () => {
  const roleName = useSelector((state) => state.userSettings.roleName);
  const history = useHistory();
  const isApprovalView =
    history.location.pathname == "/course-view/approve-event";
  const [open, setOpen] = useState(false);
  const [events, setEvents] = useState([]);
  const [currentEvent, setCurrentEvent] = useState({});

  const init = async () => {
    if (isApprovalView) {
      const result = await ticketsEventService.getToAuthorize();
      setEvents(result);
    } else {
      const result = await ticketsEventService.getByUser();
      setEvents(result);
    }
  };

  useEffect(() => {
    init();
  }, []);

  const showDrawer = (gridItem) => {
    setCurrentEvent(gridItem);
    setOpen(true);
  };

  const onClose = () => setOpen(false);

  const handleEventChangeStatus = async (ticketsEventId, isApproved) => {
    const confirmationResult = await MySwal.fire({
      title: `¿Deseas ${isApproved ? "autorizar" : "rechazar"} este evento?`,
      text: "No podrás revertir esta acción",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `${isApproved ? "Autorizar" : "Rechazar"}`,
      cancelButtonText: "Cancelar",
    });

    if (confirmationResult.isConfirmed) {
      const result = await ticketsEventService.changeStatus({
        ticketsEventId,
        isApproved,
      });

      if (result) {
        setOpen(false);
        init();
      }
    }
  };

  return (
    <section className="course-container-view">
      <Row gutter={16} style={{ margin: "0" }}>
        <Col xs={24}>
          <HeaderCourse
            title={"Cartelera"}
            event={() => history.push("/course-view")}
          />
        </Col>
        <Col xs={24}>
          <div className={"event-pride-approve-events"}>
            {isApprovalView ? (
              <h3>
                Eventos por autorizar{" "}
                <Tag color={"#ff2f24"}>{events.length}</Tag>
              </h3>
            ) : (
              <h3>
                Mis eventos <Tag color={"#ff2f24"}>{events.length}</Tag>
              </h3>
            )}
          </div>
        </Col>
        {events.length === 0 ? (
          <Col xs={24}>
            <Result status="info" title="No hay eventos en este momento" />
          </Col>
        ) : (
          events.map((item) => {
            return (
              <Col
                xl={{ offset: 4, span: 16 }}
                lg={{ offset: 2, span: 18 }}
                md={{ offset: 0, span: 24 }}
                sm={12}
                xs={24}
                key={item.ticketsEventId}
              >
                <Card
                  className={"event-pride-approve-card"}
                  onClick={() => showDrawer(item)}
                >
                  <div className={"left"}>
                    <div className={"img"}>
                      <img alt={""} src={item.eventTicketBanner} />
                    </div>
                    <div className={"info"}>
                      <h2>
                        {item.eventName.length >= 52
                          ? item.eventName.substring(0, 52) + "..."
                          : item.eventName}
                      </h2>
                      <p>
                        Sector: <span>{item.ticketsEventSectorName}</span>
                      </p>
                      <p>
                        Aforo:<span>{item.eventAudience}</span>
                      </p>
                    </div>
                  </div>
                  <div className={"right"}>
                    <div className={"info"}>
                      <p>
                        Por: <br />
                        <span>{item.eventOwnerName}</span>
                      </p>
                      <p>
                        En: <br />
                        <span>
                          {item.eventPlaceName}, {item.eventState}
                        </span>
                      </p>
                    </div>
                    <p>
                      Fecha de registro: <br />
                      <span>
                        {moment(item.creationDate).format(
                          "DD/MM/YYYY,  HH:mm A"
                        )}
                      </span>
                    </p>
                  </div>
                </Card>
              </Col>
            );
          })
        )}
        <EventApproveModal
          open={open}
          onClose={onClose}
          currentItem={currentEvent}
          changeStatus={handleEventChangeStatus}
          isApprovalView={isApprovalView}
        />
        <Col xs={24}>
          <FooterHome />
        </Col>
      </Row>
    </section>
  );
};
