import React, { useState } from "react";
import { Tooltip } from "reactstrap";
import {
  BsFacebook,
  BsInstagram,
  BsLink,
  BsLinkedin,
  BsTiktok,
  BsYoutube,
} from "react-icons/bs";

export function ItemSocial(props) {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <>
      <a
        href={props.socialUrl}
        target="_blank"
        className="a_ico_social"
        id={"Tooltip-" + props.socialIcoName}
      >
        {props.socialIcoName === "Instagram" ? <BsInstagram /> : ""}
        {props.socialIcoName === "LinkedIn" ? <BsLinkedin /> : ""}
        {props.socialIcoName === "Facebook" ? <BsFacebook /> : ""}
        {props.socialIcoName === "Twitter" ? (
          <svg viewBox="0 0 512 512" fill="white" height="16">
            <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
          </svg>
        ) : (
          ""
        )}
        {props.socialIcoName === "TikTok" ? <BsTiktok /> : ""}
        {props.socialIcoName === "Youtube" ? <BsYoutube /> : ""}
        {props.socialIcoName === "WebSite" ? <BsLink /> : ""}
        {props.socialIcoName === "Web" ? <BsLink /> : ""}
      </a>
      <Tooltip
        placement="top"
        isOpen={tooltipOpen}
        target={"Tooltip-" + props.socialIcoName}
        toggle={toggle}
      >
        {props.socialIcoName}
      </Tooltip>
    </>
  );
}
