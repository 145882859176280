import React from "react";
import "./audience.scss";
import "moment/locale/es";
import bgPridetalks from "../../assets/imgs/dashboard/bg_pridetalks.svg";
import { Button } from "antd";
import { AiOutlinePlayCircle } from "react-icons/ai";
import dayjs from "dayjs";
import es from "dayjs/locale/es";

export const LastItemAudience = (props) => {
  return (
    <>
      <article
        className="audience-header"
        style={{ backgroundImage: `url(${bgPridetalks})` }}
        key={props.item.eventId}
      >
        <div className="audience-header__info">
          <div className="audience-header__info-text">
            <div className={"content"}>
              <h4>¡Disfruta de la última transmisión!</h4>
              <h3>
                {props.item.data.title.length >= 50
                  ? props.item.data.title.substring(0, 50) + "..."
                  : props.item.data.title}
              </h3>
              <p>{`${dayjs(props.item.data.conferenceDate)
                .locale(es)
                .format("dddd")} ${dayjs(props.item.data.conferenceDate).format(
                "DD"
              )} de ${dayjs(props.item.data.conferenceDate)
                .locale(es)
                .format("MMMM")} a las ${dayjs(
                props.item.data.conferenceDate
              ).format("HH:mm")} hrs`}</p>
            </div>
            <div className="audience-header__info-btn">
              <Button
                onClick={() =>
                  props.handleLinkConference(props.item, true, true)
                }
                className="-orange"
                icon={<AiOutlinePlayCircle />}
                htmlType={"button"}
                type={"primary"}
              >
                Ver video
              </Button>
            </div>
          </div>
        </div>
        <div className="audience-header__thumb">
          <div className="audience-card">
            <div className="img">
              <img
                src={props.item.files.length > 0 && props.item.files[0].path}
                alt={props.item.data.title}
              />
            </div>
          </div>
        </div>
      </article>
    </>
  );
};
