import React from 'react';

import './titleAdmin.scss'
import {FaChevronLeft} from "react-icons/fa";
import {Button} from "antd";

export function TitleAdmin({title,subtitle,event, btnBack = true}) {
    return (
        <div className="admin-title">
            <div>
                <h2>{title}</h2>
                <h6>{subtitle}</h6>
            </div>
            {
                (btnBack) ?
                    <Button type={'primary'} htmlType={'button'} className="btn-back" onClick={event}>
                        <FaChevronLeft />
                        <p>Regresar</p>
                    </Button>
                    : null
            }
        </div>
    );
}
