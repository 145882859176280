import React from "react";
import { Link } from "react-router-dom";

import "./rewards.scss";
import { Col, Row } from "reactstrap";
import imagePoints from "../../assets/imgs/banner-rewards.png";

export function HeadRewards({ reward }) {
  return (
    <>
      <div className="rewards-title">
        <h2>Rewards</h2>
      </div>

      <Row>
        <Col lg={6}>
          <section className="rewards-account">
            <article className="rewards-account-title">
              <h4>Estado de cuenta</h4>
            </article>
            <article className="rewards-account-content">
              <div className="rewards-account-cards">
                <div className="rewards-account-card">
                  <Link to="/rewards">
                    <div className="balance">
                      <h4>Saldo</h4>
                      <div className="icon-container">
                        <i className="icon-Asset-357"></i>
                      </div>
                    </div>
                    <div className="points">
                      <h5>{reward}</h5>
                      <h6>Puntos</h6>
                      <Link to="/rewards" className="btn-budget">
                        Ver Saldo <span></span>
                      </Link>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="rewards-account-btns">
                <Link to="/redemption-history" className="btn-gradient-orange">
                  <p>Historial de canje</p>
                  <span>
                    <i className="icon-chevron-right"></i>
                  </span>
                </Link>
                <Link to="/account-status" className="btn-gradient-orange">
                  <p>Estado de cuenta</p>
                  <span>
                    <i className="icon-chevron-right"></i>
                  </span>
                </Link>
              </div>
            </article>
          </section>
        </Col>
        <Col lg={6}>
          <h3>¿Cómo ganar puntos?</h3>
          <div className="rewards-points">
            <img alt="" src={imagePoints} />
          </div>
        </Col>
      </Row>
    </>
  );
}
