import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { JobBankService } from "../../services/jobBank.service";
import { Col, Row } from "antd";
import { FaChevronRight } from "react-icons/fa";
import { Button } from "rsuite";
import { Label } from "reactstrap";

export function FormEditSectionJob({ id, toggleView, job }) {
  const [sections, setSections] = useState([]);
  const [defaultContentState, setDefaultContentState] = useState();
  const [sectionValues, setSectionValues] = useState([]);

  useEffect(() => {
    JobBankService.getJobSections().then((data) => {
      let defaultContentState = data.map((section) => {
        let description = job.jobSection.find(
          (x) => x.jobSectionTypeId === section.jobSectionTypeId
        )?.description;
        if (description === undefined) return EditorState.createEmpty();
        let blocksFromHtml = htmlToDraft(description);
        let { contentBlocks, entityMap } = blocksFromHtml;
        let contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        return EditorState.createWithContent(contentState);
      });

      setDefaultContentState(defaultContentState);
      setSections(data);
      setSectionValues(
        data.map((section) => {
          return {
            JobSectionTypeId: section.jobSectionTypeId,
            JobSection: job.jobSection.find(
              (x) => x.jobSectionTypeId === section.jobSectionTypeId
            ) || {
              description: "",
              jobSectionTypeId: section.jobSectionTypeId,
            },
          };
        })
      );
    });
  }, []);

  const onEditorStateChange = (editorState, section) => {
    let descriptionHtml = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    let newSectionValues = sectionValues.map((x) => {
      if (x.JobSectionTypeId === section.jobSectionTypeId) {
        x.JobSection.description = descriptionHtml;
        x.JobSection.jobSectionTypeId = section.jobSectionTypeId;
      }
      return x;
    });
    setSectionValues(newSectionValues);
  };

  const onSave = (e) => {
    var validSections = false;
    sectionValues.map((x) => {
      if (
        x.JobSection.description != "" &&
        x.JobSection.description != "<p></p>" &&
        x.JobSection.description != "<p></p>\n"
      ) {
        validSections = true;
      }
    });
    if (validSections == false) {
      Swal.fire({
        text: "Favor de completar al menos una sección",
        icon: "warning",
        showCloseButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      return;
    }
    let JobSections = {
      JobId: id,
      JobSection: sectionValues.map((x) => x.JobSection),
    };
    JobBankService.updateJobSections(JobSections).then((data) => {
      if (data !== null)
        Swal.fire({
          text: "Se han actualizado las secciones de trabajo",
          icon: "success",
          showCloseButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then(() => {
          window.location.href = "/job-bank";
        });
    });
  };

  return (
    <>
      <article className="steps">
        <div className="item">
          <span>1</span>
        </div>
        <div className="lines"></div>
        <div className="item active">
          <span>2</span>
        </div>
      </article>

      <article className="content">
        <Row gutter={16}>
          <Col xs={24}>
            <h5 className="mb-4">Información adicional</h5>
          </Col>
          {sections.map((section, index) => {
            return (
              <div className="w-100" key={index}>
                <Col xs={24}>
                  <h6 className="mb-2 ff_medium">
                    {section.name === "Ubicacion" ? "Ubicación" : section.name}
                  </h6>
                </Col>
                <Col xs={24}>
                  <div className="form-dashboard-group mt-1 mb-5">
                    {defaultContentState?.[index] && (
                      <Editor
                        defaultEditorState={defaultContentState?.[index]}
                        onEditorStateChange={(e) =>
                          onEditorStateChange(e, section)
                        }
                        wrapperClassName="wrapper__editor_text"
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class"
                        toolbar={{
                          options: [
                            "inline",
                            "colorPicker",
                            "blockType",
                            "fontSize",
                            // "fontFamily",
                            "list",
                            "textAlign",
                            // "image",
                            "link",
                            "remove",
                            "history",
                          ],
                          inline: {
                            options: [
                              "bold",
                              "italic",
                              "underline",
                              "strikethrough",
                            ],
                          },
                          list: { inDropdown: true },
                          textAlign: { inDropdown: true },
                          link: { inDropdown: true },
                          history: { inDropdown: true },
                        }}
                      />
                    )}
                  </div>
                </Col>
              </div>
            );
          })}
          <Col xs={24}>
            <div className="form-dashboard-footer">
              <Button className="btn-dark" onClick={onSave}>
                Editar secciones
                <FaChevronRight />
              </Button>
            </div>
          </Col>
        </Row>
      </article>
    </>
  );
}