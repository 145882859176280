import React, {useEffect, useState} from 'react';

import { productFilterService } from '../../services/backoffice/rewardProductFilter.service';
import {Col, Form, Button, Row, Select} from "antd";
import {FaSave} from "react-icons/fa";
import {InputAdd} from "../components/inputAdd/inputAdd";
import {useParams} from "react-router-dom";

export function EditProductFilter(props) {
    //const dispatch = useDispatch();
    const {id} = useParams();
    const [showEdit, setShowEdit] = useState(false);
    const [minCouponCount, setMinCouponCount] = useState(-99999);

    //const [stock, setStock] = useState(0);
    const [catalogs, setCatalog] = useState({
        branchOfficeCatalog: [],
        departmentCatalog: [],
        jobRoleCatalog: [],
        productCatalog: []

    });

    const [productFilter, setProductFilter] = useState({
        RewardProductFilterId: 0,
        CompanyId: 0,
        RewardProductId: 0,
        BranchOfficeId: 0,
        DepartmentId: 0,
        JobRoleId: 0,
        Points: 0,
        Active: null,
        RewardCount: 0,
        FilterStock: 0,
        ParentStock: 0
    });

    const onSave = () => {
        productFilter.BranchOfficeId = parseInt(productFilter.BranchOfficeId);
        if(productFilter.BranchOfficeId === 0) productFilter.branchOfficeName = 'Todos'

        productFilter.DepartmentId = parseInt(productFilter.DepartmentId);
        if(productFilter.DepartmentId === 0) productFilter.departmentName = 'Todos'

        productFilter.JobRoleId = parseInt(productFilter.JobRoleId);
        if(productFilter.JobRoleId === 0) productFilter.roleName = 'Todos'

        productFilter.RewardProductId = parseInt(productFilter.RewardProductId);
        productFilter.Points = parseInt(productFilter.Points)
        productFilter.RewardCount = parseInt(productFilter.RewardCount)
        productFilter.ParentStock = parseInt(productFilter.ParentStock)
        if (productFilter.RewardProductFilterId && productFilter.RewardProductFilterId > 0)
            productFilterService.edit(productFilter);
        else
            productFilterService.create(productFilter);
    };

    useEffect(() => {
        productFilterService.getCatalogs().then((result) => {
            setCatalog({
                branchOfficeCatalog: result.branchOffice,
                departmentCatalog: result.department,
                jobRoleCatalog: result.jobRole,
                productCatalog: result.rewardProduct
            })
        });
    }, []);

    useEffect(() => {
        if (parseInt(id) !== 0) {
            setShowEdit(true);
            productFilterService.getById(props.rewardProductFilterId).then((result) => {
                setMinCouponCount(result.filterStock * -1);
                console.log(result)
                setProductFilter({
                    RewardProductFilterId : result.rewardProductFilterId,
                    CompanyId: result.companyId,
                    RewardProductId: String(result.rewardProductId),
                    BranchOfficeId: String(result.branchOfficeId),
                    DepartmentId: String(result.departmentId),
                    JobRoleId: String(result.jobRoleId),
                    Points: result.points,
                    Active: result.active,
                    RewardCount: result.rewardCount === null ? 0 : result.rewardCount,
                    FilterStock: result.filterStock,
                    ParentStock: result.parentStock,

                });
            });
        } else  {
            setMinCouponCount(1);
            setShowEdit(false);
        }
    }, [id]);

    const handleChangeBranchOffice = (value) => setProductFilter({...productFilter, BranchOfficeId: value})

    const handleChangeDepartmentId = (value) => setProductFilter({...productFilter, DepartmentId: value})

    const handleChangeJobRoleId = (value) => setProductFilter({...productFilter, JobRoleId: value})

    const handleChangeRewardProductId = (value) => setProductFilter({...productFilter, RewardProductId: value.target.value})

    const handleFilsterStock = (value) => setProductFilter({...productFilter, FilterStock: value})

    const handleRewardCount = (value) => setProductFilter({...productFilter, RewardCount: value})

    const handlePoints = (value) => setProductFilter({...productFilter, Points: value})

    return (
        <>
            <Form
                layout={'vertical'}
                onFinish={onSave}
                className="form-backoffice"
                  fields={[
                      {
                          "name": ["RewardProductId"],
                          "value": productFilter.RewardProductId ? productFilter.RewardProductId : ''
                      },
                      {
                          "name": ["BranchOfficeId"],
                          "value": productFilter.BranchOfficeId ? productFilter.BranchOfficeId : '0'
                      },
                      {
                          "name": ["DepartmentId"],
                          "value": productFilter.DepartmentId ? productFilter.DepartmentId : '0'
                      },
                      {
                          "name": ["JobRoleId"],
                          "value": productFilter.JobRoleId ? productFilter.JobRoleId : '0'
                      },
                      {
                          "name": ["Points"],
                          "value": parseInt(productFilter.Points)
                      },
                      {
                          "name": ["RewardCount"],
                          "value": parseInt(productFilter.RewardCount)
                      },
                  ]}
            >
                <Row gutter={16}>
                    <Col md={12} sm={24} xs={24}>
                        <Form.Item
                            label={'Sucursal'}
                            htmlFor={'BranchOfficeId'}
                            name={'BranchOfficeId'}
                        >
                            <Select
                                name="BranchOfficeId"
                                onChange={handleChangeBranchOffice}
                            >
                                <Select.Option value={'0'}>Todos</Select.Option>
                                {catalogs.branchOfficeCatalog.map((item,i) => {
                                    return (
                                        <Select.Option value={item.value} key={`${item.value},${i}`}>{item.label}</Select.Option>
                                    )}
                                )}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col md={12} sm={24} xs={24}>
                        <Form.Item
                            label={'Grupo'}
                            htmlFor={'DepartmentId'}
                            name={'DepartmentId'}
                        >
                            <Select
                                name="DepartmentId"
                                onChange={handleChangeDepartmentId}
                            >
                                <Select.Option value={'0'}>Todos</Select.Option>
                                {catalogs.departmentCatalog.map((item,i) => {
                                    return (
                                        <Select.Option value={item.value} key={`${item.value},${i}`}>{item.label}</Select.Option>
                                    )}
                                )}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col md={12} sm={24} xs={24}>
                        <Form.Item
                            label={'Categoría'}
                            htmlFor={'JobRoleId'}
                            name={'JobRoleId'}
                        >
                            <Select
                                name="JobRoleId"
                                onChange={handleChangeJobRoleId}
                            >
                                <Select.Option value={'0'}>Todos</Select.Option>
                                {catalogs.jobRoleCatalog.map((item,i) => {
                                    return (
                                        <Select.Option value={item.value} key={`${item.value},${i}`}>{item.label}</Select.Option>
                                    )}
                                )}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col md={12} sm={24} xs={24}>
                        <Form.Item
                            label={'Producto'}
                            htmlFor={'RewardProductId'}
                            rules={[
                                {
                                    required: true,
                                    message: '*Campo requerido'
                                }
                            ]}
                            name={'RewardProductId'}
                        >
                           <select className="form-control" id="rewardProductId" name="rewardProductId" onChange={handleChangeRewardProductId} required>
                                <option value="rewardProductId">Seleccione </option>
                                    {
                                        catalogs.productCatalog.map(item => <option key={item.value} value={item.value} stock={item.stock} > {item.label + ' | Existencia en bolsa: ' + item.stock}</option>)
                                    }
                                </select>
                        </Form.Item>
                    </Col>
                    {(showEdit) ?
                        <Col md={12} sm={24} xs={24}>
                            <Form.Item
                                label={'Stock del filtro'}
                                htmlFor={'FilterStock'}
                                name={'FilterStock'}
                                initialValue={productFilter.FilterStock}
                            >
                                <InputAdd
                                    name={'FilterStock'}
                                    maxLength={5}
                                    maxQuantity={99999}
                                    minQuantity={0}
                                    valueInt={productFilter.FilterStock}
                                    handleCurrentNumber={handleFilsterStock}
                                    disabled={true}
                                />
                            </Form.Item>
                        </Col>
                        : null
                    }
                    <Col md={12} sm={24} xs={24}>
                        <Form.Item
                            label={'Cant. Cupones'}
                            htmlFor={'RewardCount'}
                            rules={[
                                {
                                    required: true,
                                    message: '*Campo requerido'
                                }
                            ]}
                            name={'RewardCount'}
                        >
                            <InputAdd
                                maxLength={5}
                                name={'RewardCount'}
                                maxQuantity={99999}
                                minQuantity={-99999}
                                valueInt={productFilter.RewardCount}
                                handleCurrentNumber={handleRewardCount}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={12} sm={24} xs={24}>
                        <Form.Item
                            label={'Puntos'}
                            htmlFor={'Points'}
                            rules={[
                                {
                                    required: true,
                                    message: '*Campo requerido'
                                }
                            ]}
                        >
                            <InputAdd
                                maxLength={5}
                                name={'Points'}
                                maxQuantity={99999}
                                minQuantity={0}
                                valueInt={productFilter.Points}
                                handleCurrentNumber={handlePoints}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <div className="form-backoffice-footer">
                    <Button type={'primary'} htmlType="submit">
                        <p>Guardar</p>
                        <FaSave />
                    </Button>
                </div>
            </Form>
        </>
    );
}
