import React, { useState, useEffect } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorCommentsBox } from "./editorCommentsBox";
import { ItemsComment } from "./itemsComment";
import { companyPostCommentService } from "../../services/companyPostComment.service";
import swal from "sweetalert";

export function CommentsBox({
  companyPostId,
  bandera,
  profileUser,
  updateCountComment,
  reportId,
  setBandera,
  postType,
}) {
  const [comments, setComments] = useState([]);

  async function getComment(id) {
    companyPostCommentService
      .getById(id)
      .then((result) => {
        if (result && result.length > 0) {
          updateCountComment(result.length);
          setComments(result);
        }
      })
      .catch((err) => console.log("ERROR EN ENDPOINT comments" + err));
  }

  async function newComment(comment) {
    let newComm = {
      History: comment,
      CompanyPostId: companyPostId,
    };

    companyPostCommentService
      .create(newComm)
      .then((result) => getComment(companyPostId));
  }

  async function newSubComment(comment, SubcommentId) {
    let newSubComm = { Comment: comment };
    companyPostCommentService
      .newSubComment(newSubComm, SubcommentId)
      .then((result) => getComment(companyPostId));
  }

  async function ReportComment(CommentId, SubCommentId) {
    companyPostCommentService
      .reportComment(
        postType,
        CommentId,
        SubCommentId === undefined ? -1 : SubCommentId
      )
      .then((result) => {
        swal({ text: "Comentario Reportado", icon: "info" });
        setComments([]);
        getComment(companyPostId);
      });
  }

  async function GetReportComment(reportId) {
    companyPostCommentService.getReportById(reportId).then((result) => {
      let arrayresult = [];
      if (result != null) arrayresult.push(result);
      setComments(arrayresult);
    });
  }

  async function DeleteComment(CommentId, SubCommentId) {
    companyPostCommentService
      .deleteComment(CommentId, SubCommentId === undefined ? -1 : SubCommentId)
      .then((result) => {
        if (result != null) {
          swal({ text: result, icon: "info" });
          GetReportComment(reportId);
        }
      });
  }

  useEffect(() => {
    setBandera(1);
    if (companyPostId && reportId === undefined) getComment(companyPostId);

    if (bandera === 1) {
      if (reportId) GetReportComment(reportId);
    }
  }, [companyPostId, reportId]);

  return (
    <div className="comments_content_gral">
      <EditorCommentsBox profileUser={profileUser} clickSave={newComment} />

      <div className="comment_history">
        {comments.length === 0 ? (
          <h6 className="mb-4">Sin comentarios</h6>
        ) : (
          <h6 className="mb-4">Comentarios</h6>
        )}

        {comments.map((item) => (
          <ItemsComment
            key={item.CommentId}
            contenido={item}
            profileUser={profileUser}
            clickSave={newSubComment}
            report={ReportComment}
            eliminar={DeleteComment}
          />
        ))}
      </div>
    </div>
  );
}
