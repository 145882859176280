import React from 'react';

import {Link, useHistory} from 'react-router-dom';
import './cardModule.scss'
import {FaExternalLinkAlt} from "react-icons/fa";

export function CardModule({title, description, link, icon, color = ''}) {

    const history = useHistory();

    return (
        <Link to={link} className={`card-module ${color === '' ? 'outline' : color}`}>
            <h3>{title}</h3>
            <div>{description}</div>
            <div className="card-module-footer">
                <button className="btn-show-more" onClick={() => history.push(link)}><FaExternalLinkAlt /></button>
            </div>
            <i className="bg-icon"><span className={icon}></span></i>
        </Link>
    );
}
