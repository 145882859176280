import React, { useState, useEffect } from "react";
import { backendService } from "./../../services/backend.service";
import "moment/locale/es";
import { Preferences } from "./preferences";

export function Pending({profile,asideShow,allClose}) {
  const [pending, setPending] = useState([]);

  useEffect(() => {
    backendService.userProfileActivity(true).then((data) => setPending(data));
  }, []);

  return (
    <>
        <Preferences profile={profile} />
    </>
  );
}
