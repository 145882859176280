import React, {useEffect, useRef, useState} from "react";
import { useSurvey } from "../../services/survey.service";
import { SurveyChart } from "./surveyChart";
import { SurveyAnswerList } from "./surveyAnswerList";
import "./surveyResult.scss";
import {Button, Col, Divider, Radio, Row, Space} from "antd";
import {TitleAdmin} from "../components/titleAdmin/titleAdmin";
import { useHistory, useParams} from "react-router-dom";
import {Splide, SplideSlide} from "@splidejs/react-splide";

export const SurveyResults = () => {

  const {id} = useParams();
  const history = useHistory();
  const splidejs = useRef();

  const [surveyResult,setSurveyResult] = useState({
    surveyInfo: [],
    questions: [],
    questionIndex: 1,
    questionCount: 0,
    questionIndexSave: -1,
    chartGrouping: 1,
  });

  const handleToggleGroupChange = (e) => setSurveyResult( {...surveyResult, chartGrouping: e.target.value} );

  const getSurveyInfo = async (id) => {
    useSurvey.getSurveysInfoById(id).then((result) => {
      if (result) {
        setSurveyResult({
          ...surveyResult,
          surveyInfo: result,
          questionCount: result.questionCount,
          questions: result.questionList,
          questionIndex: 1,
        });
      }
    });
  };

  const handleArrowPrev = () => {
    if(surveyResult.questionIndex > 1) {
      setSurveyResult({ ...surveyResult, questionIndex: surveyResult.questionIndex - 1 });
      splidejs.current.splide.go('-1')
    }
  }

  const handleArrowNext = () => {
    if(surveyResult.questionIndex < surveyResult.questions.length) {
      setSurveyResult({ ...surveyResult, questionIndex: surveyResult.questionIndex + 1 });
      splidejs.current.splide.go('+1')
    }
  }

  useEffect(() => {
    if (id) getSurveyInfo(id);
  }, [id])

  return (
      <>
        <section className="dashboard-admin-container">
          <TitleAdmin
              title={'Tu Opinión'}
              subtitle={`Resultado de la encuesta | ${surveyResult.surveyInfo.title}`}
              event={() => history.push(`/admin/details-survey/${id}`)}
          />

          <Row className="section-surveys">
            <Col xs={24}>
              <Divider>
                  <Space>
                    Pregunta
                    <span>{surveyResult.questionIndex} </span>
                    <span>/</span>
                    <span>{surveyResult.questionCount}</span>
                  </Space>
              </Divider>
            </Col>

            <Col xs={24}>
              <div className={'section-surveys-header'}>
                <Button
                    type={'primary'}
                    htmlType={'button'}
                    onClick={handleArrowPrev}
                >
                  <p>Anterior</p>
                </Button>
                <Button
                    type={'primary'}
                    htmlType={'button'}
                    onClick={handleArrowNext}
                >
                  <p>Siguiente</p>
                </Button>
              </div>
            </Col>
            <Splide
                ref={splidejs}
                options={ {
                  rewind: true,
                  perPage: 1,
                  arrows: false,
                  speed: 2000,
                  perMove: 1,
                  drag: true,
                }}
            >
              {surveyResult.questions.map((question, index) => (
                  <SplideSlide>
                      {question.surveyAnswerTypeId === 2 ?
                          <div className={'section-surveys-radios'}>
                            <Radio.Group
                                onChange={handleToggleGroupChange}
                                defaultValue={surveyResult.chartGrouping}
                                size="large"
                            >
                              <Radio.Button value="1">General</Radio.Button>
                              <Radio.Button value="2">Por Género</Radio.Button>
                              <Radio.Button value="3">Por Edad</Radio.Button>
                              <Radio.Button value="4">Por Segmento</Radio.Button>
                            </Radio.Group>
                          </div>
                          :
                          <h5>Pregunta de respuesta abierta</h5>
                      }
                      <>
                        {question.surveyAnswerTypeId === 2 ?
                            <SurveyChart
                                key={index+question.surveyQuestionId}
                                surveyId={surveyResult.surveyInfo.surveyId}
                                surveyQuestionId={question.surveyQuestionId}
                                chartGrouping={surveyResult.chartGrouping}
                                questionTitle = {question.question}
                                anwerTypeId = {question.surveyAnswerTypeId}
                            />
                            :
                            <SurveyAnswerList
                                key={index+question.surveyQuestionId+index}
                                questionId={question.surveyQuestionId}
                            />
                        }
                      </>
                  </SplideSlide>
              ))}
            </Splide>
          </Row>
        </section>
      </>
  );
}
