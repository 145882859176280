import React, { useEffect, useState } from "react";
import { useSurvey } from "../../services/survey.service";
import { ResponsivePie } from "@nivo/pie";
import { ResponsiveBar} from '@nivo/bar'

export const SurveyChart = (props) => {

  const [chartData , setChartData ] = useState([]);
  const [keys, setKeys] = useState([]);

  useEffect(() => {
    if ((String(props.surveyId) !== "" && props.surveyId) ||
        (String(props.surveyQuestionId) !== "" && props.surveyQuestionId) ||
        (String(props.chartGrouping) !== "" && props.chartGrouping)
    ) getChartData(props.surveyId, props.surveyQuestionId, props.chartGrouping);
  }, [props]);


  const getChartData = async (surveyId, questionId, chartGroupId) => {

    if(parseInt(props.anwerTypeId) !== 1){
      useSurvey.getChartData(surveyId, questionId, chartGroupId).then((result) => {
        if (result.length !== []) {
          if(parseInt(chartGroupId) === 1){
            let dataValues = result?.filter(item => parseInt(item.id) === 1) ?? [];
            if(dataValues.length !== 0) {
              const dataValuesParsed = JSON.parse(dataValues[0].data);
              setChartData(dataValuesParsed)
            }
          } else {
            let dataValues = result?.filter(item => parseInt(item.id) === 0) ?? [];
            if(dataValues.length !== 0) {
              const dataValuesParsed = JSON.parse(dataValues[0].data);
              setChartData(dataValuesParsed);
              const keyValues = result.filter(item => parseInt(item.id) === 1);
              const keyValuesParsed = JSON.parse(keyValues[0].data);
              setKeys(keyValuesParsed)
            }

          }
        }

      });
    }
  };

  const styles = {
    root: {
      fontFamily: "consolas, sans-serif",
    },
    totalLabel: {
      fontSize: 24,
    },
  };

  return (
      <>
          <h2>{props.questionTitle}</h2>
          <div className="drawer__cont_svg" style={styles.root}>
              {props.chartGrouping === 1 ?
                  <ResponsivePie
                      data={chartData}
                      margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                      innerRadius={0.5}
                      padAngle={0.7}
                      cornerRadius={3}
                      activeOuterRadiusOffset={8}
                      borderWidth={1}
                      borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
                      arcLinkLabelsSkipAngle={10}
                      arcLinkLabelsTextColor="#333333"
                      arcLinkLabelsThickness={2}
                      arcLinkLabelsColor={{ from: "color" }}
                      arcLabelsSkipAngle={10}
                      arcLabelsTextColor={{
                        from: "color",
                        modifiers: [["darker", 2]],
                      }}
                      defs={[
                        {
                          id: "dots",
                          type: "patternDots",
                          background: "inherit",
                          color: "rgba(255, 255, 255, 0.3)",
                          size: 4,
                          padding: 1,
                          stagger: true,
                        },
                        {
                          id: "lines",
                          type: "patternLines",
                          background: "inherit",
                          color: "rgba(255, 255, 255, 0.3)",
                          rotation: -45,
                          lineWidth: 6,
                          spacing: 10,
                        },
                      ]}
                      legends={[
                        {
                          anchor: "bottom",
                          direction: "row",
                          justify: false,
                          translateX: 0,
                          translateY: 56,
                          itemsSpacing: 0,
                          itemWidth: 100,
                          itemHeight: 18,
                          itemTextColor: "#999",
                          itemDirection: "left-to-right",
                          itemOpacity: 1,
                          symbolSize: 18,
                          symbolShape: "circle",
                          effects: [
                            {
                              on: "hover",
                              style: {
                                itemTextColor: "#000",
                              },
                            },
                          ],
                        },
                      ]}
                  />
                  : ""
              }
              {props.chartGrouping !== 1 ?
                  <ResponsiveBar
                      data={chartData}
                      keys={keys}
                      indexBy="Grupo"
                      margin={{ top: 60, right: 100, bottom: 100, left: 60 }}
                      padding={0.45}
                      valueScale={{ type: 'linear' }}
                      indexScale={{ type: 'band', round: true }}
                      colors={{ scheme: 'nivo' }}
                      defs={[
                        {
                          id: 'dots',
                          type: 'patternDots',
                          background: 'inherit',
                          color: '#38bcb2',
                          size: 4,
                          padding: 1,
                          stagger: true
                        },
                        {
                          id: 'lines',
                          type: 'patternLines',
                          background: 'inherit',
                          color: '#eed312',
                          rotation: -45,
                          lineWidth: 6,
                          spacing: 10
                        }
                      ]}
                      borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                      axisTop={null}
                      axisRight={null}
                      axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: -45,
                        legend: 'Grupo',
                        legendPosition: 'middle',
                        legendOffset: 50
                      }}
                      axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'total',
                        legendPosition: 'middle',
                        legendOffset: -40
                      }}
                      labelSkipWidth={12}
                      labelSkipHeight={12}
                      labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                      legends={[
                        {
                          dataFrom: 'keys',
                          anchor: 'bottom-right',
                          direction: 'column',
                          justify: false,
                          translateX: 120,
                          translateY: 0,
                          itemsSpacing: 2,
                          itemWidth: 100,
                          itemHeight: 20,
                          itemDirection: 'left-to-right',
                          itemOpacity: 0.85,
                          symbolSize: 20,
                          effects: [
                            {
                              on: 'hover',
                              style: {
                                itemOpacity: 1
                              }
                            }
                          ]
                        }
                      ]}
                      role="application"
                      ariaLabel="Nivo bar chart demo"
                      // barAriaLabel={function(e){return e.id+": "+e.formattedValue+" in country: "+e.indexValue}}
                  />

                  : ""
              }

            </div>
      </>
  );
}
