import React from 'react'
import { useDropzone } from 'react-dropzone';
import { userProfileService } from '../../services/backoffice/userProfile.service'
import './users.scss'
import { useSelector } from 'react-redux';
import {FaDownload, FaUpload} from "react-icons/fa";
import {useHistory} from "react-router-dom";
import {TitleAdmin} from "../components/titleAdmin/titleAdmin";
import Swal from "sweetalert2";
import {Button, Col, Row} from "antd";

export const BulkLoad = () => {
	const companyId = useSelector(state => state.userSettings.companyId)
	const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
		multiple: false,
	});
	const history = useHistory();

	const files = acceptedFiles.map(file => (
		<li key={file.path}>
			<i className="icon-doc"></i> {file.path} - {file.size} bytes
		</li>
	));

	const upload = () => {
		if (acceptedFiles.length === 0) Swal.fire({
			text: "Debes cargar un archivo para continuar",
			icon: "warning"
		});
		else {
			if(acceptedFiles[0].type === 'text/csv')
				userProfileService.uploadMigrationFile(companyId, acceptedFiles[0]);
			else Swal.fire({
				text: "El archivo no es un formato .csv",
				icon: "warning"
			});
		}
	}

	return (
		<section className="dashboard-admin-container">
			<TitleAdmin
				title={'Usuarios'}
				subtitle={'Carga masiva'}
				event={() => history.push("/admin/users")}
			/>
			<Row gutter={16}>
				<Col xs={24} className={'form-backoffice-footer'}>
					<Button type={'primary'} htmlType={'button'} href="https://storagervadmindev.blob.core.windows.net/ctnprimecontentmx/Common/TemplateUser.csv">
						<p>Descargar template</p>
						<FaDownload />
					</Button>
				</Col>
				<Col xs={24} {...getRootProps({ className: 'dropzone' })}>
					<input {...getInputProps()} />
					<div className="dropzone-box">
						<span><FaUpload /></span>
						<p>Arrastre y suelte un archivo <span>.csv</span> aquí, o haga clic para seleccionar un archivo.</p>
					</div>
				</Col>
				{
					(acceptedFiles.length === 0) ? '' :
						<Col xs={24}>
							<h5>Archivo seleccionado:</h5>
							<ul className="list-files">{files}</ul>
						</Col>
				}
				<Col xs={24}>
					<div className="form-backoffice-footer">
						<Button onClick={upload} type={'primary'} htmlType={'button'}>
							<p>Subir archivo</p>
							<FaUpload />
						</Button>
					</div>
				</Col>
			</Row>

		</section>
	)
}
