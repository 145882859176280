import React from "react";
import "./register.scss";

export const RegisterFooter = () => {
    return (
        <>
            <footer className={'footer-register'}>
                <ul className={'footer-register__links'}>
                    <li>
                        <a target={'_blank'} href={'https://www.pridemx.com/ambienteseguro.pdf'}>Decálogo</a>
                    </li>
                    <li>
                        <a target={'_blank'} href={'https://www.pridemx.com/avisoprivacidad.pdf'}>Aviso de privacidad</a>
                    </li>
                    <li>
                        <a target={'_blank'} href={'https://www.pridemx.com/terminosycondiciones.pdf'}>Términos y condiciones</a>
                    </li>
                </ul>
                <p>2024 ©PrideMx. Todos los derechos reservados</p>
            </footer>
        </>
    );
}
