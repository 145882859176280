import React, { useState, useEffect } from "react";
import { companyPostViewService } from "../../services/compayPostView.service";
import { ItemMorePostGallery } from "./itemMorePostGallery";

export function AsideBlogCompanyGallery({
  getGallery,
  postType,
  activeAsideSurvey,
  postGalleryId,
  setLastFiter,
  currentFilter,
}) {
  const [filter, setFilter] = useState([]);
  const [filterId, setFilterId] = useState(0);

  useEffect(() => {
    companyPostViewService
      .getDateFilters(postType, postGalleryId)
      .then((result) => {
        if (result) {
          setFilter([]);
          if (result && result.length > 0) {
            setFilter(result);
            let lastItem = result[0];
            let lastMonth = lastItem.months[0];
            lastMonth.postType = postType;
            setLastFiter(lastMonth);
            setFilterId(postGalleryId);
          }
        }
      });
  }, [postType, postGalleryId]);

  return (
    // <div className={`blog-worldpride-aside-publications ${activeAsideSurvey}`}>
    <div className={`blog-worldpride-aside-publications`}>
      <h5 className="mb-3">Otras Publicaciones</h5>
      <div className="publications-list">
        {filter.map((item, i) => (
          <ItemMorePostGallery
            filter={item}
            getGallery={getGallery}
            year={item.year}
            months={item.months}
            key={i}
            currentFilter={currentFilter}
          />
        ))}
      </div>
    </div>
  );
}
