import React, { useState } from "react";
import {Modal, ModalBody, Tooltip} from "reactstrap";
import { useSelector } from "react-redux";
import { cartHook } from "../../hooks/cart.hook";
import swal from "sweetalert";
import { googleAds } from "../../services/googleAds.service";
import imgEmpty from '../../assets/imgs/no-image.jpeg';
import {Button} from "rsuite";

const ItemProduct = ({ product, provider }) => {
  const countrySetting = useSelector((state) => state.countrySetting);

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [activeBoxTooltip, setBoxTooltip] = useState("");

  const showBoxTooltip = () => {
    setBoxTooltip(activeBoxTooltip === "" ? " is-show" : "");
  };

  const toggle = () => setTooltipOpen(!tooltipOpen);

  const addPruchaseItem = () => {
    googleAds.cartEvent('conversion', product.productPrice)
    cartHook.addItem(product, provider);
    swal({
      icon: "success",
      title: "Agregado",
      text: "Se agregó el producto a tu carrito",
      button: false,
      closeOnEsc: true,
      timer: 2250,
    });
  };

  const [modal, setModal] = useState(false);

  const toggleModal = () => setModal(!modal);

  return (
    <>
      <div className="item-promo">
        <div className="item-promo-img">
          <img src={(product.urlImage) ? product.urlImage : imgEmpty} alt="product" />
        </div>
        <div className="item-promo-info">
          <div className="name">
            <p>{product.name}</p>
            <Button onClick={toggleModal}>
              <i className="fas fa-info-circle"></i>
            </Button>
          </div>
          <div className="price-add box_flex ">
            <span className="price">
              <small>Por solo</small>
              <h4>
                {countrySetting.currency}
                {Number(product.productPrice).toLocaleString(
                  countrySetting.cultureCode,
                  {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  }
                )}
              </h4>
            </span>
            <span onClick={addPruchaseItem}>
              <Button className="btn-add" id={`Tooltip${product.productId}`}>
                <i className="fas fa-cart-plus"></i>
              </Button>
              <Tooltip placement="bottom" isOpen={tooltipOpen} target={`Tooltip${product.productId}`} toggle={toggle}>
                Agregar a canasta
              </Tooltip>
            </span>
          </div>
        </div>
      </div>
      <Modal isOpen={modal} centered={true} size="md">
        <ModalBody>
          <Button className="btn-close-modal" onClick={toggleModal}><i className="icon-close"></i></Button>
          <p style={{textAlign: 'center'}}>{(product.restrictions) ? product.restrictions : `No hay restricciones para este producto`}</p>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ItemProduct;
