import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { JobBankHeader } from "./jobBankHeader";
import { JobBankService } from "../../services/jobBank.service";
import Moment from "react-moment";
import "./jobBank.scss";
import Swal from "sweetalert2";
import { BiCheck } from "react-icons/bi";
import { IoClose } from "react-icons/io5";
import {Button} from "rsuite";
import {Col, Row, Space, Table} from "antd";
import {FooterHome} from "../../components/footer/footerHome";
import { FaCheck, FaTrash } from "react-icons/fa";

export function ListNewJobs() {
  const [jobs, setJobs] = useState([]);
  const [pages, setPages] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    GetJobsToApprove();
  }, []);

  const GetJobsToApprove = () => {
    JobBankService.GetJobsToApprove(1, 8).then((result) => {
      if (!result) return;
      setJobs(result.list || []);
      setTotalCount(result.totalCount);
      setTotalPages(result.totalPages);
      let rows = [];
      for (let index = 1; index <= result.totalPages; index++) {
        rows.push(
          <li
            className={"page-item" + (1 === index ? " active" : "")}
            key={index}
          >
            <a className="page-link" onClick={() => searchPage(index)}>
              {index}
            </a>
          </li>
        );
      }
      setPages(rows);
    });
  };

  const deleteJob = (jobId) => {
    JobBankService.deleteJob(jobId).then((result) => GetJobsToApprove());
  };

  const authorize = (e) => {
    JobBankService.authorizeJob(e.jobId, true).then((result) => {
      Swal.fire({ text: "El trabajo ha sido aprobado", icon: "success" });
      GetJobsToApprove();
    });
  };

  const reject = (e) => {
    JobBankService.authorizeJob(e.jobId, false).then((result) => {
      Swal.fire({ text: "El trabajo ha sido rechazado", icon: "success" });
      GetJobsToApprove();
    });
  };

  const searchPage = (page) => {
    JobBankService.GetJobsToApprove(page, 8).then((result) => {
      if (!result) return;
      setCurrentPage(page);
      setJobs(result.list || []);
      setTotalCount(result.totalCount);

      let rows = [];
      for (let index = 1; index <= result.totalPages; index++) {
        rows.push(
          <li
            className={"page-item" + (page === index ? " active" : "")}
            key={index}
          >
            <a className="page-link" onClick={() => searchPage(index)}>
              {index}
            </a>
          </li>
        );
      }
      setPages(rows);
    });
  };

  const returnPreviousPage = () => {
    if (currentPage > 1) searchPage(currentPage - 1);
  };

  const advanceNextPage = () => {
    if (currentPage < totalPages) searchPage(currentPage + 1);
  };

  const columns = [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        fixed: 'left',
        width: 80
    },
    {
        title: 'Producto',
        dataIndex: 'title',
        key: 'title',
    },
    {
        title: 'Sucursal',
        dataIndex: 'companyName',
        key: 'companyName',
    },
    {
        title: 'Grupo',
        dataIndex: 'jobCategory',
        key: 'jobCategory',
    },
    {
        title: 'Categoría',
        dataIndex: 'publicatedBy',
        key: 'publicatedBy',
    },
    {
        title: 'Stock',
        dataIndex: 'publicationDate',
        key: 'publicationDate',
    },
    {
        title: 'Puntos',
        dataIndex: 'points',
        key: 'points',
    },
    {
        title: 'Acciones',
        dataIndex: 'options',
        key: 'options',
    },
  ];

  const dataSource = jobs.map(item => {
      return {
          key: item.jobId,
          id: item.jobId,
          title: item.title,
          companyName: item.companyName,
          jobCategory: item?.jobCategory?.[0]?.name,
          publicatedBy: item.publicatedBy,
          publicationDate: <Moment locale="es" format="DD/MMMM/YYYY">{item.publicationDate}</Moment>,
          points: item.points,
          options: <><Space>
            <Button className="btn-icon-table edit" onClick={() => authorize(item)}>
              <FaCheck />
            </Button>
            <Button className="btn-icon-table delete" onClick={() => reject(item)}>
              <FaTrash />
            </Button>
          </Space>
          </>
      };
  })

  return (
    <section className="dashboard-container-page">
      <JobBankHeader tituloInJobs="Empleos por autorizar" />
      <div className="flex-end mb-3">
        <Link to="/job-bank" className="btn-back">
          <i className="icon-chevron-left"></i>
          <p>Ir a Bolsa de trabajo </p>
        </Link>
      </div>
      <Table
          scroll={{ x: '992px', y: 800 }}
          pagination={false} dataSource={dataSource}
          columns={columns} className="table-admin"
          locale={{
              triggerDesc: 'Ordenamiento descendente',
              triggerAsc: 'Ordenamiento ascedente',
              cancelSort: 'Cancelar ordenamiento',
              emptyText: 'Sin registros',
          }}
      />
      <div className="sec_list_new_jobs mt-3">
        {
          (jobs.length > 0) ?
              <div className="job-bank-pagination">
                <nav className="pagination-jobs">
                  <ul className="pagination">
                    <li className="page-item">
                      <a className="page-link" onClick={returnPreviousPage} aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                        <span className="sr-only">Previous</span>
                      </a>
                    </li>
                    {pages}
                    <li className="page-item">
                      <a
                          className="page-link"
                          onClick={advanceNextPage}
                          aria-label="Next"
                      >
                        <span aria-hidden="true">&raquo;</span>
                        <span className="sr-only">Next</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div> : null
        }
      </div>
      <Row>
        <Col xs={24}>
          <FooterHome />
        </Col>
      </Row>
    </section>
  );
}
