import React from 'react';
import {
    BsApple,
    BsFacebook,
    BsInstagram,
    BsLink,
    BsLinkedin,
    BsSoundwave,
    BsSpotify,
    BsTiktok,
    BsTwitter,
    BsWordpress,
    BsYoutube,
} from "react-icons/bs";
import {
    BiWorld,
} from "react-icons/bi";
import { ItemSocial } from '../../pages/prideMusic/itemSocial';

const PrideMusicCard = ({ item }) => {
    const { id, imageUrl, title, subtitle, genericContactInfo } = item;
    const contactTypes = [
        "Instagram",
        "Facebook",
        "LinkedIn",
        "Twitter",
        "YoutubeChannel",
        "Web",
        "Apple",
        "SoundCloud",
        "LinkThree",
        "TikTok"
    ];
    const mainContact = genericContactInfo.find(g => g.mainContact);

    return (
        <div className="card-pride-music" key={id}>
            <div className="card-pride-music-thumb">
                <img src={imageUrl} alt="" />
            </div>
            <div className="card-pride-music-content">
                <h4 className="text-center">{title}</h4>
                <p className="text-center">{subtitle}</p>
                <ul>
                    {genericContactInfo.map((contactInfo, index) => {
                        if (contactTypes.includes(contactInfo.contactTypeName) && !contactInfo.mainContact) {
                            return (
                                <ItemSocial
                                    key={index}
                                    contactValue={contactInfo.contactValue}
                                    contactTypeName={contactInfo.contactTypeName}
                                />
                            );
                        }
                    })}
                </ul>
                <div className="card-pride-music-footer">
                    <a
                        href={`${mainContact?.contactValue}`}
                        target="_blank"
                        className="btn-gradient-music"
                    >
                        <span>
                            {mainContact?.contactTypeName == "Spotify" ? <BsSpotify /> : <BiWorld />}
                        </span>
                        <p>Escuchar</p>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default PrideMusicCard;
