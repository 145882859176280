import React from "react";
import { AsideCategoriesJobs } from "./asideCategoriesJobs";
import { JobBankHeader } from "./jobBankHeader";
import { Link } from "react-router-dom";
import { ApplicantForm } from "./applicantForm";
import "./jobBank.scss";

export function ApplicantSent() {
  return (
    <section className="wrapper_jobbank padd">
      <div className="wrapper_applicant_form">
        <div className="content_applicant_form">
          <div className="sec_form_applicant card_shadows">
            <div className="content_application_sent">
              <div className="text-center ico-sent">
                <img
                  src="./imgs/enviar-datos.png"
                  alt="Se envío tu postulación"
                />
              </div>
              <h3 className="text fnt_medium text-center mt-3 mb-4">
                ¡Se envió tu postulación!
              </h3>
              <div className="box_info_note">
                <div className="ico_note">
                  <ion-icon name="checkmark-sharp"></ion-icon>
                </div>
                <p className="m-0">
                  Te enviaremos un email de confirmación a{" "}
                  <span className="text fnt_medium">
                    carlosmario@kuimby.com.mx
                  </span>
                </p>
              </div>
              <div className="box_info_note">
                <div className="ico_note">
                  <ion-icon name="checkmark-sharp"></ion-icon>
                </div>
                <p className="m-0">
                  <b>==NAME-EMPRESA==</b> te enviará una actualización del
                  estado de tu postulación dentro de un plazo de 2 semanas
                </p>
              </div>
              <div className="bx_application">
                <h5 className="text fnt_medium c_primary">
                  Da seguimiento a tus postulaciones
                </h5>
                <p>
                  Recibirás una actualización de estado en un email de{" "}
                  <b>==NAME-EMPRESA==</b> después de algunas semanas de haber
                  enviado tu postulación. Mientras tanto, puedes ver y hacer un
                  seguimiento de tus postulaciones en Mis Postulaciones del
                  módulo de Mi perfil en Kuimby en cualquier momento.
                </p>
              </div>
              <div className="mt-4 text-center">
               
                <Link to="/job-bank" className="btn secundary mx-1">
                Volver a Bolsa de trabajo
                </Link>
                <Link to="/profile" className="btn primary mx-1">
                  Ir a Mi perfil
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
