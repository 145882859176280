import React from "react";
import InfiniteScroll from 'react-infinite-scroll-component';
import {Col, Empty, Row, Spin} from "antd";
import {CardAudience} from "./cardAudience";

export function ListOldItems(props) {

    const loadMore = () => {
        const lastitem = props.items.slice(-1).pop().data;
        props.updateComplete(lastitem.conferenceDate,lastitem.auditoriumId)
    };

    const contentStyle = {
        padding: 50,
        background: 'rgba(0, 0, 0, 0)',
    };

    return (
        <InfiniteScroll
            dataLength={props.items.length}
            next={() => loadMore()}
            hasMore={!props.disabledHasMore}
            loader={!props.disabledHasMore ? <Spin tip="Cargando"><div style={contentStyle}></div></Spin> : ''}
            scrollableTarget="scrollableDiv"
            style={{overflow: 'unset !important'}}
        >
            <Row gutter={[16,16]}>
                {props.items.map((item,i) => {
                    return (
                        <Col xl={6} lg={8} md={8} sm={12} xs={24} key={i}>
                            <CardAudience
                                item={item}
                                publishedItems={true}
                                onLive={false}
                                publishedDate={false}
                                handleLinkConference={props.handleLinkConference}
                            />
                        </Col>
                    );
                })}
                {props.items.length === 0 && <Col xs={24}>
                    <Empty description={'No hay eventos disponibles'} />
                </Col>}
            </Row>
        </InfiniteScroll>
    );
}
