import React, { useEffect, useState } from 'react';
import { rewardProviderService } from '../../services/control/rewardProvider.service';

import {useParams} from 'react-router-dom';
import {Form, Input, Button, Row, Col, Image} from "antd";
import {FaInfoCircle, FaUpload} from "react-icons/fa";
import { IoBusiness} from "react-icons/io5";

export function EditRewardProvider(props) {

    const {id} = useParams();

    const onSave = () => {
        if (id > 0) rewardProviderService.edit(reward);
        if (id == 0) rewardProviderService.create(reward);
    };

    const [reward, setReward] = useState({});

    useEffect(() => {
        setReward({
            rewardProviderId: 0,
            businessName: "",
            urlLogo: "",
            active: true,
            logoImage: null
        })
    }, [id])

    useEffect(() => {
        if (id != 0) {
            rewardProviderService.getById(id).then((result) => {
                setReward({
                    rewardProviderId: result.rewardProviderId,
                    businessName: result.businessName,
                    urlLogo: result.urlLogo,
                    active: result.active,
                    logoImage: null
                });
            });
        }
    }, []);

    const handleInputChange = (e) => setReward({...reward, [e.target.name]: e.target.value})

    const handleInputFileChange = (e) => {
        const ev = e.target.files;
        if (ev) {
            if (ev.length === 0) return setReward({...reward, logoImage: null, urlLogo: null});
            let img: HTMLImageElement, dimensions = {};
            img = document.createElement("img");

            img.onload = function () {
                dimensions = {
                    width: img.width,
                    height: img.height
                };
                if(dimensions.width > 500 || dimensions.height > 500) {
                    alert('La imagen excede las dimensiones permitidas.')
                } else {
                    const objectUrl = URL.createObjectURL(ev[0])
                    setReward( {...reward, logoImage: ev[0], urlLogo: objectUrl})
                }
            };
            img.src = URL.createObjectURL(ev[0]);
            return;
        }

        if(e.target.files[0].size > 2048) alert('El peso del archivo excede los 2MB')
    }

    return (
        <section className="dashboard-operator-body">
            <Form
                onFinish={onSave}
                className="form-pride"
                layout="vertical"
                autoComplete="off"
                fields={[
                    {
                        "name": ["businessName"],
                        "value": reward.businessName
                    },
                    {
                        "name": ["urlLogo"],
                        "value": reward.urlLogo
                    },
                ]}
            >
                <Row gutter={16}>
                    <Col lg={12} md={24} sm={24}>
                        <Form.Item
                            label="Nombre"
                            name="businessName"
                            rules={[
                                {
                                    required: true,
                                    message: "*Campo requerido"
                                }
                            ]}
                        >
                            <Input
                                prefix={<IoBusiness className="site-form-item-icon icon-gif" />}
                                size="large"
                                name="businessName"
                                onChange={handleInputChange}
                            />
                        </Form.Item>
                    </Col>
                    <Col lg={12} md={24} sm={24} xs={24}>
                        <Form.Item
                            label="Logotipo (Dimensiones máx: 500x500)"
                            name="logoImage"
                            rules={[
                                {
                                    required: (!reward.urlLogo),
                                    message: "*Campo requerido"
                                }
                            ]}
                        >
                            <div className="container-file">
                                <label htmlFor="uploadImage">Subir imagen <FaUpload /></label>
                                <Input id="uploadImage" size="large" type="File" name="logoImage" accept='image/*' onChange={handleInputFileChange}/>
                                {
                                    (reward.urlLogo && reward.urlLogo) ?
                                        <Image
                                            width={200}
                                            src={reward.urlLogo}
                                        /> : ''
                                }
                            </div>
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <div className="flex-end">
                            <Button type="primary" htmlType="submit">Guardar<i className="icon-save"></i></Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </section>
    );
}   
