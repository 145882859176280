import React, { useEffect, useRef, useState } from "react";
import Cards from "../../assets/imgs/red-pay.png";
import visaLogo from "../../assets/imgs/visa_logo.png";
import masterCardLogo from "../../assets/imgs/mastercard_logo.png";
import amexLogo from "../../assets/imgs/amex_logo.png";
import BtnRP from "../../assets/imgs/red-pay-logo.png";
import { paymentService } from "../../services/payment.service";
import { metaPixelService } from "../../services/metaPixel.service";
import "./registerServices.scss";
import { FaExclamation, FaKaaba } from "react-icons/fa";
import { newAccountService } from "../../services/newAccountService.service";
import swal from "sweetalert";
import { Form, Row, Col, Input, Button, Select } from "antd";
import cheque from "../../assets/imgs/cheque.png";

export function FormCheckoutPay(props) {
  const [datos, setDatos] = useState({
    cvv: "",
    cardNumber: "",
    firstName: "",
    LastName: "",
    CardExpirationMonth: "",
    CardExpirationYear: "",
    state: "",
    Street2Del: "",
    Street2Col: "",
    City: "",
    PostalCode: "",
    Street: "",
    streetNumber: "",
  });
  const [cardTypeName, setcardTypeName] = useState("");
  const [cvvMaxLenght, setcvvMaxLenght] = useState(0);
  const [cardImage, setCardImage] = useState(Cards);
  const [cvvPlaceholder, setCvvPlaceholder] = useState("xxx");
  const [states, setStates] = useState([]);
  const [showCVV, setShowCVV] = useState(false);
  const { Option } = Select;

  const handleInputChange = (event) => {
    if (event.target.name === "cardNumber") {
      let definedCardType = fnGetCardTypeReq(event.target.value);
      setcardTypeName(definedCardType);
      datos.cvv = "";
    }

    setDatos({ ...datos, [event.target.name]: event.target.value });
    props.Datepersonal(
      {
        ...datos,
        [event.target.name]: event.target.value,
      },
      "pay"
    );
  };

  function fnGetCardTypeReq(cardNumber) {
    const card_american = /^(?:3[47][0-9]{13})$/;
    const card_visa = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
    const card_master =
      /^5[1-5]\d{14}$|^2(?:2(?:2[1-9]|[3-9]\d)|[3-6]\d\d|7(?:[01]\d|20))\d{12}$/;
    let cardType = "";
    if (cardNumber.toString().match(card_visa)) {
      cardType = "VISA";
      setcvvMaxLenght(3);
      setCardImage(visaLogo);
      setCvvPlaceholder("xxx");
    } else if (cardNumber.toString().match(card_master)) {
      cardType = "MASTERCARD";
      setcvvMaxLenght(3);
      setCardImage(masterCardLogo);
      setCvvPlaceholder("xxx");
    } else if (cardNumber.toString().match(card_american)) {
      cardType = "AMERICAN EXPRESS";
      setcvvMaxLenght(4);
      setCardImage(amexLogo);
      setCvvPlaceholder("xxxx");
    } else {
      cardType = "UNDEFINED";
      setcvvMaxLenght(3);
      setCardImage(Cards);
      setCvvPlaceholder("xxx");
    }
    return cardType;
  }

  function getCardType(cardNumber) {
    const card_american = /^(?:3[47][0-9]{13})$/;
    const card_visa = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
    const card_master =
      /^5[1-5]\d{14}$|^2(?:2(?:2[1-9]|[3-9]\d)|[3-6]\d\d|7(?:[01]\d|20))\d{12}$/;
    let cardType = "";
    if (cardNumber.match(card_visa)) {
      cardType = "001";
    } else if (cardNumber.match(card_master)) {
      cardType = "002";
    } else if (cardNumber.match(card_american)) {
      cardType = "003";
    }
    return cardType;
  }

  async function onFinish() {
    if (!datos.countryId || datos.countryId == "") {
      return swal({
        text: "Falta seleccionar un Estado, revísalo por favor",
        icon: "error",
      });
    }

    var pixelData = {
      content_category: "Directorio",
      currency: "MXN",
      value: 10.0,
    };
    metaPixelService.trackEvent("track", "AddPaymentInfo", pixelData);
    props.Datepersonal(datos, "pay");
    datos.cardType = getCardType(datos.cardNumber);

    const result = await paymentService.verifyCard(
      datos,
      props.data.directoryInfoId
    );
    if (result.data?.responseCode == 7) {
      window.location.href = result.data.urlRedirect;
      //window.open(result.data.urlRedirect, 'targetWindow', `toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=SomeSize,height=SomeSize`);
      return props.toggleView();
    }

    if (result.data?.responseCode == 2 || result.data?.responseCode == 8) {
      swal({ text: "Tarjeta verificada con éxito", icon: "success" });
      return props.toggleView();
    }

    if (result.data?.responseCode == 7) {
      return props.toggleView();
    }

    return swal({
      text: "No pudimos registrar la tarjeta: datos incorrectos o saldo insuficiente",
      icon: "error",
    });
  }

  useEffect(() => {
    props.showButtonPackages(false);
    newAccountService.getStateCatalog().then((states) => {
      setStates(states);
    });
  }, []);

  const handleSelectState = (value) => setDatos({ ...datos, countryId: value });

  return (
    <>
      {props.directory.amount == 0 || props.directory.freePost ? (
        <div className="wrapper_mnj_free">
          <div className="box_mnj_free">
            <img
              src={cheque}
              alt="ya registramos tu servicio... y fue ¡Gratis!"
            />
            <div className="sec__info">
              <h4>
                Enhorabuena{" "}
                <span className="c_primary">{props.directory.name}</span>, ya
                registramos tu servicio... y fue{" "}
                <span className="c_blue fst-italic">¡Gratis!</span>
              </h4>

              <p className="paragraph">
                En el <strong className="c_blue">{props.directory.type}</strong>
                , no es necesario registrar tu información bancaria. Ya que
                nuestro compromiso es darte difusión y posicionamiento. Si
                quieres subir de nivel, espéralo próximamente.
              </p>
            </div>
          </div>
        </div>
      ) : (
        <Form
          className="form-pride"
          onFinish={onFinish}
          layout="vertical"
          autoComplete="off"
          fields={[
            {
              name: ["cvv"],
              value: datos.cvv,
            },
          ]}
        >
          <Row gutter={16}>
            <Col sm={12} xs={24}>
              <div className="header">
                <h3>Datos de tarjeta</h3>
              </div>
            </Col>
            <Col sm={12} xs={24}>
              <div className="flex-end">
                <Button className="btn-back" onClick={props.toBack}>
                  <i className="icon-chevron-left"></i>
                  <p>Volver</p>
                </Button>
              </div>
            </Col>
            <Col md={12} sm={12} xs={24}>
              <Form.Item
                label="Nombre(s)"
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: "*Campo requerido",
                  },
                  {
                    max: 100,
                    message: "Se permiten máximo 100 carácteres",
                  },
                ]}
              >
                <Input
                  size="large"
                  name="firstName"
                  onKeyPress={(e) => {
                    const regex = new RegExp("^[a-zA-Z ]+$");
                    const key = String.fromCharCode(
                      !e.charCode ? e.which : e.charCode
                    );
                    if (!regex.test(key)) {
                      e.preventDefault();
                      return false;
                    }
                  }}
                  maxLength={100}
                  onChange={handleInputChange}
                />
              </Form.Item>
            </Col>
            <Col md={12} sm={12} xs={24}>
              <Form.Item
                label="Apellidos"
                name="LastName"
                rules={[
                  {
                    required: true,
                    message: "*Campo requerido",
                  },
                  {
                    max: 100,
                    message: "Se permiten máximo 100 carácteres",
                  },
                ]}
              >
                <Input
                  size="large"
                  name="LastName"
                  maxLength={100}
                  onKeyPress={(e) => {
                    const regex = new RegExp("^[a-zA-Z ]+$");
                    const key = String.fromCharCode(
                      !e.charCode ? e.which : e.charCode
                    );
                    if (!regex.test(key)) {
                      e.preventDefault();
                      return false;
                    }
                  }}
                  onChange={handleInputChange}
                />
              </Form.Item>
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                label="Número de tarjeta"
                name="cardNumber"
                rules={[
                  {
                    required: true,
                    message: "*Campo requerido",
                  },
                  {
                    max: 16,
                    min: 15,
                    message: "Faltan dígitos",
                  },
                ]}
                hasFeedback
              >
                <Input
                  name="cardNumber"
                  type="text"
                  size="large"
                  maxLength={16}
                  minLength={15}
                  onChange={handleInputChange}
                  onKeyPress={(e) => {
                    e = e || window.e;
                    const charCode = e.keyCode || e.which;
                    const charStr = String.fromCharCode(charCode);
                    if (isNaN(charStr)) e.preventDefault();
                  }}
                />
              </Form.Item>
            </Col>
            <Col lg={4} md={6} sm={8} xs={12}>
              <Form.Item
                label="Mes Exp."
                name="CardExpirationMonth"
                rules={[
                  {
                    required: true,
                    message: "*Campo requerido",
                  },
                  {
                    max: 2,
                    min: 2,
                    message: "Se necesitan 2 dígitos",
                  },
                ]}
                hasFeedback
              >
                <Input
                  name="CardExpirationMonth"
                  type="text"
                  maxLength={2}
                  size="large"
                  minLength={2}
                  onChange={handleInputChange}
                  onKeyPress={(e) => {
                    e = e || window.e;
                    const charCode = e.keyCode || e.which;
                    const charStr = String.fromCharCode(charCode);
                    if (isNaN(charStr)) e.preventDefault();
                  }}
                />
              </Form.Item>
            </Col>
            <Col lg={4} md={6} sm={8} xs={12}>
              <Form.Item
                label="Año Exp."
                name="CardExpirationYear"
                rules={[
                  {
                    required: true,
                    message: "*Campo requerido",
                  },
                  {
                    max: 4,
                    min: 4,
                    message: "Se necesitan 4 dígitos",
                  },
                ]}
                hasFeedback
              >
                <Input
                  name="CardExpirationYear"
                  type="text"
                  maxLength={4}
                  minLength={4}
                  size="large"
                  onChange={handleInputChange}
                  onKeyPress={(e) => {
                    e = e || window.e;
                    const charCode = e.keyCode || e.which;
                    const charStr = String.fromCharCode(charCode);
                    if (isNaN(charStr)) e.preventDefault();
                  }}
                />
              </Form.Item>
            </Col>
            <Col lg={4} md={6} sm={8} xs={12}>
              <Form.Item
                label="CVV"
                name="cvv"
                rules={[
                  {
                    required: true,
                    message: "*Campo requerido",
                  },
                  {
                    max: cvvMaxLenght,
                    min: cvvMaxLenght,
                    message: `Se necesitan ${cvvMaxLenght} dígitos`,
                  },
                ]}
                hasFeedback
              >
                <Input
                  name="cvv"
                  type="text"
                  size="large"
                  disabled={cvvMaxLenght === 0}
                  minlength={cvvMaxLenght}
                  maxLength={cvvMaxLenght}
                  onChange={handleInputChange}
                  onFocus={() => setShowCVV(true)}
                  onBlur={() => setShowCVV(false)}
                  onKeyPress={(e) => {
                    e = e || window.e;
                    const charCode = e.keyCode || e.which;
                    const charStr = String.fromCharCode(charCode);
                    if (isNaN(charStr)) e.preventDefault();
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <div className={`card-credit ${showCVV ? "cvv" : ""}`}>
                <div className="card-credit-front">
                  <div className="circle1"></div>
                  <div className="circle2"></div>
                  <label>
                    {datos.firstName ? datos.firstName : "Nombre"}{" "}
                    {datos.LastName ? datos.LastName : "Apellidos"}
                  </label>
                  <div className="card-credit-number">
                    <p>
                      {datos.cardNumber
                        ? datos.cardNumber.match(/.{1,4}/g).join(" ")
                        : "0000-0000-0000-0000"}
                    </p>
                  </div>
                  <div className="card-credit-footer">
                    <div className="card-credit-date">
                      <p>
                        {datos.CardExpirationMonth
                          ? datos.CardExpirationMonth
                          : "Mes"}
                        /
                        {datos.CardExpirationYear
                          ? datos.CardExpirationYear
                          : "Año"}
                      </p>
                    </div>
                    <img src={cardImage} alt="" />
                  </div>
                </div>
                <div className="card-credit-back">
                  <div className="line-card"></div>
                  <div className="cvv-container">
                    <span>{datos.cvv ? datos.cvv : "CVV"}</span>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={8} sm={12} xs={24}>
              <Form.Item
                label="Estado"
                name="state"
                rules={[
                  {
                    required: true,
                    message: "*Campo Requerido",
                  },
                ]}
                initialValue={datos.state}
                hasFeedback
              >
                <Select
                  size="large"
                  name="state"
                  defaultValue={
                    datos.state ? datos.state : "Seleccionar estado"
                  }
                  onChange={handleSelectState}
                >
                  {states.map((state, index) => (
                    <Option
                      key={index}
                      onClick={() =>
                        setDatos({
                          ...datos,
                          state: state.description,
                          countryId: state.countryStateTypeId,
                        })
                      }
                      value={state.countryId}
                    >
                      {state.description}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col md={8} sm={12} xs={24}>
              <Form.Item
                label="Delegación"
                name="Street2Del"
                rules={[
                  {
                    required: true,
                    message: "*Campo requerido",
                  },
                  {
                    max: 100,
                    message: "Se permiten máximo 100 carácteres",
                  },
                ]}
                hasFeedback
              >
                <Input
                  name="Street2Del"
                  size="large"
                  type="text"
                  onKeyPress={(e) => {
                    const regex = new RegExp("^[a-zA-Z ]+$");
                    const key = String.fromCharCode(
                      !e.charCode ? e.which : e.charCode
                    );
                    if (!regex.test(key)) {
                      e.preventDefault();
                      return false;
                    }
                  }}
                  maxLength={100}
                  onChange={handleInputChange}
                />
              </Form.Item>
            </Col>
            <Col md={8} sm={12} xs={24}>
              <Form.Item
                label="Colonia"
                name="Street2Col"
                rules={[
                  {
                    required: true,
                    message: "*Campo requerido",
                  },
                  {
                    max: 100,
                    message: "Se permiten máximo 100 carácteres",
                  },
                ]}
              >
                <Input
                  name="Street2Col"
                  type="text"
                  size="large"
                  maxLength={100}
                  onKeyPress={(e) => {
                    const regex = new RegExp("^[a-zA-Z ]+$");
                    const key = String.fromCharCode(
                      !e.charCode ? e.which : e.charCode
                    );
                    if (!regex.test(key)) {
                      e.preventDefault();
                      return false;
                    }
                  }}
                  onChange={handleInputChange}
                />
              </Form.Item>
            </Col>
            <Col md={8} sm={12} xs={24}>
              <Form.Item
                label="Ciudad"
                name="City"
                rules={[
                  {
                    required: true,
                    message: "*Campo requerido",
                  },
                  {
                    max: 100,
                    message: "Se permiten máximo 100 carácteres",
                  },
                ]}
                hasFeedback
              >
                <Input
                  name="City"
                  size="large"
                  type="text"
                  onKeyPress={(e) => {
                    const regex = new RegExp("^[a-zA-Z ]+$");
                    const key = String.fromCharCode(
                      !e.charCode ? e.which : e.charCode
                    );
                    if (!regex.test(key)) {
                      e.preventDefault();
                      return false;
                    }
                  }}
                  maxLength={100}
                  onChange={handleInputChange}
                />
              </Form.Item>
            </Col>
            <Col md={4} sm={12} xs={12}>
              <Form.Item
                label="Código postal"
                name="PostalCode"
                rules={[
                  {
                    required: true,
                    message: "*Campo requerido",
                  },
                  {
                    max: 5,
                    message: "Se permiten máximo 5 dígitos",
                  },
                ]}
                hasFeedback
              >
                <Input
                  name="PostalCode"
                  size="large"
                  type="text"
                  maxLength={5}
                  onChange={handleInputChange}
                  onKeyPress={(e) => {
                    e = e || window.e;
                    const charCode = e.keyCode || e.which;
                    const charStr = String.fromCharCode(charCode);
                    if (isNaN(charStr)) e.preventDefault();
                  }}
                />
              </Form.Item>
            </Col>
            <Col md={8} sm={12} xs={24}>
              <Form.Item
                label="Calle"
                name="Street"
                rules={[
                  {
                    required: true,
                    message: "*Campo requerido",
                  },
                  {
                    max: 100,
                    message: "Se permiten máximo 100 carácteres",
                  },
                ]}
                hasFeedback
              >
                <Input
                  name="Street"
                  type="text"
                  size="large"
                  maxLength={100}
                  onChange={handleInputChange}
                />
              </Form.Item>
            </Col>
            <Col md={4} sm={12} xs={12}>
              <Form.Item
                label="No."
                name="streetNumber"
                rules={[
                  {
                    required: true,
                    message: "*Campo requerido",
                  },
                  {
                    max: 5,
                    message: "Se permiten máximo 5 dígitos",
                  },
                ]}
                hasFeedback
              >
                <Input
                  name="streetNumber"
                  type="text"
                  size="large"
                  maxLength={5}
                  onChange={handleInputChange}
                  onKeyPress={(e) => {
                    e = e || window.e;
                    const charCode = e.keyCode || e.which;
                    const charStr = String.fromCharCode(charCode);
                    if (isNaN(charStr)) e.preventDefault();
                  }}
                />
              </Form.Item>
            </Col>
            <Col md={12}>
              <div className="badge-pride blue">
                <span>
                  <FaExclamation />
                </span>
                <p>
                  <strong>Nota:</strong> Se genera un micro cargo de $
                  {props.amountToVerify} para validar tu método de pago, una vez
                  aprobado el servicio se hará el reembolso del cargo.
                </p>
              </div>
            </Col>
            <Col md={12}>
              <div className="badge-pride">
                <span>
                  <FaExclamation />
                </span>
                <p>
                  El cobro será efectuado hasta obtener la aprobación de tu
                  servicio, una vez publicado, el cargo se realizará de forma
                  automática.
                </p>
              </div>
            </Col>
            <Col xs={24}>
              <div className="box_foot_checkout">
                <span className="red__pay">
                  <p>Powered by</p>
                  <img
                    src={BtnRP}
                    alt="Métodos de pagos en Red Voucher Online, Red Pay Pasarela de pago, México"
                  />
                </span>
                <Button htmlType="submit" type="primary" className="submit">
                  Terminar Registro
                  <i className="icon-send"></i>
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
}
