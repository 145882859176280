import React, {useEffect, useRef, useState} from 'react';

import { Button } from "rsuite";
import { useSurveyScale } from '../../services/surveyScale.service';
import {useHistory, useParams} from 'react-router-dom';
import {Input, Space, Table} from "antd";
import {FaEdit, FaTrash} from "react-icons/fa";
import {SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
const MySwal = withReactContent(Swal);

export function SearchSurveyScale(props) {
    const [surveyScales, setSurveyScales] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const { surveyId } = useParams();
    const location = useHistory();

    const handleDelete = (data) => {
        MySwal.fire({
            title: '¿Estás seguro?',
            text: "No podrás revertir esta acción",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#FF002A',
            confirmButtonText: 'Eliminar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if(result.isConfirmed)
                useSurveyScale.deleteSurveyScale(data).then((result) => getSurveyScales());
        })
    }

    async function getSurveyScales() {
        useSurveyScale.getSurveyScales().then((result) => {
            if (result) setSurveyScales(result);
        });
    }

    const goToSurvey = (id) => location.push(`/admin/details-survey-scale/${surveyId}/${id}`)

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{padding: 8,}} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Buscar`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Buscar
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{width: 90,}}
                    >
                        Limpiar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (text),
    });

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            fixed: 'left',
            width: 80,
            sorter: (a, b) => a.id - b.id,
            sortDirections: ['descend','ascend'],
        },
        {
            title: 'Titulo de escala',
            dataIndex: 'title',
            key: 'title',
            ...getColumnSearchProps('title'),
        },
        {
            title: 'Acciones',
            dataIndex: 'options',
            key: 'options',
        },
    ];

    const dataSource = surveyScales?.map(surveyScale => {
        return {
            key: surveyScale.surveyScaleId,
            id: surveyScale.surveyScaleId,
            title: surveyScale.description,
            options: <><Space>
                <Button type="button" className="btn-icon-table edit" onClick={() => goToSurvey(surveyScale.surveyScaleId)}>
                    <FaEdit />
                </Button>
                <Button type="button" className="btn-icon-table delete" onClick={() => handleDelete(surveyScale)}>
                    <FaTrash />
                </Button>
            </Space></>
        };
    })

    useEffect(() => {
        getSurveyScales();
    }, [props.refresh]);

    return (
        <div className='table-admin'>
            <Table
                className="table-admin"
                scroll={{ x: '992px', y: 800 }}
                columns={columns}
                dataSource={dataSource}
                locale={{
                    triggerDesc: 'Ordenamiento descendente',
                    triggerAsc: 'Ordenamiento ascedente',
                    cancelSort: 'Cancelar ordenamiento',
                    emptyText: 'Sin registros',
                }}
            />
        </div>

    );
}
