import React from "react";
import { FaFacebook, FaInstagram, FaTiktok, FaYoutube } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import "./socialMedia.scss";

export const SocialMedia = () => {
  return (
    <section className={"social-media"}>
      <p>¡Síguenos!</p>
      <ul className={"social-media__list"}>
        <li>
          <a
            target={"_blank"}
            href={"https://www.facebook.com/SomosPrideMx/"}
            className={"facebook"}
          >
            <FaFacebook />
          </a>
        </li>
        <li>
          <a
            target={"_blank"}
            href={"https://instagram.com/somos_pridemx?igshid=YmMyMTA2M2Y="}
            className={"instagram"}
          >
            <FaInstagram />
          </a>
        </li>
        <li>
          <a
            target={"_blank"}
            href={"https://www.youtube.com/@pridemx"}
            className={"youtube"}
          >
            <FaYoutube />
          </a>
        </li>
        <li>
          <a
            target={"_blank"}
            href={"https://www.tiktok.com/@somos_pridemx"}
            className={"tiktok"}
          >
            <FaTiktok />
          </a>
        </li>
        <li>
          <a
            target={"_blank"}
            href={"https://twitter.com/SomosPrideMx"}
            className={"twitter"}
          >
            <FaXTwitter />
          </a>
        </li>
      </ul>
    </section>
  );
};
