import React from 'react';
import { EditSurveyScale } from './editSurveyScale';
import {TitleAdmin} from "../components/titleAdmin/titleAdmin";

export function DetailsSurveyScale(props) {
    return (
        <div className="dashboard-admin-container">
            <TitleAdmin
                title={'Escalas de valoración'}
                subtitle={props.match.params.id !== "0" ? "Editar escala" : "Nueva escala"}
                event={() => window.history.back()}
            />
            <EditSurveyScale surveyScaleId={props.match.params.id} />
        </div>
    );
}
