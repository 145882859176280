import React from "react";
import { Whisper } from "rsuite";
import {
  BsFacebook,
  BsInstagram,
  BsLinkedin,
  BsTiktok,
  BsYoutube,
} from "react-icons/bs";
import { BiHelpCircle, BiWorld } from "react-icons/bi";
import { FaXTwitter } from "react-icons/fa6";
import { Tooltip } from "antd";

export function ItemSocial(props) {
  const renderTooltip = (
    <Tooltip id="button-tooltip">{props.socialIcoName}</Tooltip>
  );

  return (
    <Whisper
      placement="top"
      trigger="hover"
      speaker={renderTooltip}
      controlId="button-tooltip"
    >
      <Tooltip placement="bottom" title={props.socialIcoName}>
        <a href={props.socialUrl} target="_blank" className="link">
          {props.socialIcoName === "LinkedIn" ? <BsLinkedin /> : ""}
          {props.socialIcoName === "Instagram" ? <BsInstagram /> : ""}
          {props.socialIcoName === "Facebook" ? <BsFacebook /> : ""}
          {props.socialIcoName === "Twitter" ? <FaXTwitter /> : ""}
          {props.socialIcoName === "TikTok" ? <BsTiktok /> : ""}
          {props.socialIcoName === "YoutubeChannel" ? <BsYoutube /> : ""}
          {props.socialIcoName === "Help" ? <BiHelpCircle /> : ""}
          {props.socialIcoName === "WebSite" ? <BiWorld /> : ""}
          {props.socialIcoName === "Web" ? <BiWorld /> : ""}
        </a>
      </Tooltip>
    </Whisper>
  );
}
