import React, { useState } from "react";
import { Button, Col, Drawer, Row, Modal, Image, Tag } from "antd";
import ReactPlayer from "react-player";
import { FaPlay } from "react-icons/fa";
import { useSelector } from "react-redux";
import moment from "moment";

export const EventApproveModal = ({
  open,
  onClose,
  currentItem,
  changeStatus,
  isApprovalView,
}) => {
  const roleName = useSelector((state) => state.userSettings.roleName);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [videoStop, setVideoStop] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
    setVideoStop(true);
  };

  return (
    <Drawer onClose={onClose} open={open} size={"large"}>
      <aside className={"event-pride-approve-aside"}>
        <Row gutter={16}>
          <Col xs={24}>
            <section className={"header"}>
              <h2>Información completa del evento</h2>
              {isApprovalView && (
                <div className={"btns"}>
                  <Button
                    type={"primary"}
                    className={"cancel"}
                    onClick={() =>
                      changeStatus(currentItem.ticketsEventId, false)
                    }
                  >
                    Rechazar
                  </Button>
                  <Button
                    type={"primary"}
                    className={"accept"}
                    onClick={() =>
                      changeStatus(currentItem.ticketsEventId, true)
                    }
                  >
                    Autorizar
                  </Button>
                </div>
              )}
            </section>
          </Col>
          <Col xs={24}>
            <section className={"content-section-first"}>
              <img
                src={currentItem.eventTicketBanner}
                alt={currentItem.eventName}
              />
              <div className={"info"}>
                <article className={"info-left"}>
                  <h3>{currentItem.eventName}</h3>
                  <label>
                    Sector: <span>{currentItem.ticketsEventSectorName}</span>
                  </label>
                  <p>{currentItem.eventDescription}</p>
                </article>
                <article className={"info-right"}>
                  <label>
                    Fecha:{" "}
                    <span>
                      {moment(currentItem.creationDate).format(
                        "DD/MM/YYYY,  HH:mm A"
                      )}
                    </span>
                  </label>
                </article>
              </div>
            </section>
          </Col>
          <Col xs={24}>
            <section className={"content-section-second"}>
              <>
                {currentItem.eventTicketPromo && (
                  <article className={"banner"}>
                    <Image src={currentItem.eventTicketPromo} />
                  </article>
                )}
              </>
              <>
                {currentItem.eventTicketAd && (
                  <article className={"ad-ticket"}>
                    <Image src={currentItem.eventTicketAd} />
                  </article>
                )}
              </>
              <>
                {currentItem.eventTicketVideo && (
                  <article className={"video"}>
                    <ReactPlayer
                      width={"100%"}
                      height={125}
                      url={currentItem.eventTicketVideo}
                    />
                    <Button
                      htmlType={"button"}
                      type={"primary"}
                      className={"btn-play"}
                      onClick={showModal}
                    >
                      <FaPlay />
                    </Button>
                  </article>
                )}
              </>
            </section>
          </Col>
          <Col xs={24}>
            <section className={"content-section-third"}>
              <p>
                <span>Aforo</span>
                {currentItem.eventAudience}
              </p>
              <p>
                <span>Costo de boleto</span>
                {currentItem.eventPriceTicket}
              </p>
              <p>
                <span>Observaciones de venta</span>
                {currentItem.eventNote}
              </p>
            </section>
          </Col>
          <Col xs={24}>
            <section className={"content-section-fourth"}>
              <h4>¿En dónde será?</h4>
              <div className={"content"}>
                <article className={"left"}>
                  <p>
                    En:
                    <span>
                      {currentItem.eventPlaceName},{" "}
                      {currentItem.eventStreetName},{" "}
                      {currentItem.eventNeighboor}, {currentItem.eventCity},{" "}
                      {currentItem.eventState}, {currentItem.eventZipCode}
                    </span>
                  </p>
                  <p>
                    Fecha y hora:
                    <span>
                      {moment(currentItem.eventDate).format(
                        "DD/MM/YYYY,  HH:mm A"
                      )}
                    </span>
                  </p>
                  <p>
                    A la venta a partir de:
                    <span>
                      {moment(currentItem.eventTicketDateStart).format(
                        "DD/MM/YYYY,  HH:mm A"
                      )}
                    </span>
                  </p>
                  <p>
                    A la venta hasta:
                    <span>
                      {moment(currentItem.eventTicketDateEnd).format(
                        "DD/MM/YYYY,  HH:mm A"
                      )}
                    </span>
                  </p>
                  <p>
                    Duración del evento:
                    <span>{currentItem.eventTime} Minuto(s)</span>
                  </p>
                </article>
                <article className={"right"}>
                  <p>
                    Teléfono:<span>{currentItem.eventPhone}</span>
                  </p>
                </article>
              </div>
            </section>
          </Col>
          <Col xs={24}>
            <section className={"content-section-sixth"}>
              <h4>Anfitrión</h4>
              <div className={"content"}>
                <article className={"left"}>
                  <p>
                    Por:<span>{currentItem.eventOwnerName}</span>
                  </p>
                  <p>
                    Correo electrónico:
                    <span>{currentItem.eventOwnerEmail}</span>
                  </p>
                </article>
                <article className={"right"}>
                  <p>
                    Teléfono:<span>{currentItem.eventOwnerPhone}</span>
                  </p>
                </article>
              </div>
            </section>
          </Col>
          <Col xs={24}>
            <section className={"content-section-seventh"}>
              <h4>Datos bancarios</h4>
              <div className={"content"}>
                <article className={"first"}>
                  <div className={"top"}>
                    <p>
                      Titular:<span>{currentItem.eventOwnerBankName}</span>
                    </p>
                    <p>
                      Banco:
                      <span style={{ textTransform: "uppercase" }}>
                        {currentItem.eventOwnerBank}
                      </span>
                    </p>
                    <p>
                      CLABE:<span>{currentItem.eventOwnerClabe}</span>
                    </p>
                  </div>
                  <div className={"bottom"}>
                    <p>
                      Carátula de Edo. Cuentas:
                      <a
                        href={currentItem.eventOwnerFileBankAccount}
                        className={"link text-orange"}
                        target="_blank"
                      >
                        Ver
                      </a>
                    </p>
                  </div>
                </article>
                <article className={"second"}>
                  <p>
                    ¿Requiere factura?:
                    <span>{currentItem.eventOwnerInvoice ? "Sí" : "No"}</span>
                  </p>
                </article>
                <>
                  {currentItem.eventOwnerInvoice && (
                    <article className={"three"}>
                      <div className={"top"}>
                        <p>
                          Razón social:
                          <span>{currentItem.eventOwnerCompanyReason}</span>
                        </p>
                        <p>
                          RFC:<span>{currentItem.eventOwnerRFC}</span>
                        </p>
                      </div>
                      <div className={"bottom"}>
                        <p>
                          Constancia situación fiscal:
                          <a
                            href={currentItem.eventOwnerFileConstancy}
                            className={"link text-orange"}
                            target="_blank"
                          >
                            Ver
                          </a>
                        </p>
                      </div>
                    </article>
                  )}
                </>
              </div>
            </section>
          </Col>
        </Row>
      </aside>
      <Modal
        title="Video Promocional"
        open={isModalOpen}
        footer={false}
        onCancel={() => {
          setIsModalOpen(false);
          setVideoStop(false);
        }}
      >
        <ReactPlayer
          width={"100%"}
          height={"auto"}
          url={currentItem.eventTicketVideo}
          controls={true}
          playing={videoStop}
        />
      </Modal>
    </Drawer>
  );
};
