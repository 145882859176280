import React, { useState } from 'react';
import { AdminDeparment } from './adminDeparment'
import { AdminCompanyEventType } from './adminCompanyEventType'
import { BranchOffice } from './branchOffice/branchOffice'
import { AdminJobRoles } from './jobRoles/adminJobRole'
import { Notice } from './notice/notice'
import { CompanyCustomize } from './companyCustomize/companyCustomize'
import { useSelector } from 'react-redux';
import './control.scss'
import '../admin/admin.scss';
import {Tabs} from "antd";
import {TitleAdmin} from "../components/titleAdmin/titleAdmin";
import {useHistory} from "react-router-dom";

export const Control = () => {
	const [activeTab, setActiveTab] = useState('1');
	const companyId = useSelector(state => state.userSettings.companyId)
	const history = useHistory();

	const onChange = (key) => setActiveTab(key)

	const items = [
		{
			key: '1',
			label: 'Grupos',
			children: <AdminDeparment />,
		},
		{
			key: '2',
			label: 'Tipos de evento',
			children: <AdminCompanyEventType />,
		},
		{
			key: '3',
			label: 'Sucursales',
			children: <BranchOffice />,
		},
		{
			key: '4',
			label: 'Categorías',
			children: <AdminJobRoles />,
		},
		{
			key: '5',
			label: 'Avisos',
			children: <Notice />,
		},
		{
			key: '6',
			label: 'Personalización',
			children: <CompanyCustomize CompanyId={companyId}  />,
		},
	];

	return (
		<section className="dashboard-admin-container">
			<TitleAdmin
				title={'Control'}
				subtitle={'Personalización de sitio'}
				event={() => history.push("/admin")}
			/>
			<Tabs defaultActiveKey={activeTab} items={items} onChange={onChange} />
		</section>
	)
}
