import React, { useEffect, useState } from "react";
import { Link, useParams, useRouteMatch } from "react-router-dom";
import useInputForm from "../../components/CustonHooks/FormHook";
import { FaChevronLeft } from "react-icons/fa";

import "./jobBank.scss";
import { postulanteService } from "../../services/postulantJob.service";
import Swal from "sweetalert2";
import { backendService } from "../../services/backend.service";

export function ApplicantForm({ closeForm, job, user }) {
  const [fileValidator, setFileValidator] = useState(false);
  const [valMessage, setValMessage] = useState("");
  const [archivo, setArchivo] = useState({
    archivo: null,
    archivoNombre: "",
    archivoURL: "",
  });

  let { id } = useParams();

  useEffect(() => {
    getFile(user.urlCurriculum);
  }, []);

  const onSave = (e) => {
    if (validateFile(e.target.files[0])) {
      setArchivo({
        archivo: e.target.files[0],
        archivoNombre: e.target.files[0].name,
      });
      setFileValidator(false);
    } else {
      setFileValidator(true);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const formdata = new FormData();
    if (archivo.archivo === null) {
      formdata.append("Curriculum", null);
    } else {
      formdata.append("Curriculum", archivo.archivo);
    }

    formdata.append("JobId", id);

    postulanteService.agregarPostulante(formdata).then((result) => {
      if (result?.responseCode === "00") {
        //corregir lo de mensajes y mandar el correo.
        Swal.fire({
          title: "¡Se envió tu postulación!",
          text: "Si resultas seleccionado para una entrevista, la empresa te notificará a través de la bandeja de mensajes",
          icon: "success",
          allowOutsideClick: false,
        }).then(() => {
          closeForm();
        });
      } else {
        Swal.fire({
          title: "Error al postularse",
          text: result.responseMessage,
          icon: "error",
          allowOutsideClick: false,
        }).then(() => {
          closeForm();
        });
      }
    });
  };

  const getFile = async (url) => {
    if (url === null) return;
    let barra = url.lastIndexOf("/");
    let nombre = url.substring(barra + 1);

    let response = await fetch(url);
    let data = await response.blob();
    let file = new File([data], nombre);
    let container = new DataTransfer();
    container.items.add(file);
    document.querySelector("#archivo").files = container.files;

    setArchivo({
      archivo: file,
      archivoNombre: nombre,
    });
  };

  const imageExt = ["pdf", "doc", "docx"];

  const validateFile = (fileData) => {
    var re = /(?:\.([^.]+))?$/;
    if (fileData.size > 10000000) {
      setValMessage("El tamaño debe ser menor a 10MB");
      return false;
    }

    var ext = re.exec(fileData.name)[1];
    if (imageExt.includes(ext)) {
      setValMessage("");
      return true;
    } else {
      setValMessage("Formato no válido");
      return false;
    }
  };

  // const { inputs, handleInputChange, handleSubmit, setInputs } = useInputForm(
  //   onSave,
  //   data
  // );

  return (
    <div className="wrapper_applicant_form">
      <div className="content_applicant_form">
        <br />
        <div>
          <button className="btn-back trans" onClick={() => closeForm()}>
            <FaChevronLeft />
            <p>Regresar</p>
          </button>
        </div>

        <div className="sec_form_applicant card_shadows">
          <div className="content_application">
            <div className="info_business_applicant">
              <h5 className="text fnt_medium mb-1">{job.title}</h5>
              <p>
                {job.companyName} - {job.location}
              </p>
            </div>
            <form className="info_user_applicant">
              {user && (
                <div>
                  <p className="mb-1">Información del postulante</p>
                  <h5 className="text fnt_medium mb-1">
                    {user?.firstName +
                      " " +
                      user?.middleName +
                      " " +
                      user?.lastName}
                  </h5>
                  <p>{user?.email}</p>
                  <p>{user?.phoneNumber}</p>
                  <p>
                    {user.town}, {user.colony}. CP. {user.postalCode}
                  </p>
                </div>
              )}
              <div className="mt-4">
                <p className="mb-1">
                  Adjunta tu currículum <i>(Formato .pdf o .docx)</i>
                </p>
                {fileValidator && (
                  <p className="mb-1 text-danger">{valMessage}</p>
                )}
                <div className="custom-file">
                  <input
                    type="file"
                    className="file-upload"
                    id="archivo"
                    lang="es"
                    name="archivo"
                    accept=".pdf"
                    // onChange={handleInputChange}
                    onChange={(x) => onSave(x)}
                    // required={true}
                  />
                  {archivo.archivoNombre === "" ? (
                    <label className="custom-file-label" for="archivo">
                      Seleccionar Archivo
                    </label>
                  ) : (
                    <label className="custom-file-label" for="archivo">
                      {archivo.archivoNombre}
                    </label>
                  )}
                  {/* <label className="custom-file-label" for="archivo">
                    Seleccionar Archivo
                  </label> */}
                </div>
              </div>
              <div className="text-right mt-4">
                {/* to="/applicant-sent" */}
                <button className="btn primary" onClick={onSubmit}>
                  Continuar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
