import React, {useEffect, useState, forwardRef, useImperativeHandle, useRef} from 'react';
import { companyEventTypeService } from '../../services/companyEventType.service';
import '../admin/admin.scss';
import {Input, Space, Table, Button, Tag} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {FaEdit, FaTrash} from "react-icons/fa";
import MySwal from "sweetalert2";

const SearchCompanyEventType = forwardRef((props, ref) => {

    const [dataGrid, setDataGrid] = useState([]);
    const handleDelete = (event, data) => {
        MySwal.fire({
            title:"¿Deseas desactivar encuesta?",
            text: "No podrás revertir esta acción",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Eliminar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if(result.isConfirmed)
                companyEventTypeService.deletetCompanyEventType(data).then((result) => { getCompanyEventTypes(); });
        })
    };
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    async function getCompanyEventTypes() {
        companyEventTypeService.getCompanyEventType()
            .then((result) => {
                if (result) setDataGrid(result);
            });
    }

    useEffect(() => {
        getCompanyEventTypes();
    }, []);

    const refreshDataGrid = () => {
        getCompanyEventTypes();
    }

    useImperativeHandle(ref, () => ({
        refreshDataGrid: refreshDataGrid
    }));

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{padding: 8,}} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Buscar`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{width: 90,}}
                    >
                        Buscar
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Limpiar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const columnsEvents = [
        {
            title: 'Tipo de evento',
            key: 'eventType',
            dataIndex: 'eventType',
            fixed: 'left',
            width: 120,
            ...getColumnSearchProps('eventType')
        },
        {
            title: '¿Genera recompensa?',
            key: 'reward',
            dataIndex: 'reward',
            width: 100,
        },
        {
            title: '¿Genera notificación?',
            key: 'notification',
            dataIndex: 'notification',
            width: 100,
        },
        {
            title: '¿Seleccionará la audiencia?',
            key: 'audience',
            dataIndex: 'audience',
            width: 100,
        },
        {
            title: '¿Se unirán nuevos registros?',
            key: 'records',
            dataIndex: 'records',
            width: 100,
        },
        {
            title: 'Puntos',
            key: 'points',
            dataIndex: 'points',
            width: 100,
            sorter: (a, b) => {
                if(a.points < b.points) {
                    return -1;
                }
                if(a.points > b.points) {
                    return 1;
                }
                return 0;
            },
            sortDirections: ['descend','ascend'],
        },
        {
            title: 'Opciones',
            key: 'options',
            dataIndex: 'options',
            width: 90,
        },
    ];

    const dataEvents = dataGrid?.map(item => {
        return (
            {
                "key": item.companyEventTypeId,
                eventType: item.eventTypeDesc,
                reward:  <Tag color={item.isRewarded ? 'success' : 'error'}>{item.isRewarded ? 'Sí' : 'No'}</Tag>,
                notification: <Tag color={item.isNotification ? 'success' : 'error'}>{item.isNotification ? 'Sí' : 'No'}</Tag>,
                audience: <Tag color={item.isAudienceAddressed ? 'success' : 'error'}>{item.isAudienceAddressed ? 'Sí' : 'No'}</Tag>,
                records: <Tag color={item.onNewUserRegistry ? 'success' : 'error'}>{item.onNewUserRegistry ? 'Sí' : 'No'}</Tag>,
                points: item.reward,
                options: <Space wrap>
                    <Button type="primary" htmlType={'button'} className="edit" onClick={() => props.handleRowSelect(item)}>
                        <FaEdit />
                    </Button>
                    <Button type="primary" htmlType={'button'} className="delete" onClick={(event) => handleDelete(event, item)}>
                        <FaTrash />
                    </Button>
                </Space>
            }
        );
    }) ?? [];

    return (
        <Table
            className={'table-admin'}
            columns={columnsEvents}
            dataSource={dataEvents}
            locale={{
                triggerDesc: 'Ordenamiento descendente',
                triggerAsc: 'Ordenamiento ascedente',
                cancelSort: 'Cancelar ordenamiento',
                emptyText: 'Sin registros',
            }}
            scroll={{ x: '992px', y: 800 }}
        />
    );
});

export default SearchCompanyEventType;
