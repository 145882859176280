import React, { useEffect, useState } from "react";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { useHistory, useLocation } from "react-router-dom";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  EmailShareButton,
} from "react-share";
import "./header.scss";

import { userService } from "../../services/user.service";
import { useSelector } from "react-redux";
import { backendService } from "../../services/backend.service";
import {
  isPushNotificationSupported,
  sendNotification,
  initializePushNotifications,
} from "./header.notification";
import { InputGroupText, Input, InputGroup, Alert } from "reactstrap";
import {
  FaBellSlash,
  FaCopy,
  FaEnvelope,
  FaFacebook,
  FaLinkedin,
  FaSignOutAlt,
  FaWhatsapp,
} from "react-icons/fa";
import { ButtonToolbar, Dropdown } from "rsuite";
import { FaXTwitter } from "react-icons/fa6";
import { ImgCache } from "../CustonHooks/imgCache";
import "rsuite/dist/styles/rsuite-default.css";
import { Tooltip } from "antd";

export function Header({ userProfile, windowDimensions, messages }) {
  const history = useHistory();
  const roleName = useSelector((state) => state.userSettings.roleName);
  const [notificationInfo, setNotificationInfo] = useState({
    notificationInfoData: [],
  });

  const [companyLogo, setCompanyLogo] = useState("");
  const [connection, setConnection] = useState(null);

  /* states for dropdowns */
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => {
    setDropdownOpen((prevState) => !prevState);
  };

  /* end states for dropdowns */
  const logout = () => {
    const style = document.getElementsByTagName("style");
    document
      .getElementsByTagName("head")[0]
      .removeChild(style[style.length - 1]);
    userService.logout().then(() => {
      history.push("/");
    });
  };

  const miPerfil = () => history.push("profile");

  useEffect(() => {
    if (performance.navigation.type !== 1) {
      let isFirstTime = localStorage["isFirstTime"];
      if (isFirstTime === undefined) {
        localStorage["isFirstTime"] = true;
        if (isPushNotificationSupported()) {
          initializePushNotifications().then(function (dataConsent) {
            if (dataConsent === "granted") GetnotificationApi(true, false);
          });
        }
      }
    }
    GetnotificationApi(false, true);
  }, []);

  const GetnotificationApi = (ShowPush, ShowNotif) => {
    backendService.GetNotificationData().then((result) => {
      if (result) {
        if (ShowPush) sendNotification(result);
        if (ShowNotif) setNotificationInfo({ notificationInfoData: result });
      }
    });
  };

  const [pathname, setPathname] = useState(useLocation());

  let urls = window.location.href;

  const goHome = () => {
    history.push("/home");
    setActiveLink("home");
  };
  const goAdminDashboard = () => {
    history.push("/admin");
    setActiveLink("admin");
  };

  const [activeLink, setActiveLink] = useState("");

  const getCurrentRoute = () => {
    if (
      pathname.pathname === "/audience" ||
      pathname.pathname == "/course-view" ||
      pathname.pathname === "/pride-music" ||
      pathname.pathname === "/blog-company" ||
      pathname.pathname == "/stories-pride"
    ) {
      setActiveLink("categoriesPride");
    }
    if (
      // pathname.pathname === "/messages" ||
      pathname.pathname == "/rewards" ||
      pathname.pathname == "/profile" ||
      // pathname.pathname == "/diary" ||
      pathname.pathname == "/surveys"
    ) {
      setActiveLink("userInfo");
    }
    if (
      pathname.pathname === "/interest-group" ||
      pathname.pathname == "/caring-community" ||
      pathname.pathname == "/discount" ||
      pathname.pathname == "/job-bank" ||
      pathname.pathname == "/register-job" ||
      pathname.pathname == "/directory"
    ) {
      setActiveLink("communityPride");
    }
    if (pathname.pathname === "/home") setActiveLink("home");
    if (pathname.pathname === "/admin") setActiveLink("admin");
  };

  useEffect(() => {
    getCurrentRoute();
  }, [pathname.pathname]);

  const [visible, setVisible] = useState(false);
  const onDismiss = () => setVisible(!visible);
  const showAlert = () => setVisible(!visible);

  const [widgets, setWidgets] = useState({
    surveyWidget: {},
    messageWidget: {},
    rewardWidget: {},
    courseWidget: {},
    providerWidget: [{ id: 0, logoCrls: "" }],
  });

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {
          connection.on("beNotified", (notification) => {
            const listaEventos = [];
            listaEventos.push(notification);
            sendNotification(listaEventos);
            GetnotificationApi(false, true);
          });
        })
        .catch((error) => console.log(error));
    }
    getCurrentRoute();
  }, [connection]);

  useEffect(() => {
    backendService
      .userSettings()
      .then((result) => setCompanyLogo(result.companyLogo));
    backendService.widgets().then((data) => {
      if (data.surveyWidget != null) {
        let date = new Date();
        date.setDate(date.getDate() + data.surveyWidget.expirationDays);
        data.surveyWidget.expirationDate = date;
      }
      setWidgets(data);
    });
    const connection = new HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_HUB_URL}/notification`, {
        headers: { NoBlocking: true },
      })
      .withAutomaticReconnect()
      .build();

    setConnection(connection);
  }, []);

  return (
    <header className="dashboard-header">
      <div className="dashboard-header-brand">
        <a href={roleName === "Operaciones" ? "/homeOps/1" : "/home"}>
          <ImgCache src={companyLogo} alt={"Logo Kuimby"} />
        </a>
        {/* <Doodle view='rwd__movil' init='particlesInit'/> */}
      </div>
      {windowDimensions <= 992 ? (
        ""
      ) : (
        <div className="dashboard-header-menu">
          <ul>
            <li>
              <ButtonToolbar
                onClick={goHome}
                className={`btn-menu dashboard ${
                  activeLink === "home" ? "dashboardLink" : ""
                }`}
              >
                <i className="icon-home"></i>
              </ButtonToolbar>
            </li>
            <li>
              <ButtonToolbar className="user">
                <Dropdown
                  className={`btn-menu user ${
                    activeLink === "userInfo" ? "userLink" : ""
                  }`}
                  trigger={["click", "hover"]}
                  title={
                    <>
                      <i className="icon-profile"></i>
                      {widgets.surveyWidget ||
                      widgets.rewardWidget > 0 ? (
                        <div className="circle-notification"></div>
                      ) : (
                        ""
                      )}
                    </>
                  }
                >
                  <Dropdown.Item className="user" href="/profile">
                    <div className="sublinks">
                      <i className="icon-user"></i>
                      <p> Mi Perfil</p>
                    </div>
                  </Dropdown.Item>
                  {/* <Dropdown.Item href="/messages" className="user">
                    <div className="sublinks">
                      <span>
                        <i className="icon-envelope"></i>
                      </span>
                      <p>
                        Mensajes
                        {messages.unreadCount > 0 ? (
                          <span className="notification">
                            {messages.unreadCount}
                          </span>
                        ) : (
                          ""
                        )}
                      </p>
                    </div>
                  </Dropdown.Item> */}
                  {/* <Dropdown.Item href="/diary" className="user">
                    <div className="sublinks">
                      <span>
                        <i className="icon-calendar-time"></i>
                      </span>
                      <p>Calendario</p>
                    </div>
                  </Dropdown.Item> */}
                  <Dropdown.Item href="/rewards" className="user">
                    <div className="sublinks">
                      <span>
                        <i className="icon-gif"></i>
                      </span>
                      <p>
                        Rewards
                        {widgets.rewardWidget ? (
                          <span className="notification">
                            {widgets.rewardWidget.quantity}
                          </span>
                        ) : (
                          ""
                        )}
                      </p>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item href="/surveys" className="user">
                    <div className="sublinks">
                      <span>
                        <i className="icon-opinion"></i>
                      </span>
                      <p>
                        Tu Opinión
                        {widgets.surveyWidget ? (
                          <span className="notification">
                            {widgets.surveyWidget.quantity}
                          </span>
                        ) : (
                          ""
                        )}
                      </p>
                    </div>
                  </Dropdown.Item>
                </Dropdown>
              </ButtonToolbar>
            </li>
            <li>
              <ButtonToolbar className="categories">
                <Dropdown
                  className={`btn-menu categories ${
                    activeLink === "categoriesPride" ? "categoriesLink" : ""
                  }`}
                  title={
                    <>
                      <i className="icon-categories"></i>
                      {widgets.courseWidget ? (
                        <div className="circle-notification"></div>
                      ) : (
                        ""
                      )}
                    </>
                  }
                  trigger={["click", "hover"]}
                >
                  <Dropdown.Item href="/blog-company" className="categories">
                    <div className="sublinks">
                      <i className="icon-pride-world"></i>
                      <p>Mundo Pride</p>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item href="/stories-pride" className="categories">
                    <div className="sublinks">
                      <i className="icon-pride"></i>
                      <p>Orgullo LGBTQ+</p>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item href="/course-view" className="categories">
                    <div className="sublinks">
                      <i className="icon-billboard"></i>
                      <p>
                        Cartelera Pride
                        {widgets.courseWidget ? (
                          <span className="notification">
                            {widgets.courseWidget.quantity}
                          </span>
                        ) : (
                          ""
                        )}
                      </p>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item href="/audience" className="categories">
                    <div className="sublinks">
                      <i className="icon-pride-talks"></i>
                      <p>PrideTalks</p>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item href="/pride-music" className="categories">
                    <div className="sublinks">
                      <i className="icon-rewards"></i>
                      <p>Pride Music</p>
                    </div>
                  </Dropdown.Item>
                </Dropdown>
              </ButtonToolbar>
            </li>
            <li>
              <ButtonToolbar className="community">
                <Dropdown
                  className={`btn-menu community ${
                    activeLink === "communityPride" ? "communityLink" : ""
                  }`}
                  title={<i className="icon-community"></i>}
                  trigger={["click", "hover"]}
                >
                  <Dropdown.Item href="/directory" className="community">
                    <div className="sublinks">
                      <i className="icon-entrepreneurs"></i>
                      <p>Economía Incluyente</p>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item className="community" href="/interest-group">
                    <div className="sublinks">
                      <i className="icon-interest-group"></i>
                      <p>Grupos de Interés</p>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item href="/discount" className="community">
                    <div className="sublinks">
                      <i className="icon-discounts"></i>
                      <p>Beneficios</p>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item href="/caring-community" className="community">
                    <div className="sublinks">
                      <i className="icon-hand-heart"></i>
                      <p>Comunidad Solidaria</p>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item href="/job-bank" className="community">
                    <div className="sublinks">
                      <i className="icon-jobs"></i>
                      <p>Bolsa de trabajo</p>
                    </div>
                  </Dropdown.Item>
                </Dropdown>
              </ButtonToolbar>
            </li>
            {
              {
                "Recursos Humanos": (
                  <li>
                    <ButtonToolbar
                      onClick={goAdminDashboard}
                      className={`btn-menu admin ${
                        activeLink === "admin" ? "adminLink" : ""
                      }`}
                    >
                      <i className="icon-user-admin"></i>
                    </ButtonToolbar>
                  </li>
                ),
              }[roleName]
            }
          </ul>
        </div>
      )}
      <div className="dashboard-header-user">
        <div className="btns-dropdowns">
          <Tooltip placement="bottom" title={"Compartir página"}>
            <ButtonToolbar>
              <Dropdown
                trigger={["click"]}
                title={<i className="icon-share"></i>}
                className="button-circle light share"
              >
                <div className="sharing-content">
                  <p>Comparte esta página</p>
                  <div className="icons">
                    <FacebookShareButton url={urls} className="facebook">
                      <FaFacebook size={32} />
                    </FacebookShareButton>
                    <TwitterShareButton url={urls} className="twitter">
                      <FaXTwitter size={32} />
                    </TwitterShareButton>
                    <WhatsappShareButton url={urls} className="whatsapp">
                      <FaWhatsapp size={32} />
                    </WhatsappShareButton>
                    <LinkedinShareButton url={urls} className="linkedin">
                      <FaLinkedin size={32} />
                    </LinkedinShareButton>
                    <EmailShareButton url={urls} className="email">
                      <FaEnvelope size={32} />
                    </EmailShareButton>
                  </div>
                  <Alert color="success" isOpen={visible} toggle={onDismiss}>
                    ¡Link copiado!
                  </Alert>
                  <p>O copia el link</p>
                  <div className="copy-paste">
                    <InputGroup>
                      <Input type="text" readOnly value={urls} />
                      <InputGroupText>
                        <button
                          onClick={() => {
                            navigator.clipboard.writeText(urls);
                            showAlert();
                          }}
                        >
                          <FaCopy />
                        </button>
                      </InputGroupText>
                    </InputGroup>
                  </div>
                </div>
              </Dropdown>
            </ButtonToolbar>
          </Tooltip>

          {windowDimensions >= 1280 ? (
            <Tooltip placement="bottom" title={"Notificaciones"}>
              <ButtonToolbar>
                <Dropdown
                  className="button-circle light notifications"
                  trigger={["click"]}
                  title={
                    <>
                      <i className="icon-notifications"></i>
                      <span className="quantity">
                        {notificationInfo?.notificationInfoData?.length}
                      </span>
                    </>
                  }
                >
                  <div className="notifications-body">
                    <div className="notifications-title">Notificaciones</div>
                    <ul
                      className={`notifications-content ${
                        notificationInfo?.notificationInfoData?.length > 0
                          ? ""
                          : "empty"
                      }`}
                    >
                      {notificationInfo?.notificationInfoData?.length > 0 ? (
                        notificationInfo.notificationInfoData.map(
                          (item, index) => (
                            <li
                              className="notifications-content-item"
                              key={index}
                            >
                              <a href={item.link} onClick={() => toggle(item)}>
                                <span>
                                  {item.companyEventTypeNotificationMessage}
                                </span>
                                <p>{item.message}</p>
                              </a>
                            </li>
                          )
                        )
                      ) : (
                        <li className="notifications-content-empty">
                          <FaBellSlash />
                          <span>No hay nuevas notificaciones</span>
                        </li>
                      )}
                    </ul>
                  </div>
                </Dropdown>
              </ButtonToolbar>
            </Tooltip>
          ) : (
            ""
          )}
        </div>
        <ButtonToolbar>
          <Dropdown
            className="button-user"
            trigger={["click"]}
            title={
              userProfile && userProfile.genderIdentityFlag ? (
                <>
                  <div className={`avatar `}>
                    <div
                      className={`avatar-box rotating ${userProfile.genderIdentityFlag}`}
                    ></div>
                    <img
                      src={userProfile && userProfile.urlProfileImage}
                      alt="PMX"
                    />
                  </div>
                  {windowDimensions <= 1280 ? (
                    <span className="quantity">
                      {notificationInfo?.notificationInfoData?.length}
                    </span>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <>
                  <div className={`avatar`}>
                    <div className="avatar-box rotating"></div>
                    <img
                      src={userProfile && userProfile.urlProfileImage}
                      alt="PMX"
                    />
                  </div>
                  {windowDimensions <= 1280 ? (
                    <span className="quantity">
                      {notificationInfo?.notificationInfoData?.length}
                    </span>
                  ) : (
                    ""
                  )}
                </>
              )
            }
          >
            <Dropdown.Item onClick={miPerfil} href="/profile">
              <i className="icon-user"></i>
              <p>Mi Perfil</p>
            </Dropdown.Item>
            {windowDimensions <= 1280 ? (
              <Dropdown
                className="dropdown-notifications"
                placement="leftStart"
                title={
                  <>
                    <span
                      style={{
                        background: "#6f6f6f",
                        borderRadius: ".25rem",
                        width: "25px",
                        height: "20px",
                        color: "white",
                        marginLeft: ".5rem",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      {notificationInfo?.notificationInfoData?.length}
                    </span>
                    <p>Notificaciones</p>
                    <i className="icon-notifications"></i>
                  </>
                }
              >
                <Dropdown.Menu
                  title={
                    <>
                      <div className="notifications-body">
                        <div className="notifications-title">
                          Notificaciones
                        </div>
                        <ul
                          className={`notifications-content ${
                            notificationInfo?.notificationInfoData?.length > 0
                              ? ""
                              : "empty"
                          }`}
                        >
                          {notificationInfo?.notificationInfoData?.length >
                          0 ? (
                            notificationInfo.notificationInfoData.map(
                              (item, index) => (
                                <li
                                  className="notifications-content-item"
                                  key={index}
                                >
                                  <a
                                    href={item.link}
                                    onClick={() => toggle(item)}
                                  >
                                    <span>
                                      {item.companyEventTypeNotificationMessage}
                                    </span>
                                    <p>{item.message}</p>
                                  </a>
                                </li>
                              )
                            )
                          ) : (
                            <li className="notifications-content-empty">
                              <FaBellSlash />
                              <span>No hay nuevas notificaciones</span>
                            </li>
                          )}
                        </ul>
                      </div>
                    </>
                  }
                ></Dropdown.Menu>
              </Dropdown>
            ) : (
              ""
            )}
            <Dropdown.Item href="/logout" onClick={logout}>
              <i>
                <FaSignOutAlt />
              </i>
              <p> Cerrar sesión</p>
            </Dropdown.Item>
          </Dropdown>
        </ButtonToolbar>
      </div>
      <script src="/service-worker.js" />
    </header>
  );
}
