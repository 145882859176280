import React, {useState} from "react";
import { attendeeService } from "../../services/attendee.service";
import { SelectUsers } from "./selectUsers";
import { useEffect } from "react";
import {FaChevronRight, FaPlus, FaTimesCircle, FaTrash} from "react-icons/fa";
import {Col, Row} from "antd";
import {HeadingAdmin} from "../../backoffice/components/headingAdmin/headingAdmin";
import {Table} from "reactstrap";
import {Checkbox} from "../../backoffice/components/checkbox/checkbox";
import MySwal from "sweetalert2";

export const AudienceUsers = ({ targetId, eventType, selectUser, showBtn = false }) => {
  const [users, setUsers] = useState([]);
  const [showSelect, setShowSelect] = useState(false);
  const [show, setShow] = useState(showBtn);
  const [userSelec, setUserSelec] = useState([]);
  const [usersRemove, setUsersRemove] = useState([]);

  const getUsers = () => {
    attendeeService.getAttendeeUsers(targetId, eventType).then((result) => {
      if (result) {
        setUsers(result);
        selectUser(result);
      } else {
        setUsers([]);
        selectUser([]);
      }
    });
  };

  const addUsers = () => {
    setUsers([...users, ...userSelec]);
    selectUser([...users, ...userSelec]);
    closeModal();
  };

  const removeUsers = (removeUsersId) => {
    MySwal.fire({
      title: '¿Estás seguro?',
      text: "No podrás revertir esta acción",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#FF002A',
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if(result.isConfirmed) {
        setUsers(users.filter((item) => !usersRemove.includes(item.id)));
        selectUser(users.filter((item) => !usersRemove.includes(item.id)));
        setUsersRemove([]);
      }
    })

  };

  const closeModal = () => {
    setShowSelect(false);
    setUserSelec([]);
  };

  const handleSelectUser = (value, data) => {
    data.check = value;
    if (data.check) setUsersRemove([...usersRemove, data.id]);
    else setUsersRemove(usersRemove.filter((item) => item != data.id));
  };

  const handleSelectUserAll = (value) => {
    let localUsers = [];
    users.map((data) => {
      if (value && !data.check) {
        usersRemove.push(data.id);
        localUsers = usersRemove;
      }
      data.check = value;
    });

    setUsersRemove(localUsers.length === 0 ? [] : [...localUsers]);
  };

  useEffect(() => {
    setShow(showBtn);
    getUsers();
  }, [targetId, eventType,showBtn]);

  return (
      <>
        <HeadingAdmin
            text={show ? "Ocultar audiencia" : "Ver audiencia"}
            icon={show ?  <FaTimesCircle /> : <FaChevronRight />}
            event={() => show ?  setShow(false) : setShow(true)}
        />
        {show && (
            <Row gutter={16}>
              <Col xs={24}>
                <SelectUsers
                    selectUser={setUserSelec}
                    title="Agregar usuarios:"
                    usersFilter={users}
                    filter={true}
                />
              </Col>
              {userSelec.length > 0 && (
                  <Col xs={24}>
                    <HeadingAdmin
                        text={'Agregar'}
                        icon={<FaPlus />}
                        event={() => addUsers()}
                    />
                  </Col>
              )}
              <Col xs={24}>
                <h4>Audiencia</h4>
                {usersRemove.length > 0 && (
                    <HeadingAdmin
                        text={'Eliminar'}
                        icon={<FaTrash />}
                        event={() => removeUsers(usersRemove)}
                    />
                )}
              </Col>
              <Col xs={24}>
                <div className="table-responsive">
                  <Table className="admin-table" hover>
                    <thead>
                    <tr>
                      <th>Nombre</th>
                      <th>Grupo</th>
                      <th>
                        {users.length > 0 ?
                            <Checkbox
                                text={'Seleccionar'}
                                label={`checkboxAllAtenndee`}
                                handleValueCheck={(val) => handleSelectUserAll(val)}
                            />
                            : "Seleccionar"}
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    {users && (
                        <>
                          {users.map((user, index) => (
                              <tr key={index}>
                                <td>{user.name}</td>
                                <td>{user.department}</td>
                                <td>
                                  <Checkbox
                                      text={''}
                                      label={`check-a-${user.id}`}
                                      name={(user.check)}
                                      handleValueCheck={(val) => handleSelectUser(val,user)}
                                  />
                                </td>
                              </tr>
                          ))}
                        </>
                    )}
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
        )}
      </>
  );
}
