import React from "react";
import {Input} from "reactstrap";
import {BsSearch} from "react-icons/bs";

export function Searches({ placeHolder, handleSearch }) {
    return (
        <div className="searcher-container">
            <Input type="text" placeholder={placeHolder} onChange={(e) => handleSearch(e.target.value)}/>
            <label className="icon"><BsSearch /></label>
        </div>
    );
}
