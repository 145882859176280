import React, { useEffect, useState } from "react";
import {
  Col,
  Form,
  Input,
  Row,
  Select,
  Steps,
  Button,
  Checkbox,
  Result,
  DatePicker,
  TimePicker,
} from "antd";
import {
  FaChevronLeft,
  FaChevronRight,
  FaClock,
  FaDollarSign,
} from "react-icons/fa";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { FaPerson } from "react-icons/fa6";
import dayjs from "dayjs";
import { InputFile } from "../../backoffice/components/inputFile/inputFile";
import { FooterHome } from "../../components/footer/footerHome";
import { HeaderCourse } from "./headerCourse";
import { EventCardInformative } from "./eventCardInformative";
import { ticketsEventService } from "../../services/ticketsEvent.service";
import moment from "moment";

export const EventForm = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [eventSectors, setEventSectors] = useState([]);
  const [picker, setPicker] = useState("day");
  const [eventTicket, setEventTicket] = useState({
    idEventTicket: 0,
    eventName: "",
    eventSector: 0,
    eventDescription: "",
    eventPriceTicket: 0,
    eventAudience: "",
    eventNote: "",
    eventTicketBanner: [],
    eventTicketPromo: [],
    eventTicketAd: [],
    eventTicketVideo: [],
    eventPlaceName: "",
    eventStreetName: "",
    eventZipCode: 0,
    eventState: "",
    eventCity: "",
    eventNeighboor: "",
    eventPhone: "",
    eventDate: "",
    eventHour: "",
    eventTicketDateStart: "",
    eventTicketDateEnd: "",
    eventTicketHourStart: "",
    eventTicketHourEnd: "",
    eventTime: 0,
    eventOwnerPhone: "",
    eventOwnerEmail: "",
    eventOwnerBankName: "",
    eventOwnerName: "",
    eventOwnerBank: "",
    eventOwnerClabe: 0,
    eventOwnerFileBankAccount: [],
    eventOwnerInvoice: false,
    eventOwnerCompanyReason: "",
    eventOwnerRFC: "",
    eventOwnerFileConstancy: [],
  });
  const history = useHistory();

  useEffect(() => {
    const init = async () => {
      const result = await ticketsEventService.GetEventSectors();
      setEventSectors(result);
    };

    init();
  }, []);

  const onFinish = async () => {
    switch (currentStep) {
      case 0:
        if (eventTicket.eventTicketPromo.length === 0) {
          return Swal.fire({
            title: "¡Ups!",
            text: "Te falta una imagen de banner promocional del evento",
            icon: "warning",
          });
        }

        if (eventTicket.eventTicketBanner.length === 0) {
          return Swal.fire({
            title: "¡Ups!",
            text: "Te falta una imagen de banner del evento",
            icon: "warning",
          });
        }

        setCurrentStep(1);
        break;
      case 1:
        if (eventTicket.eventTime === 0) {
          return Swal.fire({
            title: "¡Ups!",
            text: "Tu evento necesita una duración mayor a 0 minutos",
            icon: "warning",
          });
        }
        setCurrentStep(2);
        break;
      case 2:
        setCurrentStep(3);
        break;
      case 3:
        if (eventTicket.eventOwnerFileBankAccount.length === 0)
          return Swal.fire({
            title: "¡Ups!",
            text: "Falta la carátula de tu estado de cuenta",
            icon: "warning",
          });
        if (eventTicket.eventOwnerInvoice) {
          if (eventTicket.eventOwnerFileConstancy.length === 0)
            return Swal.fire({
              title: "¡Ups!",
              text: "Falta el archivo de constancia de situación fiscal",
              icon: "warning",
            });
        }

        eventTicket.eventTicketAd = eventTicket.eventTicketAd[0];
        eventTicket.eventTicketVideo = eventTicket.eventTicketVideo[0];
        eventTicket.eventTicketBanner = eventTicket.eventTicketBanner[0];
        eventTicket.eventTicketPromo = eventTicket.eventTicketPromo[0];
        eventTicket.eventOwnerFileBankAccount =
          eventTicket.eventOwnerFileBankAccount[0];
        eventTicket.eventOwnerFileConstancy =
          eventTicket.eventOwnerFileConstancy[0];

        let data = new FormData();

        for (let key in eventTicket) {
          data.append(key, eventTicket[key]);
        }
        const result = await ticketsEventService.create(data);
        if (result) setCurrentStep(4);
        break;
    }
  };

  const onChangeCheckedInvoice = (e) =>
    setEventTicket({ ...eventTicket, eventOwnerInvoice: e.target.checked });

  const stepsItems = [
    {
      title: "¿De qué va tu evento?",
    },
    {
      title: "¿Cuándo y dónde será?",
    },
    {
      title: "Un poco del anfitrión",
    },
    {
      title: "¿A dónde te depositaremos?",
    },
  ];

  const handleSelectSector = (value) =>
    setEventTicket({ ...eventTicket, eventSector: value });

  const handleInput = (e) =>
    setEventTicket({ ...eventTicket, [e.target.name]: e.target.value });

  const handleFile = (name, files) => {
    setEventTicket({ ...eventTicket, [name]: files });
  };

  const handleFileRemove = (name) => {
    setEventTicket({ ...eventTicket, [name]: [] });
  };

  const handleDate = (_date, date, name) =>
    setEventTicket({ ...eventTicket, [name]: date });

  const handleTime = (_time, time, name) =>
    setEventTicket({ ...eventTicket, [name]: time });

  const handleTimeRange = (_time, time) => {
    setEventTicket({
      ...eventTicket,
      eventTicketHourStart: time[0],
      eventTicketHourEnd: time[1],
    });
  };

  const handleEventTime = (e) =>
    setEventTicket({ ...eventTicket, eventTime: parseInt(e.target.value) });

  const handleBackStep = () => {
    if (currentStep === 1) setCurrentStep(0);
    if (currentStep === 2) setCurrentStep(1);
    if (currentStep === 3) setCurrentStep(2);
  };

  const disabledDateStart = (current) => {
    return current && current < dayjs().startOf("day");
  };

  const disabledDateEnd = (current) => {
    return (
      current &&
      current <= moment(eventTicket.eventTicketDateStart, "DD/MM/YYYY")
    );
  };

  return (
    <section className="course-container-view">
      <Row gutter={16} style={{ margin: "0" }}>
        <Col xs={24}>
          <HeaderCourse
            title={"Cartelera"}
            event={() => history.push("/course-view")}
          />
        </Col>
        {currentStep === 4 ? (
          <Col xs={24}>
            <div className="drawer_result">
              <Result
                status="success"
                title="¡Solicitud exitosa!"
                subTitle="¡Has creado exitosamente una solicitud de un nuevo evento!"
                extra={[
                  <Button
                    type="primary"
                    key="console"
                    className={"btn-pride-ticket"}
                    onClick={() => history.push("/course-view")}
                  >
                    Terminar <FaChevronRight />
                  </Button>,
                ]}
              />
            </div>
          </Col>
        ) : (
          <>
            <Col
              lg={{ offset: 3, span: 18 }}
              md={{ offset: 2, span: 22 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <header className="event-pride-ticket-header">
                <h2>Registremos tu evento</h2>
                <h3>
                  Este formulario es para aquellas personas que desean organizar
                  un evento y vender con nosotros.
                </h3>
                <p>¡Solo te tomará 3 minutitos!</p>
              </header>
            </Col>

            <div className="box__step_cartelera">
              <Steps size="small" current={currentStep} items={stepsItems} />
            </div>
            <Col
              xl={{ offset: 3, span: 18 }}
              lg={{ offset: 0, span: 24 }}
              md={{ offset: 0, span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Row gutter={16} style={{ margin: "0" }}>
                <Col xl={6} lg={6} md={6} sm={24} xs={24}>
                  {
                    {
                      0: (
                        <EventCardInformative
                          title={"¿De qué va tu evento?"}
                          subtitle={
                            "Nombra tu evento y dile a los asistentes por qué deberían venir"
                          }
                          paragraph={""}
                        />
                      ),
                      1: (
                        <EventCardInformative
                          title={"¿En dónde será?"}
                          subtitle={
                            "Ayude a las personas de la zona a descubrir tu evento."
                          }
                          paragraph={""}
                        />
                      ),
                      2: (
                        <EventCardInformative
                          title={"Un poco del anfitrión"}
                          subtitle={
                            "Queremos conocerte para saber a quién dirigirnos."
                          }
                          paragraph={
                            "Nuestro equipo se pondrá en contacto contigo para afinar detalles."
                          }
                        />
                      ),
                      3: (
                        <EventCardInformative
                          title={"¿A dónde te depositaremos?"}
                          subtitle={"Cuentas claras, amistades largas..."}
                          paragraph={
                            "Nuestro equipo se pondrá en contacto contigo para afinar detalles."
                          }
                        />
                      ),
                    }[currentStep]
                  }
                </Col>
                <Col xl={18} lg={18} md={18} sm={24} xs={24}>
                  <Form
                    className={"event-pride-ticket-form"}
                    layout={"vertical"}
                    onFinish={onFinish}
                  >
                    {
                      {
                        0: (
                          <Row gutter={16}>
                            <Col xl={15} lg={14} md={14} sm={24} xs={24}>
                              <Form.Item
                                label={"Nombre de evento"}
                                onChange={handleInput}
                                htmlFor={"eventName"}
                                name={"eventName"}
                                rules={[
                                  {
                                    required: true,
                                    message: "*Campo requerido",
                                  },
                                ]}
                              >
                                <Input
                                  placeholder={"Ejem: Nombre de evento..."}
                                  size={"large"}
                                  name={"eventName"}
                                  count={{
                                    show: true,
                                    max: 100,
                                  }}
                                  maxLength={100}
                                />
                              </Form.Item>
                            </Col>
                            <Col xl={9} lg={10} md={10} sm={24} xs={24}>
                              <Form.Item
                                label={"Sector"}
                                name={"eventSector"}
                                htmlFor={"eventSector"}
                                rules={[
                                  {
                                    required: true,
                                    message: "*Campo requerido",
                                  },
                                ]}
                              >
                                <Select
                                  defaultValue="Seleccionar"
                                  onChange={handleSelectSector}
                                  size={"large"}
                                  options={eventSectors}
                                  name={"eventSector"}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24}>
                              <Form.Item
                                label={"Describe de qué va tu evento"}
                                name={"eventDescription"}
                                htmlFor={"eventDescription"}
                                onChange={handleInput}
                                rules={[
                                  {
                                    required: true,
                                    message: "*Campo requerido",
                                  },
                                ]}
                              >
                                <Input.TextArea
                                  name={"eventDescription"}
                                  size={"large"}
                                  count={200}
                                  rows={5}
                                  maxLength={200}
                                  showCount={true}
                                  placeholder={
                                    "Ejem: Aquí va una descripción para tu evento..."
                                  }
                                ></Input.TextArea>
                              </Form.Item>
                            </Col>
                            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                              <Form.Item
                                label={"Costo de boletos"}
                                name={"eventPriceTicket"}
                                htmlFor={"eventPriceTicket"}
                                onChange={handleInput}
                                rules={[
                                  {
                                    required: true,
                                    message: "*Campo requerido",
                                  },
                                  {
                                    pattern: new RegExp(/^[0-9]*$/g),
                                    message: "Sólo se permiten números",
                                  },
                                ]}
                              >
                                <Input
                                  onKeyPress={(e) => {
                                    if (!/[0-9]/.test(e.key))
                                      e.preventDefault();
                                  }}
                                  maxLength={7}
                                  prefix={<FaDollarSign />}
                                  type={"text"}
                                  name={"eventPriceTicket"}
                                  size={"large"}
                                />
                              </Form.Item>
                            </Col>
                            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                              <Form.Item
                                label={<p>Aforo total del evento</p>}
                                name={"eventAudience"}
                                htmlFor={"eventAudience"}
                                onChange={handleInput}
                                rules={[
                                  {
                                    required: true,
                                    message: "*Campo requerido",
                                  },
                                  {
                                    pattern: new RegExp(/^[0-9]*$/g),
                                    message: "Sólo se permiten números",
                                  },
                                ]}
                              >
                                <Input
                                  onKeyPress={(e) => {
                                    if (!/[0-9]/.test(e.key))
                                      e.preventDefault();
                                  }}
                                  maxLength={7}
                                  prefix={<FaPerson />}
                                  type={"text"}
                                  name={"eventAudience"}
                                  size={"large"}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24}>
                              <Form.Item
                                label={
                                  <p>
                                    Observaciones de venta{" "}
                                    <span className={"text-orange"}>
                                      (Opcional)
                                    </span>
                                  </p>
                                }
                                name={"eventNote"}
                                htmlFor={"eventNote"}
                                onChange={handleInput}
                              >
                                <Input.TextArea
                                  rows={5}
                                  placeholder={
                                    "Ejem: Alguna nota extra para tu evento..."
                                  }
                                  name={"eventNote"}
                                  size={"large"}
                                  count={200}
                                  maxLength={200}
                                  showCount={true}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24}>
                              <h5>
                                Añade imágenes que resalten lo espectacular que
                                será.
                              </h5>
                              <Row gutter={16} style={{ margin: "0" }}>
                                <Col md={12} sm={24} xs={24}>
                                  <Form.Item label={<p>Banner del evento</p>}>
                                    <InputFile
                                      onFilesSelected={(f) =>
                                        handleFile("eventTicketBanner", f)
                                      }
                                      deleteImage={() =>
                                        handleFileRemove("eventTicketBanner")
                                      }
                                      width={1200}
                                      height={700}
                                      edit={
                                        eventTicket.eventTicketBanner?.length >
                                          0 ?? false
                                      }
                                      valueFile={eventTicket.eventTicketBanner}
                                      accept={["image"]}
                                      nameFile={"eventTicketBanner"}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col md={12} sm={24} xs={24}>
                                  <Form.Item label={<p>Banner promocional </p>}>
                                    <InputFile
                                      onFilesSelected={(f) =>
                                        handleFile("eventTicketPromo", f)
                                      }
                                      deleteImage={() =>
                                        handleFileRemove("eventTicketPromo")
                                      }
                                      width={1200}
                                      height={700}
                                      edit={
                                        eventTicket.eventTicketPromo?.length >
                                          0 ?? false
                                      }
                                      valueFile={eventTicket.eventTicketPromo}
                                      accept={["image"]}
                                      nameFile={"eventTicketPromo"}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col md={12} sm={24} xs={24}>
                                  <Form.Item
                                    label={
                                      <p>
                                        Publicidad de boleto{" "}
                                        <span className={"text-orange"}>
                                          (Opcional)
                                        </span>
                                      </p>
                                    }
                                  >
                                    <InputFile
                                      onFilesSelected={(f) =>
                                        handleFile("eventTicketAd", f)
                                      }
                                      deleteImage={() =>
                                        handleFileRemove("eventTicketAd")
                                      }
                                      width={1200}
                                      height={700}
                                      edit={
                                        eventTicket.eventTicketAd?.length > 0 ??
                                        false
                                      }
                                      valueFile={eventTicket.eventTicketAd}
                                      accept={["image"]}
                                      nameFile={"eventTicketAd"}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col md={12} sm={24} xs={24}>
                                  <Form.Item
                                    label={
                                      <p>
                                        Video{" "}
                                        <span className={"text-orange"}>
                                          (Opcional)
                                        </span>
                                      </p>
                                    }
                                  >
                                    <InputFile
                                      onFilesSelected={(f) =>
                                        handleFile("eventTicketVideo", f)
                                      }
                                      deleteImage={() =>
                                        handleFileRemove("eventTicketVideo")
                                      }
                                      edit={
                                        eventTicket.eventTicketAd?.length > 0 ??
                                        false
                                      }
                                      valueFile={eventTicket.eventTicketVideo}
                                      accept={["video"]}
                                      nameFile={"eventTicketVideo"}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        ),
                        1: (
                          <Row gutter={16}>
                            <Col xl={9} lg={10} md={10} sm={24} xs={24}>
                              <Form.Item
                                label={"Nombre del lugar"}
                                name={"eventPlaceName"}
                                htmlFor={"eventPlaceName"}
                                onChange={handleInput}
                                rules={[
                                  {
                                    required: true,
                                    message: "*Campo requerido",
                                  },
                                ]}
                              >
                                <Input
                                  onKeyPress={(e) => {
                                    if (
                                      !/^[ a-zA-ZÀ-ÿ\u00f1\u00d1]*$/g.test(
                                        e.key
                                      )
                                    )
                                      e.preventDefault();
                                  }}
                                  placeholder={
                                    "Ejem: Nombre del lugar del evento..."
                                  }
                                  name={"eventPlaceName"}
                                  size={"large"}
                                  count={{
                                    show: true,
                                    max: 50,
                                  }}
                                  maxLength={50}
                                />
                              </Form.Item>
                            </Col>
                            <Col xl={15} lg={14} md={14} sm={24} xs={24}>
                              <Form.Item
                                label={"Calle y número"}
                                name={"eventStreetName"}
                                htmlFor={"eventStreetName"}
                                onChange={handleInput}
                                rules={[
                                  {
                                    required: true,
                                    message: "*Campo requerido",
                                  },
                                ]}
                              >
                                <Input
                                  type={"text"}
                                  placeholder={"Ejem: Nombre de calle #"}
                                  name={"eventStreetName"}
                                  size={"large"}
                                  count={{
                                    show: true,
                                    max: 100,
                                  }}
                                  maxLength={100}
                                />
                              </Form.Item>
                            </Col>
                            <Col xl={9} lg={9} md={12} sm={24} xs={24}>
                              <Form.Item
                                label={"Código postal"}
                                name={"eventZipCode"}
                                htmlFor={"eventZipCode"}
                                onChange={handleInput}
                                rules={[
                                  {
                                    required: true,
                                    message: "*Campo requerido",
                                  },
                                  {
                                    pattern: new RegExp(/^[0-9]*$/g),
                                    message: "Sólo se permiten números",
                                  },
                                  {
                                    min: 5,
                                    message: "Se necesitan 5 dígitos",
                                  },
                                ]}
                              >
                                <Input
                                  type={"text"}
                                  onKeyPress={(e) => {
                                    if (!/[0-9]/.test(e.key))
                                      e.preventDefault();
                                  }}
                                  maxLength={5}
                                  placeholder={"Ejem: 45124"}
                                  name={"eventZipCode"}
                                  size={"large"}
                                />
                              </Form.Item>
                            </Col>
                            <Col xl={8} lg={9} md={12} sm={24} xs={24}>
                              <Form.Item
                                label={
                                  <p>
                                    Teléfono{" "}
                                    <span className={"text-orange"}>
                                      (Opcional)
                                    </span>
                                  </p>
                                }
                                name={"eventPhone"}
                                htmlFor={"eventPhone"}
                                onChange={handleInput}
                              >
                                <Input
                                  maxLength={10}
                                  onKeyPress={(e) => {
                                    if (!/[0-9]/.test(e.key))
                                      e.preventDefault();
                                  }}
                                  placeholder={"Ejem: 00-00-00-00-00"}
                                  name={"eventPhone"}
                                  size={"large"}
                                />
                              </Form.Item>
                            </Col>
                            <Col xl={8} lg={8} md={12} sm={24} xs={24}>
                              <Form.Item
                                label={"Estado"}
                                name={"eventState"}
                                htmlFor={"eventState"}
                                onChange={handleInput}
                                rules={[
                                  {
                                    required: true,
                                    message: "*Campo requerido",
                                  },
                                ]}
                              >
                                <Input
                                  onKeyPress={(e) => {
                                    if (
                                      !/^[ a-zA-ZÀ-ÿ\u00f1\u00d1]*$/g.test(
                                        e.key
                                      )
                                    )
                                      e.preventDefault();
                                  }}
                                  placeholder={"Ejem: Estado"}
                                  name={"eventState"}
                                  size={"large"}
                                  count={{
                                    show: true,
                                    max: 40,
                                  }}
                                  maxLength={40}
                                />
                              </Form.Item>
                            </Col>
                            <Col xl={8} lg={8} md={12} sm={24} xs={24}>
                              <Form.Item
                                label={"Municipio/Delegación"}
                                name={"eventCity"}
                                htmlFor={"eventCity"}
                                onChange={handleInput}
                                rules={[
                                  {
                                    required: true,
                                    message: "*Campo requerido",
                                  },
                                ]}
                              >
                                <Input
                                  onKeyPress={(e) => {
                                    if (
                                      !/^[A-Za-z0-9 _]*[A-Za-z0-9 _][A-Za-z0-9 _]*$/g.test(
                                        e.key
                                      )
                                    )
                                      e.preventDefault();
                                  }}
                                  placeholder={"Ejem: Ciudad"}
                                  name={"eventCity"}
                                  size={"large"}
                                  count={{
                                    show: true,
                                    max: 40,
                                  }}
                                  maxLength={40}
                                />
                              </Form.Item>
                            </Col>
                            <Col xl={8} lg={8} md={12} sm={24} xs={24}>
                              <Form.Item
                                label={"Colonia"}
                                name={"eventNeighboor"}
                                htmlFor={"eventNeighboor"}
                                onChange={handleInput}
                                rules={[
                                  {
                                    required: true,
                                    message: "*Campo requerido",
                                  },
                                ]}
                              >
                                <Input
                                  onKeyPress={(e) => {
                                    if (
                                      !/^[A-Za-z0-9 _]*[A-Za-z0-9 _][A-Za-z0-9 _]*$/g.test(
                                        e.key
                                      )
                                    )
                                      e.preventDefault();
                                  }}
                                  placeholder={"Ejem: Colonia"}
                                  name={"eventNeighboor"}
                                  size={"large"}
                                  count={{
                                    show: true,
                                    max: 40,
                                  }}
                                  maxLength={40}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24}>
                              <h5>
                                Informe a los asistentes cuando presentarse para
                                que aparten esta fecha.
                              </h5>
                              <Row gutter={16}>
                                <Col xl={9} lg={8} md={12} sm={24} xs={24}>
                                  <Form.Item
                                    label={"¿Cuándo será tu evento?"}
                                    name={"eventDate"}
                                    htmlFor={"eventDate"}
                                    rules={[
                                      {
                                        required: true,
                                        message: "*Campo requerido",
                                      },
                                    ]}
                                  >
                                    <DatePicker
                                      onChange={(_d, date) =>
                                        handleDate(_d, date, "eventDate")
                                      }
                                      format={"DD/MM/YYYY"}
                                      name={"eventDate"}
                                      placeholder={"Seleccionar fecha"}
                                      size={"large"}
                                      showToday={false}
                                      disabledDate={disabledDateStart}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col xl={9} lg={8} md={12} sm={24} xs={24}>
                                  <Form.Item
                                    label={"Horario de tu evento"}
                                    name={"eventHour"}
                                    htmlFor={"eventHour"}
                                    onChange={handleInput}
                                    rules={[
                                      {
                                        required: true,
                                        message: "*Campo requerido",
                                      },
                                    ]}
                                  >
                                    <TimePicker
                                      onChange={(_t, time) =>
                                        handleTime(_t, time, "eventHour")
                                      }
                                      name={"eventHour"}
                                      format={"HH:mm"}
                                      placeholder={"Seleccionar hora"}
                                      minuteStep={10}
                                      showNow={false}
                                      size={"large"}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Col>
                            <Col xs={24}>
                              <h5>
                                Asigna fecha y horarios de venta de boletos
                              </h5>
                              <Row gutter={16}>
                                <Col xl={9} lg={8} md={12} sm={24} xs={24}>
                                  <Form.Item
                                    label={"Fecha de inicio"}
                                    name={"eventTicketDateStart"}
                                    htmlFor={"eventTicketDateStart"}
                                    rules={[
                                      {
                                        required: true,
                                        message: "*Campo requerido",
                                      },
                                    ]}
                                  >
                                    <DatePicker
                                      onChange={(_d, date) =>
                                        handleDate(
                                          _d,
                                          date,
                                          "eventTicketDateStart"
                                        )
                                      }
                                      format={"DD/MM/YYYY"}
                                      name={"eventTicketDateStart"}
                                      placeholder={"Inicio"}
                                      size={"large"}
                                      showToday={false}
                                      disabledDate={disabledDateStart}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col xl={9} lg={8} md={12} sm={24} xs={24}>
                                  <Form.Item
                                    label={"Fecha de finalización"}
                                    name={"eventTicketDateEnd"}
                                    htmlFor={"eventTicketDateEnd"}
                                    rules={[
                                      {
                                        required: true,
                                        message: "*Campo requerido",
                                      },
                                    ]}
                                  >
                                    <DatePicker
                                      onChange={(_d, date) =>
                                        handleDate(
                                          _d,
                                          date,
                                          "eventTicketDateEnd"
                                        )
                                      }
                                      format={"DD/MM/YYYY"}
                                      name={"eventTicketDateEnd"}
                                      placeholder={"Finalización"}
                                      size={"large"}
                                      showToday={false}
                                      disabled={
                                        eventTicket.eventTicketDateStart === ""
                                      }
                                      disabledDate={disabledDateEnd}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col xl={9} lg={8} md={12} sm={24} xs={24}>
                                  <Form.Item
                                    label={"Horario de inicio y finalización"}
                                    name={"eventHourRange"}
                                    htmlFor={"eventHourRange"}
                                    onChange={handleInput}
                                    rules={[
                                      {
                                        required: true,
                                        message: "*Campo requerido",
                                      },
                                    ]}
                                  >
                                    <TimePicker.RangePicker
                                      onChange={handleTimeRange}
                                      name={"eventHourRange"}
                                      format={"HH:mm"}
                                      minuteStep={10}
                                      showNow={false}
                                      placeholder={["Inicio", "Finalización"]}
                                      size={"large"}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col xl={9} lg={8} md={12} sm={24} xs={24}>
                                  <Form.Item
                                    label={"Duración de tu evento (minutos)"}
                                    name={"eventTime"}
                                    htmlFor={"eventTime"}
                                    onChange={handleEventTime}
                                    rules={[
                                      {
                                        required: true,
                                        message: "*Campo requerido",
                                      },
                                      {
                                        pattern: new RegExp(/^[0-9]*$/g),
                                        message: "Sólo se permiten números",
                                      },
                                    ]}
                                  >
                                    <Input
                                      onKeyPress={(e) => {
                                        if (!/[0-9]/.test(e.key))
                                          e.preventDefault();
                                      }}
                                      maxLength={3}
                                      prefix={<FaClock />}
                                      type={"text"}
                                      name={"eventTime"}
                                      size={"large"}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        ),
                        2: (
                          <Row gutter={16} style={{ margin: "0" }}>
                            <Col xl={15} lg={16} md={12} sm={24} xs={24}>
                              <Form.Item
                                label={"Nombre completo del organizador"}
                                name={"eventOwnerName"}
                                htmlFor={"eventOwnerName"}
                                onChange={handleInput}
                                rules={[
                                  {
                                    required: true,
                                    message: "*Campo requerido",
                                  },
                                  {
                                    pattern: /^[ a-zA-ZÀ-ÿ\u00f1\u00d1]*$/g,
                                    message: "Sólo se permiten letras",
                                  },
                                ]}
                              >
                                <Input
                                  onKeyPress={(e) => {
                                    if (
                                      !/^[ a-zA-ZÀ-ÿ\u00f1\u00d1]*$/g.test(
                                        e.key
                                      )
                                    )
                                      e.preventDefault();
                                  }}
                                  placeholder={"Ejem: Nombre Apellido..."}
                                  name={"eventOwnerName"}
                                  size={"large"}
                                  count={{
                                    show: true,
                                    max: 70,
                                  }}
                                  maxLength={70}
                                />
                              </Form.Item>
                            </Col>
                            <Col xl={9} lg={8} md={12} sm={24} xs={24}>
                              <Form.Item
                                label={"Teléfono"}
                                name={"eventOwnerPhone"}
                                htmlFor={"eventOwnerPhone"}
                                onChange={handleInput}
                                rules={[
                                  {
                                    required: true,
                                    message: "*Campo requerido",
                                  },
                                  {
                                    pattern: /^(?:\d*)$/,
                                    message: "Sólo se permiten números",
                                  },
                                  {
                                    min: 10,
                                    message: "Se necesitan 10 números",
                                  },
                                ]}
                              >
                                <Input
                                  onKeyPress={(e) => {
                                    if (!/[0-9]/.test(e.key))
                                      e.preventDefault();
                                  }}
                                  maxLength={10}
                                  placeholder={"Ejem: 00-00-00-00-00"}
                                  name={"eventOwnerPhone"}
                                  size={"large"}
                                />
                              </Form.Item>
                            </Col>
                            <Col xl={15} lg={16} md={12} sm={24} xs={24}>
                              <Form.Item
                                label={"Correo electrónico"}
                                name={"eventOwnerEmail"}
                                htmlFor={"eventOwnerEmail"}
                                onChange={handleInput}
                                rules={[
                                  {
                                    required: true,
                                    type: "email",
                                    message:
                                      "*Se necesita un formato de correo",
                                  },
                                ]}
                              >
                                <Input
                                  placeholder={"Ejem: nombre@dominio"}
                                  name={"eventOwnerEmail"}
                                  size={"large"}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        ),
                        3: (
                          <Row gutter={16} style={{ margin: "0" }}>
                            <Col xl={15} lg={16} md={12} sm={24} xs={24}>
                              <Form.Item
                                label={"Nombre del titular"}
                                name={"eventOwnerBankName"}
                                htmlFor={"eventOwnerBankName"}
                                onChange={handleInput}
                                rules={[
                                  {
                                    required: true,
                                    message: "*Campo requerido",
                                  },
                                  {
                                    pattern: /^[ a-zA-ZÀ-ÿ\u00f1\u00d1]*$/g,
                                    message: "Sólo se permiten letras",
                                  },
                                ]}
                              >
                                <Input
                                  onKeyPress={(e) => {
                                    if (
                                      !/^[ a-zA-ZÀ-ÿ\u00f1\u00d1]*$/g.test(
                                        e.key
                                      )
                                    )
                                      e.preventDefault();
                                  }}
                                  placeholder={"Ejem: Nombre Apellido..."}
                                  name={"eventOwnerBankName"}
                                  size={"large"}
                                  count={{
                                    show: true,
                                    max: 70,
                                  }}
                                  maxLength={70}
                                />
                              </Form.Item>
                            </Col>
                            <Col xl={9} lg={8} md={12} sm={24} xs={24}>
                              <Form.Item
                                label={"Banco"}
                                name={"eventOwnerBank"}
                                htmlFor={"eventOwnerBank"}
                                onChange={handleInput}
                                rules={[
                                  {
                                    required: true,
                                    message: "*Campo requerido",
                                  },
                                  {
                                    pattern: /^[ a-zA-ZÀ-ÿ\u00f1\u00d1]*$/g,
                                    message: "Sólo se permiten letras",
                                  },
                                ]}
                              >
                                <Input
                                  onKeyPress={(e) => {
                                    if (
                                      !/^[ a-zA-ZÀ-ÿ\u00f1\u00d1]*$/g.test(
                                        e.key
                                      )
                                    )
                                      e.preventDefault();
                                  }}
                                  placeholder={"Ejem: Nombre del banco"}
                                  name={"eventOwnerBank"}
                                  size={"large"}
                                  maxLength={40}
                                  count={{
                                    show: true,
                                    max: 40,
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col xl={15} lg={16} md={12} sm={24} xs={24}>
                              <Form.Item
                                label={"Clave interbancaria"}
                                name={"eventOwnerClabe"}
                                htmlFor={"eventOwnerClabe"}
                                onChange={handleInput}
                                rules={[
                                  {
                                    required: true,
                                    message: "*Campo requerido",
                                  },
                                  {
                                    pattern: new RegExp(/^[0-9]*$/g),
                                    message: "Sólo se permiten números",
                                  },
                                  {
                                    min: 18,
                                    message: "Se necesitan 18 dígitos",
                                  },
                                ]}
                              >
                                <Input
                                  onKeyPress={(e) => {
                                    if (!/[0-9]/.test(e.key))
                                      e.preventDefault();
                                  }}
                                  maxLength={18}
                                  placeholder={"Ejem: 098713207860784591"}
                                  name={"eventOwnerClabe"}
                                  size={"large"}
                                />
                              </Form.Item>
                            </Col>
                            <Col xl={9} lg={8} md={12} sm={24} xs={24}>
                              <Form.Item label={"Carátula de Estado de cuenta"}>
                                <InputFile
                                  onFilesSelected={(f) =>
                                    handleFile("eventOwnerFileBankAccount", f)
                                  }
                                  deleteImage={() =>
                                    handleFileRemove(
                                      "eventOwnerFileBankAccount"
                                    )
                                  }
                                  edit={
                                    eventTicket.eventOwnerFileBankAccount
                                      ?.length > 0 ?? false
                                  }
                                  valueFile={
                                    eventTicket.eventOwnerFileBankAccount
                                  }
                                  width={1280}
                                  height={1980}
                                  accept={["image"]}
                                  nameFile={"eventOwnerFileBankAccount"}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24}>
                              <Form.Item
                                label={"Facturación"}
                                name={"eventOwnerInvoice"}
                                htmlFor={"eventOwnerInvoice"}
                              >
                                <Checkbox
                                  onChange={onChangeCheckedInvoice}
                                  checked={eventTicket.eventOwnerInvoice}
                                >
                                  ¿Necesitas facturar?
                                </Checkbox>
                              </Form.Item>
                            </Col>
                            {eventTicket.eventOwnerInvoice ? (
                              <>
                                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                  <Form.Item
                                    label={"Razón social"}
                                    name={"eventOwnerCompanyReason"}
                                    htmlFor={"eventOwnerCompanyReason"}
                                    onChange={handleInput}
                                    rules={[
                                      {
                                        required: true,
                                        message: "*Campo requerido",
                                      },
                                    ]}
                                  >
                                    <Input
                                      onKeyPress={(e) => {
                                        if (
                                          !/^[ a-zA-ZÀ-ÿ\u00f1\u00d1]*$/g.test(
                                            e.key
                                          )
                                        )
                                          e.preventDefault();
                                      }}
                                      placeholder={"Ejem: S.A...."}
                                      name={"eventOwnerCompanyReason"}
                                      size={"large"}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                  <Form.Item
                                    label={"RFC"}
                                    name={"eventOwnerRFC"}
                                    htmlFor={"eventOwnerRFC"}
                                    onChange={handleInput}
                                    rules={[
                                      {
                                        required: true,
                                        message: "*Campo requerido",
                                      },
                                      {
                                        pattern:
                                          /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/g,
                                        message:
                                          "El formato de R.F.C. es erróneo",
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder={"Ejem: JDME990523F78"}
                                      name={"eventOwnerRFC"}
                                      size={"large"}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                  <Form.Item
                                    label={"Constancia situación fiscal"}
                                  >
                                    <InputFile
                                      onFilesSelected={(f) =>
                                        handleFile("eventOwnerFileConstancy", f)
                                      }
                                      deleteImage={() =>
                                        handleFileRemove(
                                          "eventOwnerFileConstancy"
                                        )
                                      }
                                      edit={
                                        eventTicket.eventOwnerFileConstancy
                                          ?.length > 0 ?? false
                                      }
                                      valueFile={
                                        eventTicket.eventOwnerFileConstancy
                                      }
                                      accept={["pdf"]}
                                      nameFile={"eventOwnerFileConstancy"}
                                    />
                                  </Form.Item>
                                </Col>
                              </>
                            ) : null}
                          </Row>
                        ),
                      }[currentStep]
                    }
                    <Col xs={24}>
                      <div className={"event-pride-ticket-form-footer"}>
                        {currentStep > 0 ? (
                          <>
                            <Button
                              onClick={handleBackStep}
                              className="btn-pride-ticket back"
                            >
                              <FaChevronLeft /> Regresar
                            </Button>
                          </>
                        ) : (
                          <div></div>
                        )}
                        <Button
                          type={"primary"}
                          htmlType={"submit"}
                          className={"btn-pride-ticket"}
                        >
                          {currentStep === 3 ? "Guardar" : "Siguiente"}
                          <FaChevronRight />
                        </Button>
                      </div>
                    </Col>
                  </Form>
                </Col>
                <Col xs={24}>
                  <FooterHome />
                </Col>
              </Row>
            </Col>
          </>
        )}
      </Row>
    </section>
  );
};
