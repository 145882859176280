import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import "./jobBank.scss";
import Moment from "react-moment";
import {BsArrowRight} from "react-icons/bs";
import {Button} from "antd";

export function ItemJobPublished({ job, misEmpl, deleteJob, activateJob , jobPublished, key}) {
  const [isAdmin, setIsAdmin] = useState(false);
  const roleName = useSelector((state) => state.userSettings.roleName);
  
  let publicationDate = new Date(job.publicationDate);
  let mxnPesos = new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
});
  useEffect(() => {
    // setIsAdmin(()=> {return roleName == "Recursos Humanos"})
    if (roleName === "Recursos Humanos") {
      setIsAdmin(true);
    }
  }, [roleName]);

  return (
    <article className={`job-bank-card ${jobPublished}`} key={key}>
        <div className="job-bank-card-header">
          <div className="thumb">
            <img src={job.companyImage} alt={"Logo empresa " + job.companyName} />
          </div>
          <div className="info">
            <h5>
              {job.title
                  ? job.title.length <= 35
                      ? job.title
                      : job.title.substring(0, 35) + "..." : ""}
            </h5>
            <div className="content">
              <p className="location"><i className="icon-location"></i> {job.location}</p>
              <p className="company"><i className="icon-hotel"></i> {job.companyName}</p>
            </div>
            <div className="date">
              <p><Moment locale="es" format="DD [de] MMMM YYYY">{job.publicationDate}</Moment></p>
            </div>
          </div>
        </div>
        <div className="job-bank-card-footer">
          <div className="price">
            <p>{ mxnPesos.format( job.salary)}</p>
          </div>
          <div className="btns">
            {isAdmin === false &&
              <Link to={"/details-job/" + job.jobId} className="btn-outline-green">
                Aplicar <span><BsArrowRight /></span>
              </Link>}
            {(job.createUser === localStorage.getItem("userId") || isAdmin === true) &&
                <Link to={"/candidates/" + job.jobId} className="btn-outline-green">
                  Ver candidatos
                  <span><BsArrowRight /></span>
                </Link>}
            {misEmpl && job.active &&
              <Link to={"/edit-job/" + job.jobId} className="btn-outline-green">
                Editar <span><BsArrowRight /></span>
              </Link>
            }
            {misEmpl && job.active &&
              <Button onClick={() => deleteJob(job.jobId)} className="btn-outline-green">
                Dar de baja <span><BsArrowRight /></span>
              </Button>
            }
            {misEmpl && !job.active &&
              <Button onClick={() => activateJob(job.jobId)} className="btn-outline-green">
                Reactivar <span><BsArrowRight /></span>
              </Button>
            }
          </div>
        </div>
      </article>
  );
}
