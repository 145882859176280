import React, { useEffect, useRef, useState } from "react";
import { useAnswerConfig } from "../../services/surveyAnswerConfig.service";
import { FaEdit, FaTrash } from "react-icons/fa";
import { Col, Input, Space, Button, Table, Tag } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
const MySwal = withReactContent(Swal);

export function SearchAnswer(props) {
  const [answerData, setAnswersData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleDelete = (data) => {
    MySwal.fire({
      title: "¿Estás seguro?",
      text: "No podrás revertir esta acción",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#FF002A",
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed)
        useAnswerConfig.deleteAnswerConfig(data).then((result) => getAnswers());
    });
  };

  const handleSelectAnswer = (data) => {
    if (props.onSelectAnswer) props.onSelectAnswer(data);
  };

  async function getAnswers() {
    useAnswerConfig
      .getAnswerConfigs(props.surveyQuestionId)
      .then((result) => setAnswersData(result));
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Buscar`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Limpiar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columnsAnswers = [
    {
      title: "Orden",
      key: "order",
      dataIndex: "order",
      fixed: "left",
      width: 5,
      sorter: (a, b) => {
        if (a.order < b.order) {
          return -1;
        }
        if (a.order > b.order) {
          return 1;
        }
        return 0;
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Respuesta",
      key: "answer",
      dataIndex: "answer",
      width: 300,
      sorter: (a, b) => {
        if (a.answer < b.answer) {
          return -1;
        }
        if (a.answer > b.answer) {
          return 1;
        }
        return 0;
      },
      sortDirections: ["descend", "ascend"],
      ...getColumnSearchProps("answer"),
    },
    {
      title: "Estatus",
      key: "status",
      dataIndex: "status",
      width: 80,
      sorter: (a, b) => {
        if (a.status < b.status) {
          return -1;
        }
        if (a.status > b.status) {
          return 1;
        }
        return 0;
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Opciones",
      key: "options",
      dataIndex: "options",
      width: 90,
    },
  ];

  const dataAnswers =
    answerData?.map((item) => {
      return {
        key: item.surveyAnswerConfigId,
        order: item.answerOrder,
        answer: item.answer,
        status: (
          <Tag color={item.estatus === "Activa" ? "success" : "blue"}>
            {item.estatus}
          </Tag>
        ),
        options: (
          <Space wrap>
            {!props.isSurveyAlreadyStarted && (
              <Button
                type="primary"
                htmlType={"button"}
                className="edit"
                onClick={() => handleSelectAnswer(item)}
              >
                <FaEdit />
              </Button>
            )}
            <Button
              type="primary"
              htmlType={"button"}
              className="delete"
              onClick={() => handleDelete(item)}
            >
              <FaTrash />
            </Button>
          </Space>
        ),
      };
    }) ?? [];

  useEffect(() => {
    if (props.surveyQuestionId !== "") getAnswers();
  }, [props.refresh]);

  return (
    <div className="wpr_quest_search">
      <Table
        className={"table-admin"}
        columns={columnsAnswers}
        dataSource={dataAnswers}
        locale={{
          triggerDesc: "Ordenamiento descendente",
          triggerAsc: "Ordenamiento ascedente",
          cancelSort: "Cancelar ordenamiento",
          emptyText: "Sin registros",
        }}
      />
    </div>
  );
}
