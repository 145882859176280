import React, { useEffect, useState } from 'react';
import { rewardCategoryService } from '../../services/control/rewardCategory.service';
import {useParams} from 'react-router-dom';
import {Form, Input,Col, Row,Button} from "antd";
import { FaQuestion} from "react-icons/fa";
import { IoBusiness} from "react-icons/io5";
import {AiFillDatabase} from "react-icons/ai";

export function EditRewardCategory() {

    const {id} = useParams();
    const onSave = (data) => {
        if(reward.rewardCategoryId != 0) rewardCategoryService.edit(reward);
        if(reward.rewardCategoryId == 0) rewardCategoryService.create(reward);
    };

    const [reward, setReward] = useState({});

    useEffect(() => {
        setReward({
            rewardCategoryId: 0,
            description: "",
            urlIcon: "",
            active: true
        })
    }, [id])

    useEffect(() => {
        if (id != 0) {
            rewardCategoryService.getById(id).then((result) => {
                setReward({
                    rewardCategoryId: result.rewardCategoryId,
                    description: result.description,
                    urlIcon: result.urlIcon,
                    active: result.active
                });
            });
        }
    }, []);

    const handleInputChange = (e) => {
        setReward({
            ...reward,
            [e.target.name]: e.target.value
        })
    }

    return (
        <>
            <Form onFinish={onSave}
                  className="form-pride"
                  layout="vertical"
                  autoComplete="off"
                  fields={[
                      {
                          "name": ["description"],
                          "value": reward.description
                      },
                      {
                          "name": ["urlIcon"],
                          "value": reward.urlIcon
                      },
                  ]}
            >
                <Row gutter={16}>
                    <Col md={12} sm={24} xs={24}>
                        <Form.Item
                            label="Descripción"
                            name="description"
                        >
                            <Input
                                prefix={<AiFillDatabase className="site-form-item-icon icon-gif" />}
                                size="large"
                                name="description"
                                onChange={handleInputChange}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={12} sm={24} xs={24}>
                        <Form.Item
                           label="Ícono"
                           name="urlIcon"
                        >
                            <Input
                                prefix={<FaQuestion className="site-form-item-icon icon-gif" />}
                                size="large"
                                name="urlIcon"
                                onChange={handleInputChange}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <div className="flex-end">
                            <Button type="primary" htmlType="submit">
                                Guardar
                                <i className="icon-save"></i>
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </>
    );
}   
