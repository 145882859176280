import React from "react";
import Moment from "react-moment";
import "moment/locale/es";

export function ItemConversation(props) {
  return (
    <div className={`box-item ${props.source}`}>
      <div className="box-item-conversation">
        <div className="box-item-conversation-content">
          <div className="info-mnj">
            <span className="info-mnj-author">{props.author}</span>
            <span className="info-mnj-check">
              {props.source === "out" ? (
                <span className="check-message">
                  {!props.checkMnj ? (
                    <ion-icon name="chatbubble-outline"></ion-icon>
                  ) : (
                    <ion-icon name="chatbubble"></ion-icon>
                  )}
                </span>
              ) : (
                ""
              )}
            </span>
          </div>
          <p className="message">{props.chatMnj}</p>
        </div>
        <div className="datatime-mnj">
          <Moment format="ddd D/MM/YY hh:mm a">{props.dateTime}</Moment>
        </div>
      </div>
    </div>
  );
}
