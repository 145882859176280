import React from "react";
import { Col, Empty, Row } from "antd";
import { CardAudience } from "./cardAudience";
import dayjs from "dayjs";

export function ListItems(props) {
  const now = dayjs();

  return (
    <Row gutter={[16, 16]}>
      {props.items.map((item, i) => (
        <Col xl={6} lg={8} md={8} sm={12} xs={24} key={i}>
          <CardAudience
            item={item}
            publishedItems={false}
            onLive={
              now < dayjs(item.data.endDate) &&
              now > dayjs(item.data.conferenceDate)
            }
            publishedDate={true}
            handleLinkConference={props.handleLinkConference}
          />
        </Col>
      ))}
      {props.items.length === 0 && (
        <Col xs={24}>
          <Empty description={"No hay eventos disponibles"} />
        </Col>
      )}
    </Row>
  );
}
