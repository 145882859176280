import React, { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "./carouselAsideHome.scss";
// import data from "../../assets/jsons/interestGroup.json";
import { useGenericSection } from "../../services/genericSection.service";

export function CarouselAsideHome() {
  const [data, setData] = useState([]);
  const [disabledHasMore, setDisabledHasMore] = useState(false);
  const loadData = (id) => {
    useGenericSection.getGenericItemByCompanyAndSection(2, id, 9).then(items => {
      if (items.length > 0) {
        const newItems = [...data, ...items];
        setData(newItems);
      } else {
        setDisabledHasMore(true);
      }
    });
  }
  useEffect(() => {
    loadData();
  }, []);
  return (
    <div className="wrapper_muro_groupint">
      <h3 className="ttls_in_muro">
        <Link to="/interest-group">Grupos de interés</Link>
      </h3>
      <div>
        {data.map((item, index) => {
          
          if (index < 4) {
            return (
              <article key={item.id} className="item_muro_groupint">
                <div className="muro_groupint_picture">
                  <img src={item.imageUrl} alt="" />
                </div>
                <p className="muro_groupint_info">{item.title}</p>
              </article>
            );
          }
        })}
      </div>
      <p className="link_more_go"><Link to="/interest-group">Ver más</Link> </p>
    </div>
  );
}
