import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import "moment/locale/es";
import { chatService } from "./../../services/chat.service";
import { Searches } from "./searches";
import { TtlsChats } from "./ttlsChats";
import { AvatarChats } from "./avatarChats";

export function ListChats({ showChat, chatNotification, setNotifications }) {
  const [chats, setChats] = useState([]);
  const [chatsSearch, setChatsSearch] = useState([]);

  useEffect(() => {
    chatService.getChats().then((result) => {
      setChats(result);
      setChatsSearch(result);
      const unread = result.filter((item) => item.unread);
      setNotifications(unread.map((i) => i.contactId));
    });
  }, []);

  useEffect(() => {
    if (Object.keys(chatNotification).length > 0) {
      const filteredChat = chats.find(
        (item) => item.contactId == chatNotification.contactId
      );
      if (filteredChat) {
        chatNotification.name = filteredChat.name;
        chatNotification.unread += filteredChat.unread;
        chatNotification.image = filteredChat.image;
      }

      const filteredChats = chats.filter(
        (item) => item.contactId != chatNotification.contactId
      );
      if (chatNotification.unread) filteredChats.unshift(chatNotification);

      setChats(filteredChats);
      setChatsSearch(filteredChats);
    }
  }, [chatNotification]);

  const handleSearch = (text) => {
    if (text) {
      text = text.removeAccents().toUpperCase();
      let chatFiltered = chats.filter((item) =>
        item.name.removeAccents().toUpperCase().includes(text)
      );
      if (chatFiltered.length > 0) {
        let contactsFiltered = Object.assign([], chatFiltered);
        setChatsSearch(contactsFiltered);
        return;
      }

      setChatsSearch([]);
    } else setChatsSearch(chats);
  };

  return (
    <div className="list-chats-container active">
      <TtlsChats title="Chats" />
      <Searches placeHolder="Buscar chat" handleSearch={handleSearch} />
      <TtlsChats title="Recientes" />
      {chatsSearch.map((item) => (
        <div
          className={`list-chats-container-item ${item.unread && "new-mnj"}`}
          key={item.contactId}
          onClick={() => showChat(item)}
          title={item.name}
        >
          <AvatarChats img={item.image} titleImg={item.name} flagClass={item.identityFlagName}/>
          <div className="info-chat">
            <div className="name">{item.name}</div>
            <div className="time">
              <Moment format="hh:mm a">{item.lastMessageDate}</Moment>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
