import React, {useState, useEffect} from "react";

import "./users.scss";
import "../admin/admin.scss";
import { userProfileService } from "../../services/backoffice/userProfile.service";
import moment from "moment";
import {useHistory} from "react-router-dom";
import {FaEdit} from "react-icons/fa";
import {Button, Col, message, Row, Space, Table, Pagination, Divider, Tag} from "antd";
import {TitleAdmin} from "../components/titleAdmin/titleAdmin";
import {HeadingAdmin} from "../components/headingAdmin/headingAdmin";
import Search from "antd/es/input/Search";

export const Users = () => {
  const [disablePagination, setDisablePagination] = useState(false);
    const [tableData, setTableData] = useState({
    list: [],
    totalPages: 1,
    totalCount: 0,
    pageIndex: 1,
    filterBy: "",
    pageSize: 20,
    filter: "",
    orderBy: "EmployeeNo",
    ascending: false,
  });
  const history = useHistory();

  const onSearch = (value, _e, info) => {
    setTableData({
      ...tableData,
      filter: value === "" ? '' : value,
      filterBy: value === "" ? '' : "Name",
      pageIndex: 1,
    })
  }

  const getUsers = () => {
    userProfileService
        .getAll(tableData.pageIndex, tableData.pageSize, tableData.filterBy, tableData.filter, tableData.orderBy, tableData.ascending)
        .then((result) => {
          if (result.list.length > 0){
            setTableData({
              ...tableData,
              list: result.list,
              totalPages: result.totalPages,
              totalCount: result.totalCount
            });
            setDisablePagination(false)
          }
        });
  };

  const handleSelectPage = (page, pageSize) => {
    setDisablePagination(true)
    setTableData({...tableData,
      pageIndex: page,
      pageSize: pageSize
    })
  };

  const goUserDetail = (id) => history.push( "/admin/users-details/"+id);

  const handleMenuClick = (e) => {
    let msg = '';
    if(e.key === '1') msg = 'Carga individual de usuario';
    else msg = 'Carga masiva de usuarios'
    return message.success(msg);
  };

  const items = [
    {
      label: <Button onClick={() => history.push('/admin/users-details/new')}>Carga individual</Button>,
      key: '1',
    },
    {
      label: <Button onClick={() => history.push('/admin/bulk-load')}>Carga Masiva</Button>,
      key: '2',
    },
  ];

  const menuProps = {
    items,
    onClick: (e) => handleMenuClick(e),
  };

  const columns = [
    {
      title: 'No. Usuario',
      key: 'id',
      dataIndex: 'id',
      fixed: 'left',
      width: 120,
    },
    {
      title: 'Nombre completo',
      key: 'fullname',
      dataIndex: 'fullname',
      width: 100,
    },
    {
      title: 'Género',
      key: 'gender',
      dataIndex: 'gender',
      width: 100,
    },
    {
      title: 'Edad',
      key: 'age',
      dataIndex: 'age',
      width: 100,
    },
    {
      title: 'Antigüedad',
      key: 'old',
      dataIndex: 'old',
      width: 100,
    },
    {
      title: 'Sucursal',
      key: 'branchOffice',
      dataIndex: 'branchOffice',
      width: 100,
    },
    {
      title: 'Grupo',
      key: 'group',
      dataIndex: 'group',
      width: 100,
    },
    {
      title: 'Opciones',
      key: 'options',
      dataIndex: 'options',
      width: 90,
    },
  ];

  const data = tableData.list?.map(item => {
    return (
        {
          id: item.employeeNo,
          fullname: item.name,
          gender:  item.gender,
          age: moment().diff(item.birthday, "years"),
          old: item.seniority,
          branchOffice: item.branchOfficeName,
          group: item.jobRoleName,
          options: <Space wrap>
            <Button type="primary" htmlType={'button'} className="edit" onClick={() => goUserDetail(item.aspNetUserId)}>
              <FaEdit />
            </Button>
          </Space>
        }
    );
  }) ?? [];

  useEffect(() => {
    getUsers()
  }, []);

  useEffect(() => {
    getUsers()
  }, [tableData.pageIndex, tableData.pageSize, tableData.filter]);

  return (
      <section className="dashboard-admin-container">
        <TitleAdmin
            title={'Usuarios'}
            subtitle={'Lista de usuarios'}
            event={() => history.push('/admin')}
        />
        <Row gutter={16}>
          <Col xs={24}>
            <HeadingAdmin
                btnAdd={false}
                isDropdown={true}
                menuDropdown={menuProps}
                btnDropdownName={`Cargar usuarios`}
            />
          </Col>
          <Col xl={12} md={12} sm={24} xs={24}>
            <Search
                placeholder="Buscar usuarios"
                allowClear
                size={'large'}
                enterButton
                onSearch={onSearch}
            />
          </Col>
          <Col xl={12} md={12} sm={24} xs={24}>
            <Divider dashed={true} orientation={'right'}>
              <Tag color={'default'}>
                <Space>
                  <p style={{fontSize: '1.1rem'}}>Total de usuarios:</p>
                  <p style={{fontSize: '1.1rem'}}><strong>{tableData.totalCount}</strong></p>
                </Space>
              </Tag>
            </Divider>
          </Col>
          <Col xs={24}>
            <Table
              className={'table-admin'}
              columns={columns}
              dataSource={data}
              pagination={false}
              locale={{
                triggerDesc: 'Ordenamiento descendente',
                triggerAsc: 'Ordenamiento ascedente',
                cancelSort: 'Cancelar ordenamiento'
              }}
              scroll={{ x: '992px', y: 800 }}
            />
            <div className="table-admin-footer">
              <Pagination
                  disabled={disablePagination}
                  onChange={handleSelectPage}
                  current={tableData.pageIndex}
                  defaultCurrent={tableData.pageIndex}
                  total={tableData.totalCount}
                  pageSize={tableData.pageSize}
                  responsive={true}
              />
            </div>
          </Col>
        </Row>
      </section>
  );
}
