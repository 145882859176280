import React, { useEffect, useState, useRef } from "react";
import { chatService } from "./../../services/chat.service";
import { TtlsChats } from "./ttlsChats";
import { AvatarChats } from "./avatarChats";
import { ItemConversation } from "./itemConversation";
import InfiniteScroll from "react-infinite-scroll-component";
import {BsChevronLeft} from "react-icons/bs";
import { FaEllipsisV, FaPaperPlane, FaTimesCircle} from "react-icons/fa";

import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";

export function ChatConversations({
  currentChat,
  newMessageReceived,
  notification,
  toggle,
  openChat,
}) {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [dateTime, setDateTime] = useState(null);
  const [disabledHasMore, setDisabledHasMore] = useState(false);
  const [blockedChat, setBlockedChat] = useState({});
  const inputElementRef = useRef();
  const buttonRef = useRef();

  useEffect(() => {
    if (Object.keys(currentChat).length > 0) {
      chatService.getMessages(currentChat.contactId).then((result) => {
        setMessages(result);
        if (result.length > 0) {
          setDateTime(result.at(-1).date);
          chatService.updateRead(currentChat.contactId);
        } else setDisabledHasMore(true);
      });
    }
  }, [currentChat]);

  useEffect(() => {
    const msgExist = messages.some(item => item.messageId === newMessageReceived.messageId);

    if (Object.keys(newMessageReceived).length > 0 && openChat && !msgExist ) {
      setMessages([newMessageReceived, ...messages]);
      if (!newMessageReceived.isSender)
        chatService.updateRead(currentChat.contactId);
    }
  }, [newMessageReceived, openChat]);

  useEffect(() => {
    if (notification != "" && !notification.includes("blockedContact")) {
      let messagesUnread = messages.map((m) => {
        if (m.messageId == notification) m.status = "Read";
        return m;
      });
      setMessages(messagesUnread);
    } else contactStatus();
  }, [notification]);

  const contactStatus = () => {
    chatService.contactStatus(currentChat.contactId).then((result) => {
      setBlockedChat(result);
    });
  };

  const timelineRef = useRef();
  const onSubmit = (e) => {
    e.preventDefault();
    if (newMessage != "") {
      buttonRef.current.disabled = true;
      timelineRef.current.scrollTo(0, timelineRef.current.scrollHeight);
      const chatMessage = {
        message: newMessage,
        receiver: {
          id: currentChat.contactId,
          name: currentChat.name,
          image: currentChat.image,
          identityFlagName: currentChat.identityFlagName,
        },
      };
      chatService.sendMessage(chatMessage).then(() => {
        setNewMessage("");
        buttonRef.current.disabled = false;
        inputElementRef.current.focus();
      });
    }
  };

  const loadMore = () => {
    chatService.getMessages(currentChat.contactId, dateTime).then((result) => {
      if (result.length > 0) {
        setMessages([...messages, ...result]);
        setDateTime(result.at(-1).date);
      }
      setDisabledHasMore(true);
    });
  };

  const lockAndUnlock = async () => {
    if (blockedChat.byMe)
      await chatService.contactUnlocked(currentChat.contactId);
    else await chatService.blockedContact(currentChat.contactId);

    contactStatus();
  };

  const handleNewMessage = (event) => {
    buttonRef.current.disabled = !event.target.value;
    setNewMessage(event.target.value);
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleUserBlocked = () => setDropdownOpen((prevState) => !prevState);

  return (
    <div className="sections-chat active conversations-container">
      <div className="conversations-container-chats">
        <button type="button" className="button-back" onClick={() => toggle("1")}>
          <BsChevronLeft />
        </button>
        <AvatarChats img={currentChat.image} flagClass={currentChat.identityFlagName}/>
        <div className="info">
          <TtlsChats title={currentChat.name} />
          {blockedChat && blockedChat.byMe == true && (<p>Usuario bloqueado</p>)}
        </div>

        <Dropdown className="btn-group" isOpen={dropdownOpen} toggle={toggleUserBlocked} direction="top">
          <DropdownToggle><FaEllipsisV /></DropdownToggle>
          <DropdownMenu>
            <DropdownItem  onClick={lockAndUnlock}>
              {blockedChat && blockedChat.byMe == true ? (
                <p>Desbloquear usuario</p>
              ) : (
                <p>Bloquear usuario</p>
              )}
              <span><FaTimesCircle /></span>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
      <div className="conversations-container-content" id="scrollableDiv" ref={timelineRef}>
        <InfiniteScroll
          dataLength={messages.length}
          next={loadMore}
          style={{ display: "flex", flexDirection: "column-reverse" }}
          inverse={true}
          hasMore={true && !disabledHasMore}
          loader={<h4>Cargando...</h4>}
          scrollableTarget="scrollableDiv"
        >
          {messages.map((item) => {
            return item.isSender ? (
              <ItemConversation
                key={item.messageId}
                source="out"
                author="Tu."
                checkMnj={item.status === "Read"}
                dateTime={item.date}
                chatMnj={item.message}
              />
            ) : (
              <ItemConversation
                key={item.messageId}
                source="in"
                author={item.senderName}
                checkMnj={true}
                dateTime={item.date}
                chatMnj={item.message}
              />
            );
          })}
        </InfiniteScroll>
      </div>
      <div className="conversations-container-typing">
        {!currentChat.readOnly && blockedChat && blockedChat.locked == false && (
          <form onSubmit={onSubmit}>
            <div className="form-group text">
              <textarea
                className="form-control"
                id="message"
                placeholder="Escribe un mensaje aquí"
                value={newMessage}
                onChange={handleNewMessage}
                ref={inputElementRef}
              ></textarea>
              <button type="submit" className="btn" ref={buttonRef} disabled={true}>
                <FaPaperPlane />
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}
